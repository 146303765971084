export const UserConstants = {
    AUTH_USER: 'AUTH_USER',
    AUTH_USER_CHECKING: 'AUTH_USER_CHECKING',
    AUTH_USER_USING_TOKEN: 'AUTH_USER_USING_TOKEN',
    UNAUTH_USER: 'UNAUTH_USER',
    AUTH_ERROR: 'AUTH_ERROR',

    AUTH_USER_FORGOT_PASSWORD: 'AUTH_USER_FORGOT_PASSWORD',
    AUTH_USER_FORGOT_PASSWORD_ERROR: 'AUTH_USER_FORGOT_PASSWORD_ERROR',

    GET_USER_PROFILE: 'GET_USER_PROFILE',
    EDIT_USER_PROFILE: 'EDIT_USER_PROFILE',
    EDIT_USER_PROFILE_ERROR: 'EDIT_USER_PROFILE_ERROR',

    RESET_CODE_CHECKING: 'RESET_CODE_CHECKING',
    RESET_CODE_CHECKED: 'RESET_CODE_CHECKED',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',    

    AUTH_LOADING_STATUS: 'AUTH_LOADING_STATUS',

    BUDDY_LAST_LOCATION: 'BUDDY_LAST_LOCATION',
    SHOWCASE_LAST_LOCATION: 'SHOWCASE_LAST_LOCATION',

};
