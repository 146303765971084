import React, { useEffect, useContext } from 'react';


import { authUserSignOut } from '../../redux/user/user.actions';
import { connect } from 'react-redux';
import { SocketContext } from '../../socket';

function Logout(props) {
    
    const socket = useContext(SocketContext)

    useEffect(() => {
        socket.volatile.emit("UserLogout")
        props.authUserSignOut(props.role, props.id)
        // socket.disconnect()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            Goodbye buddy...
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        role: state.user.role,
        id: state.user.id
    }
};

export default (connect(mapStateToProps, { authUserSignOut })(Logout));

