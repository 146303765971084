
import { AllMethodConstants } from './method.constants';

export const method = (state = {}, action) => {
    switch (action.type) {
        case AllMethodConstants.EMPTY_METHOD:
           return { ...state, data: "" };
        case AllMethodConstants.GET_METHOD:
            return { ...state, ...action.payload, methodChange: Date.now() + "Suceess"};

        case AllMethodConstants.METHOD_LOADING:
            return { ...state, loading: action.payload };
            
        case AllMethodConstants.GET_METHOD_ERROR:
            return { ...state, ...action.payload, methodChange: Date.now() + "False" };

        default:
            return state;
    }
}
