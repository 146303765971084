
import { combineReducers } from 'redux';
import { supplyConfiguration } from './supply-configuration/supplyConfiguration.reducer'
import { curationFee } from "./curation-fee/curationFee.reducer"
import { dealMonitoring } from './deal-monitoring/dealMonitoring.reducer';

export const mediagrid = combineReducers({
    supplyConfiguration,
    dealMonitoring,
    curationFee
});
