
import { AllEventsConstants } from './events.constants';

export const events = (state = {}, action) => {
    switch (action.type) {
        case AllEventsConstants.EMPTY_EVENTS:
           return { ...state, data: "" };
        case AllEventsConstants.GET_EVENTS:
            return { ...state, ...action.payload, eventsChange: Date.now() + "Suceess"};
        case AllEventsConstants.EVENTS_LOADING:
            return { ...state, loading: action.payload };
        case AllEventsConstants.GET_EVENTS_ERROR:
            return { ...state, ...action.payload, eventsChange: Date.now() + "False" };

            
        case AllEventsConstants.GET_EVENT_BY_ID:
            return { ...state, profile: action.payload, error: '' }
        case AllEventsConstants.GET_EVENT_BY_ID_ERROR:
            return { ...state, profile: false, error: action.payload }
            
        case AllEventsConstants.GET_EVENT_GALLERY_BY_ID:
            return { ...state, gallery: action.payload, error: '' }
        case AllEventsConstants.GET_EVENT_GALLERY_BY_ID_ERROR:
            return { ...state, gallery: false, error: action.payload }

        default:
            return state;
    }
}
