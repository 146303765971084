import { HelpersConstants } from "./helpers.constants";

export const helpers = (state = {}, action) => {
  switch (action.type) {
    case HelpersConstants.BB_GET_ALL_TIMEZONES_INIT:
      return { ...state, timezones: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_TIMEZONES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_TIMEZONES_ERROR:
      return {
        ...state,
        timezones: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_UNIQUE_CPM_INIT:
      return { ...state, uniqieCpm: { loading: action.payload } };
    case HelpersConstants.BB_GET_UNIQUE_CPM_SUCCESS:
    case HelpersConstants.BB_GET_UNIQUE_CPM_ERROR:
      return {
        ...state,
        uniqieCpm: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_CURRENCIES_INIT:
      return { ...state, currencies: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_CURRENCIES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_CURRENCIES_ERROR:
      return {
        ...state,
        currencies: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_INIT:
      return { ...state, dealTypeFormat: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_SUCCESS:
    case HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_ERROR:
      return {
        ...state,
        dealTypeFormat: { data: [...action.payload], loading: false },
      };
    case HelpersConstants.BB_GET_DEAL_TYPE_BY_COUNTRY_SUCCESS:
    case HelpersConstants.BB_GET_DEAL_TYPE_BY_COUNTRY_ERROR:
      return {
        ...state,
        dealTypeFormatByCountry: { data: [...action.payload], loading: false },
      };
    case HelpersConstants.BB_EMPTY_DEAL_TYPE_FORMAT:
      return { ...state, dealTypeFormat: "" };
    case HelpersConstants.BB_EMPTY_DEAL_TYPE_BY_COUNTRY:
      return { ...state, dealTypeFormatByCountry: "" };

    case HelpersConstants.BB_GET_ALL_ROLES_INIT:
      return { ...state, roles: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_ROLES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_ROLES_ERROR:
      return { ...state, roles: { data: [...action.payload], loading: false } };

    case HelpersConstants.BB_GET_ALL_COUNTRIES_INIT:
      return { ...state, countries: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_COUNTRIES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_COUNTRIES_ERROR:
      return {
        ...state,
        countries: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_STATES_INIT:
      return { ...state, states: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_STATES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_STATES_ERROR:
      return {
        ...state,
        states: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_CITIES_INIT:
      return { ...state, cities: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_CITIES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_CITIES_ERROR:
      return {
        ...state,
        cities: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_EMPTY_CITIES:
      return { ...state, cities: "" };
    case HelpersConstants.BB_EMPTY_STATES:
      return { ...state, states: "" };

    case HelpersConstants.BB_GET_ALL_NETWORKS_INIT:
      return { ...state, networks: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_NETWORKS_SUCCESS:
    case HelpersConstants.BB_GET_ALL_NETWORKS_ERROR:
      return {
        ...state,
        networks: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_PUBLISHERS_INIT:
      return { ...state, publishers: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_PUBLISHERS_SUCCESS:
    case HelpersConstants.BB_GET_ALL_PUBLISHERS_ERROR:
      return {
        ...state,
        publishers: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_ACCOUNTS_INIT:
      return { ...state, accounts: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_ACCOUNTS_SUCCESS:
    case HelpersConstants.BB_GET_ALL_ACCOUNTS_ERROR:
      return {
        ...state,
        accounts: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_ACCOUNTS_POP_INIT:
      return { ...state, popAccounts: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_ACCOUNTS_POP_SUCCESS:
    case HelpersConstants.BB_GET_ALL_ACCOUNTS_POP_ERROR:
      return {
        ...state,
        popAccounts: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_CATEGORIES_INIT:
      return { ...state, categories: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_CATEGORIES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_CATEGORIES_ERROR:
      return {
        ...state,
        categories: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_HAC_TEMPERATURE_INIT:
      return { ...state, hacTemperature: { loading: action.payload } };
    case HelpersConstants.BB_GET_HAC_TEMPERATURE_SUCCESS:
    case HelpersConstants.BB_GET_HAC_TEMPERATURE_ERROR:
      return {
        ...state,
        hacTemperature: { data: { ...action.payload }, loading: false },
      };

    case HelpersConstants.BB_GET_ALL_CAMPAIGNS_INIT:
      return { ...state, campaigns: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_CAMPAIGNS_SUCCESS:
    case HelpersConstants.BB_GET_ALL_CAMPAIGNS_ERROR:
      return {
        ...state,
        campaigns: { data: [...action.payload], loading: false },
      };

    case HelpersConstants.BB_GET_ALL_SITES_INIT:
      return { ...state, sites: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_SITES_SUCCESS:
    case HelpersConstants.BB_GET_ALL_SITES_ERROR:
      return { ...state, sites: { data: [...action.payload], loading: false } };

    case HelpersConstants.BB_GET_ALL_DSPS_INIT:
      return { ...state, dsps: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_DSPS_SUCCESS:
    case HelpersConstants.BB_GET_ALL_DSPS_ERROR:
      return { ...state, dsps: { data: [...action.payload], loading: false } };

    case HelpersConstants.BB_EMPTY_PUBLISHERS:
      return { ...state, publishers: "" };
    case HelpersConstants.BB_EMPTY_SITES:
      return { ...state, sites: "" };
    case HelpersConstants.BB_EMPTY_NETWORKS:
      return { ...state, networks: "" };
    case HelpersConstants.BB_EMPTY_ACCOUNTS:
      return { ...state, accounts: "" };
    case HelpersConstants.BB_EMPTY_ACCOUNTS_POP:
      return { ...state, popAccounts: "" };
    case HelpersConstants.BB_EMPTY_CATEGORIES:
      return { ...state, categories: "" };
    case HelpersConstants.BB_EMPTY_CAMPAIGNS:
      return { ...state, campaigns: "" };
    case HelpersConstants.BB_EMPTY_DSPS:
      return { ...state, dsps: "" };

    case HelpersConstants.BB_CHANGE_OWNERSHIP_STATUS:
      return { ...state, transferLoading: action.payload };
    case HelpersConstants.BB_CHANGE_OWNERSHIP_SUCCESS:
      return {
        ...state,
        transferOwnershipSuccess: true,
        transferResp: action.payload,
      };
    case HelpersConstants.BB_CHANGE_OWNERSHIP_ERROR:
      return {
        ...state,
        transferOwnershipSuccess: false,
        transferResp: action.payload,
      };

    case HelpersConstants.BB_CHECK_ACCESS_STATUS:
      return { ...state, accessLoading: action.payload };
    case HelpersConstants.BB_CHECK_ACCESS_SUCCESS:
      return {
        ...state,
        checkAccessSuccess: true,
        checkAccessResp: action.payload,
      };
    case HelpersConstants.BB_CHECK_ACCESS_ERROR:
      return {
        ...state,
        checkAccessSuccess: false,
        checkAccessResp: action.payload,
      };

    case HelpersConstants.BB_GET_DSP_GUIDE_LOADING:
      return { ...state, dspGuideLoading: action.payload };
    case HelpersConstants.BB_GET_DSP_GUIDE_SUCCESS:
    case HelpersConstants.BB_GET_DSP_GUIDE_ERROR:
      return { ...state, dspGuide: action.payload };

    case HelpersConstants.BB_GET_AD_SPEC_LOADING:
      return { ...state, adSpecLoading: action.payload };
    case HelpersConstants.BB_GET_AD_SPEC_SUCCESS:
    case HelpersConstants.BB_GET_AD_SPEC_ERROR:
      return { ...state, adSpec: action.payload };

    case HelpersConstants.BB_GET_VIDEO_ID_LOADING:
      return { ...state, videoIdLoading: action.payload };
    case HelpersConstants.BB_GET_VIDEO_ID_SUCCESS:
    case HelpersConstants.BB_GET_VIDEO_ID_ERROR:
      return { ...state, videoId: action.payload };

    /* Gross Margin */
    case HelpersConstants.BB_GROSS_MARGIN_LOADING_STATUS:
      return { ...state, gross_margin_loading: action.payload };
    case HelpersConstants.BB_GROSS_MARGIN_SUCCESS:
      return { ...state, gross_margin_data: action.payload };
    case HelpersConstants.BB_GROSS_MARGIN_ERROR:
      return { ...state, gross_margin_data: action.payload };
    case HelpersConstants.EMPTY_GROSS_MARGIN:
      return { ...state, gross_margin_data: "" };

    case HelpersConstants.BB_GET_BESPOKE_BY_COUNTRY_INIT:
      return { ...state, bespokeByCountry: { loading: action.payload } };
    case HelpersConstants.BB_GET_BESPOKE_BY_COUNTRY_SUCCESS:
    case HelpersConstants.BB_GET_BESPOKE_BY_COUNTRY_ERROR:
      return {
        ...state,
        bespokeByCountry: { data: [...action.payload], loading: false },
      };
    case HelpersConstants.EMPTY_BESPOKE_BY_COUNTRY:
      return { ...state, bespokeByCountry: "" };

    case HelpersConstants.BB_GET_ALL_PROVIDERS_INIT:
      return { ...state, providers: { loading: action.payload } };
    case HelpersConstants.BB_GET_ALL_PROVIDERS_SUCCESS:
    case HelpersConstants.BB_GET_ALL_PROVIDERS_ERROR:
      return {
        ...state,
        providers: { data: [...action.payload], loading: false },
      };

    /* Product Margin */
    case HelpersConstants.BB_PRODUCT_MARGIN_LOADING_STATUS:
      return { ...state, product_margin_loading: action.payload };
    case HelpersConstants.BB_PRODUCT_MARGIN_SUCCESS:
      return { ...state, product_margin_data: action.payload };
    case HelpersConstants.BB_PRODUCT_MARGIN_ERROR:
      return { ...state, product_margin_data: action.payload };
    case HelpersConstants.EMPTY_PRODUCT_MARGIN:
      return { ...state, product_margin_data: "" };

    default:
      return state;
  }
};
