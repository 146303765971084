import React from 'react'
import QrCode from '../../../../utilities/QR-Code'
import SendEmail from './sendEmail'
import { FastField } from 'formik';
import { TextAreaWithCTC } from '../../../../form-helpers'
import { config } from '../../../../../helpers/config'
import classnames from "classnames"
function QrPreviewEmail(props) {

    const { iniV, creativePage = false, link = iniV._id, email = true } = props;

    return (
        <>
            <div className={classnames("d-flex flex-row flex-wrap align-content-stretch  p-0 pr-4", creativePage ? "col-12 creative-page-QR" : "col-md-12 col-lg-12 col-xl-7 my-4")}>
                <div className={classnames(creativePage ? "margin_15_x" : "mr-2 ")}>
                    <p className="bb-text_small p-0 mb-2">Preview QR</p>
                    <QrCode size="105" value={config.previewServer + link} />
                </div>
                <div className={classnames("flex-grow-1", creativePage ? "margin_15_x" : "mr-2")}>
                    <p className="bb-text_small p-0 mb-2">Preview link</p>
                    <FastField name='previewUrl'
                        value={config.previewServer + link}
                        containerClassName="p-0 flex-grow-1 align-self-center mt-2"
                        labelClassName="h5"
                        isHtml={false}
                        row="5"
                        isExteranLinkRequired={true}
                        placeholder="" className="text-dark bb-text_small h-125px" component={TextAreaWithCTC}
                    />
                </div>
                {email ? <div className={classnames("mo-email-button", creativePage ? "" : "flex-grow-1")}>
                    <p className="bb-text_small p-0 mb-2">Email link</p>
                    <SendEmail {...props} url={config.previewServer + link} adname={iniV.ad_name} adid={iniV._id} emailIcon={true} />
                </div> : null}
            </div>
        </>
    )
}

export default QrPreviewEmail   