export const DailyTestResultsConstants = {
    GET_ALL_DAILY_TEST_RESULTS_SUCCESS: "GET_ALL_DAILY_TEST_RESULTS_SUCCESS",
    GET_ALL_DAILY_TEST_RESULTS_ERROR: "GET_ALL_DAILY_TEST_RESULTS_ERROR",
    ALL_DAILY_TEST_RESULTS_LOADING: "ALL_DAILY_TEST_RESULTS_LOADING",
    
    UPDATE_DAILY_TEST_RESULTS_SUCCESS: 'UPDATE_DAILY_TEST_RESULTS_SUCCESS',
    UPDATE_DAILY_TEST_RESULTS_ERROR: 'UPDATE_DAILY_TEST_RESULTS_ERROR',
    UPDATE_DAILY_TEST_RESULTS_LOADING: 'UPDATE_DAILY_TEST_RESULTS_LOADING',
    
    SEND_DAILY_TEST_EMAIL_SUCCESS: 'SEND_DAILY_TEST_EMAIL_SUCCESS',
    SEND_DAILY_TEST_EMAIL_ERROR: 'SEND_DAILY_TEST_EMAIL_ERROR',
};
