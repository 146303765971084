
import { AllCampaignConstants } from './campaign.constants';

export const campaign = (state = {}, action) => {
    switch (action.type) {
        case AllCampaignConstants.GET_ALL_BRIEF_CAMPAIGN_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case AllCampaignConstants.GET_ALL_BRIEF_CAMPAIGN_PAGINATE_ERROR: return { ...state, ...action.payload, data: "" }

        case AllCampaignConstants.EMPTY_BRIEF_CAMPAIGN_BY_PROFILE: return { ...state, profile: "" }
        case AllCampaignConstants.GET_BRIEF_CAMPAIGN_BY_ID: return { ...state, profile: action.payload }
        case AllCampaignConstants.GET_BRIEF_CAMPAIGN_BY_ID_ERROR: return { ...state, profile: action.payload }

        case AllCampaignConstants.BRIEF_CAMPAIGN_CURRENT_PAGE: return { ...state, page: action.payload }
        case AllCampaignConstants.BRIEF_CAMPAIGN_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case AllCampaignConstants.BRIEF_CAMPAIGN_SEARCH_VALUE: return { ...state, search: action.payload }
        case AllCampaignConstants.BRIEF_CAMPAIGN_TOTAL_COUNT: return { ...state, total: action.payload }
        case AllCampaignConstants.BRIEF_CAMPAIGN_LOADING_STATUS: return { ...state, loading: action.payload }

        case AllCampaignConstants.DELETE_BRIEF_CAMPAIGN:
        case AllCampaignConstants.DELETE_BRIEF_CAMPAIGN_ERROR:
            return { ...state, deleteStatus: action.payload }


        case AllCampaignConstants.ACTIVATE_BRIEF_CAMPAIGN:
        case AllCampaignConstants.ACTIVATE_BRIEF_CAMPAIGN_ERROR:
            return { ...state, activateStatus: action.payload }

        case AllCampaignConstants.ADD_BRIEF_CAMPAIGN_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case AllCampaignConstants.ADD_BRIEF_CAMPAIGN_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case AllCampaignConstants.EDIT_BRIEF_CAMPAIGN_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case AllCampaignConstants.EDIT_BRIEF_CAMPAIGN_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
}
