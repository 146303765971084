import { PrebuiltConstants } from "./prebuilt.constants";

export const prebuiltMedias = (state = {}, action) => {
    switch (action.type) {
        case PrebuiltConstants.GET_ALL_PREBUILT_INIT: return { ...state, all: { loading: true } }
        case PrebuiltConstants.GET_ALL_PREBUILT_SUCCESS: return { ...state, all: action.payload }
        case PrebuiltConstants.GET_ALL_PREBUILT_ERROR: return { ...state, all: action.payload }

        case PrebuiltConstants.GET_ALL_PREBUILT_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case PrebuiltConstants.GET_ALL_PREBUILT_PAGINATE_ERROR: return { ...state, ...action.payload }

        case PrebuiltConstants.EMPTY_PREBUILT_BY_PROFILE: return { ...state, profile: "" }
        case PrebuiltConstants.GET_PREBUILT_BY_ID: return { ...state, profile: action.payload }
        case PrebuiltConstants.GET_PREBUILT_BY_ID_ERROR: return { ...state, profile: action.payload }

        case PrebuiltConstants.PREBUILT_CURRENT_PAGE: return { ...state, page: action.payload }

        case PrebuiltConstants.PREBUILT_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case PrebuiltConstants.PREBUILT_SEARCH_VALUE: return { ...state, search: action.payload }

        case PrebuiltConstants.PREBUILT_STATUS_VALUE: return { ...state, status: action.payload }

        case PrebuiltConstants.PREBUILT_TOTAL_COUNT: return { ...state, total: action.payload }

        case PrebuiltConstants.PREBUILT_LOADING_STATUS: return { ...state, loading: action.payload }

        case PrebuiltConstants.DELETE_PREBUILT:
        case PrebuiltConstants.DELETE_PREBUILT_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case PrebuiltConstants.ACTIVATE_PREBUILT:
        case PrebuiltConstants.ACTIVATE_PREBUILT_ERROR:
            return { ...state, activateStatus: action.payload }

        case PrebuiltConstants.ADD_PREBUILT_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case PrebuiltConstants.ADD_PREBUILT_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case PrebuiltConstants.EDIT_PREBUILT_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case PrebuiltConstants.EDIT_PREBUILT_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
