import { CreativeDSPConstant } from './dsp.constants';

export default function dspR(state = {}, action) {

    switch (action.type) {
        
        case CreativeDSPConstant.GET_DSPS_FOR_ACCOUNT_INIT: return { ...state, all: {loading: true} }
        
        case CreativeDSPConstant.GET_DSPS_FOR_ACCOUNT_SUCCESS: 
        case CreativeDSPConstant.GET_DSPS_FOR_ACCOUNT_ERROR:  return { ...state, all: action.payload }

        case CreativeDSPConstant.EMPTY_DSPS_FOR_ACCOUNT: return {...state, all: ""};

        default:
            return state;
    }
}; 
