export const CreativeRequestConstants = {

    SEND_USER_REQUEST: "SEND_USER_REQUEST",
    SEND_USER_REQUEST_LOADING: "SEND_USER_REQUEST_LOADING",
    SEND_USER_REQUEST_SUCCESS: "SEND_USER_REQUEST_SUCCESS",
    SEND_USER_REQUEST_ERROR: "SEND_USER_REQUEST_ERROR",

    GET_CURATED_MEDIA_BRIEF_BY_ID_SUCCESS: "GET_CURATED_MEDIA_BRIEF_BY_ID_SUCCESS",
    GET_CURATED_MEDIA_BRIEF_BY_ID_ERROR: "GET_CURATED_MEDIA_BRIEF_BY_ID_ERROR",
    EMPTY_CURATED_MEDIA_BRIEF_BY_ID: "EMPTY_CURATED_MEDIA_BRIEF_BY_ID"

};