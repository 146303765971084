export const Scope3BenchmarkConstants = {
    GET_ALL_BENCHMARK_SUCCESS: "GET_ALL_BENCHMARK_SUCCESS",
    ALL_BENCHMARK_LIMIT: "ALL_BENCHMARK_LIMIT",
    GET_ALL_BENCHMARK_ERROR: "GET_ALL_BENCHMARK_ERROR",
    ALL_BENCHMARK_LOADING: "ALL_BENCHMARK_LOADING",
    
    GET_BENCHMARK_BY_ID: 'GET_BENCHMARK_BY_ID',
    EMPTY_BENCHMARK_BY_PROFILE: 'EMPTY_BENCHMARK_BY_PROFILE',
    GET_BENCHMARK_BY_ID_ERROR: 'GET_BENCHMARK_BY_ID_ERROR',

    ADD_BENCHMARK_SUCCESS: 'ADD_BENCHMARK_SUCCESS',
    ADD_BENCHMARK_ERROR: 'ADD_BENCHMARK_ERROR',

    EDIT_BENCHMARK_SUCCESS: 'EDIT_BENCHMARK_SUCCESS',
    EDIT_BENCHMARK_ERROR: 'EDIT_BENCHMARK_ERROR',
    
    DELETE_BENCHMARK_SUCCESS: 'DELETE_BENCHMARK_SUCCESS',
    DELETE_BENCHMARK_ERROR: 'DELETE_BENCHMARK_ERROR',
    ACTIVATE_BENCHMARK_SUCCESS: 'ACTIVATE_BENCHMARK_SUCCESS',
    ACTIVATE_BENCHMARK_ERROR: 'ACTIVATE_BENCHMARK_ERROR',
};
