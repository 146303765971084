export const RequestConstants = {
    GET_ALL_REQUEST: 'GET_ALL_REQUEST',
    ALL_REQUEST_LOADING: 'ALL_REQUEST_LOADING',
    GET_ALL_REQUEST_ERROR: 'GET_ALL_REQUEST_ERROR',
    ALL_REQUEST_CURRENT_PAGE: 'ALL_REQUEST_CURRENT_PAGE',
    ALL_REQUEST_LIMIT_PER_PAGE: 'ALL_REQUEST_LIMIT_PER_PAGE',
    ALL_REQUEST_SEARCH_VALUE: 'ALL_REQUEST_SEARCH_VALUE',
    ALL_REQUEST_STATUS_VALUE: 'ALL_REQUEST_STATUS_VALUE',
    ALL_REQUEST_LIMIT: 'ALL_REQUEST_LIMIT',
    CHECK_REQUEST_SUCCESS: 'CHECK_REQUEST_SUCCESS',
    CHECK_REQUEST_ERROR: 'CHECK_REQUEST_ERROR',


    GET_ALL_LATEST_REQUEST: 'GET_ALL_LATEST_REQUEST',
    ALL_LATEST_REQUEST_LOADING: 'ALL_LATEST_REQUEST_LOADING',
    GET_ALL_LATEST_REQUEST_ERROR: 'GET_ALL_LATEST_REQUEST_ERROR',
    ALL_LATEST_REQUEST_CURRENT_PAGE: 'ALL_LATEST_REQUEST_CURRENT_PAGE',
    ALL_LATEST_REQUEST_LIMIT_PER_PAGE: 'ALL_LATEST_REQUEST_LIMIT_PER_PAGE',
    ALL_LATEST_REQUEST_SEARCH_VALUE: 'ALL_LATEST_REQUEST_SEARCH_VALUE',
    ALL_LATEST_REQUEST_STATUS_VALUE: 'ALL_LATEST_REQUEST_STATUS_VALUE',
    ALL_LATEST_REQUEST_LIMIT: 'ALL_LATEST_REQUEST_LIMIT',

    BB_GET_DATA_MODAL_SUCCESS: 'BB_GET_DATA_MODAL_SUCCESS',
    BB_GET_DATA_MODAL_ERROR: 'BB_GET_DATA_MODAL_ERROR',
    MODAL_LOADING: 'MODAL_LOADING',

    EMPTY_ALL_REQUESTS: 'EMPTY_ALL_REQUESTS',

    ARCHIVE_STATUS_REQUEST_SUCCESS: "ARCHIVE_STATUS_REQUEST_SUCCESS",
    ARCHIVE_STATUS_REQUEST_ERROR: "ARCHIVE_STATUS_REQUEST_ERROR",
    ACTIVATE_STATUS_REQUEST_SUCCESS: "ACTIVATE_STATUS_REQUEST_SUCCESS",
    ACTIVATE_STATUS_REQUEST_ERROR: "ACTIVATE_STATUS_REQUEST_ERROR"
}