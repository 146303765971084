import { combineReducers } from 'redux';

import { verticals } from "./vertical/verticals.reducer";
import { objectives } from "./objective/objectives.reducer";
import {creatives} from "./creatives/creatives.reducer"
import {benchmark} from "./benchmark/benchmark.reducer"

export const management = combineReducers({
    verticals,
    objectives,
    creatives,
    benchmark
});
