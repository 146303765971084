import { ObjectivesConstants } from './objectives.constants';

export const objectives = (state = {}, action) => {
    switch (action.type) {
        case ObjectivesConstants.GET_ALL_SHOWCASE_OBJECTIVES:
            return {
                ...state,
                ...action.payload
            };

        case ObjectivesConstants.ALL_SHOWCASE_OBJECTIVES_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case ObjectivesConstants.GET_ALL_SHOWCASE_OBJECTIVES_ERROR:
            return {
                ...state,
                error: true
            };
        case ObjectivesConstants.ALL_SHOWCASE_OBJECTIVES_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case ObjectivesConstants.ALL_SHOWCASE_OBJECTIVES_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case ObjectivesConstants.ALL_SHOWCASE_OBJECTIVES_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case ObjectivesConstants.ALL_SHOWCASE_OBJECTIVES_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case ObjectivesConstants.ALL_SHOWCASE_OBJECTIVES_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case ObjectivesConstants.DELETE_SHOWCASE_OBJECTIVES_SUCCESS:
            return {
                ...state,
                objectivesDelete: action.payload
            };
        case ObjectivesConstants.DELETE_SHOWCASE_OBJECTIVES_ERROR:
            return {
                ...state,
                objectivesDelete: action.payload
            };
        case ObjectivesConstants.ACTIVATE_SHOWCASE_OBJECTIVES_SUCCESS:
            return {
                ...state,
                objectivesActivate: action.payload
            };
        case ObjectivesConstants.ACTIVATE_SHOWCASE_OBJECTIVES_ERROR:
            return {
                ...state,
                objectivesActivate: action.payload
            };
        case ObjectivesConstants.BB_ADD_SHOWCASE_OBJECTIVES_SUCCESS:
            return {
                ...state,
                addObjectivesSuccess: true,
                addResp: action.payload
            };
        case ObjectivesConstants.BB_ADD_SHOWCASE_OBJECTIVES_ERROR:
            return {
                ...state,
                addObjectivesSuccess: false,
                ...action.payload
            };
        case ObjectivesConstants.BB_ADD_SHOWCASE_OBJECTIVES_LOADING:
            return {
                ...state,
                addObjectivesLoading: action.payload
            };
        case ObjectivesConstants.BB_EDIT_SHOWCASE_OBJECTIVES_SUCCESS:
            return {
                ...state,
                editObjectivesSuccess: true,
                editResp: action.payload
            };
        case ObjectivesConstants.BB_EDIT_SHOWCASE_OBJECTIVES_ERROR:
            return {
                ...state,
                editObjectivesSuccess: false,
                ...action.payload
            };
        case ObjectivesConstants.BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_INIT:
            return { ...state, dropDown: { loading: action.payload } };
        case ObjectivesConstants.BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_SUCCESS:
        case ObjectivesConstants.BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_ERROR:
            return { ...state, dropDown: { data: [...action.payload], loading: false } };

        default:
            return state;
    }
}
