import React from "react";
import InputPicker from 'rsuite/InputPicker';
import classNames from "classnames";
import './index.scss'
export const StatusFilter = (props) => {
  const mountRef = React.createRef();

  const { status, onChange, optionData = [], placeholder, customStyles = {}, isDefault = true, resetValue = "", isCleanable = true, label = "Status:",
    className = "", marginBottom = true, noLabel = false, labelClassName = "", menuClassName = "", labelKey = "label", valueKey = "value",
    labelSeparator = " ", groupBy = "", archiveField = "", showArchived = false,
    container = false,
  } = props;

  const defaultOptions = [
    {
      "label": "Active",
      "value": "1",
    },
    {
      "label": "Inactive",
      "value": "0",
    },
  ]

  let data = isDefault ? defaultOptions : optionData;

  const getLabelKey = () => {
    if (typeof labelKey === "object" && labelKey.length > 0) {
      return labelKey[0]
    }

    return labelKey
  }

  const RenderMenuItem = (label, item) => {
    let labelToView = ""
    if (typeof labelKey === "string") {
      labelToView = label;
    } else {
      var o = "";
      for (var i = 0; i < labelKey.length; i++) {
        if (i !== labelKey.length - 1) {
          o += item[labelKey[i]] + labelSeparator;
        }
        else {
          o += item[labelKey[i]];
        }

      }
      labelToView = o.trim();
    }

    if(archiveField && (item[archiveField] !== showArchived)) {
      return (
          <span style={{ color: "#858585" }}>
              {labelToView}
          </span>
      )
    }
    return labelToView
  }
  return (
    <React.Fragment>
      {noLabel ? null : <span className={classNames("bb-status-filter-text mt-2 mr-1", labelClassName)}>{label}</span>}

      <div ref={mountRef} className={classNames("mr-2 bb-status-filter", className, marginBottom ? "mb-3" : "")}>
        <InputPicker
          container={container ? container : () => mountRef.current}
          groupBy={groupBy}
          labelKey={getLabelKey()}
          valueKey={valueKey}
          value={status}
          onChange={val => onChange({ target: { value: val ? val : resetValue } })}
          style={isDefault === true ? { width: 120, ...customStyles } : { ...customStyles }}
          placeholder={placeholder || "No filter"}
          defaultValue=""
          cleanable={isCleanable}
          menuClassName={classNames(menuClassName, container ? '' :  'bb-input-picker')  }
          data={data}
          renderMenuItem={RenderMenuItem}
        />
      </div>
    </React.Fragment>
  )
}
