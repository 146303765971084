import { Scope3BenchmarkConstants } from './scope3Benchmark.constants';

export const scope3Benchmark = (state = {}, action) => {
    switch (action.type) {
        case Scope3BenchmarkConstants.GET_ALL_BENCHMARK_SUCCESS: return { ...state, ...action.payload };
        case Scope3BenchmarkConstants.GET_ALL_BENCHMARK_ERROR: return { ...state, error: true };

        case Scope3BenchmarkConstants.ALL_BENCHMARK_LOADING: return { ...state, loading: action.payload };
        case Scope3BenchmarkConstants.ALL_BENCHMARK_LIMIT: return { ...state, total: action.payload };
        
        case Scope3BenchmarkConstants.DELETE_BENCHMARK_SUCCESS: return { ...state, deleteStatus: action.payload };
        case Scope3BenchmarkConstants.DELETE_BENCHMARK_ERROR: return { ...state, deleteStatus: action.payload };
        case Scope3BenchmarkConstants.ACTIVATE_BENCHMARK_SUCCESS: return { ...state, activateStatus: action.payload };
        case Scope3BenchmarkConstants.ACTIVATE_BENCHMARK_ERROR: return { ...state, activateStatus: action.payload };

        case Scope3BenchmarkConstants.ADD_BENCHMARK_SUCCESS: return { ...state, addFERSuccess: true, addResp: action.payload };
        case Scope3BenchmarkConstants.ADD_BENCHMARK_ERROR: return { ...state, addFERSuccess: false, ...action.payload };
        case Scope3BenchmarkConstants.EDIT_BENCHMARK_SUCCESS: return { ...state, editFERSuccess: true, editResp: action.payload };
        case Scope3BenchmarkConstants.EDIT_BENCHMARK_ERROR: return { ...state, editFERSuccess: false, ...action.payload };
        
        case Scope3BenchmarkConstants.EMPTY_BENCHMARK_BY_PROFILE:
        case Scope3BenchmarkConstants.GET_BENCHMARK_BY_ID:
        case Scope3BenchmarkConstants.GET_BENCHMARK_BY_ID_ERROR:
            return { ...state, profile: action.payload };
        default:
            return state;
    }
}