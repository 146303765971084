export const ObjectivesConstants = {
    ALL_SHOWCASE_OBJECTIVES_CURRENT_PAGE: "ALL_SHOWCASE_OBJECTIVES_CURRENT_PAGE",
    ALL_SHOWCASE_OBJECTIVES_LIMIT_PER_PAGE: "ALL_SHOWCASE_OBJECTIVES_LIMIT_PER_PAGE",
    ALL_SHOWCASE_OBJECTIVES_SEARCH_VALUE: "ALL_SHOWCASE_OBJECTIVES_SEARCH_VALUE",
    ALL_SHOWCASE_OBJECTIVES_STATUS_VALUE: "ALL_SHOWCASE_OBJECTIVES_STATUS_VALUE",

    GET_ALL_SHOWCASE_OBJECTIVES: "GET_ALL_SHOWCASE_OBJECTIVES",
    ALL_SHOWCASE_OBJECTIVES_LIMIT: "ALL_SHOWCASE_OBJECTIVES_LIMIT",
    GET_ALL_SHOWCASE_OBJECTIVES_ERROR: "GET_ALL_SHOWCASE_OBJECTIVES_ERROR",
    ALL_SHOWCASE_OBJECTIVES_LOADING: "ALL_SHOWCASE_OBJECTIVES_LOADING",

    DELETE_SHOWCASE_OBJECTIVES_SUCCESS: 'DELETE_SHOWCASE_OBJECTIVES_SUCCESS',
    DELETE_SHOWCASE_OBJECTIVES_ERROR: 'DELETE_SHOWCASE_OBJECTIVES_ERROR',
    ACTIVATE_SHOWCASE_OBJECTIVES_SUCCESS: 'ACTIVATE_SHOWCASE_OBJECTIVES_SUCCESS',
    ACTIVATE_SHOWCASE_OBJECTIVES_ERROR: 'ACTIVATE_SHOWCASE_OBJECTIVES_ERROR',


    BB_ADD_SHOWCASE_OBJECTIVES_SUCCESS: 'BB_ADD_SHOWCASE_OBJECTIVES_SUCCESS',
    BB_ADD_SHOWCASE_OBJECTIVES_ERROR: 'BB_ADD_SHOWCASE_OBJECTIVES_ERROR',
    BB_ADD_SHOWCASE_OBJECTIVES_LOADING: 'BB_ADD_SHOWCASE_OBJECTIVES_LOADING',

    BB_EDIT_SHOWCASE_OBJECTIVES_SUCCESS: 'BB_EDIT_SHOWCASE_OBJECTIVES_SUCCESS',
    BB_EDIT_SHOWCASE_OBJECTIVES_ERROR: 'BB_EDIT_SHOWCASE_OBJECTIVES_ERROR',

    BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_INIT: 'BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_INIT',
    BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_SUCCESS: 'BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_SUCCESS',
    BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_ERROR: 'BB_GET_ACTIVE_SHOWCASE_OBJECTIVES_ERROR'
};
