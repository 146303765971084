import { DailyTestResultsConstants } from './results.constants';

export const results = (state = {}, action) => {
    switch (action.type) {
        case DailyTestResultsConstants.GET_ALL_DAILY_TEST_RESULTS_SUCCESS: return { ...state, ...action.payload };
        case DailyTestResultsConstants.GET_ALL_DAILY_TEST_RESULTS_ERROR: return { ...state, data: null, error: true };

        case DailyTestResultsConstants.ALL_DAILY_TEST_RESULTS_LOADING: return { ...state, loading: action.payload };
        
        case DailyTestResultsConstants.UPDATE_DAILY_TEST_RESULTS_SUCCESS: return { ...state, updateStatus: action.payload };
        case DailyTestResultsConstants.UPDATE_DAILY_TEST_RESULTS_ERROR: return { ...state, updateStatus: action.payload };
        case DailyTestResultsConstants.UPDATE_DAILY_TEST_RESULTS_LOADING: return { ...state, updateLoading: action.payload };
        
        case DailyTestResultsConstants.SEND_DAILY_TEST_EMAIL_SUCCESS: return { ...state, sendEmailStatus: true, sendEmailResp: action.payload };
        case DailyTestResultsConstants.SEND_DAILY_TEST_EMAIL_ERROR: return { ...state, sendEmailStatus: false, ...action.payload };
        default:
            return state;
    }
}