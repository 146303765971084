import { BespokeConstants } from "./bespoke.constants";

export const bespokes = (state = {}, action) => {
    switch (action.type) {
        case BespokeConstants.GET_ALL_BESPOKE_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case BespokeConstants.GET_ALL_BESPOKE_PAGINATE_ERROR: return { ...state, ...action.payload }

        case BespokeConstants.EMPTY_BESPOKE_BY_PROFILE: return { ...state, profile: "" }
        case BespokeConstants.GET_BESPOKE_BY_ID: return { ...state, profile: action.payload }
        case BespokeConstants.GET_BESPOKE_BY_ID_ERROR: return { ...state, profile: action.payload }

        case BespokeConstants.BESPOKE_CURRENT_PAGE: return { ...state, page: action.payload }
        case BespokeConstants.BESPOKE_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case BespokeConstants.BESPOKE_SEARCH_VALUE: return { ...state, search: action.payload }
        case BespokeConstants.BESPOKE_STATUS_VALUE: return { ...state, status: action.payload }
        case BespokeConstants.BESPOKE_TOTAL_COUNT: return { ...state, total: action.payload }

        case BespokeConstants.BESPOKE_LOADING_STATUS: return { ...state, loading: action.payload }

        default:
            return state;
    }
};
