import React, { Component } from 'react'
import classnames from 'classnames'
import parse from 'html-react-parser';
import { Tooltip as ReactTooltip } from 'react-tooltip'

export default class Tooltip extends Component {

    render() {
        const { isLabelHtml = false, label, className, iconname, light, htmlFor, tooltipClassName = "", onClick = null, iconClassName = "",
        default_id = "", showIcon = true,  arrowColor = "", icon = false
     } = this.props;
        let id = ""
        if (default_id) {
            id = default_id
        }
        else id = label.replace(/[^a-zA-Z0-9]/g, '');
        return (
            <React.Fragment>
                <label onClick={onClick} className={classnames(className)} data-tooltip-id={id + "-help"} htmlFor={htmlFor || (id + "-help")}>{isLabelHtml ? parse(label) : label}
                    <i className={classnames("fas pl-2", light ? "text-white" : "text-dark", iconname ? iconname : "fa-question-circle", showIcon ? "" : "d-none", iconClassName)}
                        data-tooltip-id={id + "-help"} aria-hidden="true">
                            {icon ? <img src="/images/icons/green-fleet.png" alt="green" /> : null}
                        </i>
                </label>
                <ReactTooltip arrowColor={arrowColor} id={id + "-help"} className={classnames("ft-tooltip", tooltipClassName)} clickable={true}>
                    {this.props.children}
                </ReactTooltip>
            </React.Fragment>
        )
    }
} 
