export const DimensionConstants  = {
    ALL_DIMENSION_CURRENT_PAGE: "ALL_DIMENSION_CURRENT_PAGE",
    ALL_DIMENSION_LIMIT_PER_PAGE: "ALL_DIMENSION_LIMIT_PER_PAGE",
    ALL_DIMENSION_SEARCH_VALUE: "ALL_DIMENSION_SEARCH_VALUE",
    ALL_DIMENSION_STATUS_VALUE: "ALL_DIMENSION_STATUS_VALUE",

    GET_ALL_DIMENSION_SUCCESS: "GET_ALL_DIMENSION_SUCCESS",
    ALL_DIMENSION_LIMIT: "ALL_DIMENSION_LIMIT",
    GET_ALL_DIMENSION_ERROR: "GET_ALL_DIMENSION_ERROR",
    ALL_DIMENSION_LOADING: "ALL_DIMENSION_LOADING",

    DELETE_DIMENSION_SUCCESS: 'DELETE_DIMENSION_SUCCESS',
    DELETE_DIMENSION_ERROR:'DELETE_DIMENSION_ERROR',
    ACTIVATE_DIMENSION_SUCCESS:'ACTIVATE_DIMENSION_SUCCESS',
    ACTIVATE_DIMENSION_ERROR:'ACTIVATE_DIMENSION_ERROR',


    BB_ADD_DIMENSION_SUCCESS:'BB_ADD_DIMENSION_SUCCESS',
    BB_ADD_DIMENSION_ERROR:'BB_ADD_DIMENSION_ERROR',
    BB_ADD_DIMENSION_LOADING:'BB_ADD_DIMENSION_LOADING',

    BB_EDIT_DIMENSION_SUCCESS:'BB_EDIT_DIMENSION_SUCCESS',
    BB_EDIT_DIMENSION_ERROR:'BB_EDIT_DIMENSION_ERROR',
    
    BB_GET_USER_DIMENSION_INIT:'BB_GET_USER_DIMENSION_INIT',
    BB_GET_USER_DIMENSION_SUCCESS:'BB_GET_USER_DIMENSION_SUCCESS',
    BB_GET_USER_DIMENSION_ERROR:'BB_GET_USER_DIMENSION_ERROR',

    BB_GET_ALL_DIMENSION_INIT:'BB_GET_ALL_DIMENSION_INIT',
    BB_GET_ALL_DIMENSION_SUCCESS:'BB_GET_ALL_DIMENSION_SUCCESS',
    BB_GET_ALL_DIMENSION_ERROR:'BB_GET_ALL_DIMENSION_ERROR',

    EMPTY_ALL_DIMENSION:'EMPTY_ALL_DIMENSION',

    GET_EDIT_DIMENSION_INFO_INIT:"GET_EDIT_DIMENSION_INFO_INIT",
    GET_EDIT_DIMENSION_INFO_SUCCESS:"GET_EDIT_DIMENSION_INFO_SUCCESS",
    GET_EDIT_DIMENSION_INFO_ERROR:"GET_EDIT_DIMENSION_INFO_ERROR",
    EMPTY_EDIT_DIMENSION_INFO:"EMPTY_EDIT_DIMENSION_INFO"
};
