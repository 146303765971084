import React, { useState } from 'react'
import classnames from 'classnames'

import { SketchPicker } from 'react-color';
import { FormError } from '../FormError'
import './color-input.scss'

const renderLabel = (labelClassName, label, isOptional) => {
    return (
        <label className={classnames("mb-1", labelClassName)}>{label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}</label>
    );
}

const ColorInput = ({ disabled = false, onChange, field, containerClassName = "",
    form, labelClassName = "", inputContainerClassName = "", className = "",
    keyPressCheck = true, isHex = true,
    labelPosition = "start", isOptional = false,
    label, placeholder = "", form: { touched, errors } }) => {

    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [color, setColor] = useState(field.value || "")

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
        form.setFieldValue(field.name, color) 
        
    };

    const handleSelect = () => {
        setDisplayColorPicker(false)
        if (typeof onChange === 'function') {
            onChange(field.value)
            setColor(field.value)
        }
    };

    return (
        <div className={containerClassName}>
            {
                // eslint-disable-next-line
                labelPosition == "start" && label ? renderLabel(labelClassName, label, isOptional) : null
            }
            <div className={classnames(inputContainerClassName)}>
                <div className="d-flex flex-row bb-color-picker">
                    <div className="bb-color-picker-bg-container" onClick={handleClick}>
                        <div className="bb-color-picker-bg" style={{ background: field.value }} />
                    </div>
                    {displayColorPicker ? <div className="bb-color-picker-popover d-flex flex-column">
                        <div className="bb-color-picker-cover" onClick={handleClose} />
                        <div className="bb-color-picker-container">
                        <SketchPicker disableAlpha={true} color={field.value || ""}
                            onChange={async (color) => {
                                isHex ?
                                    await form.setFieldValue(field.name, color.hex) :
                                    await form.setFieldValue(field.name, `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b}, ${color.rgb.a})`)
                                
                            }}
                        />
                        <div className="d-flex flex-row justify-content-between bb-color-picker-button-container">
                            <button type="button"  onClick={handleClose} className="bb-button mb-2 align-self-center position-relative">Close </button>
                            <button type="button"  onClick={handleSelect} className="bb-button mb-2 align-self-center position-relative">Select</button>

                        </div>
                        </div>
                    </div> : null}


                    <input className={classnames("form-control", className, touched[field.name] && errors[field.name] ? 'error' : '')}
                        placeholder={placeholder ? placeholder : label}
                        type="input" {...field}
                        disabled={disabled}
                        onBlur={(col) => {
                            if (typeof onChange === 'function' && (color !== col.target.value)) {
                                onChange(col.target.value)
                                setColor(col.target.value)
                            }
                        }}
                        autoComplete={field.name}
                        onKeyPress={(e) => {
                            keyPressCheck && e.key === 'Enter' && e.preventDefault();
                        }}
                    />
                </div>
                <FormError name={field.name} />
            </div>
            {
                // eslint-disable-next-line
                labelPosition == "end" && label ? renderLabel(labelClassName, label, isOptional) : null
            }
        </div>
    )
}

export default ColorInput