export const CarouselFormatConstants = {
    ALL_CAROUSEL_FORMAT_CURRENT_PAGE: "ALL_CAROUSEL_FORMAT_CURRENT_PAGE",
    ALL_CAROUSEL_FORMAT_LIMIT_PER_PAGE: "ALL_CAROUSEL_FORMAT_LIMIT_PER_PAGE",
    ALL_CAROUSEL_FORMAT_SEARCH_VALUE: "ALL_CAROUSEL_FORMAT_SEARCH_VALUE",
    ALL_CAROUSEL_FORMAT_STATUS_VALUE: "ALL_CAROUSEL_FORMAT_STATUS_VALUE",

    GET_ALL_CAROUSEL_FORMAT_SUCCESS: "GET_ALL_CAROUSEL_FORMAT_SUCCESS",
    ALL_CAROUSEL_FORMAT_LIMIT: "ALL_CAROUSEL_FORMAT_LIMIT",
    GET_ALL_CAROUSEL_FORMAT_ERROR: "GET_ALL_CAROUSEL_FORMAT_ERROR",
    ALL_CAROUSEL_FORMAT_LOADING: "ALL_CAROUSEL_FORMAT_LOADING",

    GET_CAROUSEL_FORMAT_BY_ID: "GET_CAROUSEL_FORMAT_BY_ID",
    GET_CAROUSEL_FORMAT_BY_ID_ERROR: "GET_CAROUSEL_FORMAT_BY_ID_ERROR",

    DELETE_CAROUSEL_FORMAT_SUCCESS: 'DELETE_CAROUSEL_FORMAT_SUCCESS',
    DELETE_CAROUSEL_FORMAT_ERROR: 'DELETE_CAROUSEL_FORMAT_ERROR',
    ACTIVATE_CAROUSEL_FORMAT_SUCCESS: 'ACTIVATE_CAROUSEL_FORMAT_SUCCESS',
    ACTIVATE_CAROUSEL_FORMAT_ERROR: 'ACTIVATE_CAROUSEL_FORMAT_ERROR',


    BB_ADD_CAROUSEL_FORMAT_SUCCESS: 'BB_ADD_CAROUSEL_FORMAT_SUCCESS',
    BB_ADD_CAROUSEL_FORMAT_ERROR: 'BB_ADD_CAROUSEL_FORMAT_ERROR',

    BB_EDIT_CAROUSEL_FORMAT_SUCCESS: 'BB_EDIT_CAROUSEL_FORMAT_SUCCESS',
    BB_EDIT_CAROUSEL_FORMAT_ERROR: 'BB_EDIT_CAROUSEL_FORMAT_ERROR',

    EMPTY_CAROUSEL_FORMAT_PROFILE: 'EMPTY_CAROUSEL_FORMAT_PROFILE',
    EMPTY_ALL_CAROUSEL_FORMAT: 'EMPTY_ALL_CAROUSEL_FORMAT',
};
