export const SupplyConfigurationConstants = {
    ALL_SUPPLY_CONFIGURATION_CURRENT_PAGE: "ALL_SUPPLY_CONFIGURATION_CURRENT_PAGE",
    ALL_SUPPLY_CONFIGURATION_LIMIT_PER_PAGE: "ALL_SUPPLY_CONFIGURATION_LIMIT_PER_PAGE",

    GET_ALL_SUPPLY_CONFIGURATION_SUCCESS: "GET_ALL_SUPPLY_CONFIGURATION_SUCCESS",
    ALL_SUPPLY_CONFIGURATION_LIMIT: "ALL_SUPPLY_CONFIGURATION_LIMIT",
    GET_ALL_SUPPLY_CONFIGURATION_ERROR: "GET_ALL_SUPPLY_CONFIGURATION_ERROR",
    ALL_SUPPLY_CONFIGURATION_LOADING: "ALL_SUPPLY_CONFIGURATION_LOADING",

    
    GET_SUPPLY_CONFIGURATION_BY_ID: 'GET_SUPPLY_CONFIGURATION_BY_ID',
    EMPTY_SUPPLY_CONFIGURATION_BY_PROFILE: 'EMPTY_SUPPLY_CONFIGURATION_BY_PROFILE',
    GET_SUPPLY_CONFIGURATION_BY_ID_ERROR: 'GET_SUPPLY_CONFIGURATION_BY_ID_ERROR',

    ADD_SUPPLY_CONFIGURATION_SUCCESS: 'ADD_SUPPLY_CONFIGURATION_SUCCESS',
    ADD_SUPPLY_CONFIGURATION_ERROR: 'ADD_SUPPLY_CONFIGURATION_ERROR',

    EDIT_SUPPLY_CONFIGURATION_SUCCESS: 'EDIT_SUPPLY_CONFIGURATION_SUCCESS',
    EDIT_SUPPLY_CONFIGURATION_ERROR: 'EDIT_SUPPLY_CONFIGURATION_ERROR',

    ACTIVATE_SUPPLY_CONFIGURATION: 'ACTIVATE_SUPPLY_CONFIGURATION',
    ACTIVATE_SUPPLY_CONFIGURATION_ERROR: 'ACTIVATE_SUPPLY_CONFIGURATION_ERROR', 

    DELETE_SUPPLY_CONFIGURATION: 'DELETE_SUPPLY_CONFIGURATION',
    DELETE_SUPPLY_CONFIGURATION_ERROR: 'DELETE_SUPPLY_CONFIGURATION_ERROR',
};
