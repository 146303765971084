
import { AllCommentConstants } from './comment.constants';

export const comment = (state = {}, action) => {
    switch (action.type) {
        case AllCommentConstants.EMPTY_COMMENTS: return { ...state, data: "" }
        case AllCommentConstants.GET_COMMENTS:
        case AllCommentConstants.GET_COMMENTS_ERROR: return { ...state, data: action.payload }


        case AllCommentConstants.DELETE_COMMENT:
        case AllCommentConstants.DELETE_COMMENT_ERROR:
            return { ...state, deleteStatus: action.payload }

        case AllCommentConstants.ADD_COMMENT_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case AllCommentConstants.ADD_COMMENT_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case AllCommentConstants.EDIT_COMMENT_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case AllCommentConstants.EDIT_COMMENT_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        case AllCommentConstants.SEND_TO_BIG_ERROR:
        case AllCommentConstants.SEND_TO_BIG_SUCCESS: return { ...state, sendToBigStatus: action.payload }
        case AllCommentConstants.SEND_TO_CLIENT_SUCCESS:
        case AllCommentConstants.SEND_TO_CLIENT_ERROR: return { ...state, sendToClientStatus: action.payload }
        case AllCommentConstants.FEEDBACK_CONFIRMED_SUCCESS:
        case AllCommentConstants.FEEDBACK_CONFIRMED_ERROR: return { ...state, feedbackConfirmedtatus: action.payload }

        case AllCommentConstants.COMMENT_LOADING_STATUS: return { ...state, loading: action.payload }

        default:
            return state;
    }
}
