import { PerformanceFeedbackConstants } from './performanceFeedback.constants';

export const performanceFeedback = (state = {}, action) => {
    switch (action.type) {
        case PerformanceFeedbackConstants.GET_PERFORMANCE_FEEDBACK_SUCCESS: return { ...state, ...action.payload };
        case PerformanceFeedbackConstants.PERFORMANCE_FEEDBACK_LOADING: return { ...state, loading: action.payload };
        case PerformanceFeedbackConstants.GET_PERFORMANCE_FEEDBACK_ERROR: return { ...state, error: true };
        case PerformanceFeedbackConstants.PERFORMANCE_FEEDBACK_CURRENT_PAGE: return { ...state, page: action.payload };
        case PerformanceFeedbackConstants.PERFORMANCE_FEEDBACK_LIMIT_PER_PAGE: return { ...state, limit: action.payload };
        case PerformanceFeedbackConstants.PERFORMANCE_FEEDBACK_LIMIT: return { ...state, total: action.payload };

        default: return state;
    }
}