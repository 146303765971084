import React from 'react'
import classnames from 'classnames'
import { Field } from 'formik'
import FormImage from './FormImage'
import VideoPreview from "./video-preview"

class EditImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFileRemoved: false
    }
  }

  removeFile = (setFieldValue, field) => {
    setFieldValue(field.name, null);
    field.value = null;
    this.setState({ isFileRemoved: true });
  };

  renderThumbnail = () => {
    const { previewClassName, isVideo = false, isDoc = false, field } = this.props;

    if (isVideo) {
      return (
        <div className="position-relative video-preview">
          <VideoPreview className={classnames(previewClassName, "w-100")} src={field.value} />
          <i className="fas fa-video fa-video-camera position-absolute right-4 right-bottom" alt="" />
        </div>
      )
    }

    return (
      <img src={isDoc ? "/images/documentIcon.svg" : field.value}
        onClick={() => { if (isDoc) { window.open(field.value, '_blank'); } }}
        alt="" className={classnames(previewClassName, "align-self-center")} />
    )
  }

  render() {

    const { previewContainerClassName, containerClassName, setFieldValue, toBase64 = false, 
      field, isLabelHtml, label, isVideo = false, isDoc = false, accept = "", isMedia = false, isDisabled, strictDivClassName = false, favicon = false, imageWithGif = false } = this.props;
    const { isFileRemoved } = this.state;

    return (
      isFileRemoved || !field.value || typeof field.value === "object"
        ?
        <Field
          component={FormImage} containerClassName={strictDivClassName ? containerClassName : classnames("bb-logo-upload align-self-center", containerClassName)} name={field.name}
          previewContainerClassName={previewContainerClassName}
          setFieldValue={setFieldValue}
          isLabelHtml={isLabelHtml}
          favicon={favicon}
          imageWithGif={imageWithGif}
          label={label}
          toBase64={toBase64}
          isVideo={isVideo}
          isDoc={isDoc}
          isMedia={isMedia}
          accept={accept}
          onChange={async (event) => {
            if (typeof this.props.onChange === 'function') {
              this.props.onChange(this.props.values);
            }
          }}
        />
        :
        <div id="bb-image-upload-container" className={classnames("bb-image-upload-container", containerClassName)}>
          {isDisabled ? null :
            <i
              className="bb-remove-image fas fa-trash-alt fa-1dot5x"
              onClick={async (event) => {
                await this.removeFile(setFieldValue, field)
                if (this.props.onRemoveChange) {
                  this.props.onChange(this.props.values);
                }
              }}
            />
          }
          <div className={classnames(previewContainerClassName, "bb-preview-container d-flex")}>
            {this.renderThumbnail()}

          </div>
        </div>
    );
  }

}

export default EditImage