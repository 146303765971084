import React from "react";
import Spinner from '../utilities/Spinner'
import classnames from 'classnames'
import VideoPreview from "../form-helpers/video-preview"

class Preview extends React.Component {
    state = {
        loading: false,
        previewSrc: undefined,
        video:false
    };


    init(props) {
        if (!props.file) {
            return;
        }

        this.setState({ loading: true }, () => {
            let reader = new FileReader();

            reader.onloadend = () => {
                if(this.props.isMedia && reader.result.includes("video")){
                    this.setState({ loading: false, previewSrc: reader.result, video: true });
                }else{
                    this.setState({ loading: false, previewSrc: reader.result });
                }
                
            };

            reader.readAsDataURL(props.file);
        });
    }

    componentDidMount() {
        this.init(this.props)
    }
    
    componentDidUpdate(prevProps) {
        const { file } = this.props;
        if (prevProps && (prevProps.file !== file)) {
            this.init(this.props)
        }
    }

    renderThumbnail = () => {        
        const { file,previewClassName, isVideo, isDoc } = this.props;
        const { previewSrc, video } = this.state;

        if(isVideo || video){
            return (
                <div className="position-relative video-preview">
                    <VideoPreview className={classnames(previewClassName, "w-100")} src={previewSrc}/>
                    <i className="fas fa-video fa-video-camera position-absolute right-4 right-bottom" alt="" />
                </div>
            )
        }
        //isVideo ? "/images/videoIcon.svg" :
        return (
            <img src={isDoc ? "/images/documentIcon.svg" :  previewSrc} alt={file.name} className={classnames(previewClassName,"align-self-center")} />
        )
    }

    render() {
        const { file, previewContainerClassName } = this.props; 
        const { loading } = this.state;

        if (!file) {
            return null; 
        }

        if (loading) {
            return <Spinner />;
        }

        return (
            <div className={classnames(previewContainerClassName, "bb-preview-container d-flex")} 
            onDragExit={this.props.onDragExit}
                onDragLeave={this.props.onDragExit}
                onDragEnter={this.props.onDragOver}
                onDragOver={this.props.onDragOver}
                onDrop={this.props.onDrop}
            >
                          {this.renderThumbnail()}      
            </div>
        );   
    }  
}

export default Preview 