export const DealMonitoringConstants = {
    GET_ALL_DEAL_MONITORING_SUCCESS: "GET_ALL_DEAL_MONITORING_SUCCESS",
    ALL_DEAL_MONITORING_LIMIT: "ALL_DEAL_MONITORING_LIMIT",
    GET_ALL_DEAL_MONITORING_ERROR: "GET_ALL_DEAL_MONITORING_ERROR",
    EMPTY_DEAL_MONITORING: "EMPTY_DEAL_MONITORING",
    ALL_DEAL_MONITORING_LOADING: "ALL_DEAL_MONITORING_LOADING",
    GET_DEAL_BY_TOKEN_SUCCESS: "GET_DEAL_BY_TOKEN_SUCCESS",
    GET_DEAL_BY_TOKEN_ERROR: "GET_DEAL_BY_TOKEN_ERROR",
    DEAL_BY_TOKEN_LOADING: "DEAL_BY_TOKEN_LOADING",
    EMPTY_DEAL_BY_TOKEN: "EMPTY_DEAL_BY_TOKEN",
};
