export const VerticalsConstants = {
    ALL_SHOWCASE_VERTICALS_CURRENT_PAGE: "ALL_SHOWCASE_VERTICALS_CURRENT_PAGE",
    ALL_SHOWCASE_VERTICALS_LIMIT_PER_PAGE: "ALL_SHOWCASE_VERTICALS_LIMIT_PER_PAGE",
    ALL_SHOWCASE_VERTICALS_SEARCH_VALUE: "ALL_SHOWCASE_VERTICALS_SEARCH_VALUE",
    ALL_SHOWCASE_VERTICALS_STATUS_VALUE: "ALL_SHOWCASE_VERTICALS_STATUS_VALUE",

    GET_ALL_SHOWCASE_VERTICALS: "GET_ALL_SHOWCASE_VERTICALS",
    ALL_SHOWCASE_VERTICALS_LIMIT: "ALL_SHOWCASE_VERTICALS_LIMIT",
    GET_ALL_SHOWCASE_VERTICALS_ERROR: "GET_ALL_SHOWCASE_VERTICALS_ERROR",
    ALL_SHOWCASE_VERTICALS_LOADING: "ALL_SHOWCASE_VERTICALS_LOADING",

    DELETE_SHOWCASE_VERTICALS_SUCCESS: 'DELETE_SHOWCASE_VERTICALS_SUCCESS',
    DELETE_SHOWCASE_VERTICALS_ERROR: 'DELETE_SHOWCASE_VERTICALS_ERROR',
    ACTIVATE_SHOWCASE_VERTICALS_SUCCESS: 'ACTIVATE_SHOWCASE_VERTICALS_SUCCESS',
    ACTIVATE_SHOWCASE_VERTICALS_ERROR: 'ACTIVATE_SHOWCASE_VERTICALS_ERROR',


    BB_ADD_SHOWCASE_VERTICALS_SUCCESS: 'BB_ADD_SHOWCASE_VERTICALS_SUCCESS',
    BB_ADD_SHOWCASE_VERTICALS_ERROR: 'BB_ADD_SHOWCASE_VERTICALS_ERROR',
    BB_ADD_SHOWCASE_VERTICALS_LOADING: 'BB_ADD_SHOWCASE_VERTICALS_LOADING',

    BB_EDIT_SHOWCASE_VERTICALS_SUCCESS: 'BB_EDIT_SHOWCASE_VERTICALS_SUCCESS',
    BB_EDIT_SHOWCASE_VERTICALS_ERROR: 'BB_EDIT_SHOWCASE_VERTICALS_ERROR',

    BB_GET_ACTIVE_SHOWCASE_VERTICALS_INIT: 'BB_GET_ACTIVE_SHOWCASE_VERTICALS_INIT',
    BB_GET_ACTIVE_SHOWCASE_VERTICALS_SUCCESS: 'BB_GET_ACTIVE_SHOWCASE_VERTICALS_SUCCESS',
    BB_GET_ACTIVE_SHOWCASE_VERTICALS_ERROR: 'BB_GET_ACTIVE_SHOWCASE_VERTICALS_ERROR'
};
