import { EmissionReportingConstants } from './reporting.constants';

export const allReporting = (state = {}, action) => {
    switch (action.type) {
        case EmissionReportingConstants.GET_ALL_EMISSION_REPORTING_SUCCESS: return { ...state, ...action.payload };
        case EmissionReportingConstants.ALL_EMISSION_REPORTING_LOADING: return { ...state, loading: action.payload };
        case EmissionReportingConstants.GET_ALL_EMISSION_REPORTING_ERROR: return { ...state, error: true };
        
        default:
            return state;
    }
}