import { combineReducers } from 'redux';
import { method } from './methods/method.reducer'
import { company } from './company/company.reducer'
import { offsetOptions } from './offset-options/offsetOptions.reducer'
import { pendingEmission } from "./pending-emission/pendingEmission.reducer"
import { myEmission } from "./my-emmision/myEmission.reducer"
import { events } from "./events/events.reducer"
import { offsetCategory } from "./offset-category/offsetCategory.reducer"
import { offsetEmission } from "./offset-emmision/offsetEmission.reducer"
import {southpole} from "./southpole/southpole.reducer"

export const greenProject = combineReducers({
    method,
    company,
    offsetOptions,
    myEmission,
    pendingEmission,
    events,
    offsetCategory,
    offsetEmission,
    southpole
});
