import { AnalyticsConstants } from "./analytics.constants";

export const analytics = (state = {}, action) => {
    switch (action.type) {
        case AnalyticsConstants.GET_ALL_ANALYTICS_SUCCESS: return { ...state, ...action.payload }
        case AnalyticsConstants.GET_ALL_ANALYTICS_ERROR: return { ...state, ...action.payload }

        case AnalyticsConstants.EMPTY_ANALYTICS: return { ...state, data: "", summary: "" }

        case AnalyticsConstants.ANALYTICS_LOADING_STATUS: return { ...state, loading: action.payload }
        
        case AnalyticsConstants.GET_ALL_COMPANIES: return { ...state, allCompanies: action.payload }

        default:
            return state;
    }
};
