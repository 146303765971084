import React from 'react'
import DatePicker from 'react-datepicker';
import classnames from 'classnames'
import { ErrorMessage  } from 'formik'
import "./date-picker.scss"

class CustomDatePicker extends React.Component {
    render() {
        const {
            field, label ="", placeholder = "", minDate = false, disabled = false, className, dateFormat="MM/dd/yyyy",
            setFieldValue,
            containerClassName, labelClassName, inputContainerClassName, inline = false, isClearable = false, maxDate = false, isMonthPicker = false
            //form: { touched, errors }
        } = this.props


        return (
            <div className={classnames(containerClassName, "bb-form-datepicker")}>
                {label && <label className={classnames("pt-2 mb-1", labelClassName)}>{label}</label>}
                <div className={inputContainerClassName}>
                    <DatePicker
                        autoComplete="off"
                        disabled={disabled}
                        dateFormat={dateFormat}
                        selected={field.value}
                        peekNextMonth
                        maxDate={maxDate}
                        inline={inline}
                        isClearable={isClearable}
                        showMonthDropdown
                        showYearDropdown
                        showMonthYearPicker={isMonthPicker}
                        minDate={minDate}
                        // minDate={!minDate ? subDays(new Date(), 1) : new Date(minDate)}
                        dropdownMode="select"
                        onChange={(date) => { setFieldValue(field.name, date) }}
                        name={field.name}
                        onBlur={field.onBlur}
                        placeholderText={placeholder}
                        className={classnames("form-control bb-date-picker", className)}
                    />
                    {/* {<span className={classnames(touched[field.name] && errors[field.name] ? "required" : "required-nt")}>{errors[field.name]}</span>} */}
                    <ErrorMessage name={field.name} render={msg => <span className="required">{msg}</span>}/>              
                    {
                        //disabled && touched[field.name] && errors[field.name] && <span className="required">{errors[field.name]}</span>
                    }
                </div>
            </div>
        )
    }
}

export default CustomDatePicker