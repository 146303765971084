export const SuperFormatConstants = {
    ALL_SUPER_FORMAT_CURRENT_PAGE: "ALL_SUPER_FORMAT_CURRENT_PAGE",
    ALL_SUPER_FORMAT_LIMIT_PER_PAGE: "ALL_SUPER_FORMAT_LIMIT_PER_PAGE",
    ALL_SUPER_FORMAT_SEARCH_VALUE: "ALL_SUPER_FORMAT_SEARCH_VALUE",
    ALL_SUPER_FORMAT_STATUS_VALUE: "ALL_SUPER_FORMAT_STATUS_VALUE",

    GET_ALL_SUPER_FORMAT_SUCCESS: "GET_ALL_SUPER_FORMAT_SUCCESS",
    ALL_SUPER_FORMAT_LIMIT: "ALL_SUPER_FORMAT_LIMIT",
    GET_ALL_SUPER_FORMAT_ERROR: "GET_ALL_SUPER_FORMAT_ERROR",
    ALL_SUPER_FORMAT_LOADING: "ALL_SUPER_FORMAT_LOADING",

    DELETE_SUPER_FORMAT_SUCCESS: 'DELETE_SUPER_FORMAT_SUCCESS',
    DELETE_SUPER_FORMAT_ERROR: 'DELETE_SUPER_FORMAT_ERROR',
    ACTIVATE_SUPER_FORMAT_SUCCESS: 'ACTIVATE_SUPER_FORMAT_SUCCESS',
    ACTIVATE_SUPER_FORMAT_ERROR: 'ACTIVATE_SUPER_FORMAT_ERROR',


    BB_ADD_SUPER_FORMAT_SUCCESS: 'BB_ADD_SUPER_FORMAT_SUCCESS',
    BB_ADD_SUPER_FORMAT_ERROR: 'BB_ADD_SUPER_FORMAT_ERROR',
    BB_ADD_SUPER_FORMAT_LOADING: 'BB_ADD_SUPER_FORMAT_LOADING',

    BB_EDIT_SUPER_FORMAT_SUCCESS: 'BB_EDIT_SUPER_FORMAT_SUCCESS',
    BB_EDIT_SUPER_FORMAT_ERROR: 'BB_EDIT_SUPER_FORMAT_ERROR',

    BB_GET_USER_SUPER_FORMAT_INIT: 'BB_GET_USER_SUPER_FORMAT_INIT',
    BB_GET_USER_SUPER_FORMAT_SUCCESS: 'BB_GET_USER_SUPER_FORMAT_SUCCESS',
    BB_GET_USER_SUPER_FORMAT_ERROR: 'BB_GET_USER_SUPER_FORMAT_ERROR',

    BB_GET_USER_SUPER_FORMAT_INIT_DIMENSION: 'BB_GET_USER_SUPER_FORMAT_INIT_DIMENSION',
    BB_GET_USER_SUPER_FORMAT_SUCCESS_DIMENSION: 'BB_GET_USER_SUPER_FORMAT_SUCCESS_DIMENSION',
    BB_GET_USER_SUPER_FORMAT_ERROR_DIMENSION: 'BB_GET_USER_SUPER_FORMAT_ERROR_DIMENSION',


    EMPTY_ALL_SUPER_FORMAT: 'EMPTY_ALL_SUPER_FORMAT',

    GET_EDIT_SUPER_FORMAT_INFO_INIT: "GET_EDIT_SUPER_FORMAT_INFO_INIT",
    GET_EDIT_SUPER_FORMAT_INFO_SUCCESS: "GET_EDIT_SUPER_FORMAT_INFO_SUCCESS",
    GET_EDIT_SUPER_FORMAT_INFO_ERROR: "GET_EDIT_SUPER_FORMAT_INFO_ERROR",
    EMPTY_EDIT_SUPER_FORMAT_INFO: "EMPTY_EDIT_SUPER_FORMAT_INFO"
};
