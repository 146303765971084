export const DominosBasketSizeConstants = {
    GET_ALL_DOMINOS_BASKET_SIZE_PAGINATE_SUCCESS: 'GET_ALL_DOMINOS_BASKET_SIZE_PAGINATE_SUCCESS',
    GET_ALL_DOMINOS_BASKET_SIZE_PAGINATE_ERROR: 'GET_ALL_DOMINOS_BASKET_SIZE_PAGINATE_ERROR',

    DOMINOS_BASKET_SIZE_CURRENT_PAGE: 'DOMINOS_BASKET_SIZE_CURRENT_PAGE',
    DOMINOS_BASKET_SIZE_LIMIT_PER_PAGE: 'DOMINOS_BASKET_SIZE_LIMIT_PER_PAGE',
    DOMINOS_BASKET_SIZE_SEARCH_VALUE: 'DOMINOS_BASKET_SIZE_SEARCH_VALUE',
    DOMINOS_BASKET_SIZE_STATUS_VALUE: 'DOMINOS_BASKET_SIZE_STATUS_VALUE',
    DOMINOS_BASKET_SIZE_TOTAL_COUNT: 'DOMINOS_BASKET_SIZE_TOTAL_COUNT',
    
    GET_DOMINOS_BASKET_SIZE_BY_ID: 'GET_DOMINOS_BASKET_SIZE_BY_ID',
    EMPTY_DOMINOS_BASKET_SIZE_BY_PROFILE: 'EMPTY_DOMINOS_BASKET_SIZE_BY_PROFILE',
    GET_DOMINOS_BASKET_SIZE_BY_ID_ERROR: 'GET_DOMINOS_BASKET_SIZE_BY_ID_ERROR',

    ADD_DOMINOS_BASKET_SIZE_SUCCESS: 'ADD_DOMINOS_BASKET_SIZE_SUCCESS',
    ADD_DOMINOS_BASKET_SIZE_ERROR: 'ADD_DOMINOS_BASKET_SIZE_ERROR',

    EDIT_DOMINOS_BASKET_SIZE_SUCCESS: 'EDIT_DOMINOS_BASKET_SIZE_SUCCESS',
    EDIT_DOMINOS_BASKET_SIZE_ERROR: 'EDIT_DOMINOS_BASKET_SIZE_ERROR',

    ACTIVATE_DOMINOS_BASKET_SIZE: 'ACTIVATE_DOMINOS_BASKET_SIZE',
    ACTIVATE_DOMINOS_BASKET_SIZE_ERROR: 'ACTIVATE_DOMINOS_BASKET_SIZE_ERROR', 

    DELETE_DOMINOS_BASKET_SIZE: 'DELETE_DOMINOS_BASKET_SIZE',
    DELETE_DOMINOS_BASKET_SIZE_ERROR: 'DELETE_DOMINOS_BASKET_SIZE_ERROR',
    
    DOMINOS_BASKET_SIZE_LOADING_STATUS: 'DOMINOS_BASKET_SIZE_LOADING_STATUS',

    EMPTY_DOMINOS_BASKET_SIZE: 'EMPTY_DOMINOS_BASKET_SIZE',
}