import { CategoryConstants } from "./categories.constants";

export const categories = (state = {}, action) => {
    switch (action.type) {
        case CategoryConstants.GET_ALL_CATEGORY_INIT: return { ...state, all: { loading: true } }
        case CategoryConstants.GET_ALL_CATEGORY_SUCCESS: return { ...state, all: action.payload }
        case CategoryConstants.GET_ALL_CATEGORY_ERROR: return { ...state, all: action.payload }

        case CategoryConstants.GET_ALL_CATEGORY_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case CategoryConstants.GET_ALL_CATEGORY_PAGINATE_ERROR: return { ...state, ...action.payload }

        case CategoryConstants.EMPTY_CATEGORY_BY_PROFILE: return { ...state, profile: "" }
        case CategoryConstants.GET_CATEGORY_BY_ID: return { ...state, profile: action.payload }
        case CategoryConstants.GET_CATEGORY_BY_ID_ERROR: return { ...state, profile: action.payload }

        case CategoryConstants.CATEGORY_CURRENT_PAGE: return { ...state, page: action.payload }

        case CategoryConstants.CATEGORY_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case CategoryConstants.CATEGORY_SEARCH_VALUE: return { ...state, search: action.payload }

        case CategoryConstants.CATEGORY_STATUS_VALUE: return { ...state, status: action.payload }

        case CategoryConstants.CATEGORY_TOTAL_COUNT: return { ...state, total: action.payload }

        case CategoryConstants.CATEGORY_LOADING_STATUS: return { ...state, loading: action.payload }

        case CategoryConstants.DELETE_CATEGORY:
        case CategoryConstants.DELETE_CATEGORY_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case CategoryConstants.ACTIVATE_CATEGORY:
        case CategoryConstants.ACTIVATE_CATEGORY_ERROR:
            return { ...state, activateStatus: action.payload }

        case CategoryConstants.ADD_CATEGORY_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case CategoryConstants.ADD_CATEGORY_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case CategoryConstants.EDIT_CATEGORY_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case CategoryConstants.EDIT_CATEGORY_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
