import React from 'react';
import classnames from 'classnames'
import './skeleton.scss';

function Skeleton(props) {
    const { width = '150px', height = '100%', widthRandomness = 0, heightRandomness = 0, borderRadius = '8px', color = 'grey', count = 1, animated = true, className="" } = props;
    const w = parseFloat(width);
    const h = parseFloat(height);
    const wm = width.toString().replace(/\d+/g, '');
    const hm = height.toString().replace(/\d+/g, '');
    const elements = [];

    for (let i = 0; i < count; i++) {
        const width = `${w - (Math.random() * w * widthRandomness)}${wm}`;
        const height = `${h - (Math.random() * h * heightRandomness)}${hm}`;
        elements.push(
            <span
                className={classnames("react-skeleton-load", `${animated ? 'animated' : ''}`, className)}
                key={i}
                style={{
                    width,
                    height,
                    borderRadius,
                    backgroundColor: color
                }}
            >
                &zwnj;
      </span>
        );
        if (i !== count - 1) {
            elements.push(<br key={`br-${i}`} />);
        }
    }

    return (
        <>
            {elements}
        </>
    );
};

export default Skeleton;