import { CarouselFormatConstants } from './carouselFormat.constants';
import { combineReducers } from 'redux';

const allCarouselFormat = (state = {}, action) => {
    switch (action.type) {
        case CarouselFormatConstants.GET_ALL_CAROUSEL_FORMAT_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case CarouselFormatConstants.ALL_CAROUSEL_FORMAT_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case CarouselFormatConstants.GET_ALL_CAROUSEL_FORMAT_ERROR:
            return {
                ...state,
                error: true
            };
        case CarouselFormatConstants.ALL_CAROUSEL_FORMAT_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case CarouselFormatConstants.ALL_CAROUSEL_FORMAT_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case CarouselFormatConstants.ALL_CAROUSEL_FORMAT_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case CarouselFormatConstants.ALL_CAROUSEL_FORMAT_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case CarouselFormatConstants.ALL_CAROUSEL_FORMAT_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case CarouselFormatConstants.DELETE_CAROUSEL_FORMAT_SUCCESS:
            return {
                ...state,
                carouselFormatDelete: action.payload
            };
        case CarouselFormatConstants.DELETE_CAROUSEL_FORMAT_ERROR:
            return {
                ...state,
                carouselFormatDelete: action.payload
            };
        case CarouselFormatConstants.ACTIVATE_CAROUSEL_FORMAT_SUCCESS:
            return {
                ...state,
                carouselFormatActivate: action.payload
            };
        case CarouselFormatConstants.ACTIVATE_CAROUSEL_FORMAT_ERROR:
            return {
                ...state,
                carouselFormatActivate: action.payload
            };
        case CarouselFormatConstants.BB_ADD_CAROUSEL_FORMAT_SUCCESS:
            return {
                ...state,
                addCarouselFormatSuccess: true,
                addResp: action.payload
            };
        case CarouselFormatConstants.BB_ADD_CAROUSEL_FORMAT_ERROR:
            return {
                ...state,
                addCarouselFormatSuccess: false,
                ...action.payload
            };
        case CarouselFormatConstants.BB_EDIT_CAROUSEL_FORMAT_SUCCESS:
            return {
                ...state,
                editCarouselFormatSuccess: true,
                editResp: action.payload
            };
        case CarouselFormatConstants.BB_EDIT_CAROUSEL_FORMAT_ERROR:
            return {
                ...state,
                editCarouselFormatSuccess: false,
                ...action.payload
            };
        case CarouselFormatConstants.EMPTY_ALL_CAROUSEL_FORMAT:
            return { ...state, dropDown: "" };

        case CarouselFormatConstants.EMPTY_CAROUSEL_FORMAT_PROFILE:
            return { ...state, profile: "" };
        case CarouselFormatConstants.GET_CAROUSEL_FORMAT_BY_ID:
        case CarouselFormatConstants.GET_CAROUSEL_FORMAT_BY_ID_ERROR:
            return { ...state, profile: action.payload };
        default:
            return state;
    }
}

export const carouselFormat = combineReducers({
    allCarouselFormat
});
