export const NetworkConstants = {
    GET_ALL_NETWORKS: 'GET_ALL_NETWORKS',
    GET_ALL_NETWORKS_ERROR: 'GET_ALL_NETWORKS_ERROR',

    NETWORK_CURRENT_PAGE: 'NETWORK_CURRENT_PAGE',
    NETWORK_LIMIT_PER_PAGE: 'NETWORK_LIMIT_PER_PAGE',
    NETWORK_SEARCH_VALUE: 'NETWORK_SEARCH_VALUE',
    NETWORK_FILTER_STATUS: 'NETWORK_FILTER_STATUS',
    NETWORK_TOTAL_COUNT: 'NETWORK_TOTAL_COUNT',

    ADD_NEW_NETWORK: 'ADD_NEW_NETWORK',    
    ADD_NEW_NETWORK_ERROR: 'ADD_NEW_NETWORK_ERROR',

    EDIT_NETWORK: 'EDIT_NETWORK',    
    EDIT_NETWORK_ERROR: 'EDIT_NETWORK_ERROR',

    DELETE_NETWORK: 'DELETE_NETWORK',
    DELETE_NETWORK_ERROR: 'DELETE_NETWORK_ERROR', 

    ACTIVATE_NETWORK: 'ACTIVATE_NETWORK',
    ACTIVATE_NETWORK_ERROR: 'ACTIVATE_NETWORK_ERROR', 
    
    GET_NETWORK_BY_ID: 'GET_NETWORK_BY_ID',
    GET_NETWORK_BY_ID_ERROR: 'GET_NETWORK_BY_ID_ERROR',

    NETWORK_LOADING_STATUS: 'NETWORK_LOADING_STATUS',
    
    EMPTY_NETWORK_PROFILE: 'EMPTY_NETWORK_PROFILE',

    CHECK_NETWORK_OWNERSHIP: 'CHECK_NETWORK_OWNERSHIP',

    GET_NETWORK_INFO : 'GET_NETWORK_INFO',
    GET_NETWORK_INFO_ERROR : 'GET_NETWORK_INFO_ERROR',
    EMPTY_NETWORK_INFO: 'EMPTY_NETWORK_INFO'
};
