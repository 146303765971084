export const FaqsConstants = {
    ALL_FAQS_CURRENT_PAGE: "ALL_FAQS_CURRENT_PAGE",
    ALL_FAQS_LIMIT_PER_PAGE: "ALL_FAQS_LIMIT_PER_PAGE",
    ALL_FAQS_SEARCH_VALUE: "ALL_FAQS_SEARCH_VALUE",
    ALL_FAQS_STATUS_VALUE: "ALL_FAQS_STATUS_VALUE",

    GET_ALL_FAQS: "GET_ALL_FAQS",
    ALL_FAQS_LIMIT: "ALL_FAQS_LIMIT",
    GET_ALL_FAQS_ERROR: "GET_ALL_FAQS_ERROR",
    ALL_FAQS_LOADING: "ALL_FAQS_LOADING",

    DELETE_FAQS_SUCCESS: 'DELETE_FAQS_SUCCESS',
    DELETE_FAQS_ERROR: 'DELETE_FAQS_ERROR',
    ACTIVATE_FAQS_SUCCESS: 'ACTIVATE_FAQS_SUCCESS',
    ACTIVATE_FAQS_ERROR: 'ACTIVATE_FAQS_ERROR',

    GET_FAQ_BY_ID: 'GET_FAQ_BY_ID',
    GET_FAQ_BY_ID_SUCCESS: 'GET_FAQ_BY_ID_SUCCESS',
    GET_FAQ_BY_ID_ERROR: 'GET_FAQ_BY_ID_ERROR',

    BB_ADD_FAQS_SUCCESS: 'BB_ADD_FAQS_SUCCESS',
    BB_ADD_FAQS_ERROR: 'BB_ADD_FAQS_ERROR',
    BB_ADD_FAQS_LOADING: 'BB_ADD_FAQS_LOADING',

    BB_EDIT_FAQS_SUCCESS: 'BB_EDIT_FAQS_SUCCESS',
    BB_EDIT_FAQS_ERROR: 'BB_EDIT_FAQS_ERROR',

    BB_GET_ACTIVE_FAQS_INIT: 'BB_GET_ACTIVE_FAQS_INIT',
    BB_GET_ACTIVE_FAQS_SUCCESS: 'BB_GET_ACTIVE_FAQS_SUCCESS',
    BB_GET_ACTIVE_FAQS_ERROR: 'BB_GET_ACTIVE_FAQS_ERROR'
};
