export const MasterSitelistConstants = {
    GET_ALL_MASTER_SITELIST_INIT: 'GET_ALL_MASTER_SITELIST_INIT',
    GET_ALL_MASTER_SITELIST_SUCCESS: 'GET_ALL_MASTER_SITELIST_SUCCESS',
    GET_ALL_MASTER_SITELIST_ERROR: 'GET_ALL_MASTER_SITELIST_ERROR',

    GET_ALL_MASTER_SITELIST_PAGINATE_SUCCESS: 'GET_ALL_MASTER_SITELIST_PAGINATE_SUCCESS',
    GET_ALL_MASTER_SITELIST_PAGINATE_ERROR: 'GET_ALL_MASTER_SITELIST_PAGINATE_ERROR',

    MASTER_SITELIST_CURRENT_PAGE: 'MASTER_SITELIST_CURRENT_PAGE',
    MASTER_SITELIST_LIMIT_PER_PAGE: 'MASTER_SITELIST_LIMIT_PER_PAGE',
    MASTER_SITELIST_SEARCH_VALUE: 'MASTER_SITELIST_SEARCH_VALUE',
    MASTER_SITELIST_STATUS_VALUE: 'MASTER_SITELIST_STATUS_VALUE',
    MASTER_SITELIST_TOTAL_COUNT: 'MASTER_SITELIST_TOTAL_COUNT',
    
    GET_MASTER_SITELIST_BY_ID: 'GET_MASTER_SITELIST_BY_ID',
    EMPTY_MASTER_SITELIST_BY_PROFILE: 'EMPTY_MASTER_SITELIST_BY_PROFILE',
    GET_MASTER_SITELIST_BY_ID_ERROR: 'GET_MASTER_SITELIST_BY_ID_ERROR',

    ADD_MASTER_SITELIST_SUCCESS: 'ADD_MASTER_SITELIST_SUCCESS',
    ADD_MASTER_SITELIST_ERROR: 'ADD_MASTER_SITELIST_ERROR',

    EDIT_MASTER_SITELIST_SUCCESS: 'EDIT_MASTER_SITELIST_SUCCESS',
    EDIT_MASTER_SITELIST_ERROR: 'EDIT_MASTER_SITELIST_ERROR',

    ACTIVATE_MASTER_SITELIST: 'ACTIVATE_MASTER_SITELIST',
    ACTIVATE_MASTER_SITELIST_ERROR: 'ACTIVATE_MASTER_SITELIST_ERROR', 

    DELETE_MASTER_SITELIST: 'DELETE_MASTER_SITELIST',
    DELETE_MASTER_SITELIST_ERROR: 'DELETE_MASTER_SITELIST_ERROR',
    
    MASTER_SITELIST_LOADING_STATUS: 'MASTER_SITELIST_LOADING_STATUS',

    EMPTY_MASTER_SITELIST: 'EMPTY_MASTER_SITELIST',
}