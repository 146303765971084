import { DealTokenRefConstants } from "./dealTokenRef.constants";

export const dealTokenRefs = (state = {}, action) => {
    switch (action.type) {
        case DealTokenRefConstants.GET_ALL_DEAL_TOKEN_REF_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case DealTokenRefConstants.GET_ALL_DEAL_TOKEN_REF_PAGINATE_ERROR: return { ...state, ...action.payload }

        case DealTokenRefConstants.DEAL_TOKEN_REF_CURRENT_PAGE: return { ...state, page: action.payload }
        case DealTokenRefConstants.DEAL_TOKEN_REF_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case DealTokenRefConstants.DEAL_TOKEN_REF_TOTAL_COUNT: return { ...state, total: action.payload }
        case DealTokenRefConstants.DEAL_TOKEN_REF_LOADING_STATUS: return { ...state, loading: action.payload }

        default:
            return state;
    }
};
