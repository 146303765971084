import { DailyTestLogConstants } from './log.constants';

export const log = (state = {}, action) => {
    switch (action.type) {
        case DailyTestLogConstants.GET_ALL_DAILY_TEST_LOG_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case DailyTestLogConstants.ALL_DAILY_TEST_LOG_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case DailyTestLogConstants.GET_ALL_DAILY_TEST_LOG_ERROR:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }
}