export const TFEConfigConstants = {
    ALL_TFE_CONFIG_CURRENT_PAGE: "ALL_TFE_CONFIG_CURRENT_PAGE",
    ALL_TFE_CONFIG_LIMIT_PER_PAGE: "ALL_TFE_CONFIG_LIMIT_PER_PAGE",
    ALL_TFE_CONFIG_STATUS_VALUE: 'ALL_TFE_CONFIG_STATUS_VALUE',

    GET_ALL_TFE_CONFIG_SUCCESS: "GET_ALL_TFE_CONFIG_SUCCESS",
    ALL_TFE_CONFIG_LIMIT: "ALL_TFE_CONFIG_LIMIT",
    GET_ALL_TFE_CONFIG_ERROR: "GET_ALL_TFE_CONFIG_ERROR",
    ALL_TFE_CONFIG_LOADING: "ALL_TFE_CONFIG_LOADING",
    
    DELETE_TFE_CONFIG_SUCCESS: 'DELETE_TFE_CONFIG_SUCCESS',
    DELETE_TFE_CONFIG_ERROR: 'DELETE_TFE_CONFIG_ERROR',
    ACTIVATE_TFE_CONFIG_SUCCESS: 'ACTIVATE_TFE_CONFIG_SUCCESS',
    ACTIVATE_TFE_CONFIG_ERROR: 'ACTIVATE_TFE_CONFIG_ERROR',
};
