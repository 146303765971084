export const AirtoryBuilderConstants = {

    AIRTORY_BUILDER_CREATIVE_LOADING: 'AIRTORY_BUILDER_CREATIVE_LOADING',
    AIRTORY_BUILDER_NEW_CREATIVE_SUCCESS: 'AIRTORY_BUILDER_NEW_CREATIVE_SUCCESS',
    AIRTORY_BUILDER_NEW_CREATIVE_ERROR: 'AIRTORY_BUILDER_NEW_CREATIVE_ERROR',

    AIRTORY_BUILDER_UNMOUNT: 'AIRTORY_BUILDER_UNMOUNT',

    AIRTORY_BUILDER_ASSET_LOADING: 'AIRTORY_BUILDER_ASSET_LOADING',
    AIRTORY_BUILDER_ADD_ASSET_SUCCESS: 'AIRTORY_BUILDER_ADD_ASSET_SUCCESS',
    AIRTORY_BUILDER_ADD_ASSET_ERROR: 'AIRTORY_BUILDER_ADD_ASSET_ERROR',

    AIRTORY_BUILDER_PREVIEW_LOADING: 'AIRTORY_BUILDER_PREVIEW_LOADING',
    AIRTORY_GENERATE_PREVIEW_SUCCESS: 'AIRTORY_GENERATE_PREVIEW_SUCCESS',
    AIRTORY_GENERATE_PREVIEW_ERROR: 'AIRTORY_GENERATE_PREVIEW_ERROR',

    AIRTORY_GET_TAG_SUCCESS: 'AIRTORY_GET_TAG_SUCCESS',
    AIRTORY_GET_TAG_ERROR: 'AIRTORY_GET_TAG_ERROR',

    AIRTORY_BUILDER_REMOVE_ASSET_SUCCESS: 'AIRTORY_BUILDER_REMOVE_ASSET_SUCCESS',
    AIRTORY_BUILDER_REMOVE_ASSET_ERROR: 'AIRTORY_BUILDER_REMOVE_ASSET_ERROR',

    INIT_AIRTORY_BUILDER_ADD:'INIT_AIRTORY_BUILDER_ADD',

    AIRTORY_BUILDER_UPDATE_SUCCESS:'AIRTORY_BUILDER_UPDATE_SUCCESS',
    AIRTORY_BUILDER_UPDATE_ERROR:'AIRTORY_BUILDER_UPDATE_ERROR',

    AIRTORY_STATUS_LOG_INIT:'AIRTORY_STATUS_LOG_INIT',
    AIRTORY_STATUS_LOG_SUCCESS:'AIRTORY_STATUS_LOG_SUCCESS',
    AIRTORY_STATUS_LOG_ERROR:'AIRTORY_STATUS_LOG_ERROR',

    AIRTORY_BUILDER_SOCIALMEDIA_DATA_LOADING: 'AIRTORY_BUILDER_SOCIALMEDIA_DATA_LOADING',
    AIRTORY_BUILDER_SOCIALMEDIA_DATA_SUCCESS: 'AIRTORY_BUILDER_SOCIALMEDIA_DATA_SUCCESS',
    AIRTORY_BUILDER_SOCIALMEDIA_DATA_ERROR: 'AIRTORY_BUILDER_SOCIALMEDIA_DATA_ERROR',
    AIRTORY_BUILDER_EMPTY_ASSET_ID:'AIRTORY_BUILDER_EMPTY_ASSET_ID'



};
