import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import classnames from 'classnames'
import { FormError } from './FormError'
import { getValueFromObject } from '../utilities/customFunctions'
import { renderFormatGuide, renderNoAddonInfo } from '../audiences/render-helpers/format-guide'
import Tooltip from '../utilities/Tooltip'
import Skeleton from '../../helpers/Skeleton'
import parse from "html-react-parser"
import { decarboniseEyeIcon } from '../utilities/customRenders';
import { isEqual } from 'lodash';
import { usePrevious } from '../utilities/customHooks';

function SelectInput(props) {

    const
        { disabled, isEdit = false, options, field, form, classNamePrefix, containerClassName, inputContainerClassName, menuPlacement = "auto",
            isLoading = false, onChange, labelSeparator = " ", isSearchable = true, portalTarget = null, customOptionLabel = null, multiLimit = false, bottomClassName ="",
            labelClassName, className, placeholder, label, selectLabel = "label", selectValue = "value", isOptional = false, form: { setFieldTouched },
            indexNumber, isClearable = false, disabledField = "disable", disabledBoolean = true, disableThisField = "", disableThisBoolean = false,
            showHelpIcon = false, skeletonLoading = false, showNoAddonInfo = false, subLabel = "" , subLabelName = "", hasSubLabel = false, creatable = false, isHelpFlex = window.innerWidth < 576, 
            iconImage = false, onClickIcon = null, hasSelectAll = false, checkDecarbonise = false, selectAllValue = null, bottomTextClick = false, bottomText = false, isAdmin = false
        } = props;
    const [dynamicOptions, setDynamicOptions] = useState(options)
    const selectAllOption = {id: "BB_SELECT_ALL", [selectLabel]: "All", [selectValue]: "BB_SELECT_ALL"}
    const prevProps = usePrevious(props)
    
    useEffect(() => {
        if(creatable && options && !options.find(opt => opt.value === field.value)){
            setDynamicOptions([...dynamicOptions, {[selectLabel]: field.value, [selectValue]: field.value}])
        }
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        if(options && props.isMulti && hasSelectAll){
            if(field.value?.length !== options.length){
                if(selectAllValue && field?.value[0]?.id === "ALL") setDynamicOptions([])
                else setDynamicOptions([ selectAllOption, ...options])
            }
            else{
                setDynamicOptions([ ...options])
            }
        }
        if(creatable && options && !isEqual(options, prevProps?.options)) setDynamicOptions([ ...options])
        //eslint-disable-next-line
    }, [options])
    const getOptionLabel = (option) => {
        if(customOptionLabel && option.id !== "BB_SELECT_ALL" && option.id !== selectAllValue) return parse(customOptionLabel(option))
        if (typeof selectLabel === "string") {
            if(creatable && option.__isNew__) return option.label
            return option[selectLabel];
        } else {
            var o = "";
            for (var i = 0; i < selectLabel.length; i++) {
                if (i !== selectLabel.length - 1) {
                    o += option[selectLabel[i]] + labelSeparator;
                }
                else {
                    o += option[selectLabel[i]];
                }

            }
            return o.trim();
        }
    }

    const formatOptionLabel = (option) => {
        let optionLabel = <>
            {getOptionLabel(option)}{checkDecarbonise && option?.decarbonise ? <span className="card-dercarbonised-icon ml-2">{decarboniseEyeIcon()}</span> : ""}
        </>
        if(hasSubLabel){
            return (
                <div className={classnames('row', option[disableThisField] === disableThisBoolean ? "bb-color_disabled" : "")}>
                    <div className='col-7'>{optionLabel}</div>
                    <div  className='col-5'>
                        {option[subLabelName] ? <div className={classnames('d-flex bb-color_disabled', subLabel ? "justify-content-between" : "justify-content-end")}>
                            {subLabel ? <span>{subLabel}:</span> : null}
                            <span className='pl-1 text-truncate'>{option[subLabelName]}</span>
                        </div> : null}
                    </div>
                </div>
            )
        }
        if (option[disableThisField] === disableThisBoolean) {
            return (
                <div style={{ color: "#858585" }}>
                    {optionLabel}
                </div>
            )
        }
        else {
            return (<div className="d-flex align-items-center">{optionLabel}</div>);
        }
    };
    const selectProps = {
        isMulti: props.isMulti === true ? true : false,
        options: (creatable || hasSelectAll ? dynamicOptions : options),
        name: field.name,
        value: isEdit && (field.value !== undefined && field.value !== null) && (creatable || hasSelectAll ? dynamicOptions : options) && (creatable || hasSelectAll ? dynamicOptions : options).length ? (getValueFromObject((creatable || hasSelectAll ? dynamicOptions : options), typeof field.value === "object" ? field.value[selectValue] : field.value, selectValue)) : field.value,
        //value: dynamicOptions ? dynamicOptions.find(option => option[selectValue] === field.value) : '',
        //getOptionLabel: (option) => option[selectLabel],
        getOptionLabel: getOptionLabel,
        getOptionValue: (option) => option[selectValue],
        onFocus: f => setFieldTouched(field.name, true, true),
        onChange: async (opt, opt2) => {
            let option = opt, allSelected = option?.length === options?.length
            if(creatable && option.__isNew__){
                option = {[selectLabel]: option.label, [selectValue]: option.value}
                setDynamicOptions([...dynamicOptions, option])
            }
            
            if(hasSelectAll && props.isMulti && (option[option.length-1]?.id === "BB_SELECT_ALL" || option[option.length-1]?.id === selectAllValue)) {
                allSelected = true
                if(selectAllValue) await form.setFieldValue(field.name, {...selectAllOption, [selectValue]: selectAllValue})
                else await form.setFieldValue(field.name, options)
            } else {
                await form.setFieldValue(field.name, option);
            }

            if(props.isMulti && hasSelectAll){
                if(allSelected) {
                    if(selectAllValue) setDynamicOptions([])
                    else setDynamicOptions([...options])
                }
                else setDynamicOptions([ selectAllOption, ...options])
            }

            if (typeof props.showPreview === 'function') {
                props.showPreview(props.values)
            }
            if (onChange) { onChange(option, form.setFieldValue, indexNumber, opt2); }
        },
        onBlur: field.onBlur,
        placeholder: (placeholder || "Choose..."),
        className: classnames(className, form.touched[field.name] && form.errors[field.name] ? 'bb-select-error' : ''),
        isDisabled: disabled,
        classNamePrefix: classNamePrefix || "bb-select",
        //menuPortalTarget: portalBody ? document.getElementById(portalBody) : document.body,
        menuPortalTarget: portalTarget,
        isLoading: isLoading,
        styles: {menuPortal: base => ({ ...base, zIndex: 9999 }) },
        menuPlacement: (menuPlacement || "auto"),
        isClearable: isClearable,
        isOptionDisabled: (option) => {
            let disable = false
            if (option[disabledField] === disabledBoolean) {
                disable = true
            }
            else if (multiLimit) {
                let val = isEdit && (field.value !== undefined && field.value !== null) && (creatable || hasSelectAll ? dynamicOptions : options) && (creatable || hasSelectAll ? dynamicOptions : options).length ? (getValueFromObject((creatable || hasSelectAll ? dynamicOptions : options), typeof field.value === "object" ? field.value[selectValue] : field.value, selectValue)) : field.value
                disable = val.length >= multiLimit
            }
            return disable
        },
        formatOptionLabel: (disableThisField || hasSubLabel || checkDecarbonise) && formatOptionLabel,
        isSearchable: isSearchable,
    }
    const renderHelp = () => {
        return <Tooltip light={true}
            tooltipClassName={"budster-format-help-container"}
            className={classnames("cursor-pointer", "align-self-center")} label={""} htmlFor={""} iconname="fa-info-circle fa-1dot5x">
            {renderFormatGuide()}
        </Tooltip>
    }
    const renderAddon = () => {
        return <>
            <Tooltip light={true}
                default_id={"Addon"}
                tooltipClassName={"budster-format-help-container "}
                className={classnames("cursor-pointer", "align-self-center ml-2")} label={""} htmlFor={""} iconname="fa-bell fa-1dot5x">
                {renderNoAddonInfo()}
            </Tooltip>
            </>
    }
    return (
        <div className={containerClassName}>
            {label && <label className={classnames("pt-2 mb-1", labelClassName)}>{label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}{isAdmin ?

                <Tooltip default_id="star" light={true} className="m-0 cursor-pointer" label={""} iconname="fas fa-star ml-1 ">
                    <div className="p-2 text-left maxWidth-300"><span>This setting is available to admins only.</span></div>
                </Tooltip>
                : null}</label>}
            <div className={inputContainerClassName}>
                {skeletonLoading ? <Skeleton height="12" /> :
                    (creatable ? <CreatableSelect {...selectProps} /> : <Select {...selectProps}/>)
                }
                {(showHelpIcon && isHelpFlex) && renderHelp()}
                {(showNoAddonInfo && isHelpFlex) && renderAddon()}
                <div className="mt-1 col-12 p-0">
                    <FormError name={field.name} />
                </div>
            </div>
            {(showHelpIcon && !isHelpFlex) && renderHelp()}
            {(showNoAddonInfo && !isHelpFlex) && renderAddon()}
            {iconImage &&
                <div className={classnames("align-self-center ml-2", onClickIcon ? "cursor-pointer" : "")} onClick={() => onClickIcon && onClickIcon()}>
                    <img src={iconImage} alt="" width={30} height={30} style={{borderRadius: "5px"}}/>
                </div>
            }
            {bottomText && !disabled  ? <div className={classnames("bb-color_gray_imp cursor-pointer m-0 bb-text_small max-w-500", bottomClassName)} onClick={bottomTextClick ? () => bottomTextClick() : null}>{bottomText}</div> : null}
        </div>
    )

}
export default SelectInput