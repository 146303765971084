export const CompanyConstants = {
    GET_ALL_COMPANIES: 'GET_ALL_COMPANIES',
    GET_ALL_COMPANIES_ERROR: 'GET_ALL_COMPANIES_ERROR',

    COMPANY_CURRENT_PAGE: 'COMPANY_CURRENT_PAGE',
    COMPANY_LIMIT_PER_PAGE: 'COMPANY_LIMIT_PER_PAGE',
    COMPANY_SEARCH_VALUE: 'COMPANY_SEARCH_VALUE',
    COMPANY_FILTER_STATUS: 'COMPANY_FILTER_STATUS',
    COMPANY_TOTAL_COUNT: 'COMPANY_TOTAL_COUNT',

    ADD_NEW_COMPANY: 'ADD_NEW_COMPANY',    
    ADD_NEW_COMPANY_ERROR: 'ADD_NEW_COMPANY_ERROR',

    EDIT_COMPANY: 'EDIT_COMPANY',    
    EDIT_COMPANY_ERROR: 'EDIT_COMPANY_ERROR',

    DELETE_COMPANY: 'DELETE_COMPANY',
    DELETE_COMPANY_ERROR: 'DELETE_COMPANY_ERROR', 

    ACTIVATE_COMPANY: 'ACTIVATE_COMPANY',
    ACTIVATE_COMPANY_ERROR: 'ACTIVATE_COMPANY_ERROR', 
    
    GET_COMPANY_BY_ID: 'GET_COMPANY_BY_ID',
    GET_COMPANY_BY_ID_ERROR: 'GET_COMPANY_BY_ID_ERROR',

    GET_NETWORKS_FOR_COMPANY: 'GET_NETWORKS_FOR_COMPANY',
    GET_NETWORKS_FOR_COMPANY_ERROR:'GET_NETWORKS_FOR_COMPANY_ERROR',

    COMPANY_LOADING_STATUS: 'COMPANY_LOADING_STATUS',

    EMPTY_COMPANY_PROFILE: 'EMPTY_COMPANY_PROFILE',
    CHECK_COMPANY_OWNERSHIP: 'CHECK_COMPANY_OWNERSHIP',

    GET_ALL_DSPS_FOR_COMPANIES : 'GET_ALL_DSPS_FOR_COMPANIES',
    GET_ALL_DSPS_FOR_COMPANIES_ERROR : 'GET_ALL_DSPS_FOR_COMPANIES_ERROR',
    EMPTY_COMPANY_DSPS: 'EMPTY_COMPANY_DSPS',

    GET_ACCOUNT_INFO : 'GET_ACCOUNT_INFO',
    GET_ACCOUNT_INFO_ERROR : 'GET_ACCOUNT_INFO_ERROR',
    EMPTY_ACCOUNT_INFO: 'EMPTY_ACCOUNT_INFO',

    PERFORMANCE_COMPANY_LOADING_STATUS : 'PERFORMANCE_COMPANY_LOADING_STATUS',
    PERFORMANCE_COMPANY : 'PERFORMANCE_COMPANY',
    PERFORMANCE_PLUS_COMPANY:"PERFORMANCE_PLUS_COMPANY",
    PERFORMANCE_COMPANY_ERROR: 'PERFORMANCE_COMPANY_ERROR'
};
