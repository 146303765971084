import { CompanyConstants } from './companies.constants';

export const companies = (state = {}, action) => {

    switch (action.type) {
        case CompanyConstants.GET_ALL_COMPANIES:
            return { ...state, data: action.payload }
        case CompanyConstants.GET_ALL_COMPANIES_ERROR:
            return { ...state, data: action.payload }

        case CompanyConstants.GET_COMPANY_BY_ID:
            return { ...state, profile: action.payload }
        case CompanyConstants.GET_COMPANY_BY_ID_ERROR:
            return { ...state, profile: action.payload }

        case CompanyConstants.COMPANY_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case CompanyConstants.COMPANY_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload }

        case CompanyConstants.COMPANY_SEARCH_VALUE:
            return { ...state, search: action.payload }

        case CompanyConstants.COMPANY_FILTER_STATUS:
            return { ...state, status: action.payload }

        case CompanyConstants.COMPANY_TOTAL_COUNT:
            return { ...state, total: action.payload }

        case CompanyConstants.COMPANY_LOADING_STATUS:
            return { ...state, loading: action.payload }

        case CompanyConstants.DELETE_COMPANY:
        case CompanyConstants.DELETE_COMPANY_ERROR:
            return { ...state, deleteStatus: action.payload }

        case CompanyConstants.ACTIVATE_COMPANY:
        case CompanyConstants.ACTIVATE_COMPANY_ERROR:
            return { ...state, activateStatus: action.payload }

        case CompanyConstants.ADD_NEW_COMPANY:
            return { ...state, addNewStatus: true, addNewResp: action.payload }
        case CompanyConstants.ADD_NEW_COMPANY_ERROR:
            return { ...state, addNewStatus: false, addNewResp: action.payload }

        case CompanyConstants.EDIT_COMPANY:
            return { ...state, editStatus: true, editResp: action.payload }
        case CompanyConstants.EDIT_COMPANY_ERROR:
            return { ...state, editStatus: false, editResp: action.payload }

        case CompanyConstants.GET_NETWORKS_FOR_COMPANY:
        case CompanyConstants.GET_NETWORKS_FOR_COMPANY_ERROR:
            return { ...state, networks: action.payload }

        case CompanyConstants.EMPTY_COMPANY_PROFILE:
            return { ...state, profile: "" }

        case CompanyConstants.CHECK_COMPANY_OWNERSHIP:
            return { ...state, checkOwner: action.payload }

        case CompanyConstants.GET_ALL_DSPS_FOR_COMPANIES:
            return { ...state, dsps: action.payload }
        case CompanyConstants.GET_ALL_DSPS_FOR_COMPANIES_ERROR:
            return { ...state, dsps: action.payload }
        case CompanyConstants.EMPTY_COMPANY_DSPS:
            return { ...state, dsps: "" }

        case CompanyConstants.GET_ACCOUNT_INFO:
        case CompanyConstants.GET_ACCOUNT_INFO_ERROR:
            return { ...state, info: action.payload }
        case CompanyConstants.EMPTY_ACCOUNT_INFO:
            return { ...state, info: "" }

        case CompanyConstants.PERFORMANCE_COMPANY: 
            return { ...state, addPerformanceStatus: true, addPerformanceResp: action.payload }
        case CompanyConstants.PERFORMANCE_PLUS_COMPANY: 
            return { ...state, isPerformancePlus: action.payload }
        case CompanyConstants.PERFORMANCE_COMPANY_ERROR:
            return { ...state, addPerformanceStatus: false, addPerformanceResp: action.payload }
        case CompanyConstants.PERFORMANCE_COMPANY_LOADING_STATUS:
            return { ...state, performanceLoading: action.payload }
        default:
            return state;
    }
}; 
