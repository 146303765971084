
import { DealDashboardConstants } from './dashboard.constants';

export const dashboard = (state = {}, action) => {

    switch (action.type) {

        case DealDashboardConstants.BB_GET_ALL_DEAL_PACING_SUCCESS:
            return { ...state, data: action.payload }
        case DealDashboardConstants.BB_GET_ALL_DEAL_PACING_ERROR:
            return { ...state, data: action.payload }

        case DealDashboardConstants.BB_GET_DEAL_SUMMARY_SUCCESS:
        case DealDashboardConstants.BB_GET_DEAL_SUMMARY_ERROR:
            return { ...state, summary: action.payload }

        case DealDashboardConstants.BB_DEAL_PACING_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case DealDashboardConstants.BB_DEAL_PACING_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload }

        case DealDashboardConstants.BB_DEAL_PACING_SEARCH_VALUE:
            return { ...state, search: action.payload }

        case DealDashboardConstants.BB_DEAL_PACING_STATUS_VALUE:
            return { ...state, status: action.payload }

        case DealDashboardConstants.BB_DEAL_PACING_TOTAL_COUNT:
            return { ...state, total: action.payload }

        case DealDashboardConstants.BB_DEAL_PACING_LOADING_STATUS:
            return { ...state, loading: action.payload }

        case DealDashboardConstants.BB_EDIT_DEAL_PACING_REVIEW_SUCCESS:
            return { ...state, editStatus: true, editResp: action.payload }

        case DealDashboardConstants.BB_EDIT_DEAL_PACING_REVIEW_ERROR:
            return { ...state, editStatus: false, editResp: action.payload }

        case DealDashboardConstants.EMPTY_DEAL_PACING:
            return { ...state, data: "" }

        case DealDashboardConstants.BB_DEAL_SALES_REPORT_LOADING_STATUS:
            return { ...state, loading: action.payload }
        case DealDashboardConstants.BB_DEAL_SALES_REPORT_SUCCESS:
            return { ...state, sales_data: action.payload }
        case DealDashboardConstants.BB_DEAL_SALES_REPORT_ERROR:
            return { ...state, sales_data: action.payload }
        case DealDashboardConstants.EMPTY_DEAL_SALES_REPORT:
            return { ...state, sales_data: "" }

        case DealDashboardConstants.BB_GET_DEAL_SALES_REPORT_SUMMARY_SUCCESS:
        case DealDashboardConstants.BB_GET_DEAL_SALES_REPORT_SUMMARY_ERROR:
            return { ...state, sales_summary: action.payload }

        case DealDashboardConstants.BB_GET_DEAL_SALES_REPORT_ACTIVE_COUNT_SUCCESS:
        case DealDashboardConstants.BB_GET_DEAL_SALES_REPORT_ACTIVE_COUNT_ERROR:
            return { ...state, sales_daily_summary: action.payload }

        case DealDashboardConstants.BB_HIVE_REPORT_SUCCESS:
            return { ...state, hive_data: action.payload }
        case DealDashboardConstants.BB_HIVE_REPORT_ERROR:
            return { ...state, hive_data: action.payload }
        case DealDashboardConstants.EMPTY_HIVE_REPORT:
            return { ...state, hive_data: "" }
        default:
            return state;
    }
}