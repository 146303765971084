import { ForeignExchangeRateConstants } from './foreignExchangeRate.constants';

export const foreignExchangeRate = (state = {}, action) => {
    switch (action.type) {
        case ForeignExchangeRateConstants.GET_ALL_FOREIGN_EXCHANGE_RATE_SUCCESS: return { ...state, ...action.payload };
        case ForeignExchangeRateConstants.GET_ALL_FOREIGN_EXCHANGE_RATE_ERROR: return { ...state, error: true };

        case ForeignExchangeRateConstants.ALL_FOREIGN_EXCHANGE_RATE_LOADING: return { ...state, loading: action.payload };
        case ForeignExchangeRateConstants.ALL_FOREIGN_EXCHANGE_RATE_LIMIT: return { ...state, total: action.payload };
        
        case ForeignExchangeRateConstants.ALL_FOREIGN_EXCHANGE_RATE_CURRENT_PAGE: return { ...state, page: action.payload };
        case ForeignExchangeRateConstants.ALL_FOREIGN_EXCHANGE_RATE_LIMIT_PER_PAGE: return { ...state, limit: action.payload };
        
        case ForeignExchangeRateConstants.DELETE_FOREIGN_EXCHANGE_RATE_SUCCESS: return { ...state, deleteStatus: action.payload };
        case ForeignExchangeRateConstants.DELETE_FOREIGN_EXCHANGE_RATE_ERROR: return { ...state, deleteStatus: action.payload };
        case ForeignExchangeRateConstants.ACTIVATE_FOREIGN_EXCHANGE_RATE_SUCCESS: return { ...state, activateStatus: action.payload };
        case ForeignExchangeRateConstants.ACTIVATE_FOREIGN_EXCHANGE_RATE_ERROR: return { ...state, activateStatus: action.payload };

        case ForeignExchangeRateConstants.ADD_FOREIGN_EXCHANGE_RATE_SUCCESS: return { ...state, addFERSuccess: true, addResp: action.payload };
        case ForeignExchangeRateConstants.ADD_FOREIGN_EXCHANGE_RATE_ERROR: return { ...state, addFERSuccess: false, ...action.payload };
        case ForeignExchangeRateConstants.EDIT_FOREIGN_EXCHANGE_RATE_SUCCESS: return { ...state, editFERSuccess: true, editResp: action.payload };
        case ForeignExchangeRateConstants.EDIT_FOREIGN_EXCHANGE_RATE_ERROR: return { ...state, editFERSuccess: false, ...action.payload };
        
        case ForeignExchangeRateConstants.EMPTY_FOREIGN_EXCHANGE_RATE_BY_PROFILE:
        case ForeignExchangeRateConstants.GET_FOREIGN_EXCHANGE_RATE_BY_ID:
        case ForeignExchangeRateConstants.GET_FOREIGN_EXCHANGE_RATE_BY_ID_ERROR:
            return { ...state, profile: action.payload };
        default:
            return state;
    }
}