export const PerformanceProgramsConstants = {
    GET_PERFORMANCE_PROGRAMS_ERROR: "GET_PERFORMANCE_PROGRAMS_ERROR",
    GET_PERFORMANCE_PROGRAMS_SUCCESS: "GET_PERFORMANCE_PROGRAMS_SUCCESS",
    
    PERFORMANCE_PROGRAMS_LOADING: "PERFORMANCE_PROGRAMS_LOADING",
    
    GET_PERFORMANCE_SPENDING_ERROR: "GET_PERFORMANCE_SPENDING_ERROR",
    GET_PERFORMANCE_SPENDING_SUCCESS: "GET_PERFORMANCE_SPENDING_SUCCESS",

    REQUEST_PERFORMANCE_DEMO_SUCCESS: 'REQUEST_PERFORMANCE_DEMO_SUCCESS',
    REQUEST_PERFORMANCE_DEMO_ERROR: 'REQUEST_PERFORMANCE_DEMO_ERROR',
};
