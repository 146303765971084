import React from 'react';
import { connect } from 'react-redux';

import { Outlet, Navigate } from "react-router-dom"

const Authentication = props => {
  
    let { role, subcode, allowedUsers } = props;    
   

    if (role) {
        if (allowedUsers.includes(role + subcode)) {

            return <Outlet />
        }
        else {
            return <Navigate to="/403" replace />
        }
    }
    else {
        return null
    }
}



function mapStateToProps(state) {
    return {
        role: state.user.role,
        subcode: state.user.subcode || 0,
    };
}

export default connect(mapStateToProps, {})(Authentication);

