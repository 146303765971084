import { notify } from '../utilities/customFunctions'

export const ValidationError = (props) => {

    const { submitCount, errors, isValidating } = props;
    
    return (
        submitCount > 0 && isValidating && errors ?
            notify("One or more fields have an error. Please check and try again.", "e")
            : null
    )
}
