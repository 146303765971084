
export const DealDashboardConstants = {

    BB_GET_ALL_DEAL_PACING_SUCCESS: 'BB_GET_ALL_DEAL_PACING_SUCCESS',
    BB_GET_ALL_DEAL_PACING_ERROR: 'BB_GET_ALL_DEAL_PACING_ERROR',

    BB_GET_DEAL_SUMMARY_SUCCESS: 'BB_GET_DEAL_SUMMARY_SUCCESS',
    BB_GET_DEAL_SUMMARY_ERROR: 'BB_GET_DEAL_SUMMARY_ERROR',

    BB_DEAL_PACING_CURRENT_PAGE: "BB_DEAL_PACING_CURRENT_PAGE",
    BB_DEAL_PACING_LIMIT_PER_PAGE: "BB_DEAL_PACING_LIMIT_PER_PAGE",
    BB_DEAL_PACING_SEARCH_VALUE: "BB_DEAL_PACING_SEARCH_VALUE",
    BB_DEAL_PACING_STATUS_VALUE: "BB_DEAL_PACING_STATUS_VALUE",
    BB_DEAL_PACING_TOTAL_COUNT: "BB_DEAL_PACING_TOTAL_COUNT",

    BB_EDIT_DEAL_PACING_REVIEW_SUCCESS: 'BB_EDIT_DEAL_PACING_REVIEW_SUCCESS',
    BB_EDIT_DEAL_PACING_REVIEW_ERROR: 'BB_EDIT_DEAL_PACING_REVIEW_ERROR',

    BB_DEAL_PACING_LOADING_STATUS: "BB_DEAL_PACING_LOADING_STATUS",
    EMPTY_DEAL_PACING: "EMPTY_DEAL_PACING",

    BB_DEAL_SALES_REPORT_LOADING_STATUS: "BB_DEAL_SALES_REPORT_LOADING_STATUS",
    BB_DEAL_SALES_REPORT_SUCCESS: "BB_DEAL_SALES_REPORT_SUCCESS",
    BB_DEAL_SALES_REPORT_ERROR: "BB_DEAL_SALES_REPORT_ERROR",
    EMPTY_DEAL_SALES_REPORT: "EMPTY_DEAL_SALES_REPORT",

    BB_GET_DEAL_SALES_REPORT_SUMMARY_SUCCESS: 'BB_GET_DEAL_SALES_REPORT_SUMMARY_SUCCESS',
    BB_GET_DEAL_SALES_REPORT_SUMMARY_ERROR: 'BB_GET_DEAL_SALES_REPORT_SUMMARY_ERROR',

    BB_GET_DEAL_SALES_REPORT_ACTIVE_COUNT_SUCCESS: 'BB_GET_DEAL_SALES_REPORT_ACTIVE_COUNT_SUCCESS',
    BB_GET_DEAL_SALES_REPORT_ACTIVE_COUNT_ERROR: 'BB_GET_DEAL_SALES_REPORT_ACTIVE_COUNT_ERROR',

    EMPTY_HIVE_REPORT: "EMPTY_HIVE_REPORT",
    BB_HIVE_REPORT_SUCCESS: "BB_HIVE_REPORT_SUCCESS",
    BB_HIVE_REPORT_ERROR: "BB_HIVE_REPORT_ERROR"

};
