import { UserRolesConstants } from './userRoles.constants';

export const userRoles = (state = {}, action) => {
    switch (action.type) {
        case UserRolesConstants.GET_ALL_USER_ROLES:
            return {
                ...state,
                ...action.payload
            };

        case UserRolesConstants.ALL_USER_ROLES_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case UserRolesConstants.GET_ALL_USER_ROLES_ERROR:
            return {
                ...state,
                error: true
            };
        case UserRolesConstants.ALL_USER_ROLES_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case UserRolesConstants.ALL_USER_ROLES_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case UserRolesConstants.ALL_USER_ROLES_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case UserRolesConstants.ALL_USER_ROLES_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case UserRolesConstants.ALL_USER_ROLES_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case UserRolesConstants.DELETE_USER_ROLES_SUCCESS:
            return {
                ...state,
                userRolesDelete: action.payload
            };
        case UserRolesConstants.DELETE_USER_ROLES_ERROR:
            return {
                ...state,
                userRolesDelete: action.payload
            };
        case UserRolesConstants.ACTIVATE_USER_ROLES_SUCCESS:
            return {
                ...state,
                userRolesActivate: action.payload
            };
        case UserRolesConstants.ACTIVATE_USER_ROLES_ERROR:
            return {
                ...state,
                userRolesActivate: action.payload
            };
        case UserRolesConstants.BB_ADD_USER_ROLES_SUCCESS:
            return {
                ...state,
                addUserRolesSuccess: true,
                addResp: action.payload
            };
        case UserRolesConstants.BB_ADD_USER_ROLES_ERROR:
            return {
                ...state,
                addUserRolesSuccess: false,
                ...action.payload
            };
        case UserRolesConstants.BB_ADD_USER_ROLES_LOADING:
            return {
                ...state,
                addUserRolesLoading: action.payload
            };
            case UserRolesConstants.BB_EDIT_USER_ROLES_SUCCESS:
                return {
                    ...state,
                    editUserRolesSuccess: true,
                    editResp: action.payload
                };
            case UserRolesConstants.BB_EDIT_USER_ROLES_ERROR:
                return {
                    ...state,
                    editUserRolesSuccess: false,
                    ...action.payload
                };
        case UserRolesConstants.BB_GET_USER_USER_ROLES_INIT:
            return { ...state, dropDown: { loading: action.payload } };
        case UserRolesConstants.BB_GET_USER_USER_ROLES_SUCCESS:
        case UserRolesConstants.BB_GET_USER_USER_ROLES_ERROR:
            return { ...state, dropDown: { data: [...action.payload], loading: false } };

        default:
            return state;
    }
}
