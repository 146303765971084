export const AllOffsetEmissionConstants = {
    GET_DEAL_EMISSION: 'GET_DEAL_EMISSION', 
    DEAL_EMISSION_LOADING: 'DEAL_EMISSION_LOADING',
    GET_DEAL_EMISSION_ERROR: 'GET_DEAL_EMISSION_ERROR',    
    EMPTY_DEAL_EMISSION:'EMPTY_DEAL_EMISSION',
    
    GET_OFFSET_CERTIFICATE: 'GET_OFFSET_CERTIFICATE', 
    OFFSET_CERTIFICATE_LOADING: 'OFFSET_CERTIFICATE_LOADING',
    GET_OFFSET_CERTIFICATE_ERROR: 'GET_OFFSET_CERTIFICATE_ERROR',    
    EMPTY_OFFSET_CERTIFICATE:'EMPTY_OFFSET_CERTIFICATE',

    GET_OFFSET_COMMITTED: 'GET_OFFSET_COMMITTED', 
    OFFSET_COMMITTED_LOADING: 'OFFSET_COMMITTED_LOADING',
    GET_OFFSET_COMMITTED_ERROR: 'GET_OFFSET_COMMITTED_ERROR',    
    EMPTY_OFFSET_COMMITTED:'EMPTY_OFFSET_COMMITTED',

    GET_OFFSET_COMMITTED_BY_ID: 'GET_OFFSET_COMMITTED_BY_ID', 
    OFFSET_COMMITTED_BY_ID_LOADING: 'OFFSET_COMMITTED_BY_ID_LOADING',
    EMPTY_OFFSET_COMMITTED_BY_ID:'EMPTY_OFFSET_COMMITTED_BY_ID',

    GET_OFFSET_CERTIFICATE_BY_ID: 'GET_OFFSET_CERTIFICATE_BY_ID', 
    OFFSET_CERTIFICATE_BY_ID_LOADING: 'OFFSET_CERTIFICATE_BY_ID_LOADING',
    EMPTY_OFFSET_CERTIFICATE_BY_ID:'EMPTY_OFFSET_CERTIFICATE_BY_ID',
    
    CREATE_COMMIT_OFFSET_SUCCESS: 'CREATE_COMMIT_OFFSET_SUCCESS',
    CREATE_COMMIT_OFFSET_ERROR: 'CREATE_COMMIT_OFFSET_ERROR',
    CREATE_COMMIT_OFFSET_LOADING: 'CREATE_COMMIT_OFFSET_LOADING',

    SEND_EMAIL_REPORT_COMMITTED: "SEND_EMAIL_REPORT_COMMITTED",
    SEND_EMAIL_REPORT_COMMITTED_ERROR: "SEND_EMAIL_REPORT_COMMITTED_ERROR",
    
    SEND_EMAIL_REPORT_COMPLETED: "SEND_EMAIL_REPORT_COMPLETED",
    SEND_EMAIL_REPORT_COMPLETED_ERROR: "SEND_EMAIL_REPORT_COMPLETED_ERROR",

    SEND_EMAIL_CERT_COMPLETED: "SEND_EMAIL_CERT_COMPLETED",
    SEND_EMAIL_CERT_COMPLETED_ERROR: "SEND_EMAIL_CERT_COMPLETED_ERROR",
    
    DOWNLOAD_REPORT_COMPLETED_LOADING: 'DOWNLOAD_REPORT_COMPLETED_LOADING',
    DOWNLOAD_REPORT_COMMITTED_LOADING: 'DOWNLOAD_REPORT_COMMITTED_LOADING',

    CREATE_OFFSET_ORDER_ERROR: 'CREATE_OFFSET_ORDER_ERROR',
    CREATE_OFFSET_ORDER:'CREATE_OFFSET_ORDER'
};
