import { NetworkConstants } from './networks.constants';

export const networks = (state = {}, action) => {

    switch (action.type) {
        /* Network */
        case NetworkConstants.GET_ALL_NETWORKS:
            return { ...state, data: action.payload }
        case NetworkConstants.GET_ALL_NETWORKS_ERROR:
            return { ...state, data: action.payload }

        case NetworkConstants.GET_NETWORK_BY_ID:
            return { ...state, profile: action.payload }
        case NetworkConstants.GET_NETWORK_BY_ID_ERROR:
            return { ...state, profile: action.payload }

        case NetworkConstants.NETWORK_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case NetworkConstants.NETWORK_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload }

        case NetworkConstants.NETWORK_SEARCH_VALUE:
            return { ...state, search: action.payload }

        case NetworkConstants.NETWORK_FILTER_STATUS:
            return { ...state, status: action.payload }

        case NetworkConstants.NETWORK_TOTAL_COUNT:
            return { ...state, total: action.payload }

        case NetworkConstants.NETWORK_LOADING_STATUS:
            return { ...state, loading: action.payload }

        case NetworkConstants.DELETE_NETWORK: 
        case NetworkConstants.DELETE_NETWORK_ERROR:
        return { ...state, deleteStatus: action.payload }
        
        case NetworkConstants.ACTIVATE_NETWORK:
        case NetworkConstants.ACTIVATE_NETWORK_ERROR:
            return { ...state, activateStatus: action.payload }
        
        case NetworkConstants.ADD_NEW_NETWORK:
            return { ...state, addNewStatus: true, addNewResp: action.payload } 
        case NetworkConstants.ADD_NEW_NETWORK_ERROR:
            return { ...state, addNewStatus: false, addNewResp: action.payload }        

        case NetworkConstants.EDIT_NETWORK:
            return { ...state, editStatus: true, editResp: action.payload }
        case NetworkConstants.EDIT_NETWORK_ERROR:
            return { ...state, editStatus: false, editResp: action.payload }

        case NetworkConstants.EMPTY_NETWORK_PROFILE:
                return { ...state, profile: ""}

        case NetworkConstants.CHECK_NETWORK_OWNERSHIP:
                return { ...state, checkOwner: action.payload}

        case NetworkConstants.GET_NETWORK_INFO:
        case NetworkConstants.GET_NETWORK_INFO_ERROR:
            return { ...state, info: action.payload }
        case NetworkConstants.EMPTY_NETWORK_INFO:
            return { ...state, info: "" }

        default:
            return state;
    }
}; 
