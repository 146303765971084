import React from 'react'
import classnames from 'classnames'
import Avatar from 'react-avatar';
import { SUPER_ADMIN } from '../../helpers/roles'

const UserAvatar = ({ name = "", firstName, lastName, role, className, imageSrc = "", size= 60 }) => {

    return (
        <Avatar className={classnames("img-responsive img-fluid align-self-center", className)}
            style={{ fontFamily: 'inherit' }}
            color="#FFF"
            fgColor="#ec1555"
            src={imageSrc}
            // eslint-disable-next-line
            round={role == SUPER_ADMIN ? "10px" : true} size={size} textSizeRatio={2} name={ name ? name : firstName?.replace(/\s/ig, "") + " " + lastName?.replace(/\s/ig, "")} />

    )
}

export default UserAvatar