import React, { lazy } from "react";
import { Route, Navigate, Routes, useLocation, useParams, useNavigate } from "react-router-dom";

import App from "../components/app";

import CheckSession from './auth/CheckSession';
import UAC from './auth/UAC';
import withGA from "../helpers/GA/withGA-HOC";

import {
    SUPER_ADMIN, NETWORK_MANAGER, ACCOUNT_MANAGER,
    BIG_MOBILE_SALESPERSON, AFFILIATE, CREATIVE_MANAGER, ADMIN,
    SALES_DIRECTOR, C_LEVEL, ESG_TEAM, ALL_SALES
} from "../helpers/roles"

import Error404 from '../components/404/'

import Login from '../components/auth/Login';
import ForgotPassword from '../components/auth/ForgotPassword'
import ResetPassword from '../components/auth/ResetPassword'
import Logout from '../components/auth/Logout';
import { usePrevious } from "../components/utilities/customHooks";


const ManageNetworks = lazy(() => import('../components/management/networks'))

const ManageNetworksForm = lazy(() => import('../components/management/networks/form'))
const ManageCompanies = lazy(() => import('../components/management/companies'))
const ManageCompaniesForm = lazy(() => import('../components/management/companies/form'))

const AllAds = lazy(() => import('../components/creative/all-ads/'))
const AmplifiedIntelligence = lazy(() => import('../components/optimisation/attentv-ads'))
const Lumen = lazy(() => import('../components/optimisation/lumen'))
const CreativeOptimisationDeals = lazy(() => import('../components/optimisation/creative'))
const OptimisationCreative = lazy(() => import('../components/optimisation/creatives'))

const AllUsers = lazy(() => import('../components/settings/users'))
const NewUser = lazy(() => import('../components/settings/users/newuser'))
const EditUser = lazy(() => import('../components/settings/users/edit'))

const Dimensions = lazy(() => import("../components/settings/dimensions"))
const DimensionsForm = lazy(() => import("../components/settings/dimensions/form"))
const UserRoles = lazy(() => import("../components/settings/user-roles"))
const Faqs = lazy(() => import("../components/settings/faq"))
const FaqForm = lazy(() => import("../components/settings/faq/form"))

const NexdBilling = lazy(() => import("../components/billing/nexd-billing"))
const AirtoryBilling = lazy(() => import("../components/billing/airtory-billing"))


const Changeuserinfo = lazy(() => import('../components/settings/changeuserinfo'))

const AdBuilderAdmin = lazy(() => import('../components/ad-builder/admin/builder'))
const EditBuilder = lazy(() => import('../components/ad-builder/admin/edit'))
const SuperFormat = lazy(() => import('../components/settings/super-formats'))
const SuperFormatForm = lazy(() => import('../components/settings/super-formats/form'))
const CarouselFormat = lazy(() => import("../components/settings/carousel-formats"))
const CarouselFormatForm = lazy(() => import("../components/settings/carousel-formats/form"))
const CustomFormat = lazy(() => import('../components/settings/custom-format'))
const FormatTypeTier = lazy(() => import("../components/settings/format-type-tier"))
const FormatTypeTierForm = lazy(() => import("../components/settings/format-type-tier/form"))
const CurationFee = lazy(() => import('../components/settings/curated-media/curation-fee'))
const CurationFeeForm = lazy(() => import('../components/settings/curated-media/curation-fee/form'))
const DominosBasketSize = lazy(() => import('../components/settings/dominos-basket-size'))
const DominosBasketSizeForm = lazy(() => import('../components/settings/dominos-basket-size/form'))
const DominosMasterCampaign = lazy(() => import('../components/settings/dominos-master-campaign'))
const DominosMasterCampaignForm = lazy(() => import('../components/settings/dominos-master-campaign/form'))

const SegmentCategory = lazy(() => import('../components/admin/data-provider/segment-category'))
const SegmentCategoryForm = lazy(() => import('../components/admin/data-provider/segment-category/form'))

const SegmentPricing = lazy(() => import('../components/admin/data-provider/segment-pricing'))
const SegmentPricingForm = lazy(() => import('../components/admin/data-provider/segment-pricing/form'))

const CreativeRequests = lazy(() => import('../components/services/creative-requests'))
const CreativeReport = lazy(() => import("../components/creative/report"))

const UserReport = lazy(() => import('../components/analytics'))
const AnalyticsDemo = lazy(() => import('../components/analytics/demo'))
const GrossMarginDashboard = lazy(() => import('../components/gross-margin/dashboard'))
const ProductMarginDashboard = lazy(() => import('../components/budget/product-margin/dashboard/index_v2'))
const BMTarget = lazy(() => import('../components/budget/target'))
const BMTargetForm = lazy(() => import('../components/budget/target/form'))


/* Deal ID Dashboard */
const DealIdReport = lazy(() => import('../components/deals/dashboard/report/index_v7'))
const DealIdDashboard = lazy(() => import('../components/deals/dashboard'))
const MyHiveActivity = lazy(() => import('../components/deals/hive-activity'))
/* My deals */
const MyDealsAudience = lazy(() => import('../components/audiences/deal-info/deal-audience'))
const MyDealsCreative = lazy(() => import('../components/audiences/deal-info/deal-creatives'))

const Categories = lazy(() => import('../components/settings/curated-media/category'))
const CategoryForm = lazy(() => import('../components/settings/curated-media/category/form'))

const ContextualMedia = lazy(() => import("../components/settings/curated-media/contextual-media"))
const ContextualMediaDetail = lazy(() => import("../components/settings/curated-media/contextual-media/detail"))
const DealTokenReference = lazy(() => import("../components/settings/curated-media/deal-token-reference"))
const Bespoke = lazy(() => import("../components/settings/curated-media/bespoke"))
const Addons = lazy(() => import('../components/settings/curated-media/addons'))
const AddonsForm = lazy(() => import('../components/settings/curated-media/addons/form'))

const ManageFormatType = lazy(() => import("../components/settings/curated-media/format-type"))
const ManageFormatTypeForm = lazy(() => import("../components/settings/curated-media/format-type/form"))


const CreateNewDeal = lazy(() => import('../components/audiences/contextual/create-deal'))
const ChooseMedia = lazy(() => import('../components/audiences/contextual/choose-media'))
const BuildAds = lazy(() => import('../components/audiences/contextual/build-ads'))
const BuildAdsYourself = lazy(() => import('../components/audiences/contextual/build-ads/yourself/builder'))
const DealSummary = lazy(() => import('../components/audiences/contextual/summary'))
const BriefBigContextual = lazy(() => import('../components/audiences/contextual/build-ads/brief'))

const PerformanceDashboard = lazy(() => import('../components/performance/dashboard'))
const PerformanceTracking = lazy(() => import('../components/performance/performance_tracking'))
const SpendTracking = lazy(() => import('../components/performance/spend_tracking'))

/* Showcase */
const Showcase = lazy(() => import('../components/showcase'))
const Vertical = lazy(() => import('../components/showcase/management/vertical'))
const Objective = lazy(() => import('../components/showcase/management/objective'))

const ShowcaseCreatives = lazy(() => import("../components/showcase/management/creatives"))
const ShowcaseCreativesCreate = lazy(() => import("../components/showcase/management/creatives/add"))
const ShowcaseCreativesEdit = lazy(() => import("../components/showcase/management/creatives/edit"))


const ShowcaseBenchmark = lazy(() => import("../components/showcase/management/benchmark"))
const ShowcaseBenchmarkCreate = lazy(() => import("../components/showcase/management/benchmark/add"))


const Help = lazy(() => import('../components/help'))
const GetDSPGuide = lazy(() => import('../components/audiences/render-helpers/dsp-guide/get-dsp-guide'))
const GetAdSpec = lazy(() => import('../components/audiences/render-helpers/ad-specs'))
const GetVideoId = lazy(() => import('../components/audiences/render-helpers/video-tutorial'))
const OnlineUsers = lazy(() => import('../components/admin/online'))
const AdminDashboard = lazy(() => import('../components/admin/dashboard'))
// const SupplyConfiguration = lazy(() => import('../components/admin/mediagrid/supply-configuration'))
// const SupplyConfigurationForm = lazy(() => import('../components/admin/mediagrid/supply-configuration/form'))
const DealMonitoring = lazy(() => import('../components/admin/mediagrid/deal-monitoring'))

const LumenReport = lazy(() => import('../components/admin/lumen/report'))
const Log = lazy(() => import('../components/admin/log'))

const VendorStatus = lazy(() => import('../components/admin/vendor/status'))

const DailyTestLog = lazy(() => import('../components/admin/daily-test/log'))
const DailyTestResults = lazy(() => import('../components/admin/daily-test/results'))

const TFEConfig = lazy(() => import('../components/admin/others/tfe-config'))
const PerformanceFeedback = lazy(() => import('../components/admin/others/performance-feedback'))
const PerformanceSubscription = lazy(() => import('../components/admin/others/performance-subscription'))
const PacingLog = lazy(() => import('../components/admin/others/pacing-log'))
const ForeignExchangeRate = lazy(() => import('../components/admin/gross-margin/exchange-rate'))
const ForeignExchangeRateForm = lazy(() => import('../components/admin/gross-margin/exchange-rate/form'))
const AttentvAdsDemo = lazy(() => import('../components/addons/attentvads'))

const Scope3Benchmark = lazy(() => import('../components/admin/scope3-benchmark'))
const Scope3BenchmarkForm = lazy(() => import('../components/admin/scope3-benchmark/form'))

const Transparency = lazy(() => import('../components/emission/transparency-policy'))
const MyStatus = lazy(() => import('../components/emission/status'))
const Settings = lazy(() => import('../components/emission/settings'))
const Scope3Reporting = lazy(() => import('../components/emission/scope-3-reporting'))
const Scope3ReportingDemo = lazy(() => import('../components/emission/scope-3-reporting/demo'))
const PrebuiltMediasGMP = lazy(() => import('../components/settings/curated-media/prebuilt-media-gmp'))
const PrebuiltMediasFormGMP = lazy(() => import('../components/settings/curated-media/prebuilt-media-gmp/form'))
const MasterSitelists = lazy(() => import('../components/settings/curated-media/master-sitelist'))
const MasterSitelistsForm = lazy(() => import('../components/settings/curated-media/master-sitelist/form'))
const BespokeDictionary = lazy(() => import('../components/settings/curated-media/bespoke-dictionary'))
const BespokeDictionaryForm = lazy(() => import('../components/settings/curated-media/bespoke-dictionary/form'))
const EmissionsOptimisationReport = lazy(() => import('../components/admin/scope-3/emissions-optimisation-report'))

const OffsetEmission = lazy(() => import('../components/emission/offset'))
const OffsetEmissionChoose = lazy(() => import('../components/emission/offset/emissions/choose-method'))
const OffsetEmissionOPSummary = lazy(() => import('../components/emission/offset/emissions/own-provider/summary'))
const OffsetEmissionOPReport = lazy(() => import('../components/emission/offset/emissions/own-provider/report'))
const SelectOffsetSummary = lazy(() => import('../components/emission/offset/emissions/with-buddy/summary'))
const OffsetEmissionSelect = lazy(() => import('../components/emission/offset/emissions/with-buddy'))
const OffsetEmissionCertificate = lazy(() => import('../components/emission/offset/emissions/with-buddy/report'))
const OffsetUsingSouthpole = lazy(() => import('../components/emission/offset/emissions/with-buddy/southpole'))
const EmissionReporting = lazy(() => import('../components/emission/emissions-reporting'))


const MyCreatives = lazy(() => import('../components/my-creatives/creatives'))
const Campaigns = lazy(() => import('../components/my-creatives/campaign'))
const CampaignsDemo = lazy(() => import('../components/my-creatives/campaign/demo'))
const CampaignsForm = lazy(() => import('../components/my-creatives/campaign/form'))
const BuildInCampaign = lazy(() => import('../components/my-creatives/builder/index'))
const BuildInCampaignBuilder = lazy(() => import('../components/my-creatives/builder/yourself'))
const BriefCreatives = lazy(() => import('../components/my-creatives/builder/brief'))
// const MyCreativesAllVersions = lazy(() => import('../components/my-creatives/creativesVersion'))
const CreativeShow = lazy(() => import('../components/my-creatives/creative'))
const CreativeReview = lazy(() => import('../components/my-creatives/creative/review'))

/* User roles */
const U_SuperAdmin = [SUPER_ADMIN]
const U_All_Admins = [...U_SuperAdmin, CREATIVE_MANAGER, ADMIN]
const U_NetworkManager = [...U_All_Admins, NETWORK_MANAGER];
const U_AccountManager = [...U_NetworkManager, ACCOUNT_MANAGER];
const U_BMSP = [BIG_MOBILE_SALESPERSON, AFFILIATE, SALES_DIRECTOR, C_LEVEL];
const U_ESG_Team = [ESG_TEAM]
const U_User = [...U_AccountManager, ...U_BMSP, ...U_ESG_Team];



const CustomRoute = (props) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    return <>
        {React.cloneElement(props.children, { location: location, params: params, navigate: navigate })}
    </>
}

const SRoutes = ({ role }) => {

    const location = useLocation();
    const prevL = usePrevious({ location })
    const PrevLocation = prevL && prevL.location ? prevL.location.pathname + prevL.location.search || '' : ''

    return (
        <Routes >
            <Route exact path="/logout" element={<CustomRoute><Logout /></CustomRoute>} />
            <Route exact path="/resetpassword" element={<CustomRoute><ResetPassword /></CustomRoute>} />

            <Route element={<CustomRoute><CheckSession /></CustomRoute>}>

                <Route exact path="/" element={<CustomRoute><Login /></CustomRoute>} />
                <Route exact path="login" element={<CustomRoute><Login /></CustomRoute>} />
                <Route exact path="/forgotpassword" element={<CustomRoute><ForgotPassword /></CustomRoute>} />

                <Route element={<CustomRoute><App /></CustomRoute>}>
                    <Route path="/dashboard" element={<CustomRoute><Navigate to="/my-deals" replace /></CustomRoute>} />
                    <Route path="/flights" element={<CustomRoute><Navigate to="/my-deals" replace /></CustomRoute>} />
                    <Route path="/reload" element={<CustomRoute><Navigate to={PrevLocation} replace /></CustomRoute>} />
                    <Route exact path="/my-creatives/review/:id" element={<CustomRoute><CreativeShow /></CustomRoute>} />

                    <Route element={<UAC allowedUsers={[...U_All_Admins]} />} >

                        <Route exact path="/online-users" element={<CustomRoute><OnlineUsers /></CustomRoute>} />
                        <Route exact path="/admin/dashboard" element={<CustomRoute><AdminDashboard /></CustomRoute>} />
                        {/* <Route exact path="/criteo/supply_configuration" element={<CustomRoute><SupplyConfiguration /></CustomRoute>} />
                        <Route exact path="/criteo/supply_configuration/create" element={<CustomRoute><SupplyConfigurationForm /></CustomRoute>} />
                        <Route exact path="/criteo/supply_configuration/edit" element={<CustomRoute><SupplyConfigurationForm /></CustomRoute>} /> */}
                        <Route exact path="/lumen/report" element={<CustomRoute><LumenReport /></CustomRoute>} />
                        <Route exact path="/vendors/status" element={<CustomRoute><VendorStatus /></CustomRoute>} />
                        <Route exact path="/others/creative_metrics_config" element={<CustomRoute><TFEConfig /></CustomRoute>} />
                        <Route exact path="/others/performance_feedback" element={<CustomRoute><PerformanceFeedback /></CustomRoute>} />
                        <Route exact path="/business/performance_subscription" element={<CustomRoute><PerformanceSubscription /></CustomRoute>} />
                        <Route exact path="/daily-test/log" element={<CustomRoute><DailyTestLog /></CustomRoute>} />
                        <Route exact path="/daily-test/results" element={<CustomRoute><DailyTestResults /></CustomRoute>} />
                        <Route exact path="/settings/daily-test-results" element={<CustomRoute><DailyTestResults /></CustomRoute>} />
                        <Route exact path="/scope-3/emissions-optimisation-report" element={<CustomRoute><EmissionsOptimisationReport /></CustomRoute>} />
                        <Route exact path="/others/pacing_log" element={<CustomRoute><PacingLog /></CustomRoute>} />

                        <Route exact path="/log/:name" element={<CustomRoute><Log /></CustomRoute>} />

                        <Route exact path="/business/foreign_exchange_rate" element={<CustomRoute><ForeignExchangeRate /></CustomRoute>} />
                        <Route exact path="/business/foreign_exchange_rate/create" element={<CustomRoute><ForeignExchangeRateForm /></CustomRoute>} />
                        <Route exact path="/business/foreign_exchange_rate/edit" element={<CustomRoute><ForeignExchangeRateForm /></CustomRoute>} />

                        <Route exact path="/green-working/benchmark" element={<CustomRoute><Scope3Benchmark /></CustomRoute>} />
                        <Route exact path="/green-working/benchmark/create" element={<CustomRoute><Scope3BenchmarkForm /></CustomRoute>} />
                        <Route exact path="/green-working/benchmark/edit" element={<CustomRoute><Scope3BenchmarkForm /></CustomRoute>} />

                        <Route exact path="/networks/create" element={<CustomRoute><ManageNetworksForm /></CustomRoute>} />
                        <Route exact path="/networks/edit" element={<CustomRoute><ManageNetworksForm /></CustomRoute>} />
                        <Route exact path="/optimisation/amplified-intelligence" element={<CustomRoute><AmplifiedIntelligence /></CustomRoute>} />
                        <Route exact path="/optimisation/creative" element={<CustomRoute><CreativeOptimisationDeals /></CustomRoute>} />
                        <Route exact path="/optimisation/lumen" element={<CustomRoute><Lumen /></CustomRoute>} />
                        <Route exact path="/optimisation/:id" element={<CustomRoute><OptimisationCreative /></CustomRoute>} />
                        <Route exact path="/services/ad-builder-bm" element={<CustomRoute><AdBuilderAdmin /></CustomRoute>} />
                        <Route exact path="/services/ad-builder-bm/:campaign" element={<CustomRoute><EditBuilder /></CustomRoute>} />

                        <Route exact path="/services/creative-requests" element={<CustomRoute><CreativeRequests /></CustomRoute>} />

                        {/* Deal ID dashboard */}
                        <Route exact path="/business/deal-monitoring" element={<CustomRoute><DealMonitoring /></CustomRoute>} />




                    </Route>

                    <Route element={<UAC allowedUsers={[...U_User]} />} >

                        <Route exact path="/my-deals" element={<CustomRoute><AllAds /></CustomRoute>} />
                        <Route exact path="/my-deals/creatives" element={<CustomRoute><MyDealsCreative /></CustomRoute>} />
                        <Route exact path="/my-deals/briefs" element={<CustomRoute><MyDealsCreative /></CustomRoute>} />
                        <Route exact path="/my-deals/audience" element={<CustomRoute><MyDealsAudience /></CustomRoute>} />


                        <Route exact path="/my-profile" element={<CustomRoute><Changeuserinfo /></CustomRoute>} />
                        <Route exact path="/settings/user-profile" element={<CustomRoute><Changeuserinfo /></CustomRoute>} />

                        <Route exact path="/creative-report/:ad_id" element={<CustomRoute><CreativeReport /></CustomRoute>} />

                        {/* Audiences */}

                        <Route exact path="/contextual/create-deal" element={<CustomRoute><CreateNewDeal /></CustomRoute>} />
                        <Route exact path="/contextual/create-deal/:id" element={<CustomRoute><CreateNewDeal /></CustomRoute>} />
                        <Route exact path="/contextual/choose-media/:id" element={<CustomRoute><ChooseMedia /></CustomRoute>} />
                        <Route exact path="/contextual/build-awesome-creative/:id" element={<CustomRoute><BuildAds /></CustomRoute>} />
                        <Route exact path="/contextual/ad-builder/:id" element={<CustomRoute><BuildAdsYourself /></CustomRoute>} />
                        <Route exact path="/contextual/brief-big/:id" element={<CustomRoute><BriefBigContextual /></CustomRoute>} />
                        <Route exact path="/contextual/summary/:id" element={<CustomRoute><DealSummary /></CustomRoute>} />

                        <Route exact path="/performance-programs" element={<CustomRoute><PerformanceDashboard /></CustomRoute>} />
                        <Route exact path="/performance-programs/performance-tracking" element={<CustomRoute><PerformanceTracking /></CustomRoute>} />
                        <Route exact path="/performance-programs/spend-tracking" element={<CustomRoute><SpendTracking /></CustomRoute>} />
                        

                        {/* Showcase */}
                        <Route exact path="/showcase" element={<CustomRoute><Showcase /></CustomRoute>} />

                        <Route exact path="/demo/attentvads" element={<CustomRoute><AttentvAdsDemo /></CustomRoute>} />

                        <Route exact path="/help" element={<CustomRoute><Help /></CustomRoute>} />
                        <Route exact path="/404Error" element={<CustomRoute><Error404 /></CustomRoute>} />
                        <Route exact path="/get-dsp-guide" element={<CustomRoute><GetDSPGuide /></CustomRoute>} />
                        <Route exact path="/get-ad-specs" element={<CustomRoute><GetAdSpec /></CustomRoute>} />
                        <Route exact path="/get-video-tutorial" element={<CustomRoute><GetVideoId /></CustomRoute>} />

                        <Route exact path="/green-working/scope-3-reporting" element={<CustomRoute><Scope3Reporting /></CustomRoute>} />
                        <Route exact path="/green-working/scope3-reporting" element={<CustomRoute><Scope3ReportingDemo /></CustomRoute>} />


                    </Route>

                    <Route exact path="/green-working/offset-emissions" element={<CustomRoute><OffsetEmission /></CustomRoute>} />
                    <Route exact path="/green-working/offset-emissions/choose-method" element={<CustomRoute><OffsetEmissionChoose /></CustomRoute>} />
                    <Route exact path="/green-working/offset-emissions/commit-offset/:id" element={<CustomRoute><OffsetEmissionOPSummary /></CustomRoute>} />
                    <Route exact path="/green-working/offset-emissions/commit-offset-report/:id" element={<CustomRoute><OffsetEmissionOPReport /></CustomRoute>} />

                    <Route exact path="/green-working/offset-emissions/select/:id" element={<CustomRoute><OffsetEmissionSelect /></CustomRoute>} />
                    <Route exact path="/green-working/offset-emissions/summary/:id" element={<CustomRoute><SelectOffsetSummary /></CustomRoute>} />

                    <Route exact path="/green-working/offset-emissions/certificate/:id" element={<CustomRoute><OffsetEmissionCertificate /></CustomRoute>} />
                    <Route exact path="/green-working/offset-emissions/offset-using-southpole/:id" element={<CustomRoute><OffsetUsingSouthpole /></CustomRoute>} />

                    <Route element={<UAC allowedUsers={[...U_SuperAdmin]} />} >
                        <Route exact path="/settings/dominos-basket-size" element={<CustomRoute><DominosBasketSize /></CustomRoute>} />
                        <Route exact path="/settings/dominos-basket-size/create" element={<CustomRoute><DominosBasketSizeForm /></CustomRoute>} />
                        <Route exact path="/settings/dominos-basket-size/edit" element={<CustomRoute><DominosBasketSizeForm /></CustomRoute>} />
                        <Route exact path="/settings/dominos-master-campaign" element={<CustomRoute><DominosMasterCampaign /></CustomRoute>} />
                        <Route exact path="/settings/dominos-master-campaign/create" element={<CustomRoute><DominosMasterCampaignForm /></CustomRoute>} />
                        <Route exact path="/settings/dominos-master-campaign/edit" element={<CustomRoute><DominosMasterCampaignForm /></CustomRoute>} />
                        <Route exact path="/settings/formats" element={<CustomRoute><SuperFormat /></CustomRoute>} />
                        <Route exact path="/settings/formats/create" element={<CustomRoute><SuperFormatForm /></CustomRoute>} />
                        <Route exact path="/settings/formats/edit" element={<CustomRoute><SuperFormatForm /></CustomRoute>} />
                        <Route exact path="/settings/custom-format" element={<CustomRoute><CustomFormat /></CustomRoute>} />
                        <Route exact path="/settings/user-roles" element={<CustomRoute><UserRoles /></CustomRoute>} />
                        <Route exact path="/settings/faqs" element={<CustomRoute><Faqs /></CustomRoute>} />
                        <Route exact path="/settings/faqs/create" element={<CustomRoute><FaqForm /></CustomRoute>} />
                        <Route exact path="/settings/faqs/edit" element={<CustomRoute><FaqForm /></CustomRoute>} />
                        <Route exact path="/contextual/format_category_tier" element={<CustomRoute><FormatTypeTier /></CustomRoute>} />
                        <Route exact path="/contextual/format_category_tier/create" element={<CustomRoute><FormatTypeTierForm /></CustomRoute>} />
                        <Route exact path="/contextual/format_category_tier/edit" element={<CustomRoute><FormatTypeTierForm /></CustomRoute>} />
                        {/* <Route exact path="/contextual/prebuilt" element={<CustomRoute><PrebuiltMedias /></CustomRoute>} />
                        <Route exact path="/contextual/prebuilt/create" element={<CustomRoute><PrebuiltMediasForm /></CustomRoute>} />
                        <Route exact path="/contextual/prebuilt/edit" element={<CustomRoute><PrebuiltMediasForm /></CustomRoute>} /> */}
                        <Route exact path="/contextual/deal_briefing" element={<CustomRoute><ContextualMedia /></CustomRoute>} />
                        <Route exact path="/contextual/deal_briefing/:id" element={<CustomRoute><ContextualMediaDetail /></CustomRoute>} />
                        <Route exact path="/contextual/deal_token_reference" element={<CustomRoute><DealTokenReference /></CustomRoute>} />
                        <Route exact path="/contextual/format_carousel" element={<CustomRoute><CarouselFormat /></CustomRoute>} />
                        <Route exact path="/contextual/format_carousel/create" element={<CustomRoute><CarouselFormatForm /></CustomRoute>} />
                        <Route exact path="/contextual/format_carousel/edit" element={<CustomRoute><CarouselFormatForm /></CustomRoute>} />
                        <Route exact path="/contextual/curation_fee" element={<CustomRoute><CurationFee /></CustomRoute>} />
                        <Route exact path="/contextual/curation_fee/create" element={<CustomRoute><CurationFeeForm /></CustomRoute>} />
                        <Route exact path="/contextual/curation_fee/edit" element={<CustomRoute><CurationFeeForm /></CustomRoute>} />
                        <Route exact path="/contextual/bespoke" element={<CustomRoute><Bespoke /></CustomRoute>} />

                        <Route exact path="/data-provider/segment-category" element={<CustomRoute><SegmentCategory /></CustomRoute>} />
                        <Route exact path="/data-provider/segment-category/create" element={<CustomRoute><SegmentCategoryForm /></CustomRoute>} />
                        <Route exact path="/data-provider/segment-category/edit" element={<CustomRoute><SegmentCategoryForm /></CustomRoute>} />
                        <Route exact path="/data-provider/segment-pricing" element={<CustomRoute><SegmentPricing /></CustomRoute>} />
                        <Route exact path="/data-provider/segment-pricing/create" element={<CustomRoute><SegmentPricingForm /></CustomRoute>} />
                        <Route exact path="/data-provider/segment-pricing/edit" element={<CustomRoute><SegmentPricingForm /></CustomRoute>} />

                        <Route exact path="/contextual/category" element={<CustomRoute><Categories /></CustomRoute>} />
                        <Route exact path="/contextual/category/create" element={<CustomRoute><CategoryForm /></CustomRoute>} />
                        <Route exact path="/contextual/category/edit" element={<CustomRoute><CategoryForm /></CustomRoute>} />
                        <Route exact path="/contextual/addons" element={<CustomRoute><Addons /></CustomRoute>} />
                        <Route exact path="/contextual/addons/create" element={<CustomRoute><AddonsForm /></CustomRoute>} />
                        <Route exact path="/contextual/addons/edit" element={<CustomRoute><AddonsForm /></CustomRoute>} />


                        {/* Showcase */}
                        <Route exact path="/showcase/management/verticals" element={<CustomRoute><Vertical /></CustomRoute>} />
                        <Route exact path="/showcase/management/objectives" element={<CustomRoute><Objective /></CustomRoute>} />
                        <Route exact path="/showcase/management/creatives" element={<CustomRoute><ShowcaseCreatives /></CustomRoute>} />
                        <Route exact path="/showcase/management/creatives/create" element={<CustomRoute><ShowcaseCreativesCreate /></CustomRoute>} />
                        <Route exact path="/showcase/management/creatives/create/:id" element={<CustomRoute><ShowcaseCreativesCreate /></CustomRoute>} />

                        <Route exact path="/showcase/management/creatives/edit" element={<CustomRoute><ShowcaseCreativesEdit /></CustomRoute>} />

                        <Route exact path="/showcase/management/benchmark" element={<CustomRoute><ShowcaseBenchmark /></CustomRoute>} />
                        <Route exact path="/showcase/management/benchmark/create" element={<CustomRoute><ShowcaseBenchmarkCreate /></CustomRoute>} />

                        <Route exact path="/gross-margin" element={<CustomRoute><GrossMarginDashboard /></CustomRoute>} />

                        <Route exact path="/business/target" element={<CustomRoute><BMTarget /></CustomRoute>} />
                        <Route exact path="/business/target/create" element={<CustomRoute><BMTargetForm /></CustomRoute>} />
                        <Route exact path="/business/target/edit" element={<CustomRoute><BMTargetForm /></CustomRoute>} />


                        <Route exact path="/contextual/prebuilt" element={<CustomRoute><PrebuiltMediasGMP /></CustomRoute>} />
                        <Route exact path="/contextual/prebuilt/create" element={<CustomRoute><PrebuiltMediasFormGMP /></CustomRoute>} />
                        <Route exact path="/contextual/prebuilt/edit" element={<CustomRoute><PrebuiltMediasFormGMP /></CustomRoute>} />
                        <Route exact path="/contextual/master-sitelist" element={<CustomRoute><MasterSitelists /></CustomRoute>} />
                        <Route exact path="/contextual/master-sitelist/create" element={<CustomRoute><MasterSitelistsForm /></CustomRoute>} />
                        <Route exact path="/contextual/master-sitelist/edit" element={<CustomRoute><MasterSitelistsForm /></CustomRoute>} />
                        <Route exact path="/contextual/bespoke-category" element={<CustomRoute><BespokeDictionary /></CustomRoute>} />
                        <Route exact path="/contextual/bespoke-category/create" element={<CustomRoute><BespokeDictionaryForm /></CustomRoute>} />
                        <Route exact path="/contextual/bespoke-category/edit" element={<CustomRoute><BespokeDictionaryForm /></CustomRoute>} />

                        {/* green-working */}
                        <Route exact path="/green-working/emissions-reporting" element={<CustomRoute><EmissionReporting /></CustomRoute>} />

                    </Route>
                    <Route element={<UAC allowedUsers={[...U_SuperAdmin, ADMIN]} />} >
                        <Route exact path="/settings/dimensions" element={<CustomRoute><Dimensions /></CustomRoute>} />
                        <Route exact path="/settings/dimensions/create" element={<CustomRoute><DimensionsForm /></CustomRoute>} />
                        <Route exact path="/settings/dimensions/edit" element={<CustomRoute><DimensionsForm /></CustomRoute>} />




                        <Route exact path="/contextual/format_category" element={<CustomRoute><ManageFormatType /></CustomRoute>} />
                        <Route exact path="/contextual/format_category/create" element={<CustomRoute><ManageFormatTypeForm /></CustomRoute>} />
                        <Route exact path="/contextual/format_category/edit" element={<CustomRoute><ManageFormatTypeForm /></CustomRoute>} />

                        <Route exact path="/billing/nexd-billing" element={<CustomRoute><NexdBilling /></CustomRoute>} />
                        <Route exact path="/billing/airtory-billing" element={<CustomRoute><AirtoryBilling /></CustomRoute>} />

                        <Route exact path="/my-creatives/edit" element={<CustomRoute><CampaignsForm /></CustomRoute>} />
                        <Route exact path="/my-creatives/create" element={<CustomRoute><CampaignsForm /></CustomRoute>} />
                        <Route exact path="/my-creatives/build/:id" element={<CustomRoute><BuildInCampaign /></CustomRoute>} />
                        <Route exact path="/my-creatives/ad-builder/:id" element={<CustomRoute><BuildInCampaignBuilder /></CustomRoute>} />
                        <Route exact path="/my-creatives/brief-big/:id" element={<CustomRoute><BriefCreatives /></CustomRoute>} />

                        
                    </Route>

                    <Route element={<UAC allowedUsers={[...U_AccountManager]} />} >

                        <Route exact path="/settings/all-users" element={<CustomRoute><AllUsers /></CustomRoute>} />
                        <Route exact path="/settings/all-users/create" element={<CustomRoute><NewUser /></CustomRoute>} />
                        <Route exact path="/settings/all-users/edit" element={<CustomRoute><EditUser /></CustomRoute>} />
                        <Route exact path="/my-creatives" element={<CustomRoute><Campaigns /></CustomRoute>} />
                        <Route exact path="/my-creatives/:id" element={<CustomRoute><MyCreatives /></CustomRoute>} />
                        {/* <Route exact path="/my-creatives/review/:id" element={<CustomRoute><CreativeShow /></CustomRoute>} /> */}
                        <Route exact path="/my-creatives/review/comments/:id" element={<CustomRoute><CreativeReview /></CustomRoute>} />

                    </Route>


                    <Route element={<UAC allowedUsers={[...U_All_Admins, ...U_BMSP]} />} >

                        <Route exact path="/networks" element={<CustomRoute><ManageNetworks /></CustomRoute>} />
                    </Route>

                    <Route element={<UAC allowedUsers={[...U_NetworkManager]} />} >
                        <Route exact path="/accounts/create" element={<CustomRoute><ManageCompaniesForm /></CustomRoute>} />
                        <Route exact path="/accounts/edit" element={<CustomRoute><ManageCompaniesForm /></CustomRoute>} />

                    </Route>
                    <Route element={<UAC allowedUsers={[...U_All_Admins, BIG_MOBILE_SALESPERSON, AFFILIATE, SALES_DIRECTOR, C_LEVEL]} />} >
                        {/* Deal ID dashboard */}
                        <Route exact path="/business/deal-report" element={<CustomRoute><DealIdReport /></CustomRoute>} />
                        <Route exact path="/business/hive-activity" element={<CustomRoute><MyHiveActivity /></CustomRoute>} />
                        {/* My Creatives Demo */}
                        <Route exact path="/my-creatives-demo" element={<CustomRoute><CampaignsDemo /></CustomRoute>} />

                    </Route>
                    <Route element={<UAC allowedUsers={[...U_All_Admins, SALES_DIRECTOR, C_LEVEL]} />} >

                        <Route exact path="/business/sales-report" element={<CustomRoute><DealIdDashboard /></CustomRoute>} />
                    </Route>

                    <Route element={<UAC allowedUsers={[...U_NetworkManager, ...U_BMSP]} />} >
                        <Route exact path="/accounts" element={<CustomRoute><ManageCompanies /></CustomRoute>} />


                    </Route>


                    <Route element={<UAC allowedUsers={[...U_SuperAdmin, ...ALL_SALES]} />} >
                        <Route exact path="/business/user-analytics" element={<CustomRoute><UserReport /></CustomRoute>} />
                        

                       

                    </Route>
                    <Route element={<UAC allowedUsers={[...U_SuperAdmin, ...U_BMSP, ...U_ESG_Team]} />} >

                        <Route exact path="/green-working/transparency-policy" element={<CustomRoute><Transparency /></CustomRoute>} />
                        <Route exact path="/green-working/status" element={<CustomRoute><MyStatus /></CustomRoute>} />
                        <Route exact path="/green-working/settings" element={<CustomRoute><Settings /></CustomRoute>} />


                    </Route>

                    <Route element={<UAC allowedUsers={[...U_SuperAdmin, C_LEVEL]} />} >
                    <Route exact path="/business/user-analytics-v2" element={<CustomRoute><AnalyticsDemo /></CustomRoute>} />
                        <Route exact path="/business/product-margin" element={<CustomRoute><ProductMarginDashboard /></CustomRoute>} />
                    </Route>

                </Route>

                <Route path="*" element={<Navigate to="/404Error" replace />} />
            </Route>

        </Routes>
    );
};

export default withGA(SRoutes);
