import React from 'react'
import TagsInput from 'react-tagsinput'
import classnames from 'classnames'
import { FormError } from './FormError'

const CustomTagsInput = ({ field, tags, handleChange,
  containerClassName, labelClassName, inputContainerClassName, className,
  label, placeholder, isOptional = false, form, emailValidation = true }) => {

  return (
    <div className={containerClassName}>
      {label ? <label className={classnames("mb-1  mo-p-0", labelClassName)}>{label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}</label> : null}
      <div className={classnames("mo-p-0", inputContainerClassName)}>
        <TagsInput value={tags}

          onChange={(value) => {
            if(form) form.setFieldTouched(field.name, true)
            if (handleChange) { handleChange(value, form.setFieldValue); }
          }}
          addOnBlur={true}
          addOnPaste={true}
          pasteSplit={
            data => {
              const separators = [',', ';', ' ','\n', '\r'];
              return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
            }
          }
          inputProps={{
            placeholder: placeholder ? placeholder : label,
            className: classnames('react-tagsinput-input ', className)
          }}
          // eslint-disable-next-line
          validationRegex={emailValidation ? /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ : /\S/}
          addKeys={[9, 13, 188, 59]} //Tab, Enter, Comma, Semi colon
        />
        <FormError name={field.name} />
      </div>
    </div>
  )
}

export default CustomTagsInput