export const ContextualConstants = {
    GET_ALL_CONTEXTUAL_PAGINATE_SUCCESS: 'GET_ALL_CONTEXTUAL_PAGINATE_SUCCESS',
    GET_ALL_CONTEXTUAL_PAGINATE_ERROR: 'GET_ALL_CONTEXTUAL_PAGINATE_ERROR',
    
    EDIT_CONTEXTUAL_SUCCESS: 'EDIT_CONTEXTUAL_SUCCESS',
    EDIT_CONTEXTUAL_ERROR: 'EDIT_CONTEXTUAL_ERROR',

    SYNC_CONTEXTUAL_SUCCESS: 'SYNC_CONTEXTUAL_SUCCESS',
    SYNC_CONTEXTUAL_ERROR: 'SYNC_CONTEXTUAL_ERROR',

    CONTEXTUAL_CURRENT_PAGE: 'CONTEXTUAL_CURRENT_PAGE',
    CONTEXTUAL_LIMIT_PER_PAGE: 'CONTEXTUAL_LIMIT_PER_PAGE',
    CONTEXTUAL_SEARCH_VALUE: 'CONTEXTUAL_SEARCH_VALUE',
    CONTEXTUAL_STATUS_VALUE: 'CONTEXTUAL_STATUS_VALUE',
    CONTEXTUAL_TOTAL_COUNT: 'CONTEXTUAL_TOTAL_COUNT',
    
    GET_CONTEXTUAL_BY_ID: 'GET_CONTEXTUAL_BY_ID',
    EMPTY_CONTEXTUAL_BY_ID: 'EMPTY_CONTEXTUAL_BY_ID',
    GET_CONTEXTUAL_BY_ID_ERROR: 'GET_CONTEXTUAL_BY_ID_ERROR',

    CONTEXTUAL_LOADING_STATUS: 'CONTEXTUAL_LOADING_STATUS',

    EMPTY_CONTEXTUAL: 'EMPTY_CONTEXTUAL',


    GET_CONTEXTUAL_STATUS_LOG: 'GET_CONTEXTUAL_STATUS_LOG',
    EMPTY_CONTEXTUAL_STATUS_LOG: 'EMPTY_CONTEXTUAL_STATUS_LOG',
    GET_CONTEXTUAL_STATUS_LOG_ERROR: 'GET_CONTEXTUAL_STATUS_LOG_ERROR',
    CONTEXTUAL_STATUS_LOG_LOADING_STATUS: 'CONTEXTUAL_STATUS_LOG_LOADING_STATUS'
}