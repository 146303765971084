
export const HelpersConstants = {

    BB_SHOWCASE_GET_ALL_VERTICALS_INIT: 'BB_SHOWCASE_GET_ALL_VERTICALS_INIT',
    BB_SHOWCASE_GET_ALL_VERTICALS_SUCCESS: 'BB_SHOWCASE_GET_ALL_VERTICALS_SUCCESS',
    BB_SHOWCASE_GET_ALL_VERTICALS_ERROR: 'BB_SHOWCASE_GET_ALL_VERTICALS_ERROR',
    BB_SHOWCASE_EMPTY_VERTICALS: 'BB_SHOWCASE_EMPTY_VERTICALS',

    BB_SHOWCASE_GET_ALL_FORMATS_INIT: 'BB_SHOWCASE_GET_ALL_FORMATS_INIT',
    BB_SHOWCASE_GET_ALL_FORMATS_SUCCESS: 'BB_SHOWCASE_GET_ALL_FORMATS_SUCCESS',
    BB_SHOWCASE_GET_ALL_FORMATS_ERROR: 'BB_SHOWCASE_GET_ALL_FORMATS_ERROR',
    BB_SHOWCASE_EMPTY_FORMATS: 'BB_SHOWCASE_EMPTY_FORMATS',

    BB_SHOWCASE_GET_ALL_DIMENSIONS_INIT: 'BB_SHOWCASE_GET_ALL_DIMENSIONS_INIT',
    BB_SHOWCASE_GET_ALL_DIMENSIONS_SUCCESS: 'BB_SHOWCASE_GET_ALL_DIMENSIONS_SUCCESS',
    BB_SHOWCASE_GET_ALL_DIMENSIONS_ERROR: 'BB_SHOWCASE_GET_ALL_DIMENSIONS_ERROR',
    BB_SHOWCASE_EMPTY_DIMENSIONS: 'BB_SHOWCASE_EMPTY_DIMENSIONS',

    BB_SHOWCASE_GET_ALL_OBJECTIVES_INIT: 'BB_SHOWCASE_GET_ALL_OBJECTIVES_INIT',
    BB_SHOWCASE_GET_ALL_OBJECTIVES_SUCCESS: 'BB_SHOWCASE_GET_ALL_OBJECTIVES_SUCCESS',
    BB_SHOWCASE_GET_ALL_OBJECTIVES_ERROR: 'BB_SHOWCASE_GET_ALL_OBJECTIVES_ERROR',
    BB_SHOWCASE_EMPTY_OBJECTIVES: 'BB_SHOWCASE_EMPTY_OBJECTIVES',

    BB_SHOWCASE_GET_ALL_COUNTRIES_INIT: 'BB_SHOWCASE_GET_ALL_COUNTRIES_INIT',
    BB_SHOWCASE_GET_ALL_COUNTRIES_SUCCESS: 'BB_SHOWCASE_GET_ALL_COUNTRIES_SUCCESS',
    BB_SHOWCASE_GET_ALL_COUNTRIES_ERROR: 'BB_SHOWCASE_GET_ALL_COUNTRIES_ERROR',
    BB_SHOWCASE_EMPTY_COUNTRIES: 'BB_SHOWCASE_EMPTY_COUNTRIES',
    
    BB_SHOWCASE_GET_ALL_CREATIVES_INIT: 'BB_SHOWCASE_GET_ALL_CREATIVES_INIT',
    BB_SHOWCASE_GET_ALL_CREATIVES_SUCCESS: 'BB_SHOWCASE_GET_ALL_CREATIVES_SUCCESS',
    BB_SHOWCASE_GET_ALL_CREATIVES_ERROR: 'BB_SHOWCASE_GET_ALL_CREATIVES_ERROR',
    BB_SHOWCASE_EMPTY_CREATIVES: 'BB_SHOWCASE_EMPTY_CREATIVES',
    BB_SHOWCASE_GET_ALL_CREATIVES_TOTAL_COUNT: 'BB_SHOWCASE_GET_ALL_CREATIVES_TOTAL_COUNT',

    /* For dynamic search params */
    BB_SHOWCASE_ACTIVE_DEVICES : "BB_SHOWCASE_ACTIVE_DEVICES",
    BB_SHOWCASE_SEARCH_NAME : "BB_SHOWCASE_SEARCH_NAME",
    BB_SHOWCASE_ACTIVE_FORMATS : "BB_SHOWCASE_ACTIVE_FORMATS",
    BB_SHOWCASE_ACTIVE_DIMENSIONS : "BB_SHOWCASE_ACTIVE_DIMENSIONS",
    BB_SHOWCASE_ACTIVE_VERTICALS : "BB_SHOWCASE_ACTIVE_VERTICALS",
    BB_SHOWCASE_ACTIVE_OBJECTIVES : "BB_SHOWCASE_ACTIVE_OBJECTIVES",
    BB_SHOWCASE_SORTBY : "BB_SHOWCASE_SORTBY",
    BB_SHOWCASE_SORTTYPE : "BB_SHOWCASE_SORTTYPE",
    BB_SHOWCASE_COUNTRY : "BB_SHOWCASE_COUNTRY",
    BB_SHOWCASE_NETWORKID : "BB_SHOWCASE_NETWORKID",
    BB_SHOWCASE_ACCOUNTID : "BB_SHOWCASE_ACCOUNTID",
    BB_SHOWCASE_RESET_FILTER : "BB_SHOWCASE_RESET_FILTER"

};
