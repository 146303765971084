export const AllCreativeConstants = {
    GET_BRIEF_CREATIVE_BY_CAMPAIGN_ID: 'GET_BRIEF_CREATIVE_BY_CAMPAIGN_ID',
    EMPTY_BRIEF_CREATIVE_BY_CAMPAIGN_ID: 'EMPTY_BRIEF_CREATIVE_BY_CAMPAIGN_ID',
    GET_BRIEF_CREATIVE_BY_CAMPAIGN_ID_ERROR: 'GET_BRIEF_CREATIVE_BY_CAMPAIGN_ID_ERROR',
    
    GET_BRIEF_CREATIVE_BY_ID: 'GET_BRIEF_CREATIVE_BY_ID',
    EMPTY_BRIEF_CREATIVE_BY_ID: 'EMPTY_BRIEF_CREATIVE_BY_ID',
    GET_BRIEF_CREATIVE_BY_ID_ERROR: 'GET_BRIEF_CREATIVE_BY_ID_ERROR',

    GET_ALL_VERSIONS_BRIEF_CREATIVE: 'GET_ALL_VERSIONS_BRIEF_CREATIVE',
    EMPTY_ALL_VERSIONS_BRIEF_CREATIVE: 'EMPTY_ALL_VERSIONS_BRIEF_CREATIVE',
    GET_ALL_VERSIONS_BRIEF_CREATIVE_ERROR: 'GET_ALL_VERSIONS_BRIEF_CREATIVE_ERROR',
    
    APPROVE_BRIEF_CREATIVE: 'APPROVE_BRIEF_CREATIVE',
    APPROVE_BRIEF_CREATIVE_ERROR: 'APPROVE_BRIEF_CREATIVE_ERROR',

    DISREGARD_BRIEF_CREATIVE: 'DISREGARD_BRIEF_CREATIVE',
    DISREGARD_BRIEF_CREATIVE_ERROR: 'DISREGARD_BRIEF_CREATIVE_ERROR',

    UPDATE_CREATIVE_STATUSES: 'UPDATE_CREATIVE_STATUSES',
    UPDATE_CREATIVE_STATUSES_ERROR: 'UPDATE_CREATIVE_STATUSES_ERROR',

    ACTIVATE_BRIEF_CREATIVE: 'ACTIVATE_BRIEF_CREATIVE',
    ACTIVATE_BRIEF_CREATIVE_ERROR: 'ACTIVATE_BRIEF_CREATIVE_ERROR',

    DELETE_BRIEF_CREATIVE: 'DELETE_BRIEF_CREATIVE',
    DELETE_BRIEF_CREATIVE_ERROR: 'DELETE_BRIEF_CREATIVE_ERROR',

    RECOVER_BRIEF_CREATIVE: 'RECOVER_BRIEF_CREATIVE',
    RECOVER_BRIEF_CREATIVE_ERROR: 'RECOVER_BRIEF_CREATIVE_ERROR',
    
    PUBLISH_BRIEF_CREATIVE: 'PUBLISH_BRIEF_CREATIVE',
    PUBLISH_BRIEF_CREATIVE_ERROR: 'PUBLISH_BRIEF_CREATIVE_ERROR',

    BRIEF_CREATIVE_LOADING_STATUS: 'BRIEF_CREATIVE_LOADING_STATUS',

    EMPTY_BRIEF_CREATIVE: 'EMPTY_BRIEF_CREATIVE',
};
