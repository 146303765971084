export const BillingConstants = {

    BILLING_REPORT_INIT: "BILLING_REPORT_INIT",
    BILLING_REPORT_SUCCESS: "BILLING_REPORT_SUCCESS",
    BILLING_REPORT_ERROR: "BILLING_REPORT_ERROR",
    BILLING_REPORT_CSV_INIT: "BILLING_REPORT_CSV_INIT",
    BILLING_REPORT_CSV_SUCCESS: "BILLING_REPORT_CSV_SUCCESS",
    BILLING_REPORT_CSV_ERROR: "BILLING_REPORT_CSV_ERROR",
    EMPTY_BILLING_REPORT_CSV: "EMPTY_BILLING_REPORT_CSV",

    AIRTORY_BILLING_REPORT_INIT: "AIRTORY_BILLING_REPORT_INIT",
    AIRTORY_BILLING_REPORT_SUCCESS: "AIRTORY_BILLING_REPORT_SUCCESS",
    AIRTORY_BILLING_REPORT_ERROR: "AIRTORY_BILLING_REPORT_ERROR",
    AIRTORY_BILLING_REPORT_CSV_INIT: "AIRTORY_BILLING_REPORT_CSV_INIT",
    AIRTORY_BILLING_REPORT_CSV_SUCCESS: "AIRTORY_BILLING_REPORT_CSV_SUCCESS",
    AIRTORY_BILLING_REPORT_CSV_ERROR: "AIRTORY_BILLING_REPORT_CSV_ERROR",
    EMPTY_AIRTORY_BILLING_REPORT_CSV: "EMPTY_AIRTORY_BILLING_REPORT_CSV",
};
