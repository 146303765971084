import { DealMonitoringConstants } from './dealMonitoring.constants';

export const dealMonitoring = (state = {}, action) => {
    switch (action.type) {
        case DealMonitoringConstants.GET_ALL_DEAL_MONITORING_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case DealMonitoringConstants.ALL_DEAL_MONITORING_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case DealMonitoringConstants.GET_ALL_DEAL_MONITORING_ERROR:
            return {
                ...state,
                error: true
            };
        case DealMonitoringConstants.EMPTY_DEAL_MONITORING:
            return { ...state, data: "" }
        case DealMonitoringConstants.ALL_DEAL_MONITORING_LIMIT:
            return {
                ...state,
                total: action.payload
            };
        case DealMonitoringConstants.GET_DEAL_BY_TOKEN_SUCCESS:
            return {
                ...state,
                dealsByToken: action.payload
            };

        case DealMonitoringConstants.DEAL_BY_TOKEN_LOADING:
            return {
                ...state,
                loadingDealsByToken: action.payload
            };
        case DealMonitoringConstants.GET_DEAL_BY_TOKEN_ERROR:
            return {
                ...state,
                errorDealByToken: true
            };
        case DealMonitoringConstants.EMPTY_DEAL_BY_TOKEN:
            return { ...state, dealsByToken: "" }
            
        default:
            return state;
    }
}