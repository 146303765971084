import { AdByBmConstants } from './adByBM.constants';

export const AdByBm = (state = {}, action) => {
    switch (action.type) {
        case AdByBmConstants.AD_CREATE_REQUEST_SUCCESS:
            return {...state, adByBmSuccess: true, adByBmResp: action.payload };
        case AdByBmConstants.AD_CREATE_REQUEST_ERROR:
            return {...state, adByBmSuccess: false, adByBmResp: action.payload };
        case AdByBmConstants.AD_CREATE_REQUEST_LOADING:
            return {...state, adByBmLoading: action.payload};

        case AdByBmConstants.NEW_BRIEF_CREATIVE_TAG_SUCCESS:
            return {...state, newCTagSuccess: true, newCTagResp: action.payload, newCtagInfo: action.info};
        case AdByBmConstants.NEW_BRIEF_CREATIVE_TAG_ERROR:
            return {...state, newCTagSuccess: false, newCTagResp: action.payload };
        case AdByBmConstants.NEW_BRIEF_CREATIVE_TAG_LOADING:
            return {...state, newCTagLoading: action.payload};

        case AdByBmConstants.EDIT_BRIEF_CREATIVE_TAG_SUCCESS:
            return {...state, editCTagSuccess: true, editCTagResp: action.payload, editCtagInfo: action.info };
        case AdByBmConstants.EDIT_BRIEF_CREATIVE_TAG_ERROR:
            return {...state, editCTagSuccess: false, editCTagResp: action.payload };
        case AdByBmConstants.EDIT_BRIEF_CREATIVE_TAG_LOADING:
            return {...state, editCTagLoading: action.payload};

        default:
            return state;
    }
}