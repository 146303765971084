import React, { useState } from 'react'
import classnames from 'classnames'
import { FormError } from '../FormError'
import Tooltip from '../../utilities/Tooltip'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './text-html.scss'
import {ToolBar} from "./toolBar"

function TextHTML(props) {

    const {
        disabled = false, isEdit = false, onChange, field, containerClassName = "", form, labelClassName = "", inputContainerClassName = "", wrapperClassName = "", editorClassName = "",
        label, isOptional = false, bottomTextClick = false, bottomText = false, topTextClick = false, topText = false, tooltip = false
    } = props;

    var editorState = ""

    if (isEdit) {
        const contentBlock = htmlToDraft(field.value);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState = EditorState.createWithContent(contentState);
    }

    const [editState, setEditState] = useState(isEdit ? editorState : EditorState.createEmpty());


    const onEditorStateChange = (editorState) => {
        setEditState(editorState);
        form.setFieldValue(field.name, draftToHtml(convertToRaw(editorState.getCurrentContent())));
        if (onChange) {
            onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())), form.setFieldValue);
        }
    };
    return (
        <div className={containerClassName}>
            {(label && !tooltip) && <label className={classnames("mb-1", labelClassName)}>{label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}</label>}
            {tooltip &&
                <Tooltip light={false} className={classnames("mb-1 cursor-pointer", labelClassName, "pt-1")} label={label} htmlFor={field.name} iconname="fa-info-circle">
                    <div className="p-2 text-left maxWidth-300"><span>{tooltip}</span></div>
                </Tooltip>
            }
            <div className={inputContainerClassName}>
                {topText && !disabled ? <p className="text-right  cursor-pointer m-0  hvr-underline-from-center pl-0 bb-text_small  max-w-500" onClick={topTextClick ? () => topTextClick() : null}><i className="fas fa-trash-alt"></i></p> : null}
                <Editor
                    editorState={editState}
                    wrapperClassName={wrapperClassName}
                    editorClassName={editorClassName}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={ToolBar}
                />
                <FormError name={field.name} />
                {bottomText && !disabled ? <p className="text-right bb-color_secondary-blue cursor-pointer m-0  hvr-underline-from-center pl-0 bb-text_small max-w-500" onClick={bottomTextClick ? () => bottomTextClick() : null}>{bottomText}</p> : null}

            </div>
        </div>
    )
}

export default TextHTML;
