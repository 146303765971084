
import { AllCuratedMediaConstants } from './c-media.constants';

export const CuratedMedia = (state = {}, action) => {
    switch (action.type) {
        case AllCuratedMediaConstants.EMPTY_CURATED_MEDIA:
           return { ...state, data: "" };
        case AllCuratedMediaConstants.GET_CURATED_MEDIA:
            return { ...state, data: action.payload };

        case AllCuratedMediaConstants.CURATED_MEDIA_LOADING:
            return { ...state, loading: action.payload };
            
        case AllCuratedMediaConstants.GET_CURATED_MEDIA_ERROR:
            return { ...state, data: "" };

        default:
            return state;
    }
}
