import { combineReducers } from 'redux';
import { AllUserConstants } from './all-users.constants';

const adduser = (state = {}, action) => {

    switch (action.type) {
        case AllUserConstants.BB_ADD_USER_SUCCESS:
            return { ...state, addUserSuccess: true, addResp: action.payload };

        case AllUserConstants.BB_ADD_USER_ERROR:
            return { ...state, addUserSuccess: false, ...action.payload };

        case AllUserConstants.BB_ADD_USER_LOADING:
            return { ...state, addUserLoading: action.payload };

        default:
            return state;
    }
}


const allUsers = (state = {}, action) => {
    switch (action.type) {
        case AllUserConstants.EMPTY_USERS:
            return { ...state, data:''};

        case AllUserConstants.GET_ALL_USERS:
            return { ...state, ...action.payload };

        case AllUserConstants.ALL_USERS_LOADING:
            return { ...state, loading: action.payload };

        case AllUserConstants.GET_ALL_USERS_ERROR:
            return { ...state, error: true };

        case AllUserConstants.ALL_USERS_CURRENT_PAGE:
            return { ...state, page: action.payload };

        case AllUserConstants.ALL_USERS_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload };

        case AllUserConstants.ALL_USERS_SEARCH_VALUE:
            return { ...state, name: action.payload };

        case AllUserConstants.ALL_USERS_STATUS_VALUE:
            return { ...state, status: action.payload };

        case AllUserConstants.ALL_USERS_LIMIT:
            return { ...state, total_rows: action.payload };

        case AllUserConstants.DELETE_USER_SUCCESS:
            return { ...state, userDelete: action.payload };

        case AllUserConstants.DELETE_USER_ERROR:
            return { ...state, userDelete: action.payload };

        case AllUserConstants.ACTIVATE_USER_SUCCESS:
            return { ...state, userActivate: action.payload };

        case AllUserConstants.ACTIVATE_USER_ERROR:
            return { ...state, userActivate: action.payload };

        default:
            return state;
    }
}


const editUser = (state = {}, action) => {
    switch (action.type) {
        case AllUserConstants.GET_EDIT_USER_INFO:
            return { ...state, profile: action.payload };
        case AllUserConstants.EMPTY_EDIT_USER_INFO:
            return { ...state, profile: ''};
        case AllUserConstants.BB_EDIT_USER_SUCCESS:
            return { ...state, editUserSuccess: true, editResp: action.payload };

        case AllUserConstants.BB_EDIT_USER_ERROR:
            return { ...state, editUserSuccess: false, ...action.payload };

        case AllUserConstants.BB_EDIT_USER_LOADING:
            return { ...state, editUserLoading: action.payload };

        case AllUserConstants.RESET_USERS_PASSWORD_SA:
            return{...state, resetSuccess: true, newPassword: action.payload}

        case AllUserConstants.RESET_USERS_PASSWORD_SA_ERROR:
                    return{...state, resetSuccess: false, newPassword: "Error occured, Please try again later."}

        default:
            return state;
    }
}

const userProfile = (state = {}, action) => {
    switch (action.type) {
        case AllUserConstants.BB_GET_USER_INFORMATION:
            return {
                ...state,
                profile: action.payload,
                profilechangedsubmitted: false,
                changeUserInfoSuccess: false,
                passwordchangedsubmitted: false,
                changePasswordSuccess: false
            };
        case AllUserConstants.BB_CHANGE_USER_INFORMATION:
            return {
                ...state,
                changeUserInfoSuccess: true,
                profilechangedsubmitted: true,
                editResp: action.payload
            };
        case AllUserConstants.BB_CHANGE_USER_INFORMATION_ERROR:
            return {
                ...state,
                changeUserInfoSuccess: false,
                profilechangedsubmitted: true,
                loading: false,
                editUserSuccess: false,
                ...action.payload
            };
        case AllUserConstants.BB_CHANGE_USER_PASSWORD:
            return {
                ...state,
                changePasswordSuccess: true,
                passwordchangedsubmitted: true
            };
        case AllUserConstants.BB_CHANGE_USER_PASSWORD_ERROR:
            return {
                ...state,
                changePasswordSuccess: false,
                passwordchangedsubmitted: true
            };
        case AllUserConstants.BB_CHANGE_USER_INFORMATION_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}


export const users = combineReducers({
    userProfile,
    allUsers,
    editUser,
    adduser
});
