import React, { useEffect } from "react";
import Spinner from "../utilities/Spinner";

const styles = {
  text: {
    marginTop: 20,
    color: "#fff",

  },
  spinner: {
    backgroundColor: "rgba(1, 1, 1, 0.5)",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    top: 0,
    left: 0,
    zIndex: 10000000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
};

const Loader = ({
  loading,
  // eslint-disable-next-line
  text = "Loading...",
  fullPage,
  containerStyle,
  textStyle,
  showText = false
}) => {
  useEffect(() => {
    if(loading) document.body.classList.add("overflow-hidden")
    else document.body.classList.remove("overflow-hidden")
    return () => { document.body.classList.remove("overflow-hidden") };
  }, [loading])
  if (typeof loading !== "boolean") {
    return null;
  }

  if (!loading) {
    return null;
  }
  const mergedContainerStyle = {
    ...styles.spinner,
    ...containerStyle,
    position: fullPage ? "fixed" : "absolute"
  };
  return (
    <div style={mergedContainerStyle}>

      <Spinner />

      {showText ? <span style={{ ...styles.text, ...textStyle }}>{text}</span> : null}
    </div>
  );
};

export default Loader;
