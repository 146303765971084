export const CustomFormatConstants = {
    ALL_SHOWCASE_CUSTOM_FORMAT_CURRENT_PAGE: "ALL_SHOWCASE_CUSTOM_FORMAT_CURRENT_PAGE",
    ALL_SHOWCASE_CUSTOM_FORMAT_LIMIT_PER_PAGE: "ALL_SHOWCASE_CUSTOM_FORMAT_LIMIT_PER_PAGE",
    ALL_SHOWCASE_CUSTOM_FORMAT_SEARCH_VALUE: "ALL_SHOWCASE_CUSTOM_FORMAT_SEARCH_VALUE",
    ALL_SHOWCASE_CUSTOM_FORMAT_STATUS_VALUE: "ALL_SHOWCASE_CUSTOM_FORMAT_STATUS_VALUE",

    GET_ALL_SHOWCASE_CUSTOM_FORMAT: "GET_ALL_SHOWCASE_CUSTOM_FORMAT",
    ALL_SHOWCASE_CUSTOM_FORMAT_LIMIT: "ALL_SHOWCASE_CUSTOM_FORMAT_LIMIT",
    GET_ALL_SHOWCASE_CUSTOM_FORMAT_ERROR: "GET_ALL_SHOWCASE_CUSTOM_FORMAT_ERROR",
    ALL_SHOWCASE_CUSTOM_FORMAT_LOADING: "ALL_SHOWCASE_CUSTOM_FORMAT_LOADING",

    DELETE_SHOWCASE_CUSTOM_FORMAT_SUCCESS: 'DELETE_SHOWCASE_CUSTOM_FORMAT_SUCCESS',
    DELETE_SHOWCASE_CUSTOM_FORMAT_ERROR: 'DELETE_SHOWCASE_CUSTOM_FORMAT_ERROR',
    ACTIVATE_SHOWCASE_CUSTOM_FORMAT_SUCCESS: 'ACTIVATE_SHOWCASE_CUSTOM_FORMAT_SUCCESS',
    ACTIVATE_SHOWCASE_CUSTOM_FORMAT_ERROR: 'ACTIVATE_SHOWCASE_CUSTOM_FORMAT_ERROR',


    BB_ADD_SHOWCASE_CUSTOM_FORMAT_SUCCESS: 'BB_ADD_SHOWCASE_CUSTOM_FORMAT_SUCCESS',
    BB_ADD_SHOWCASE_CUSTOM_FORMAT_ERROR: 'BB_ADD_SHOWCASE_CUSTOM_FORMAT_ERROR',
    BB_ADD_SHOWCASE_CUSTOM_FORMAT_LOADING: 'BB_ADD_SHOWCASE_CUSTOM_FORMAT_LOADING',

    BB_EDIT_SHOWCASE_CUSTOM_FORMAT_SUCCESS: 'BB_EDIT_SHOWCASE_CUSTOM_FORMAT_SUCCESS',
    BB_EDIT_SHOWCASE_CUSTOM_FORMAT_ERROR: 'BB_EDIT_SHOWCASE_CUSTOM_FORMAT_ERROR',

    BB_GET_ACTIVE_SHOWCASE_CUSTOM_FORMAT_INIT: 'BB_GET_ACTIVE_SHOWCASE_CUSTOM_FORMAT_INIT',
    BB_GET_ACTIVE_SHOWCASE_CUSTOM_FORMAT_SUCCESS: 'BB_GET_ACTIVE_SHOWCASE_CUSTOM_FORMAT_SUCCESS',
    BB_GET_ACTIVE_SHOWCASE_CUSTOM_FORMAT_ERROR: 'BB_GET_ACTIVE_SHOWCASE_CUSTOM_FORMAT_ERROR',

    BB_GET_ALL_CUSTOM_FORMAT_INIT:'BB_GET_ALL_CUSTOM_FORMAT_INIT',
    BB_GET_ALL_CUSTOM_FORMAT_SUCCESS:'BB_GET_ALL_CUSTOM_FORMAT_SUCCESS',
    BB_GET_ALL_CUSTOM_FORMAT_ERROR:'BB_GET_ALL_CUSTOM_FORMAT_ERROR',
    EMPTY_GET_ALL_CUSTOM_FORMAT:'EMPTY_GET_ALL_CUSTOM_FORMAT'
};
