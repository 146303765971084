
import { AllOffsetOptionsConstants } from './offsetOptions.constants';

export const offsetOptions = (state = {}, action) => {
    switch (action.type) {
        case AllOffsetOptionsConstants.EMPTY_OFFSET_OPTIONS:
           return { ...state, data: "" };
        case AllOffsetOptionsConstants.GET_OFFSET_OPTIONS:
            return { ...state, ...action.payload, offsetOptionsChange: Date.now() + "Suceess"};

        case AllOffsetOptionsConstants.OFFSET_OPTIONS_LOADING:
            return { ...state, loading: action.payload };
            
        case AllOffsetOptionsConstants.GET_OFFSET_OPTIONS_ERROR:
            return { ...state, ...action.payload, offsetOptionsChange: Date.now() + "False" };

        default:
            return state;
    }
}
