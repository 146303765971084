export const AllCampaignConstants = {
    GET_ALL_BRIEF_CAMPAIGN_PAGINATE_SUCCESS: 'GET_ALL_BRIEF_CAMPAIGN_PAGINATE_SUCCESS',
    GET_ALL_BRIEF_CAMPAIGN_PAGINATE_ERROR: 'GET_ALL_BRIEF_CAMPAIGN_PAGINATE_ERROR',

    BRIEF_CAMPAIGN_CURRENT_PAGE: 'BRIEF_CAMPAIGN_CURRENT_PAGE',
    BRIEF_CAMPAIGN_LIMIT_PER_PAGE: 'BRIEF_CAMPAIGN_LIMIT_PER_PAGE',
    BRIEF_CAMPAIGN_SEARCH_VALUE: 'BRIEF_CAMPAIGN_SEARCH_VALUE',
    BRIEF_CAMPAIGN_TOTAL_COUNT: 'BRIEF_CAMPAIGN_TOTAL_COUNT',

    GET_BRIEF_CAMPAIGN_BY_ID: 'GET_BRIEF_CAMPAIGN_BY_ID',
    EMPTY_BRIEF_CAMPAIGN_BY_PROFILE: 'EMPTY_BRIEF_CAMPAIGN_BY_PROFILE',
    GET_BRIEF_CAMPAIGN_BY_ID_ERROR: 'GET_BRIEF_CAMPAIGN_BY_ID_ERROR',

    ADD_BRIEF_CAMPAIGN_SUCCESS: 'ADD_BRIEF_CAMPAIGN_SUCCESS',
    ADD_BRIEF_CAMPAIGN_ERROR: 'ADD_BRIEF_CAMPAIGN_ERROR',

    EDIT_BRIEF_CAMPAIGN_SUCCESS: 'EDIT_BRIEF_CAMPAIGN_SUCCESS',
    EDIT_BRIEF_CAMPAIGN_ERROR: 'EDIT_BRIEF_CAMPAIGN_ERROR',

    ACTIVATE_BRIEF_CAMPAIGN: 'ACTIVATE_BRIEF_CAMPAIGN',
    ACTIVATE_BRIEF_CAMPAIGN_ERROR: 'ACTIVATE_BRIEF_CAMPAIGN_ERROR',

    DELETE_BRIEF_CAMPAIGN: 'DELETE_BRIEF_CAMPAIGN',
    DELETE_BRIEF_CAMPAIGN_ERROR: 'DELETE_BRIEF_CAMPAIGN_ERROR',

    BRIEF_CAMPAIGN_LOADING_STATUS: 'BRIEF_CAMPAIGN_LOADING_STATUS',

    EMPTY_BRIEF_CAMPAIGN: 'EMPTY_BRIEF_CAMPAIGN',
};
