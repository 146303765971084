import { BespokeDictionaryConstants } from "./bespokeDictionary.constants";

export const bespokeDictionary = (state = {}, action) => {
    switch (action.type) {
        case BespokeDictionaryConstants.GET_ALL_BESPOKE_DICTIONARY_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case BespokeDictionaryConstants.GET_ALL_BESPOKE_DICTIONARY_PAGINATE_ERROR: return { ...state, ...action.payload }

        case BespokeDictionaryConstants.EMPTY_BESPOKE_DICTIONARY_BY_PROFILE: return { ...state, profile: "" }
        case BespokeDictionaryConstants.GET_BESPOKE_DICTIONARY_BY_ID: return { ...state, profile: action.payload }
        case BespokeDictionaryConstants.GET_BESPOKE_DICTIONARY_BY_ID_ERROR: return { ...state, profile: action.payload }

        case BespokeDictionaryConstants.BESPOKE_DICTIONARY_CURRENT_PAGE: return { ...state, page: action.payload }

        case BespokeDictionaryConstants.BESPOKE_DICTIONARY_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case BespokeDictionaryConstants.BESPOKE_DICTIONARY_SEARCH_VALUE: return { ...state, search: action.payload }

        case BespokeDictionaryConstants.BESPOKE_DICTIONARY_STATUS_VALUE: return { ...state, status: action.payload }

        case BespokeDictionaryConstants.BESPOKE_DICTIONARY_TOTAL_COUNT: return { ...state, total: action.payload }

        case BespokeDictionaryConstants.BESPOKE_DICTIONARY_LOADING_STATUS: return { ...state, loading: action.payload }

        case BespokeDictionaryConstants.DELETE_BESPOKE_DICTIONARY:
        case BespokeDictionaryConstants.DELETE_BESPOKE_DICTIONARY_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case BespokeDictionaryConstants.ACTIVATE_BESPOKE_DICTIONARY:
        case BespokeDictionaryConstants.ACTIVATE_BESPOKE_DICTIONARY_ERROR:
            return { ...state, activateStatus: action.payload }

        case BespokeDictionaryConstants.ADD_BESPOKE_DICTIONARY_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case BespokeDictionaryConstants.ADD_BESPOKE_DICTIONARY_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case BespokeDictionaryConstants.EDIT_BESPOKE_DICTIONARY_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case BespokeDictionaryConstants.EDIT_BESPOKE_DICTIONARY_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
