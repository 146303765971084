import { combineReducers } from 'redux';

import { user } from './user/user.reducer'
import { helpers } from "./helpers/helpers.reducer"
import { rubicon } from "./rubicon/rubicon.reducer"
import { services } from "./services/services.reducer"
import { management } from "./management/management.reducer"
import { bbcreative } from "./creatives/creatives.reducer"
import { creativeRequest } from "./creatives/request/request.reducer"
import { settings } from "./settings/settings.reducer"
import { events } from './event/event.reducer'
import { deals } from './deals/deals.reducer'
import { audiences } from './audiences/audiences.reducer'
import {showcase} from './showcase/showcase.reducer'
import { theme } from './theme/theme.reducer'
import { greenProject } from './green-project/green-project.reducer'
import { brief } from './brief/brief.reducer'

const rootReducer = combineReducers({
    user,
    settings,
    services,
    management,
    helpers,
    creativeRequest,
    bbcreative,
    rubicon,
    events,
    deals,
    audiences,
    showcase,
    theme,
    brief,
    greenProject
});

//export default rootReducer;
// eslint-disable-next-line
export default (state, action) => (
    action.type === 'UNAUTH_USER'
        ? rootReducer({...state, user: {}}, action)
        : rootReducer(state, action)
)
