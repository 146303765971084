
import { AllCompanyConstants } from './company.constants';

export const company = (state = {}, action) => {
    switch (action.type) {
        case AllCompanyConstants.EMPTY_COMPANY:
           return { ...state, data: "" };
        case AllCompanyConstants.GET_COMPANY:
            return { ...state, ...action.payload, companyChange: Date.now() + "Suceess"};

        case AllCompanyConstants.COMPANY_LOADING:
            return { ...state, loading: action.payload };
            
        case AllCompanyConstants.GET_COMPANY_ERROR:
            return { ...state, ...action.payload, companyChange: Date.now() + "False" };

        default:
            return state;
    }
}
