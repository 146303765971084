import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom';
import SRoutes from './routes/index';
import { connect } from 'react-redux';

import { getInfo } from "./redux/theme/theme.actions"
import Spinner from './components/utilities/Spinner'
import { initTheme } from './theme'
import { usePrevious } from './components/utilities/customHooks';
import history from "./helpers/history";


const CustomRouter = ({ history, ...props }) => {
    const [states, setStates] = useState({
      action: history.action,
      location: history.location
    });

  
    useLayoutEffect(() => history.listen(setStates), [history]);
    
    return (
      <Router
        {...props}
        location={states.location}
        navigationType={states.action}
        navigator={history}
      />
    );
  };
function ThemeContainer(props) {

    const { info, loading, getInfo } = props
    const prevProps = usePrevious();
    const prevLoading = prevProps && prevProps.loading

    useEffect(() => {
            getInfo();
    }, [getInfo])

    useEffect(() => {

        if (loading !== prevLoading) {
            if (!loading && info) {
                initTheme(info);
            }
        }

    }, [info, loading, prevLoading])
    
    
        return (
            <CustomRouter history={history} >
                    {loading === false ?
                        <SRoutes location={history.location} {...props}/>
                        :
                        <div className="text-center d-flex flex-column justify-content-center h-100">
                            <Spinner width="100" height="100" />
                        </div>
                    }
            </CustomRouter>
        )
    
}

const mapStateToProps = (state) => {
    return {
        loading: state.theme.loading,
        info: state.theme.info,
        role: state.user.role,

    }
};

export default connect(mapStateToProps, { getInfo })(ThemeContainer);

