import React from 'react'
import classnames from 'classnames'
import { FormError } from './FormError'
import Tooltip from '../utilities/Tooltip'
class FormRadio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { field, id, disabled, setFieldValue, value, 
            checkedValue, type, containerClassName = "", inputContainerClassName = "", 
            labelClassName = "", className = "", label = "", isOptional = false, form: { touched, errors },
            imgSrc = "", isRadioItem = true, tooltip = false, IconComponent = false } = this.props
        return (
            <div className={containerClassName}>
                {imgSrc && <img className="img-fluid bb-radio-pic align-self-center mr-1" src={imgSrc} alt=""/>}
                <div className={classnames(isRadioItem ? "radio-item" : "", inputContainerClassName)}>
                    <input type={type} id={id} {...field} name={field.name} className={classnames("form-control", className, touched[field.name] && errors[field.name] ? 'error' : '')}
                        disabled={disabled}
                        checked={type === "radio" ? (checkedValue === value ? true : false) : checkedValue}
                        onChange={async () => {
                            await setFieldValue(field.name, (type === "radio" ? field.value : !checkedValue));
                            if (typeof this.props.showPreview === 'function') {
                                this.props.showPreview(this.props.values)
                            }
                        }}
                    />
                    {label && !tooltip && <label htmlFor={id} className={classnames("mb-1", labelClassName, "pt-1")}>
                        {IconComponent && <IconComponent fill="white" className="mr-2"/>}
                        {label} 
                        {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}
                    </label>}
                    {tooltip && 
                        <Tooltip light={true} className={classnames("mb-1 cursor-pointer", labelClassName, "pt-1")} label={label} htmlFor={id} iconname="fa-info-circle">
                            <div className="p-2 text-left maxWidth-300"><span>{tooltip}</span></div>
                        </Tooltip>
                    }
                   
                    <FormError name={field.name} />
                </div>
            </div>
        )
    }
}
export default FormRadio