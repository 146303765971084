export const BespokeConstants = {
    GET_ALL_BESPOKE_PAGINATE_SUCCESS: 'GET_ALL_BESPOKE_PAGINATE_SUCCESS',
    GET_ALL_BESPOKE_PAGINATE_ERROR: 'GET_ALL_BESPOKE_PAGINATE_ERROR',

    BESPOKE_CURRENT_PAGE: 'BESPOKE_CURRENT_PAGE',
    BESPOKE_LIMIT_PER_PAGE: 'BESPOKE_LIMIT_PER_PAGE',
    BESPOKE_SEARCH_VALUE: 'BESPOKE_SEARCH_VALUE',
    BESPOKE_STATUS_VALUE: 'BESPOKE_STATUS_VALUE',
    BESPOKE_TOTAL_COUNT: 'BESPOKE_TOTAL_COUNT',
    
    GET_BESPOKE_BY_ID: 'GET_BESPOKE_BY_ID',
    EMPTY_BESPOKE_BY_PROFILE: 'EMPTY_BESPOKE_BY_PROFILE',
    GET_BESPOKE_BY_ID_ERROR: 'GET_BESPOKE_BY_ID_ERROR',

    BESPOKE_LOADING_STATUS: 'BESPOKE_LOADING_STATUS',

    EMPTY_BESPOKE: 'EMPTY_BESPOKE',
}