import ReactGA from 'react-ga4';

export const setUserGA = (userId) => {
    ReactGA.set({
        userId: userId ,                            
    });
    ReactGA.event({
        category: "User",
        action: "User logged in/verified " + userId,
      });
};

export const trackUserSignOut = () =>{
    ReactGA.event({
        category: "Log out",
        action: "User pressed the log out button",
      });
}