export const TargetConstants = {
    GET_ALL_TARGET_PAGINATE_SUCCESS: 'GET_ALL_TARGET_PAGINATE_SUCCESS',
    GET_ALL_TARGET_PAGINATE_ERROR: 'GET_ALL_TARGET_PAGINATE_ERROR',

    TARGET_CURRENT_PAGE: 'TARGET_CURRENT_PAGE',
    TARGET_LIMIT_PER_PAGE: 'TARGET_LIMIT_PER_PAGE',
    TARGET_TOTAL_COUNT: 'TARGET_TOTAL_COUNT',
    
    GET_TARGET_BY_ID: 'GET_TARGET_BY_ID',
    EMPTY_TARGET_BY_PROFILE: 'EMPTY_TARGET_BY_PROFILE',
    GET_TARGET_BY_ID_ERROR: 'GET_TARGET_BY_ID_ERROR',

    ADD_TARGET_SUCCESS: 'ADD_TARGET_SUCCESS',
    ADD_TARGET_ERROR: 'ADD_TARGET_ERROR',

    EDIT_TARGET_SUCCESS: 'EDIT_TARGET_SUCCESS',
    EDIT_TARGET_ERROR: 'EDIT_TARGET_ERROR',
    
    TARGET_LOADING_STATUS: 'TARGET_LOADING_STATUS',

    EMPTY_TARGET: 'EMPTY_TARGET',
}