export const AdTypeConstants = {
    GET_ALL_AD_TYPE_INIT: 'GET_ALL_AD_TYPE_INIT',
    GET_ALL_AD_TYPE_SUCCESS: 'GET_ALL_AD_TYPE_SUCCESS',
    GET_ALL_AD_TYPE_ERROR: 'GET_ALL_AD_TYPE_ERROR',

    GET_ALL_AD_TYPE_PAGINATE_SUCCESS: 'GET_ALL_AD_TYPE_PAGINATE_SUCCESS',
    GET_ALL_AD_TYPE_PAGINATE_ERROR: 'GET_ALL_AD_TYPE_PAGINATE_ERROR',

    AD_TYPE_CURRENT_PAGE: 'AD_TYPE_CURRENT_PAGE',
    AD_TYPE_LIMIT_PER_PAGE: 'AD_TYPE_LIMIT_PER_PAGE',
    AD_TYPE_SEARCH_VALUE: 'AD_TYPE_SEARCH_VALUE',
    AD_TYPE_STATUS_VALUE: 'AD_TYPE_STATUS_VALUE',
    AD_TYPE_TOTAL_COUNT: 'AD_TYPE_TOTAL_COUNT',
    
    GET_AD_TYPE_BY_ID: 'GET_AD_TYPE_BY_ID',
    EMPTY_AD_TYPE_BY_PROFILE: 'EMPTY_AD_TYPE_BY_PROFILE',
    GET_AD_TYPE_BY_ID_ERROR: 'GET_AD_TYPE_BY_ID_ERROR',

    ADD_AD_TYPE_SUCCESS: 'ADD_AD_TYPE_SUCCESS',
    ADD_AD_TYPE_ERROR: 'ADD_AD_TYPE_ERROR',

    EDIT_AD_TYPE_SUCCESS: 'EDIT_AD_TYPE_SUCCESS',
    EDIT_AD_TYPE_ERROR: 'EDIT_AD_TYPE_ERROR',

    ACTIVATE_DEAL_TYPE: 'ACTIVATE_DEAL_TYPE',
    ACTIVATE_DEAL_TYPE_ERROR: 'ACTIVATE_DEAL_TYPE_ERROR', 

    DELETE_AD_TYPE: 'DELETE_AD_TYPE',
    DELETE_AD_TYPE_ERROR: 'DELETE_AD_TYPE_ERROR',
    
    AD_TYPE_LOADING_STATUS: 'AD_TYPE_LOADING_STATUS',

    EMPTY_AD_TYPE: 'EMPTY_AD_TYPE',
}