export const EventConstants = {

    GET_ALL_EVENTS_SUCCESS: 'GET_ALL_EVENTS_SUCCESS',
    GET_ALL_EVENTS_ERROR: 'GET_ALL_EVENTS_ERROR',

    EVENTS_CURRENT_PAGE: 'EVENTS_CURRENT_PAGE',
    EVENTS_LIMIT_PER_PAGE: 'EVENTS_LIMIT_PER_PAGE',
    EVENTS_SEARCH_VALUE: 'EVENTS_SEARCH_VALUE',
    EVENTS_STATUS_VALUE: 'EVENTS_STATUS_VALUE',
    EVENTS_TOTAL_COUNT: 'EVENTS_TOTAL_COUNT',

    GET_EVENT_BY_ID: "GET_EVENT_BY_ID",
    GET_EVENT_BY_ID_ERROR: "GET_EVENT_BY_ID_ERROR",

    EDIT_EVENT_SUCCESS: "EDIT_EVENT_SUCCESS",
    EDIT_EVENT_ERROR: "EDIT_EVENT_ERROR",

    ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
    ADD_EVENT_ERROR: "ADD_EVENT_ERROR",

    DELETE_EVENT: 'DELETE_EVENT',
    DELETE_EVENT_ERROR: 'DELETE_EVENT_ERROR',

    EVENT_LOADING_STATUS: "EVENT_LOADING_STATUS",

    LOG_EVENT: 'LOG_EVENT',

    USER_REPORT_DATA_INIT: "USER_REPORT_DATA_INIT",
    USER_REPORT_DATA_SUCCESS: "USER_REPORT_DATA_SUCCESS",
    USER_REPORT_DATA_ERROR: "USER_REPORT_DATA_ERROR",

    TOTAL_USER_DATA_ANALYTICS_INIT: 'TOTAL_USER_DATA_ANALYTICS_INIT',
    TOTAL_USER_DATA_ANALYTICS_SUCCESS: 'TOTAL_USER_DATA_ANALYTICS_SUCCESS',
    TOTAL_USER_DATA_ANALYTICS_ERROR: 'TOTAL_USER_DATA_ANALYTICS_ERROR',


    TOTAL_USER_DATA_ANALYTICS_CSV_INIT: 'TOTAL_USER_DATA_ANALYTICS_CSV_INIT',
    TOTAL_USER_DATA_ANALYTICS_CSV_SUCCESS: 'TOTAL_USER_DATA_ANALYTICS_CSV_SUCCESS',
    TOTAL_USER_DATA_ANALYTICS_CSV_ERROR: 'TOTAL_USER_DATA_ANALYTICS_CSV_ERROR',
    EMPTY_USER_DATA_ANALYTICS_CSV:'EMPTY_USER_DATA_ANALYTICS_CSV',

    LOG_SESSION: 'LOG_SESSION',
    USER_ONLINE:"USER_ONLINE"
};
