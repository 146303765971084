import { AdTypeConstants } from "./adType.constants";

export const adTypes = (state = {}, action) => {
    switch (action.type) {
        case AdTypeConstants.GET_ALL_AD_TYPE_INIT: return { ...state, all: { loading: true } }
        case AdTypeConstants.GET_ALL_AD_TYPE_SUCCESS: return { ...state, all: action.payload }
        case AdTypeConstants.GET_ALL_AD_TYPE_ERROR: return { ...state, all: action.payload }

        case AdTypeConstants.GET_ALL_AD_TYPE_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case AdTypeConstants.GET_ALL_AD_TYPE_PAGINATE_ERROR: return { ...state, ...action.payload }

        case AdTypeConstants.EMPTY_AD_TYPE_BY_PROFILE: return { ...state, profile: "" }
        case AdTypeConstants.GET_AD_TYPE_BY_ID: return { ...state, profile: action.payload }
        case AdTypeConstants.GET_AD_TYPE_BY_ID_ERROR: return { ...state, profile: action.payload }

        case AdTypeConstants.AD_TYPE_CURRENT_PAGE: return { ...state, page: action.payload }

        case AdTypeConstants.AD_TYPE_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case AdTypeConstants.AD_TYPE_SEARCH_VALUE: return { ...state, search: action.payload }

        case AdTypeConstants.AD_TYPE_STATUS_VALUE: return { ...state, status: action.payload }

        case AdTypeConstants.AD_TYPE_TOTAL_COUNT: return { ...state, total: action.payload }

        case AdTypeConstants.AD_TYPE_LOADING_STATUS: return { ...state, loading: action.payload }

        case AdTypeConstants.DELETE_AD_TYPE:
        case AdTypeConstants.DELETE_AD_TYPE_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case AdTypeConstants.ACTIVATE_DEAL_TYPE:
        case AdTypeConstants.ACTIVATE_DEAL_TYPE_ERROR:
            return { ...state, activateStatus: action.payload }

        case AdTypeConstants.ADD_AD_TYPE_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case AdTypeConstants.ADD_AD_TYPE_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case AdTypeConstants.EDIT_AD_TYPE_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case AdTypeConstants.EDIT_AD_TYPE_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
