import { SegmentCategoryConstants } from "./segmentCategory.constants";

export const segmentCategory = (state = {}, action) => {
    switch (action.type) {
        case SegmentCategoryConstants.GET_ALL_SEGMENT_CATEGORY_INIT: return { ...state, all: { loading: true } }
        case SegmentCategoryConstants.GET_ALL_SEGMENT_CATEGORY_SUCCESS: return { ...state, all: action.payload }
        case SegmentCategoryConstants.GET_ALL_SEGMENT_CATEGORY_ERROR: return { ...state, all: action.payload }

        case SegmentCategoryConstants.GET_ALL_SEGMENT_CATEGORY_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case SegmentCategoryConstants.GET_ALL_SEGMENT_CATEGORY_PAGINATE_ERROR: return { ...state, ...action.payload }

        case SegmentCategoryConstants.EMPTY_SEGMENT_CATEGORY_BY_PROFILE: return { ...state, profile: "" }
        case SegmentCategoryConstants.GET_SEGMENT_CATEGORY_BY_ID: return { ...state, profile: action.payload }
        case SegmentCategoryConstants.GET_SEGMENT_CATEGORY_BY_ID_ERROR: return { ...state, profile: action.payload }

        case SegmentCategoryConstants.SEGMENT_CATEGORY_CURRENT_PAGE: return { ...state, page: action.payload }
        case SegmentCategoryConstants.SEGMENT_CATEGORY_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case SegmentCategoryConstants.SEGMENT_CATEGORY_SEARCH_VALUE: return { ...state, search: action.payload }
        case SegmentCategoryConstants.SEGMENT_CATEGORY_STATUS_VALUE: return { ...state, status: action.payload }
        case SegmentCategoryConstants.SEGMENT_CATEGORY_TOTAL_COUNT: return { ...state, total: action.payload }
        case SegmentCategoryConstants.SEGMENT_CATEGORY_LOADING_STATUS: return { ...state, loading: action.payload }

        case SegmentCategoryConstants.DELETE_SEGMENT_CATEGORY:
        case SegmentCategoryConstants.DELETE_SEGMENT_CATEGORY_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case SegmentCategoryConstants.ACTIVATE_SEGMENT_CATEGORY:
        case SegmentCategoryConstants.ACTIVATE_SEGMENT_CATEGORY_ERROR:
            return { ...state, activateStatus: action.payload }

        case SegmentCategoryConstants.ADD_SEGMENT_CATEGORY_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case SegmentCategoryConstants.ADD_SEGMENT_CATEGORY_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case SegmentCategoryConstants.EDIT_SEGMENT_CATEGORY_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case SegmentCategoryConstants.EDIT_SEGMENT_CATEGORY_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
