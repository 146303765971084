import React from 'react'
import classnames from 'classnames'

import Preview from '../upload-helpers/Preview'
import { FormError } from './FormError'
import parse from 'html-react-parser';

class FormImage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFileUploaded: false
    }
  }

  showPreview = () => {
    this.setState({ isFileUploaded: true });
  };

  removeFile = (setFieldValue, field) => {
    setFieldValue(field.name, null);
    field.value = null;
    this.setState({ isFileUploaded: false });
  };

  onDrop = (ev) => {

    ev.stopPropagation();
    ev.preventDefault();

    if (ev.dataTransfer.files && ev.dataTransfer.files.length) {
      if (this.props.toBase64) {
        this.toBase64(ev.dataTransfer.files[0], this.props.setFieldValue, this.props.field)
      }
      else {
        this.props.setFieldValue(this.props.field.name, ev.dataTransfer.files[0]);
      }
      this.showPreview();
      var that = this;
      setTimeout(function () {
        if (typeof that.props.onChange === 'function') {
          that.props.onChange(that.props.values);
        }
      }, 10)

    }
  }
  toBase64 = (file, setFieldValue, field) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFieldValue(field.name, reader.result);
    };
  };
  onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  }

  onDragExit = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const { containerClassName, setFieldValue, inputClassName, previewContainerClassName, field, isLabelHtml = false, isDoc = false, accept = "",
      label, isVideo = false, form, isMedia = false, imageWithGif = false, favicon = false, toBase64 = false,
    } = this.props;
    const { isFileUploaded } = this.state;

    return (
      <React.Fragment>
        <div id="bb-image-upload-container" className={classnames("bb-image-upload-container", containerClassName, form.touched[field.name] && form.errors[field.name] ? 'error' : '')}>
          {isFileUploaded && <i
            className="bb-remove-image fas fa-trash-alt fa-1dot5x"
            onClick={async (event) => {
              await this.removeFile(setFieldValue, field)
              if (typeof this.props.onChange === 'function') {
                this.props.onChange(this.props.values);
              }
            }}
          />
          }
          {!isFileUploaded &&
            <React.Fragment>
              <input
                type="file"
                name={field.name}
                id={field.name}
                accept={favicon ? ".jpg, .jpeg, .png, .gif, .ico" : imageWithGif ? ".jpg, .jpeg, .png, .gif" : isMedia ? ".mp4, .jpg, .jpeg, .png, .gif" : isVideo ? ".mp4" : isDoc ? accept : ".jpg, .jpeg, .png"}
                onChange={async (event) => {
                  if (toBase64) {
                    await this.toBase64(event.currentTarget.files[0], setFieldValue, field)
                  }
                  else {
                    await setFieldValue(field.name, event.currentTarget.files[0]);
                  }
                  form.setFieldTouched(field.name, true);
                  this.showPreview();
                  if (typeof this.props.onChange === 'function') {
                    this.props.onChange(this.props.values);
                  }
                }}
                //onChange={field.onChange}
                className={classnames("bb-input-image form-control", inputClassName)}
              />
              <label onDragExit={this.onDragExit}
                onDragLeave={this.onDragExit}
                onDragEnter={this.onDragOver}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop} htmlFor={field.name}>
                {
                  isLabelHtml ? parse(label) :
                    <React.Fragment>
                      <i className='fas fa-plus fa-2x text-dark'></i>
                      <span className='text-dark'>
                        {label}
                      </span>
                    </React.Fragment>
                }
              </label>
            </React.Fragment>
          }
          {isFileUploaded &&
            <React.Fragment>

              <Preview previewContainerClassName={previewContainerClassName} file={field.value} isVideo={isVideo} isDoc={isDoc} isMedia={isMedia} 
              onDragExit={this.onDragExit}
              onDragLeave={this.onDragExit}
              onDragEnter={this.onDragOver}
              onDragOver={this.onDragOver}
              onDrop={this.onDrop}
              />

              {field.value && field.value.name &&
                <span title={field.value.name} className="bb-input-filename bb-text_small text-dark">
                  {field.value && field.value.name}
                </span>
              }
            </React.Fragment>
          }

        </div>
        <div className="mt-2">
          <FormError name={field.name} />
        </div>
      </React.Fragment>
    );
  }
}
export default FormImage