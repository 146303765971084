import React from 'react'
import { FastField, setNestedObjectValues } from 'formik';
import FormInput from './FormInput';
import TextArea from './TextArea';

export {default as SortableItems} from "./sortable-items"
export { default as FormInput } from './FormInput'
export { default as FormImage } from './FormImage'
export { default as EditImage } from './EditImage'
export { default as SelectInput } from './SelectInput'
export { default as DatePicker } from './DatePicker/index'
export { default as FormDropdown } from './ReactDropdown'
export { default as FormPhoneInput } from './FormPhoneInput'
export { default as FormSlider } from './FormSlider/index'

export { default as FormToggle } from './FormToggle/index'
export { default as TextArea } from './TextArea'
export { default as TextHTML }from './TextHtml'
export { default as TextAreaWithCTC } from './TextAreaWithCTC'
export { default as NumberFormatInput } from './NumberFormatInput'
export { default as PercentageInput } from './PercentageInput'
export { default as TimeInput } from './TimeInput'
export { default as ColorInput } from './ColorInput/index'

export { default as CheckBoxInput } from './CheckBoxInput'

export { default as FormRadio } from './FormRadio'

export { default as TagsInput } from './TagsInput'

export { ValidationError } from './ValidationError'
export { FormError } from './FormError'


export const renderFastField = (label, placeholder, name, type = "text", disabled = false, textArea = false, isOptional = false) => {
  return (
    <FastField className="mb-1" containerClassName="form-row col-md-12 mb-3" inputContainerClassName="col-sm-12 col-md-8"
      labelClassName="bb-form-input-label col-auto" label={label} placeholder={placeholder}
      name={name} component={textArea ? TextArea : FormInput} type={type}
      disabled={disabled} isOptional={isOptional}
    />
  )
}

export const saveForm = (validateForm, setTouched, values, saveAssets, addNew = true, goNext = false) => {
  validateForm().then(async () => {
      const errors = await validateForm();
      setTouched(setNestedObjectValues(errors, true));
      saveAssets(values, errors, addNew, goNext)
  }
  )
}