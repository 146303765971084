import React from "react";
import classnames from 'classnames';
import styled from 'styled-components'

const Span = styled.span`
display: flex;
align-items: center;
margin-left: 2px;
position: absolute;
top: 50%;
transform: translate(0, -50%);
right: 5px;
height: 20px;
font-size: 12px;
font-weight: bold;
background: inherit;
color: #8e8e93;
transition: 0.2s color linear;
cursor: pointer;
`
export const SearchFilterInput = ({onKeyPress, onChange, placeholder = "Search by name", 
                                   value = "", className = "", marginBottom = true,
                                isClearable = true}) => {
    return (
        <div className={classnames("position-relative bb-search-input-container", marginBottom ? "mb-3": "")}>
            <input type="text" placeholder={placeholder} 
                    className={classnames(className, "bb-all-header-search bb-search-input p-1" )}
                    value={value} onChange={onChange}
                    onKeyPress={onKeyPress}
                  />
            {value && isClearable && <Span onClick={async (e) => {await onChange(e = {...e, target: {...e.target, value: ""}}); onKeyPress && await onKeyPress(e = {...e, key : "Cross"});}}>
            <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" aria-label="close" data-category="application"><path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path></svg>
            </Span>
            }
        </div>
    )
}
