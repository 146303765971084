
import { AlloffsetCategoryConstants } from './offsetCategory.constants';

export const offsetCategory = (state = {}, action) => {
    switch (action.type) {
        case AlloffsetCategoryConstants.GET_ALL_OFFSET_CATEGORY_INIT: return { ...state, all: { loading: true } }
        case AlloffsetCategoryConstants.GET_ALL_OFFSET_CATEGORY_SUCCESS: return { ...state, all: action.payload }
        case AlloffsetCategoryConstants.GET_ALL_OFFSET_CATEGORY_ERROR: return { ...state, all: action.payload }

        case AlloffsetCategoryConstants.GET_ALL_OFFSET_CATEGORY_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case AlloffsetCategoryConstants.GET_ALL_OFFSET_CATEGORY_PAGINATE_ERROR: return { ...state, ...action.payload }

        case AlloffsetCategoryConstants.EMPTY_OFFSET_CATEGORY_BY_PROFILE: return { ...state, profile: "" }
        case AlloffsetCategoryConstants.GET_OFFSET_CATEGORY_BY_ID: return { ...state, profile: action.payload }
        case AlloffsetCategoryConstants.GET_OFFSET_CATEGORY_BY_ID_ERROR: return { ...state, profile: action.payload }

        case AlloffsetCategoryConstants.OFFSET_CATEGORY_CURRENT_PAGE: return { ...state, page: action.payload }

        case AlloffsetCategoryConstants.OFFSET_CATEGORY_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case AlloffsetCategoryConstants.OFFSET_CATEGORY_SEARCH_VALUE: return { ...state, search: action.payload }

        case AlloffsetCategoryConstants.OFFSET_CATEGORY_STATUS_VALUE: return { ...state, status: action.payload }

        case AlloffsetCategoryConstants.OFFSET_CATEGORY_TOTAL_COUNT: return { ...state, total: action.payload }

        case AlloffsetCategoryConstants.OFFSET_CATEGORY_LOADING_STATUS: return { ...state, loading: action.payload }

        case AlloffsetCategoryConstants.DELETE_OFFSET_CATEGORY:
        case AlloffsetCategoryConstants.DELETE_OFFSET_CATEGORY_ERROR:
            return { ...state, deleteStatus: action.payload }


        case AlloffsetCategoryConstants.ACTIVATE_OFFSET_CATEGORY:
        case AlloffsetCategoryConstants.ACTIVATE_OFFSET_CATEGORY_ERROR:
            return { ...state, activateStatus: action.payload }

        case AlloffsetCategoryConstants.ADD_OFFSET_CATEGORY_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case AlloffsetCategoryConstants.ADD_OFFSET_CATEGORY_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case AlloffsetCategoryConstants.EDIT_OFFSET_CATEGORY_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case AlloffsetCategoryConstants.EDIT_OFFSET_CATEGORY_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
}
