import React from "react";
import Modal from 'react-modal'
import { loadDefaultThumbnail } from "../utilities/customFunctions";

export class ImageModal extends React.Component {
    state = {
        open: false,
        image: ""
    };

    closeModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ open: false })
    }
    openModal = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ open: true })
    }
    render() {
        const { image, width = 40, height = undefined, className = "", showDefault = false } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <img width={width} height={height} src={image ? image : showDefault ? "/images/default_deal_card.png" : ""} onClick={image ? this.openModal : null} alt="" className={className} onError={(e) => loadDefaultThumbnail(e)}/>
                {open &&
                    <Modal
                        isOpen={open}
                        onRequestClose={this.closeModal}
                        overlayClassName="bb-modal-overlay"
                        className="bb-modal-container"
                        bodyOpenClassName="overflow-hidden"

                        ariaHideApp={false}
                    >
                        <div className="bb-modal p-3 d-flex justify-content-center">
                            <div className="d-flex flex-row justify-content-between mb-4">
                                <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-dark" onClick={(e) => this.closeModal(e)}>
                                </div>
                            </div>
                            <img src={image} alt="" className="img-fluid bb-image-modal fit-contain" />
                        </div>
                    </Modal>
                }
            </React.Fragment>
        );
    }
}

export default ImageModal