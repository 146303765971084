import { TTDRequestConstants } from './ttd-requests.constants';

export const TTDrequests = (state = {}, action) => {
    switch (action.type) {
        case TTDRequestConstants.ALL_TTD_REQUEST_LOADING:
            return { ...state, loading: action.payload };
        case TTDRequestConstants.TTD_AUTH_REQUEST_LOADING:
        case TTDRequestConstants.TTD_AUTH_REQUEST_SUCCESS:
        case TTDRequestConstants.TTD_AUTH_REQUEST_ERROR:
        case TTDRequestConstants.TTD_ADVERTISER_REQUEST_LOADING:
        case TTDRequestConstants.TTD_ADVERTISER_REQUEST_SUCCESS:
        case TTDRequestConstants.TTD_ADVERTISER_REQUEST_ERROR:
        case TTDRequestConstants.TTD_DEPLOY_REQUEST_LOADING:
        case TTDRequestConstants.TTD_DEPLOY_REQUEST_SUCCESS:
        case TTDRequestConstants.TTD_DEPLOY_REQUEST_ERROR:
        case TTDRequestConstants.TTD_DEPLOY_FAILED_REQUEST_LOADING:
        case TTDRequestConstants.TTD_DEPLOY_FAILED_REQUEST_SUCCESS:
        case TTDRequestConstants.TTD_DEPLOY_FAILED_REQUEST_ERROR:
        case TTDRequestConstants.TTD_AUTH_REQUEST_PASSWORD_LOADING:
        case TTDRequestConstants.TTD_AUTH_REQUEST_PASSWORD_SUCCESS:
        case TTDRequestConstants.TTD_AUTH_REQUEST_PASSWORD_ERROR:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
