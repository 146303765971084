import { EventConstants } from "./event.constants";
import axios from 'axios';
import { toFormData } from '../../components/utilities/customFunctions'
import { config } from '../../helpers'
const ROOT_URL_API = config.apiServer + "/api";

const loading = (status) => {
    return { type: EventConstants.EVENT_LOADING_STATUS, payload: status };
};

export const getAllEvents = (limit = 10, offset = 1, name = "", status = "") => {
    return (dispatch) => {
        dispatch(loading(true));

        dispatch({ type: EventConstants.EVENTS_CURRENT_PAGE, payload: offset });
        dispatch({ type: EventConstants.EVENTS_LIMIT_PER_PAGE, payload: limit });
        dispatch({ type: EventConstants.EVENTS_SEARCH_VALUE, payload: name });
        dispatch({ type: EventConstants.EVENTS_STATUS_VALUE, payload: status });

        axios.get(`${ROOT_URL_API}/paginate-event-dictionary?limit=${limit}&offset=${offset}&name=${name}&status=${status}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(loading(false));
                if (response.data) {
                    dispatch({ type: EventConstants.GET_ALL_EVENTS_SUCCESS, payload: response.data });
                    dispatch({ type: EventConstants.EVENTS_TOTAL_COUNT, payload: response.data.total_rows });
                }
                else {
                    dispatch({ type: EventConstants.GET_ALL_EVENTS_ERROR, payload: "error" });
                }
            }).catch(error => {
                dispatch(loading(false));
                dispatch({ type: EventConstants.GET_ALL_EVENTS_ERROR, payload: "error" });
            })
    }
}


export const addNewEvent = (values) => {
    return (dispatch) => {

        dispatch(loading(true));

        axios.post(`${ROOT_URL_API}/create-event-dictionary`,
            toFormData({
                id: values.id,
                name: values.name,
                category: values.category
            })
            ,
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.ADD_EVENT_SUCCESS, payload: response.data.data.id });
                }
                else {
                    dispatch({ type: EventConstants.ADD_EVENT_ERROR, payload: "Error" + Date.now() });
                }
                dispatch(loading(false));
            }).catch((error) => {
                dispatch({ type: EventConstants.ADD_EVENT_ERROR, payload: "Error" + Date.now() });
                dispatch(loading(false));
            });
    };
};


export const updateEvent = (values, id) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.post(`${ROOT_URL_API}/update-event-dictionary/${id}`,
            toFormData({
                id: values.id,
                name: values.name,
                category: values.category
            }),
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.EDIT_EVENT_SUCCESS, payload: response.data.data + Date.now() });
                }
                else {
                    dispatch({ type: EventConstants.EDIT_EVENT_ERROR, payload: "Error" + Date.now() });
                }
                dispatch(loading(false));
            }).catch((error) => {
                dispatch({ type: EventConstants.EDIT_EVENT_ERROR, payload: "Error" + Date.now() });
                dispatch(loading(false));
            });
    };
};


export const deleteEvent = (id) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.delete(`${ROOT_URL_API}/event/${id}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.DELETE_EVENT, payload: response.data.success + Date.now() });
                }
                else {
                    dispatch({ type: EventConstants.DELETE_EVENT_ERROR, payload: false });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: EventConstants.DELETE_EVENT_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}

export const getEventById = (id) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.get(`${ROOT_URL_API}/get-event/${id}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.GET_EVENT_BY_ID, payload: response.data.data });
                }
                else {
                    dispatch({ type: EventConstants.GET_EVENT_BY_ID_ERROR, payload: false });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: EventConstants.GET_EVENT_BY_ID_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}


export const logEvent = (eventId) => {
    return (dispatch) => {
        axios.post(`${ROOT_URL_API}/log-event`,
            toFormData({
                eventTypeId: eventId,
                // offset: getTimezoneOffset()
            }),
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.LOG_EVENT, payload: response.data.success + Date.now() });
                }
                else {
                    dispatch({ type: EventConstants.LOG_EVENT, payload: "Error" + Date.now() });
                }
                dispatch(loading(false));
            }).catch((error) => {
                dispatch({ type: EventConstants.LOG_EVENT, payload: "Error" + Date.now() });
                dispatch(loading(false));
            });
    };
};

export const getReportData = (startDate, endDate, userId = "", tZ = "", roleId = "") => {
    return (dispatch) => {
        dispatch({ type: EventConstants.USER_REPORT_DATA_INIT, payload: "" });
        axios.get(`${ROOT_URL_API}/get-user-analytics?startDate=${startDate}&endDate=${endDate}&userID=${userId}&tZ=${tZ}&roleId=${roleId}`,
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.USER_REPORT_DATA_SUCCESS, payload: response.data.data });
                }
                else {
                    dispatch({ type: EventConstants.USER_REPORT_DATA_ERROR, payload: "Error" + Date.now() });
                }
            }).catch((error) => {
                dispatch({ type: EventConstants.USER_REPORT_DATA_ERROR, payload: "Error" + Date.now() });
            });
    };
}


export const getUsersAnalytics = (limit, offset, sortby = "", sorttype = "", roleId = "", startDate, endDate, timezone, email = "") => {
    return (dispatch) => {
        dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_INIT, payload: "" });
        axios.get(`${ROOT_URL_API}/get-total-user-analytics?limit=${limit}&offset=${offset}&sortby=${sortby}&sorttype=${sorttype}&roleId=${roleId}&startDate=${startDate}&endDate=${endDate}&tZ=${timezone}&email=${email}`,
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_SUCCESS, payload: { total: response.data.total_rows, data: [...response.data.data] } });
                }
                else {
                    dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_ERROR, payload: "Error" + Date.now() });
                }
            }).catch((error) => {
                dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_ERROR, payload: "Error" + Date.now() });
            });
    };
}

export const getUsersAnalyticsCSV = (limit = 10000, offset = 1, sortby = "", sorttype = "", roleId = "", startDate, endDate, timezone) => {
    return (dispatch) => {
        dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_CSV_INIT, payload: "" });
        axios.get(`${ROOT_URL_API}/get-total-user-analytics?limit=${limit}&offset=${offset}&sortby=${sortby}&sorttype=${sorttype}&roleId=${roleId}&startDate=${startDate}&endDate=${endDate}&tZ=${timezone}`,
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_CSV_SUCCESS, payload: { total: response.data.total_rows, data: [...response.data.data] } });
                }
                else {
                    dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_CSV_ERROR, payload: "Error" + Date.now() });
                }
            }).catch((error) => {
                dispatch({ type: EventConstants.TOTAL_USER_DATA_ANALYTICS_CSV_ERROR, payload: "Error" + Date.now() });
            });
    };
}
export const emptyAnalyticsCSV = () => {
    return (dispatch) => {
        dispatch({ type: EventConstants.EMPTY_USER_DATA_ANALYTICS_CSV, payload: "" });
    }
}



var time = 0;
var int = "";
export const logSession = (url, sync = false) => dispatch => {
    if (time > 10) {
        if (!sync) {
            axios.post(`${ROOT_URL_API}/log-session`, toFormData({
                timeInSec: time,
                referer: url
            }),
                { headers: { Authorization: config.authToken() } })
                .then(response => {
                    if (response.data && response.data.success) {
                        dispatch({ type: EventConstants.LOG_SESSION, payload: response.data.success + Date.now() });
                    }
                    else {
                        dispatch({ type: EventConstants.LOG_SESSION, payload: "Error" + Date.now() });
                    }
                    clearInterval(int);
                }).catch((error) => {
                    dispatch({ type: EventConstants.LOG_SESSION, payload: "Error" + Date.now() });
                    clearInterval(int);
                });
        } else {
            window.jQuery.ajax({
                type: 'POST',
                url: `${ROOT_URL_API}/log-session`,
                async: false,
                headers: { 'Authorization': config.authToken() },
                dataType: 'json',
                data: { timeInSec: time, referer: url },
                success(data) {
                    dispatch({ type: EventConstants.LOG_SESSION, payload: data.data.success + Date.now() });
                    clearInterval(int);
                },
                error(data) {
                    dispatch({ type: EventConstants.LOG_SESSION, payload: "Error" + Date.now() });
                    clearInterval(int);
                }
            });
        }
    } else {
        clearInterval(int);
    }
}
export const logSessionPage = (url) => dispatch => {
    if (time > 5) {
        axios.post(`${ROOT_URL_API}/log-session`, toFormData({
            timeInSec: time,
            referer: url
        }),
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.LOG_SESSION, payload: response.data.success + Date.now() });
                }
                else {
                    dispatch({ type: EventConstants.LOG_SESSION, payload: "Error" + Date.now() });
                }
                startSession();
            }).catch((error) => {
                dispatch({ type: EventConstants.LOG_SESSION, payload: "Error" + Date.now() });
                startSession();
            });
    } else {
        time = 0;
    }
}
function timer() {
    if (activeTime < config.activeTime && viewTime < 1) {
        time += 1;
    }
}
export const startSession = () => {
    clearInterval(int);
    time = 0;
    int = "";
    if (!int) {
        int = setInterval(timer, 1000);
    }
}
export const DEFAULT_EVENTS = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mouseWheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange'
]
var activeTime = 0;
var activeInt = "";
export const startActiveSession = () => {
    activeTime = 0;
    activeInt = "";
    if (!activeInt) {
        activeInt = setInterval(activeTimer, 1000);
    }
}
export const setActiveTimeZero = () => {
    activeTime = 0;
}
function activeTimer() {
    activeTime += 1;
}


var viewTime = 0;
var viewInit = "";
export const clearViewSession = () => {
    clearInterval(viewInit);
}
export const startViewSession = (prevLocation, store) => {
    viewTime = 0;
    viewInit = "";
    if (!viewInit) {
        viewInit = setInterval(() => ViewTimer(prevLocation, store), 1000);
    }
}
export const setViewSessionTimeZero = () => {
    viewTime = 0;
    clearInterval(viewInit);
}
function ViewTimer(prevLocation, store) {
    if (viewTime === config.viewTime) {
        store.dispatch(logSessionBlur(prevLocation))
    }
    viewTime += 1;
}

const logSessionBlur = (url = "") => dispatch => {
    if (time > 10) {
        axios.post(`${ROOT_URL_API}/log-session`, toFormData({
            timeInSec: time,
            referer: url
        }),
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: EventConstants.LOG_SESSION, payload: response.data.success + Date.now() });
                }
                else {
                    dispatch({ type: EventConstants.LOG_SESSION, payload: "Error" + Date.now() });
                }
                time = 0;
                clearInterval(viewInit);
            }).catch((error) => {
                dispatch({ type: EventConstants.LOG_SESSION, payload: "Error" + Date.now() });
                time = 0;
                clearInterval(viewInit);
            });
    } else {
        time = 0;
        clearInterval(viewInit);
    }
}

export const isOnline = (isOnline = true) => dispatch => {
    axios.post(`${ROOT_URL_API}/update-user-online-status`, toFormData({
        isOnline: isOnline
    }),
        { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {
                // dispatch({ type: EventConstants.USER_ONLINE, payload: response.data.success });
            }
            else {
                // dispatch({ type: EventConstants.USER_ONLINE, payload: "Error" + Date.now() });
            }
        }).catch((error) => {
            // dispatch({ type: EventConstants.USER_ONLINE, payload: "Error" + Date.now() });
        });
}