export const ForeignExchangeRateConstants = {
    GET_ALL_FOREIGN_EXCHANGE_RATE_SUCCESS: "GET_ALL_FOREIGN_EXCHANGE_RATE_SUCCESS",
    ALL_FOREIGN_EXCHANGE_RATE_LIMIT: "ALL_FOREIGN_EXCHANGE_RATE_LIMIT",
    ALL_FOREIGN_EXCHANGE_RATE_LIMIT_PER_PAGE: "ALL_FOREIGN_EXCHANGE_RATE_LIMIT_PER_PAGE",
    ALL_FOREIGN_EXCHANGE_RATE_CURRENT_PAGE: "ALL_FOREIGN_EXCHANGE_RATE_CURRENT_PAGE",
    GET_ALL_FOREIGN_EXCHANGE_RATE_ERROR: "GET_ALL_FOREIGN_EXCHANGE_RATE_ERROR",
    ALL_FOREIGN_EXCHANGE_RATE_LOADING: "ALL_FOREIGN_EXCHANGE_RATE_LOADING",
    
    GET_FOREIGN_EXCHANGE_RATE_BY_ID: 'GET_FOREIGN_EXCHANGE_RATE_BY_ID',
    EMPTY_FOREIGN_EXCHANGE_RATE_BY_PROFILE: 'EMPTY_FOREIGN_EXCHANGE_RATE_BY_PROFILE',
    GET_FOREIGN_EXCHANGE_RATE_BY_ID_ERROR: 'GET_FOREIGN_EXCHANGE_RATE_BY_ID_ERROR',

    ADD_FOREIGN_EXCHANGE_RATE_SUCCESS: 'ADD_FOREIGN_EXCHANGE_RATE_SUCCESS',
    ADD_FOREIGN_EXCHANGE_RATE_ERROR: 'ADD_FOREIGN_EXCHANGE_RATE_ERROR',

    EDIT_FOREIGN_EXCHANGE_RATE_SUCCESS: 'EDIT_FOREIGN_EXCHANGE_RATE_SUCCESS',
    EDIT_FOREIGN_EXCHANGE_RATE_ERROR: 'EDIT_FOREIGN_EXCHANGE_RATE_ERROR',
    
    DELETE_FOREIGN_EXCHANGE_RATE_SUCCESS: 'DELETE_FOREIGN_EXCHANGE_RATE_SUCCESS',
    DELETE_FOREIGN_EXCHANGE_RATE_ERROR: 'DELETE_FOREIGN_EXCHANGE_RATE_ERROR',
    ACTIVATE_FOREIGN_EXCHANGE_RATE_SUCCESS: 'ACTIVATE_FOREIGN_EXCHANGE_RATE_SUCCESS',
    ACTIVATE_FOREIGN_EXCHANGE_RATE_ERROR: 'ACTIVATE_FOREIGN_EXCHANGE_RATE_ERROR',
};
