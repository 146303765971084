export const AdTypeFormatConstants = {
    ALL_AD_TYPE_FORMAT_CURRENT_PAGE: "ALL_AD_TYPE_FORMAT_CURRENT_PAGE",
    ALL_AD_TYPE_FORMAT_LIMIT_PER_PAGE: "ALL_AD_TYPE_FORMAT_LIMIT_PER_PAGE",
    ALL_AD_TYPE_FORMAT_SEARCH_VALUE: "ALL_AD_TYPE_FORMAT_SEARCH_VALUE",
    ALL_AD_TYPE_FORMAT_STATUS_VALUE: "ALL_AD_TYPE_FORMAT_STATUS_VALUE",

    GET_ALL_AD_TYPE_FORMAT: "GET_ALL_AD_TYPE_FORMAT",
    ALL_AD_TYPE_FORMAT_LIMIT: "ALL_AD_TYPE_FORMAT_LIMIT",
    GET_ALL_AD_TYPE_FORMAT_ERROR: "GET_ALL_AD_TYPE_FORMAT_ERROR",
    ALL_AD_TYPE_FORMAT_LOADING: "ALL_AD_TYPE_FORMAT_LOADING",

    DELETE_AD_TYPE_FORMAT_SUCCESS: 'DELETE_AD_TYPE_FORMAT_SUCCESS',
    DELETE_AD_TYPE_FORMAT_ERROR: 'DELETE_AD_TYPE_FORMAT_ERROR',
    ACTIVATE_AD_TYPE_FORMAT_SUCCESS: 'ACTIVATE_AD_TYPE_FORMAT_SUCCESS',
    ACTIVATE_AD_TYPE_FORMAT_ERROR: 'ACTIVATE_AD_TYPE_FORMAT_ERROR',


    BB_ADD_AD_TYPE_FORMAT_SUCCESS: 'BB_ADD_AD_TYPE_FORMAT_SUCCESS',
    BB_ADD_AD_TYPE_FORMAT_ERROR: 'BB_ADD_AD_TYPE_FORMAT_ERROR',
    BB_ADD_AD_TYPE_FORMAT_LOADING: 'BB_ADD_AD_TYPE_FORMAT_LOADING',

    BB_EDIT_AD_TYPE_FORMAT_SUCCESS: 'BB_EDIT_AD_TYPE_FORMAT_SUCCESS',
    BB_EDIT_AD_TYPE_FORMAT_ERROR: 'BB_EDIT_AD_TYPE_FORMAT_ERROR',

    BB_GET_ACTIVE_AD_TYPE_FORMAT_INIT: 'BB_GET_ACTIVE_AD_TYPE_FORMAT_INIT',
    BB_GET_ACTIVE_AD_TYPE_FORMAT_SUCCESS: 'BB_GET_ACTIVE_AD_TYPE_FORMAT_SUCCESS',
    BB_GET_ACTIVE_AD_TYPE_FORMAT_ERROR: 'BB_GET_ACTIVE_AD_TYPE_FORMAT_ERROR',

    BB_GET_ALL_AD_TYPE_FORMAT_INIT:'BB_GET_ALL_AD_TYPE_FORMAT_INIT',
    BB_GET_ALL_AD_TYPE_FORMAT_SUCCESS:'BB_GET_ALL_AD_TYPE_FORMAT_SUCCESS',
    BB_GET_ALL_AD_TYPE_FORMAT_ERROR:'BB_GET_ALL_AD_TYPE_FORMAT_ERROR',
    EMPTY_GET_ALL_AD_TYPE_FORMAT:'EMPTY_GET_ALL_AD_TYPE_FORMAT',
    
    BB_GET_AD_TYPE_FORMAT_BY_ID:'BB_GET_AD_TYPE_FORMAT_BY_ID',
    BB_GET_AD_TYPE_FORMAT_BY_ID_SUCCESS:'BB_GET_AD_TYPE_FORMAT_BY_ID_SUCCESS',
    BB_GET_AD_TYPE_FORMAT_BY_ID_ERROR:'BB_GET_AD_TYPE_FORMAT_BY_ID_ERROR',
    EMPTY_GET_AD_TYPE_FORMAT_BY_ID:'EMPTY_GET_AD_TYPE_FORMAT_BY_ID'
}