import React from 'react'
import classnames from 'classnames'
import { FormError } from './FormError'
import Tooltip from '../utilities/Tooltip'
import Skeleton from '../../helpers/Skeleton'

const renderLabel = (labelClassName, type, label, isOptional, tooltip = false, field = "", light = false, tooltipIcon = "fa-info-circle") => {
  return (
    <>
      {!tooltip ?
        <label className={classnames("mb-1", labelClassName, type === "checkbox" ? "pt-1" : "pt-2")}>
          {label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}
        </label>
        :
        <div className={classnames("mb-1 ", labelClassName, "pt-1")}>
          <Tooltip light={light} className={classnames("cursor-pointer mb-0", labelClassName)} label={label} htmlFor={field.name} iconname={tooltipIcon}>
            <div className="px-2 text-left maxWidth-300"><span>{tooltip}</span></div>
          </Tooltip>
          {isOptional && <span className="bb-text_small bb-color_disabled text-left px-2 ">(optional)</span>}
        </div>
      }
    </>
  );
}

const FormInput = ({ disabled, field, rtl, containerClassName, form, onChange, tooltipIcon,
  checkedValue, labelClassName, inputContainerClassName, className, tooltip = false, light = false, skeletonLoading = false,
  labelPosition = "start", maxLength = 200, keyPressCheck = true, greenFleet = false, onBlurInput = false,
  label, placeholder, type, isOptional = false, form: { touched, errors } }) => {
  return (
    <div className={containerClassName} dir={rtl ? "rtl" : null}>
      {// eslint-disable-next-line
        labelPosition == "start" && label ? renderLabel(labelClassName, type, label, isOptional, tooltip, field, light, tooltipIcon) : null}
      <div className={inputContainerClassName}>
      {skeletonLoading ? <Skeleton height="12"/> : 
        <input className={classnames("form-control", className, touched[field.name] && errors[field.name] ? 'error' : '')}
          placeholder={placeholder ? placeholder : label}
          type={type} {...field}
          onChange={(e) => {
            form.setFieldValue(field.name, e.target.value);
            if (onChange) {
              onChange(e.target.value, form.setFieldValue);
            }
          }
          }
          onBlur={ async e => {
            await form.setFieldTouched(field.name, true);
            if (typeof onBlurInput === 'function') {
               await onBlurInput(field.value, field.name);
            }
        }}
          disabled={disabled}
          autoComplete={field.name}
          checked={type === "checkbox" ? checkedValue : false}
          onKeyPress={(e) => {
            keyPressCheck && e.key === 'Enter' && e.preventDefault();
          }}
          maxLength={maxLength}
        />
        }
        <FormError name={field.name} />

      </div>
      {greenFleet && <Tooltip light={true}
        tooltipClassName={"budster-format-help-container w-198"}
        className={classnames("cursor-pointer", "align-self-center")} label={""} htmlFor={"sdfsd"} iconname="green-fleet" icon={true}>
        This deal will help offset carbon emissions thanks to our partnership with <strong>Greenfleet.</strong> 
      </Tooltip>}
      {// eslint-disable-next-line
        labelPosition == "end" && label ? renderLabel(labelClassName, type, label, isOptional, tooltip, field) : null}
    </div>
  )
}

export default FormInput