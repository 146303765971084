export const AllSouthpoleConstants = {
    PROJECT_LOADING_STATUS : 'PROJECT_LOADING_STATUS',
    GET_ALL_PROJECT_INIT:'GET_ALL_PROJECT_INIT',
    GET_ALL_PROJECT_SUCCESS:'GET_ALL_PROJECT_SUCCESS',
    GET_ALL_PROJECT_ERROR: 'GET_ALL_PROJECT_ERROR',
    CHECKOUT_SOUTHPOLE:'CHECKOUT_SOUTHPOLE',
    CHECKOUT_SOUTHPOLE_ERROR:'CHECKOUT_SOUTHPOLE_ERROR',
    GET_ORDER_BY_ID:'GET_ORDER_BY_ID',
    GET_ORDER_BY_ID_ERROR: 'GET_ORDER_BY_ID_ERROR',
    EMPTY_ORDER_BY_ID:'EMPTY_ORDER_BY_ID'
};
