import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router";

const withGA = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        ReactGA.set({
            page,
            ...options
        });
        ReactGA.send({ hitType: "pageview", page: page });

    };

    const HOC = props => {
        const location = useLocation();

        useEffect(() => trackPage(location.pathname + location.search),
            [
                location
            ]);
            
        return <WrappedComponent {...props} location={location} />;
    };

    return HOC;
};

export default withGA
