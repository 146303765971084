export const ShowcaseBennchmarkConstants = {

    GET_ALL_SHOWCASE_BENCHMARK_INIT: 'GET_ALL_SHOWCASE_BENCHMARK_INIT',
    GET_ALL_SHOWCASE_BENCHMARK_SUCCESS: 'GET_ALL_SHOWCASE_BENCHMARK_SUCCESS',
    GET_ALL_SHOWCASE_BENCHMARK_ERROR: 'GET_ALL_SHOWCASE_BENCHMARK_ERROR',

    GET_ALL_SHOWCASE_BENCHMARK_PAGINATE_SUCCESS: 'GET_ALL_SHOWCASE_BENCHMARK_PAGINATE_SUCCESS',
    GET_ALL_SHOWCASE_BENCHMARK_PAGINATE_ERROR: 'GET_ALL_SHOWCASE_BENCHMARK_PAGINATE_ERROR',

    SHOWCASE_BENCHMARK_CURRENT_PAGE: 'SHOWCASE_BENCHMARK_CURRENT_PAGE',
    SHOWCASE_BENCHMARK_LIMIT_PER_PAGE: 'SHOWCASE_BENCHMARK_LIMIT_PER_PAGE',
    SHOWCASE_BENCHMARK_SEARCH_VALUE: 'SHOWCASE_BENCHMARK_SEARCH_VALUE',
    SHOWCASE_BENCHMARK_STATUS_VALUE: 'SHOWCASE_BENCHMARK_STATUS_VALUE',
    SHOWCASE_BENCHMARK_TOTAL_COUNT: 'SHOWCASE_BENCHMARK_TOTAL_COUNT',

    GET_SHOWCASE_BENCHMARK_BY_ID: "GET_SHOWCASE_BENCHMARK_BY_ID",
    EMPTY_SHOWCASE_BENCHMARK_BY_PROFILE: "EMPTY_SHOWCASE_BENCHMARK_BY_PROFILE",
    GET_SHOWCASE_BENCHMARK_BY_ID_ERROR: "GET_SHOWCASE_BENCHMARK_BY_ID_ERROR",

    EDIT_SHOWCASE_BENCHMARK_SUCCESS: "EDIT_SHOWCASE_BENCHMARK_SUCCESS",
    EDIT_SHOWCASE_BENCHMARK_ERROR: "EDIT_SHOWCASE_BENCHMARK_ERROR",

    ADD_SHOWCASE_BENCHMARK_SUCCESS: "ADD_SHOWCASE_BENCHMARK_SUCCESS",
    ADD_SHOWCASE_BENCHMARK_ERROR: "ADD_SHOWCASE_BENCHMARK_ERROR",

    DELETE_SHOWCASE_BENCHMARK: 'DELETE_SHOWCASE_BENCHMARK',
    DELETE_SHOWCASE_BENCHMARK_ERROR: 'DELETE_SHOWCASE_BENCHMARK_ERROR',

    ACTIVATE_SHOWCASE_BENCHMARK: 'ACTIVATE_SHOWCASE_BENCHMARK',
    ACTIVATE_SHOWCASE_BENCHMARK_ERROR: 'ACTIVATE_SHOWCASE_BENCHMARK_ERROR',

    SHOWCASE_BENCHMARK_LOADING_STATUS: "SHOWCASE_BENCHMARK_LOADING_STATUS",

    EMPTY_SHOWCASE_BENCHMARK: 'EMPTY_SHOWCASE_BENCHMARK',

};
