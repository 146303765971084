
import { combineReducers } from 'redux';
import { tfeConfig } from './tfe-config/tfeConfig.reducer';
import { foreignExchangeRate } from './foreign-exchange-rate/foreignExchangeRate.reducer';
import { scope3Benchmark } from './scope3-benchmark/scope3Benchmark.reducer';
import { performanceFeedback } from './performance-feedback/performanceFeedback.reducer';
import { performancePrograms } from './performance-programs/performancePrograms.reducer';
import { performanceSubscription } from './performance-subscription/performanceSubscription.reducer';
import { log } from './log/log.reducer';
import { dominosBasketSize } from "./dominos_basket_size/dominosBasketSize.reducer"
import { dominosMasterCampaign } from "./dominos-master-campaign/dominosMasterCampaign.reducer"
import { analytics } from './analytics-v2/analytics.reducer';

export const others = combineReducers({ tfeConfig, foreignExchangeRate, log, scope3Benchmark, performanceFeedback, performanceSubscription, performancePrograms, dominosBasketSize, dominosMasterCampaign, analytics });
