export const CurationFeeConstants = {
    ALL_CURATION_FEE_CURRENT_PAGE: "ALL_CURATION_FEE_CURRENT_PAGE",
    ALL_CURATION_FEE_LIMIT_PER_PAGE: "ALL_CURATION_FEE_LIMIT_PER_PAGE",
    ALL_CURATION_FEE_SEARCH_VALUE: "ALL_CURATION_FEE_SEARCH_VALUE",
    ALL_CURATION_FEE_STATUS_VALUE: "ALL_CURATION_FEE_STATUS_VALUE",

    GET_ALL_CURATION_FEE_SUCCESS: "GET_ALL_CURATION_FEE_SUCCESS",
    ALL_CURATION_FEE_LIMIT: "ALL_CURATION_FEE_LIMIT",
    GET_ALL_CURATION_FEE_ERROR: "GET_ALL_CURATION_FEE_ERROR",
    ALL_CURATION_FEE_LOADING: "ALL_CURATION_FEE_LOADING",

    GET_CURATION_FEE_BY_ID: "GET_CURATION_FEE_BY_ID",
    GET_CURATION_FEE_BY_ID_ERROR: "GET_CURATION_FEE_BY_ID_ERROR",

    DELETE_CURATION_FEE_SUCCESS: 'DELETE_CURATION_FEE_SUCCESS',
    DELETE_CURATION_FEE_ERROR: 'DELETE_CURATION_FEE_ERROR',
    ACTIVATE_CURATION_FEE_SUCCESS: 'ACTIVATE_CURATION_FEE_SUCCESS',
    ACTIVATE_CURATION_FEE_ERROR: 'ACTIVATE_CURATION_FEE_ERROR',

    BB_ADD_CURATION_FEE_SUCCESS: 'BB_ADD_CURATION_FEE_SUCCESS',
    BB_ADD_CURATION_FEE_ERROR: 'BB_ADD_CURATION_FEE_ERROR',

    BB_EDIT_CURATION_FEE_SUCCESS: 'BB_EDIT_CURATION_FEE_SUCCESS',
    BB_EDIT_CURATION_FEE_ERROR: 'BB_EDIT_CURATION_FEE_ERROR',

    EMPTY_CURATION_FEE_PROFILE: 'EMPTY_CURATION_FEE_PROFILE'
};
