import React, { Component } from 'react';

import { QRCode } from 'react-qrcode-logo';

export default class QrCode extends Component {


  render() {
    const { size, value } = this.props;
    return (
      value &&
      <QRCode bgColor="whitesmoke" fgColor="#1b2134" size={size || 150} qrStyle="dots" quietZone="10" ecLevel="L" logoWidth="60" logoHeight="44" logoOpacity="0.5" value={value} />
    );
  }
}