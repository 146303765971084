import React, { Component } from 'react'
import { Oval } from 'react-loader-spinner'
import classnames from 'classnames'
import './index.scss'
export default class Spinner extends Component {

    render() {
        const { className, color, height = 100, width = 100, type = "b" } = this.props;

        return (
            type === "oval" ?
                <Oval
                    height={height}
                    width={width}
                    color={color ? color : "#EC1555"}
                    wrapperClass={classnames(className, "d-flex align-self-center justify-content-center p-0")}
                    secondaryColor="white"
                />
                :
                <div className="d-flex align-self-center justify-content-center bb-spinner-container"
                    style={{ height: height + 'px', width: width + 'px' }}
                >
                    <span><img src='/images/icons/loading.svg' style={{ height: height + 'px', width: width + 'px' }} alt="" /></span>
                </div>
        );
    }
}

