import { TargetConstants } from "./target.constants";

export const target = (state = {}, action) => {
    switch (action.type) {
        case TargetConstants.GET_ALL_TARGET_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case TargetConstants.GET_ALL_TARGET_PAGINATE_ERROR: return { ...state, ...action.payload }

        case TargetConstants.EMPTY_TARGET_BY_PROFILE: return { ...state, profile: "" }
        case TargetConstants.GET_TARGET_BY_ID: return { ...state, profile: action.payload }
        case TargetConstants.GET_TARGET_BY_ID_ERROR: return { ...state, profile: action.payload }

        case TargetConstants.TARGET_CURRENT_PAGE: return { ...state, page: action.payload }
        case TargetConstants.TARGET_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case TargetConstants.TARGET_TOTAL_COUNT: return { ...state, total: action.payload }
        case TargetConstants.TARGET_LOADING_STATUS: return { ...state, loading: action.payload }
            
        case TargetConstants.ADD_TARGET_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case TargetConstants.ADD_TARGET_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case TargetConstants.EDIT_TARGET_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case TargetConstants.EDIT_TARGET_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
