import axios from 'axios';

import { UserConstants } from './user.constants'
import { config } from '../../helpers'

import { setUserGA, trackUserSignOut } from '../../helpers/GA'
import { SUPER_ADMIN, ACCOUNT_MANAGER, NETWORK_MANAGER } from '../../helpers/roles'
import { logEvent, logSession, startSession, startActiveSession, clearViewSession } from '../event/event.actions'
import { LOGIN, LOGOUT, ACTIVE } from "../../helpers/events"
import { pushLocation } from '../../components/utilities/customRoutes';

const ROOT_URL = config.apiServer;
const ROOT_URL_API = ROOT_URL + "/api";

const disconnectSocket = (socket, response) => {
    socket.volatile.emit("UserIsActive", {
        id: response.data.id, token: response.data.token,
        name: response.data.firstName + " " + response.data.lastName,
        role: response.data.role
    });
}

export const signinUser = ({ email, password }, from, socket) => {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/login`, { email, password })
            .then(response => {
                if (response.data && response.data.token) {
                    dispatch({ type: UserConstants.AUTH_USER, payload: response.data });
                    checkRole(response.data, from);
                    dispatch(logEvent(LOGIN));
                    startSession();
                    startActiveSession()
                    disconnectSocket(socket, response)
                }
                else {
                    if (response.data) {
                        dispatch(authError(response.data.msg));
                    }
                }
            }).catch(() => {
                dispatch(authError('Bad Login Info'));
            });
    };
};


export const authUserUsingToken = (path, search, socket = false) => {

    return (dispatch) => {
        dispatch({ type: UserConstants.AUTH_USER_CHECKING });
        if (config.authToken()) {
            axios.post(`${ROOT_URL_API}/verifyauth`, {}, { headers: { Authorization: config.authToken() } })
                .then(response => {

                    if (response.data && response.data.token) {
                        dispatch({ type: UserConstants.AUTH_USER_USING_TOKEN, payload: response.data });
                        // - save the jwt token
                        localStorage.setItem(config.bbToken, response.data.token);

                        if (socket) {
                            disconnectSocket(socket, response)
                        }


                        setUserGA(response.data.id);

                        dispatch(logEvent(ACTIVE));
                        if (path === '/login') {
                            pushLocation('/my-deals');
                        }
                        else {
                            pushLocation(({ pathname: path, search: search }));
                        }
                    }
                    else {
                        pushLocation('/login?from=' + path + encodeURIComponent(search));
                        dispatch(signoutUser());
                    }

                }).catch(() => {
                    pushLocation('/login?from=' + path + encodeURIComponent(search));
                    dispatch(signoutUser());
                });
        }
        else {
            pushLocation('/login?from=' + path + encodeURIComponent(search));
            dispatch(signoutUser());
        }
    };
};


export const checkIfLoggedIn = () => {
    return (dispatch) => {
        if (config.authToken()) {
            axios.post(`${ROOT_URL_API}/verifyauth`, {}, { headers: { Authorization: config.authToken() } })
                .then(response => {
                    if (response.data && response.data.token) {
                        dispatch({ type: UserConstants.AUTH_USER_USING_TOKEN, payload: response.data });
                        if (response.data.token) {
                            dispatch(logEvent(ACTIVE));
                            localStorage.setItem(config.bbToken, response.data.token);
                        }
                        else {
                            dispatch(signoutUser());
                        }
                    }
                    else {
                        dispatch(signoutUser());
                    }

                }).catch(() => {
                    dispatch(authError());
                });
        }
        else {
            dispatch(authError());
        }
    };

};

export const checkUserTokenStatus = (path, search, from) => {

    return (dispatch) => {
        dispatch({ type: UserConstants.AUTH_USER_CHECKING });
        if (config.authToken()) {
            axios.post(`${ROOT_URL_API}/verifyauth`, {}, { headers: { Authorization: config.authToken() } })
                .then(response => {

                    if (response.data && response.data.token) {
                        dispatch({ type: UserConstants.AUTH_USER_USING_TOKEN, payload: response.data });

                        localStorage.setItem(config.bbToken, response.data.token);
                        setUserGA(response.data.id);
                        dispatch(logEvent(ACTIVE));
                        if (from) {
                            pushLocation(decodeURIComponent(from));
                        }
                        else {
                            if (path === "/login" || path === "/forgotpassword" || path === "/") {
                                pushLocation("/my-deals")
                            }
                            else {
                                pushLocation(({ pathname: path, search: search }));
                            }
                        }

                    }
                    else {
                        pushLocation(({ pathname: path, search: search }));
                        dispatch(signoutUser());
                    }

                }).catch(() => {
                    pushLocation(({ pathname: path, search: search }));
                    dispatch(signoutUser());
                });
        }
        else {
            pushLocation(({ pathname: path, search: search }));
            dispatch(signoutUser());
        }
    };

};

export const authUserSignOut = (role, id) => {
    return (dispatch) => {
        trackUserSignOut()
        if (window._chatlio) {
            window._chatlio.reset();
        }
        dispatch(logEvent(LOGOUT));
        dispatch(logSession())
        clearViewSession();
        dispatch(signoutUser(role, id));
        pushLocation('/login')
    };
}

export const authError = (error) => {
    return {
        type: UserConstants.AUTH_ERROR,
        payload: error + "~" + Date.now()
    };
};

export const signoutUser = (role = "", id = "") => {
    // eslint-disable-next-line
    if (role == SUPER_ADMIN) {
        var ifrm_p = document.createElement("iframe");
        ifrm_p.setAttribute("src", config.previewServer);
        ifrm_p.style.width = "0px";
        ifrm_p.style.height = "0px";
        ifrm_p.style.border = "none";
        ifrm_p.style.display = "none";
        document.body.appendChild(ifrm_p);
        ifrm_p.onload = () => {
            ifrm_p.contentWindow.postMessage({
                action: 'remove',
                key: 'token',
                value: false
            }, config.previewServer)
            setTimeout(function () { ifrm_p.remove(); }, 1);
        }
    }
    localStorage.removeItem(config.bbToken)
    localStorage.removeItem(config.bbSettings)
    localStorage.removeItem("bb-sidebar-expanded")
    return { type: UserConstants.UNAUTH_USER };
};

const loading = (status) => {
    return {
        type: UserConstants.AUTH_LOADING_STATUS,
        payload: status
    };
};

const checkRole = (data, from, isChecking = false) => {
    // eslint-disable-next-line
    if (data.role == SUPER_ADMIN) {
        /* For preview studio */
        var ifrm_p = document.createElement("iframe");
        ifrm_p.setAttribute("src", config.previewServer);
        ifrm_p.style.width = "0px";
        ifrm_p.style.height = "0px";
        ifrm_p.style.border = "none";
        ifrm_p.style.display = "none";
        document.body.appendChild(ifrm_p);
        ifrm_p.onload = () => {
            ifrm_p.contentWindow.postMessage({
                action: 'save',
                key: 'token',
                value: { showDealInfo: true }
            }, config.previewServer)

            setTimeout(function () { ifrm_p.remove(); }, 1);
        }
    }

    if (data.url !== "" && data.url !== null && data.url !== undefined && data.url !== config.host_bb) {
        if (data.role === ACCOUNT_MANAGER || data.role === NETWORK_MANAGER) {
            window.location = data.url + "/login?token=" + data.token + "&from=" + encodeURIComponent(from);
            return false;
        }
    }

    localStorage.setItem(config.bbToken, data.token);

    if (!isChecking) {
        setUserGA(data.id);

        if (from) {
            pushLocation(decodeURIComponent(from));
        }
        else {
            pushLocation('/my-deals');
        }
    }

}

/* For resetting password */
export const forgotPassword = ({ email }) => {

    return (dispatch) => {
        dispatch(loading(true));
        axios.post(`${ROOT_URL}/reset_password`, { email: email })
            .then(response => {

                if (response.data && response.data.success) {
                    dispatch(loading(false));
                    dispatch({ type: UserConstants.AUTH_USER_FORGOT_PASSWORD, payload: true });
                }
                else {
                    dispatch(loading(false));
                    dispatch({
                        type: UserConstants.AUTH_USER_FORGOT_PASSWORD_ERROR,
                        payload: 'User not found!'
                    });
                }
            }).catch(() => {
                dispatch(loading(false));
                dispatch({
                    type: UserConstants.AUTH_USER_FORGOT_PASSWORD_ERROR,
                    payload: 'User not found!'
                });
            });
    };
}

export const checkCode = (code) => {
    return (dispatch) => {
        dispatch({ type: UserConstants.RESET_CODE_CHECKING });
        axios.get(`${ROOT_URL}/verify_code?code=${code}`)
            .then(response => {

                if (response.data && response.data.success) {
                    dispatch({ type: UserConstants.RESET_CODE_CHECKED, payload: response.data.success });

                }
                else {
                    dispatch({ type: UserConstants.RESET_CODE_CHECKED, payload: false });
                }

            }).catch(() => {
                dispatch({ type: UserConstants.RESET_CODE_CHECKED, payload: false });
            });
    }
};

export const resetPassword = ({ code, password }) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.post(`${ROOT_URL}/update_password`, { code, password })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: UserConstants.RESET_PASSWORD, payload: response.data.success });
                }
                else {
                    dispatch({ type: UserConstants.RESET_PASSWORD_ERROR, payload: false });
                }

                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: UserConstants.RESET_PASSWORD_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
};

/* For menu */
export const saveBuddyLocation = (path, search = "") => {
    return (dispatch) => {
        dispatch({ type: UserConstants.BUDDY_LAST_LOCATION, payload: { pathname: path, search: search } });
    };
}

export const saveShowcaseLocation = (path, search = "") => {
    return (dispatch) => {
        dispatch({ type: UserConstants.SHOWCASE_LAST_LOCATION, payload: { pathname: path, search: search } });
    };
}
