export const UserRolesConstants  = {
    ALL_USER_ROLES_CURRENT_PAGE: "ALL_USER_ROLES_CURRENT_PAGE",
    ALL_USER_ROLES_LIMIT_PER_PAGE: "ALL_USER_ROLES_LIMIT_PER_PAGE",
    ALL_USER_ROLES_SEARCH_VALUE: "ALL_USER_ROLES_SEARCH_VALUE",
    ALL_USER_ROLES_STATUS_VALUE: "ALL_USER_ROLES_STATUS_VALUE",

    GET_ALL_USER_ROLES: "GET_ALL_USER_ROLES",
    ALL_USER_ROLES_LIMIT: "ALL_USER_ROLES_LIMIT",
    GET_ALL_USER_ROLES_ERROR: "GET_ALL_USER_ROLES_ERROR",
    ALL_USER_ROLES_LOADING: "ALL_USER_ROLES_LOADING",

    DELETE_USER_ROLES_SUCCESS: 'DELETE_USER_ROLES_SUCCESS',
    DELETE_USER_ROLES_ERROR:'DELETE_USER_ROLES_ERROR',
    ACTIVATE_USER_ROLES_SUCCESS:'ACTIVATE_USER_ROLES_SUCCESS',
    ACTIVATE_USER_ROLES_ERROR:'ACTIVATE_USER_ROLES_ERROR',


    BB_ADD_USER_ROLES_SUCCESS:'BB_ADD_USER_ROLES_SUCCESS',
    BB_ADD_USER_ROLES_ERROR:'BB_ADD_USER_ROLES_ERROR',
    BB_ADD_USER_ROLES_LOADING:'BB_ADD_USER_ROLES_LOADING',

    BB_EDIT_USER_ROLES_SUCCESS:'BB_EDIT_USER_ROLES_SUCCESS',
    BB_EDIT_USER_ROLES_ERROR:'BB_EDIT_USER_ROLES_ERROR',
    
    BB_GET_USER_USER_ROLES_INIT:'BB_GET_USER_USER_ROLES_INIT',
    BB_GET_USER_USER_ROLES_SUCCESS:'BB_GET_USER_USER_ROLES_SUCCESS',
    BB_GET_USER_USER_ROLES_ERROR:'BB_GET_USER_USER_ROLES_ERROR'
};
