import { EventConstants } from './event.constants';

export const events = (state = {}, action) => {

    switch (action.type) {

        case EventConstants.GET_ALL_EVENTS_SUCCESS: return { ...state, ...action.payload }
        case EventConstants.GET_ALL_EVENTS_ERROR: return { ...state, ...action.payload }

        case EventConstants.GET_EVENT_BY_ID: return { ...state, profile: action.payload }
        case EventConstants.GET_EVENT_BY_ID_ERROR: return { ...state, profile: action.payload }

        case EventConstants.EVENTS_CURRENT_PAGE: return { ...state, page: action.payload }

        case EventConstants.EVENTS_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case EventConstants.EVENTS_SEARCH_VALUE: return { ...state, search: action.payload }

        case EventConstants.EVENTS_STATUS_VALUE: return { ...state, status: action.payload }

        case EventConstants.EVENTS_TOTAL_COUNT: return { ...state, total: action.payload }

        case EventConstants.EVENT_LOADING_STATUS: return { ...state, loading: action.payload }

        case EventConstants.DELETE_EVENT:
        case EventConstants.DELETE_EVENT_ERROR:
            return { ...state, deleteStatus: action.payload }

        case EventConstants.ADD_EVENT_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case EventConstants.ADD_EVENT_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case EventConstants.EDIT_EVENT_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case EventConstants.EDIT_EVENT_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        case EventConstants.LOG_EVENT: return { ...state, logged: action.payload }
        case EventConstants.LOG_SESSION: return { ...state, sessioned: action.payload }
        case EventConstants.USER_ONLINE: return { ...state, isOnline: action.payload }

        
        case EventConstants.USER_REPORT_DATA_INIT: return { ...state, userAnalytics: { loading: true } }
        case EventConstants.USER_REPORT_DATA_SUCCESS: return { ...state, userAnalytics: { loading: false, ...action.payload } }
        case EventConstants.USER_REPORT_DATA_ERROR: return { ...state, userAnalytics: { loading: false, error: action.payload } }

        case EventConstants.TOTAL_USER_DATA_ANALYTICS_INIT: return { ...state, allUserAnalytics: { loading: true } }
        case EventConstants.TOTAL_USER_DATA_ANALYTICS_SUCCESS: return { ...state, allUserAnalytics: { loading: false, ...action.payload } }
        case EventConstants.TOTAL_USER_DATA_ANALYTICS_ERROR: return { ...state, allUserAnalytics: { loading: false, error: action.payload } }

        case EventConstants.TOTAL_USER_DATA_ANALYTICS_CSV_INIT: return { ...state, allUserAnalyticsCSV: { loading: true } }
        case EventConstants.TOTAL_USER_DATA_ANALYTICS_CSV_SUCCESS: return { ...state, allUserAnalyticsCSV: { loading: false, ...action.payload, date: new Date() } }
        case EventConstants.TOTAL_USER_DATA_ANALYTICS_CSV_ERROR: return { ...state, allUserAnalyticsCSV: { loading: false, error: action.payload, date: new Date() } }
        case EventConstants.EMPTY_USER_DATA_ANALYTICS_CSV: return { ...state, allUserAnalyticsCSV: {} }
        default:
            return state;
    }
};
