import { DominosMasterCampaignConstants } from "./dominosMasterCampaign.constants";

export const dominosMasterCampaign = (state = {}, action) => {
    switch (action.type) {

        case DominosMasterCampaignConstants.GET_ALL_DOMINOS_MASTER_CAMPAIGN_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case DominosMasterCampaignConstants.GET_ALL_DOMINOS_MASTER_CAMPAIGN_PAGINATE_ERROR: return { ...state, ...action.payload }

        case DominosMasterCampaignConstants.EMPTY_DOMINOS_MASTER_CAMPAIGN_BY_PROFILE: return { ...state, profile: "" }
        case DominosMasterCampaignConstants.GET_DOMINOS_MASTER_CAMPAIGN_BY_ID: return { ...state, profile: action.payload }
        case DominosMasterCampaignConstants.GET_DOMINOS_MASTER_CAMPAIGN_BY_ID_ERROR: return { ...state, profile: action.payload }

        case DominosMasterCampaignConstants.DOMINOS_MASTER_CAMPAIGN_CURRENT_PAGE: return { ...state, page: action.payload }
        case DominosMasterCampaignConstants.DOMINOS_MASTER_CAMPAIGN_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case DominosMasterCampaignConstants.DOMINOS_MASTER_CAMPAIGN_SEARCH_VALUE: return { ...state, search: action.payload }
        case DominosMasterCampaignConstants.DOMINOS_MASTER_CAMPAIGN_TOTAL_COUNT: return { ...state, total: action.payload }
        case DominosMasterCampaignConstants.DOMINOS_MASTER_CAMPAIGN_LOADING_STATUS: return { ...state, loading: action.payload }

        case DominosMasterCampaignConstants.ADD_DOMINOS_MASTER_CAMPAIGN_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case DominosMasterCampaignConstants.ADD_DOMINOS_MASTER_CAMPAIGN_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case DominosMasterCampaignConstants.EDIT_DOMINOS_MASTER_CAMPAIGN_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case DominosMasterCampaignConstants.EDIT_DOMINOS_MASTER_CAMPAIGN_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
