import { AddonsConstants } from "./addons.constants";

export const addons = (state = {}, action) => {
    switch (action.type) {
        case AddonsConstants.GET_ALL_ADDONS_INIT: return { ...state, all: { loading: true } }
        case AddonsConstants.GET_ALL_ADDONS_SUCCESS: return { ...state, all: action.payload }
        case AddonsConstants.GET_ALL_ADDONS_ERROR: return { ...state, all: action.payload }

        case AddonsConstants.GET_ALL_ADDONS_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case AddonsConstants.GET_ALL_ADDONS_PAGINATE_ERROR: return { ...state, ...action.payload }

        case AddonsConstants.EMPTY_ADDONS_BY_PROFILE: return { ...state, profile: "" }
        case AddonsConstants.GET_ADDONS_BY_ID: return { ...state, profile: action.payload }
        case AddonsConstants.GET_ADDONS_BY_ID_ERROR: return { ...state, profile: action.payload }

        case AddonsConstants.ADDONS_CURRENT_PAGE: return { ...state, page: action.payload }

        case AddonsConstants.ADDONS_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case AddonsConstants.ADDONS_SEARCH_VALUE: return { ...state, search: action.payload }

        case AddonsConstants.ADDONS_STATUS_VALUE: return { ...state, status: action.payload }

        case AddonsConstants.ADDONS_TOTAL_COUNT: return { ...state, total: action.payload }

        case AddonsConstants.ADDONS_LOADING_STATUS: return { ...state, loading: action.payload }

        case AddonsConstants.ADD_ADDONS_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case AddonsConstants.ADD_ADDONS_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case AddonsConstants.EDIT_ADDONS_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case AddonsConstants.EDIT_ADDONS_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        case AddonsConstants.DELETE_ADDONS:
        case AddonsConstants.DELETE_ADDONS_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case AddonsConstants.ACTIVATE_ADDONS:
        case AddonsConstants.ACTIVATE_ADDONS_ERROR:
            return { ...state, activateStatus: action.payload }
        default:
            return state;
    }
};
