import { UserConstants } from './user.constants';

import { config } from '../../helpers/config'

export const user = (state = { checking: true, authenticated: false  }, action) => {

    switch (action.type) {
        case UserConstants.AUTH_USER:
            return { ...state, ...action.payload, error: '', authenticated: true, checking: false }
        case UserConstants.AUTH_USER_CHECKING:
            return { ...state, error: '', authenticated: false, checking: true }
        case UserConstants.AUTH_USER_USING_TOKEN:
            return { ...state, ...action.payload, error: '', token: localStorage.getItem(config.bbToken), authenticated: true, checking: false }
        case UserConstants.UNAUTH_USER:
            return {}
        case UserConstants.AUTH_ERROR:
            return { ...state, error: action.payload, authenticated: false, checking: false }

        case UserConstants.AUTH_USER_FORGOT_PASSWORD:
            return { ...state, successForgotPassword: action.payload, authenticated: false, checking: false }
        case UserConstants.AUTH_USER_FORGOT_PASSWORD_ERROR:
            return { ...state, errorForgotPassword: action.payload, authenticated: false, checking: false }

        case UserConstants.GET_USER_PROFILE:
            return { ...state, profile: action.payload }
        case UserConstants.EDIT_USER_PROFILE:
            return { ...state, updateStatus: action.payload }
        case UserConstants.EDIT_USER_PROFILE_ERROR:
            return { ...state, updateStatus: action.payload }

        case UserConstants.RESET_CODE_CHECKING:
            return { ...state, resetChecking: true }
        case UserConstants.RESET_CODE_CHECKED:
            return { ...state, resetChecking: false, codeStatus: action.payload }
        case UserConstants.RESET_PASSWORD:
            return { ...state, resetPasswordStatus: action.payload }
        case UserConstants.RESET_PASSWORD_ERROR:
            return { ...state, resetPasswordStatus: action.payload }

        case UserConstants.AUTH_LOADING_STATUS:
            return { ...state, loading: action.payload }

        case UserConstants.BUDDY_LAST_LOCATION:
            return { ...state, location: action.payload }
        
        case UserConstants.SHOWCASE_LAST_LOCATION:
        return { ...state, showcaseLocation: action.payload }
    
        default:
            return state;
    }
};
