import { AllMyEmissionConstants } from './myEmission.constants';

export const myEmission = (state = {}, action) => {
    switch (action.type) {
        case AllMyEmissionConstants.EMPTY_MY_EMISSION:
           return { ...state, data: "" };
        case AllMyEmissionConstants.GET_MY_EMISSION:
            return { ...state, ...action.payload};

        case AllMyEmissionConstants.MY_EMISSION_LOADING:
            return { ...state, loading: action.payload };
            
        case AllMyEmissionConstants.GET_MY_EMISSION_ERROR:
            return { ...state, ...action.payload};

        default:
            return state;
    }
}
