import React, { useState, useEffect } from 'react';
import { arrayMoveImmutable } from 'array-move';
import parse from 'html-react-parser';
import classnames from 'classnames'
import './sortable-items.scss'
import { Loader } from '../../react-overlay-loader'
import { notify, addClass } from '../../utilities/customFunctions'
import { usePrevious } from '../../utilities/customHooks'
import { FormError } from '../FormError'
import { StatusFilter } from '../../render-helpers'
import Skeleton from "../../../helpers/Skeleton"
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS as cssDndKit } from '@dnd-kit/utilities';

function SortableItems(props) {
    const { setFieldValue, inputClassName, field, isDoc = false, accept = "", containerClassName = "", maxFile = 20,
        isVideo = false, form, isMedia = false, imageWithGif = false, favicon = false, values, isEdit = false,
    } = props;
    var label = `<span class='bb-' style='color: black;font-weight: 800;padding: 12px;'>DRAG DROP OR SELECT SEQUENCE</span> <span class='bb-bg_secondary' style='border-radius: 19px;padding: 14px;margin-left: 30px;margin-right: 30px;font-weight: 700;'>Select</span>`

    const prevProps = usePrevious({ values });

    const [items, setItems] = useState(isEdit ? values[field.name] : []);
    const [loading, setLoading] = useState(false);
    const [currentStatus, setStatus] = useState("asc")
    const [isAddition, setIsAddition] = useState(false)
    const [isSorting, setIsSorting] = useState(false)

    useEffect(() => {
        if (prevProps && prevProps.values &&
            values && values[field.name] !== prevProps.values[field.name]) {
            if (isEdit) {
                if (typeof props.onChange === 'function') {
                    props.onChange(values, isSorting);
                }
                setItems(values[field.name])
            } else {
                if (typeof props.onChange === 'function') {
                    props.onChange(values, isSorting);
                }
            }
            setIsSorting(false)

        }
        //eslint-disable-next-line
    }, [values, isAddition]);

    useEffect(() => {
        if(items) setItems(resortIds(items))
        //eslint-disable-next-line
    }, [items]);

    const sortFilename = (a, b, asc = true) => {
        var i, codeA
            , codeB = 1
            , posA = 0
            , posB = 0
            , alphabet = String.alphabet

        function getCode(str, pos, code) {
            if (code) {
                // eslint-disable-next-line
                for (i = pos; code = getCode(str, i), code < 76 && code > 65;) ++i;
                return +str.slice(pos - 1, i)
            }
            code = alphabet && alphabet.indexOf(str.charAt(pos))
            return code > -1 ? code + 76 : ((code = str.charCodeAt(pos) || 0), code < 45 || code > 127) ? code
                : code < 46 ? 65               // -
                    : code < 48 ? code - 1
                        : code < 58 ? code + 18        // 0-9
                            : code < 65 ? code - 11
                                : code < 91 ? code + 11        // A-Z
                                    : code < 97 ? code - 37
                                        : code < 123 ? code + 5        // a-z
                                            : code - 63
        }

        // eslint-disable-next-line
        if ((a += "") != (b += "")) for (; codeB;) {
            codeA = getCode(a, posA++)
            codeB = getCode(b, posB++)

            if (codeA < 76 && codeB < 76 && codeA > 66 && codeB > 66) {
                codeA = getCode(a, posA, posA)
                codeB = getCode(b, posB, posA = i)
                posB = i
            }
            // eslint-disable-next-line
            if (codeA != codeB) {
                if (codeA < codeB) {
                    return asc ? -1 : 1;
                }
                if (codeA > codeB) {
                    return asc ? 1 : -1;
                }
            }
        }
        return 0
    }



    const handleSort = async (v) => {
        var value = v.target.value;
        var newItems = [...items]
        setIsSorting(true)
        setIsAddition(false)
        setLoading(true)
        setStatus(value)
        newItems.sort((a, b) => sortFilename(a.name, b.name, value === "asc" ? true : false))
        await setFieldValue(field.name, newItems)
        setItems(newItems)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    const removeFile = async (value) => {
        var index = items.indexOf(value);
        setLoading(true)
        setIsAddition(false)
        setIsSorting(true)
        var newItems = [...items]
        if (index > -1) {
            newItems.splice(index, 1);
        }
        await setFieldValue(field.name, newItems)
        setItems(newItems)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    const removeAll = async () => {
        setIsAddition(false)
        setLoading(true)
        var newItems = []
        setIsSorting(true)
        await setFieldValue(field.name, newItems)
        setItems(newItems)
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }

    const setNewSequence = async (files) => {
        return new Promise(resolve => {
            if (!files) {
                return;
            }
            var newItems = [...items]
            if (newItems?.length >= maxFile || newItems?.length + files?.length > maxFile) {
                notify(`Max file is ${maxFile}`, "e")
                return;
            }
            setLoading(true)
            for (let i = 0; i < files?.length; i++) {
                let reader = new FileReader();
                reader.onloadend = async () => {
                    let temp = files[i]
                    temp["url"] = reader.result
                    newItems.push(temp);
                    if (i === files.length - 1) {
                        setIsAddition(true)
                        newItems.sort((a, b) => sortFilename(a.name, b.name, currentStatus === "asc" ? true : false))
                        await setFieldValue(field.name, newItems)
                        setItems(newItems)
                        setTimeout(() => {
                            setLoading(false)

                        }, 1000);
                        resolve(newItems);
                    }
                };
                reader.readAsDataURL(files[i]);
            }
        });
    }

    const resortIds = (values) => {
        //resortIds is needed for SortableContext
        let temp = values
        if(temp) temp.forEach((item, index) => item.id = index + 1)
        return temp
    }

    const onDrop = async (ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        if (ev.dataTransfer.files && ev.dataTransfer.files.length) {
            await setNewSequence(ev.dataTransfer.files)
        }
    }

    const onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
    }

    const onDragExit = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }


    const SortableItem = ({ id, activeId, value }) => {
        const { setNodeRef, transform, transition, listeners } = useSortable({ id });
        const style = {
          transform: cssDndKit.Transform.toString(transform),
          transition,
        };
        return (
            <div
            {...listeners}
            ref={setNodeRef}
            style={style}
            className={(activeId === id) ? 'sortable-item bb-dragging-dnd' : 'sortable-item'}>
                <i className="bb-remove-image fas fa-trash-alt "
                    onClick={async (event) => {
                        event.preventDefault()
                        event.stopPropagation();
                        await removeFile(value)
                    }}></i>
                <img className={classnames("preview-image", isEdit && !isAddition ? "d-none" : "")} src={typeof value === 'object' ? value['url'] : value} alt="" onLoad={(e) => {
                    if (e && e.target && isEdit && !isAddition) {
                        let elm = e.target;
                        setTimeout(() => {
                            elm.classList.remove("d-none")
                            addClass(elm.nextSibling, "d-none")
                        }, 1000)
                    }

                }} />
                {isEdit && !isAddition ? <Skeleton className="skeleton" height="130px" /> : null}

            </div>)
    };

    const SortableList = ({ items, onSortEnd }) => {
        const [activeId, setActiveId] = useState(null);
        const sensors = useSensors(
            useSensor(MouseSensor, {
                activationConstraint: { distance: 2, },
            }),
            useSensor(TouchSensor, {
                // Press delay of 250ms, with tolerance of 5px of movement.
                activationConstraint: { delay: 250, tolerance: 5, },
            })
        );
        //itemids is needed for SortableContext to be array of ids not object
        const itemIds = items ? items.map((item) => item.id) : []
        return (
            <div className="sortable-container">
                <DndContext
                    sensors={sensors}
                    autoScroll={false}
                    onDragStart={({ active }) => {
                        if (active) setActiveId(active.id);
                    }}
                    onDragEnd={({ active, over }) => {
                        if (over && active.id !== over.id) {
                            onSortEnd({
                                oldIndex: active.id - 1,
                                newIndex: over.id - 1,
                            });
                        }
                        setActiveId(null);
                    }}
                    onDragCancel={() => { setActiveId(null) }}
                >
                    <SortableContext items={itemIds} strategy={horizontalListSortingStrategy}>
                        {items ? items.map((value, index) => ( <SortableItem key={`item-${index + 1}`} id={index + 1} activeId={activeId} value={value} /> ) ) : null}
                    </SortableContext>
                </DndContext>
                { items?.length < maxFile && !isEdit ?
                    <div>
                        <input
                            multiple
                            type="file"
                            name={field.name}
                            id={field.name}
                            accept={favicon ? ".jpg, .jpeg, .png, .gif, .ico" : imageWithGif ? ".jpg, .jpeg, .png, .gif" : isMedia ? ".mp4, .jpg, .jpeg, .png, .gif" : isVideo ? ".mp4" : isDoc ? accept : ".jpg, .jpeg, .png"}
                            onChange={async (event) => {
                                await setNewSequence(event.currentTarget.files)
                                form.setFieldTouched(field.name, true);
                            }}
                            className={classnames("bb-input-image form-control", inputClassName)}
                        />
                        <label className="add-label" onDragExit={onDragExit}
                            onDragLeave={onDragExit}
                            onDragEnter={onDragOver}
                            onDragOver={onDragOver}
                            onDrop={onDrop} htmlFor={field.name}>
                            {
                                <React.Fragment>
                                    <i className='fas fa-plus fa-2x text-dark'></i>
                                    {/* <span className='text-dark'>
                                        {label}
                                    </span> */}
                                </React.Fragment>
                            }
                        </label>
                    </div> : null}
            </div>
        );
    };

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        let newItems = arrayMoveImmutable(items, oldIndex, newIndex)
        setIsSorting(true)
        await setFieldValue(field.name, newItems)
        setItems(newItems)
    };

    const renderImageForm = () => {
        return (
            <React.Fragment>
                <div id="bb-image-upload-container" className={classnames("bb-image-upload-container", containerClassName, form.touched[field.name] && form.errors[field.name] ? 'error' : '')}>
                    {
                        <React.Fragment>
                            <input
                                multiple
                                type="file"
                                name={field.name}
                                id={field.name}
                                accept={favicon ? ".jpg, .jpeg, .png, .gif, .ico" : imageWithGif ? ".jpg, .jpeg, .png, .gif" : isMedia ? ".mp4, .jpg, .jpeg, .png, .gif" : isVideo ? ".mp4" : isDoc ? accept : ".jpg, .jpeg, .png"}
                                onChange={async (event) => {
                                    await setNewSequence(event.currentTarget.files)
                                    form.setFieldTouched(field.name, true);
                                }}
                                className={classnames("bb-input-image form-control", inputClassName)}
                            />
                            <label onDragExit={onDragExit}
                                onDragLeave={onDragExit}
                                onDragEnter={onDragOver}
                                onDragOver={onDragOver}
                                onDrop={onDrop} htmlFor={field.name}>
                                {
                                    parse(label)
                                }
                            </label>
                        </React.Fragment>
                    }
                </div>
                <div className="mt-2">
                    <FormError name={field.name} />
                </div>
            </React.Fragment>
        )
    }


    return (
        <>
            <Loader text="Loading..." loading={loading} fullPage />
            {items?.length === 0 ? renderImageForm() :
                <div id="sortable-container-main">
                    <SortableList items={items} onSortEnd={onSortEnd}/>
                    <div className="d-flex flex-row flex-wrap mt-4 mb-3">

                        <StatusFilter isCleanable={false} status={currentStatus} isDefault={false} onChange={handleSort} label="" className="ml-0 sort-status-filter" marginBottom={false}
                        optionData={[
                            {
                            "label": "Ascending order",
                            "value": "asc",
                            },
                            {
                            "label": "Descending order",
                            "value": "des",
                            },
                        ]}/>
                        <button type="button" onClick={removeAll} className="ml-2 bb-button bb-button-tertiary sort-remove-button mt-0 h-auto">Delete and redo sequence</button>

                    </div>
                </div>
            }
        </>
    )

}

export default SortableItems;