import { LogConstants } from './log.constants';

export const log = (state = {}, action) => {
    switch (action.type) {
        case LogConstants.GET_LOG_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case LogConstants.LOG_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case LogConstants.GET_LOG_ERROR:
            return {
                ...state,
                error: true
            };
        case LogConstants.LOG_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case LogConstants.LOG_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case LogConstants.LOG_LIMIT:
            return {
                ...state,
                total: action.payload
            };
        default:
            return state;
    }
}