import { PerformanceSubscriptionConstants } from './performanceSubscription.constants';

export const performanceSubscription = (state = {}, action) => {
    switch (action.type) {
        case PerformanceSubscriptionConstants.GET_PERFORMANCE_SUBSCRIPTION_SUCCESS: return { ...state, ...action.payload };
        case PerformanceSubscriptionConstants.PERFORMANCE_SUBSCRIPTION_LOADING: return { ...state, loading: action.payload };
        case PerformanceSubscriptionConstants.GET_PERFORMANCE_SUBSCRIPTION_ERROR: return { ...state, error: true };
        case PerformanceSubscriptionConstants.PERFORMANCE_SUBSCRIPTION_CURRENT_PAGE: return { ...state, page: action.payload };
        case PerformanceSubscriptionConstants.PERFORMANCE_SUBSCRIPTION_LIMIT_PER_PAGE: return { ...state, limit: action.payload };
        case PerformanceSubscriptionConstants.PERFORMANCE_SUBSCRIPTION_LIMIT: return { ...state, total: action.payload };

        case PerformanceSubscriptionConstants.UPDATE_PERFORMANCE_SUBSCRIPTION_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case PerformanceSubscriptionConstants.UPDATE_PERFORMANCE_SUBSCRIPTION_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default: return state;
    }
}