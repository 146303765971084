import React from 'react'
import { NumericFormat } from 'react-number-format'
import classnames from 'classnames'
import { FormError } from './FormError'

const PercentageInput = ({ disabled, field, containerClassName,
  labelClassName, inputContainerClassName, className, onChange, minValue = false, maxValue = false,
  decimalScale = 5,
  label, placeholder, isOptional = false, form: { touched, errors } }) => {

  return (
    <div className={containerClassName}>
      <label className={classnames("mb-1 pt-2", labelClassName)}>
        {label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}
      </label>
      <div className={inputContainerClassName}>
        <NumericFormat
          onValueChange={(value) => {
            if (typeof onChange === 'function') {
              onChange(value)
            }
          }}
          className={classnames("form-control", className, touched[field.name] && errors[field.name] ? 'error' : '')}
          {...field}
          thousandSeparator=""
          allowNegative={false}
          placeholder={placeholder ? placeholder : label}
          value={field.value}
          disabled={disabled}
          decimalScale={decimalScale}
          suffix={'%'}
          isAllowed={(values) => {
            const { floatValue } = values;
            if (typeof minValue == "number" && typeof maxValue == "number") {
              return (floatValue > minValue && floatValue < maxValue)
            }
            else if (typeof minValue == "number") {
              return floatValue > minValue;
            }
            else if (typeof maxValue == "number") {
              return (floatValue < maxValue) || floatValue === undefined;
            }
            return true;
          }}
        />
        <FormError name={field.name} />
      </div>
    </div>
  )
}
export default PercentageInput