export const AlloffsetCategoryConstants = {
    GET_ALL_OFFSET_CATEGORY_INIT: 'GET_ALL_OFFSET_CATEGORY_INIT',
    GET_ALL_OFFSET_CATEGORY_SUCCESS: 'GET_ALL_OFFSET_CATEGORY_SUCCESS',
    GET_ALL_OFFSET_CATEGORY_ERROR: 'GET_ALL_OFFSET_CATEGORY_ERROR',

    GET_ALL_OFFSET_CATEGORY_PAGINATE_SUCCESS: 'GET_ALL_OFFSET_CATEGORY_PAGINATE_SUCCESS',
    GET_ALL_OFFSET_CATEGORY_PAGINATE_ERROR: 'GET_ALL_OFFSET_CATEGORY_PAGINATE_ERROR',

    OFFSET_CATEGORY_CURRENT_PAGE: 'OFFSET_CATEGORY_CURRENT_PAGE',
    OFFSET_CATEGORY_LIMIT_PER_PAGE: 'OFFSET_CATEGORY_LIMIT_PER_PAGE',
    OFFSET_CATEGORY_SEARCH_VALUE: 'OFFSET_CATEGORY_SEARCH_VALUE',
    OFFSET_CATEGORY_STATUS_VALUE: 'OFFSET_CATEGORY_STATUS_VALUE',
    OFFSET_CATEGORY_TOTAL_COUNT: 'OFFSET_CATEGORY_TOTAL_COUNT',

    GET_OFFSET_CATEGORY_BY_ID: 'GET_OFFSET_CATEGORY_BY_ID',
    EMPTY_OFFSET_CATEGORY_BY_PROFILE: 'EMPTY_OFFSET_CATEGORY_BY_PROFILE',
    GET_OFFSET_CATEGORY_BY_ID_ERROR: 'GET_OFFSET_CATEGORY_BY_ID_ERROR',

    ADD_OFFSET_CATEGORY_SUCCESS: 'ADD_OFFSET_CATEGORY_SUCCESS',
    ADD_OFFSET_CATEGORY_ERROR: 'ADD_OFFSET_CATEGORY_ERROR',

    EDIT_OFFSET_CATEGORY_SUCCESS: 'EDIT_OFFSET_CATEGORY_SUCCESS',
    EDIT_OFFSET_CATEGORY_ERROR: 'EDIT_OFFSET_CATEGORY_ERROR',

    ACTIVATE_OFFSET_CATEGORY: 'ACTIVATE_OFFSET_CATEGORY',
    ACTIVATE_OFFSET_CATEGORY_ERROR: 'ACTIVATE_OFFSET_CATEGORY_ERROR',

    DELETE_OFFSET_CATEGORY: 'DELETE_OFFSET_CATEGORY',
    DELETE_OFFSET_CATEGORY_ERROR: 'DELETE_OFFSET_CATEGORY_ERROR',

    OFFSET_CATEGORY_LOADING_STATUS: 'OFFSET_CATEGORY_LOADING_STATUS',

    EMPTY_OFFSET_CATEGORY: 'EMPTY_OFFSET_CATEGORY',
};
