import { MasterSitelistConstants } from "./masterSitelist.constants";

export const masterSitelists = (state = {}, action) => {
    switch (action.type) {
        case MasterSitelistConstants.GET_ALL_MASTER_SITELIST_INIT: return { ...state, all: { loading: true } }
        case MasterSitelistConstants.GET_ALL_MASTER_SITELIST_SUCCESS: return { ...state, all: action.payload }
        case MasterSitelistConstants.GET_ALL_MASTER_SITELIST_ERROR: return { ...state, all: action.payload }

        case MasterSitelistConstants.GET_ALL_MASTER_SITELIST_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case MasterSitelistConstants.GET_ALL_MASTER_SITELIST_PAGINATE_ERROR: return { ...state, ...action.payload }

        case MasterSitelistConstants.EMPTY_MASTER_SITELIST_BY_PROFILE: return { ...state, profile: "" }
        case MasterSitelistConstants.GET_MASTER_SITELIST_BY_ID: return { ...state, profile: action.payload }
        case MasterSitelistConstants.GET_MASTER_SITELIST_BY_ID_ERROR: return { ...state, profile: action.payload }

        case MasterSitelistConstants.MASTER_SITELIST_CURRENT_PAGE: return { ...state, page: action.payload }
        case MasterSitelistConstants.MASTER_SITELIST_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case MasterSitelistConstants.MASTER_SITELIST_SEARCH_VALUE: return { ...state, search: action.payload }
        case MasterSitelistConstants.MASTER_SITELIST_STATUS_VALUE: return { ...state, status: action.payload }
        case MasterSitelistConstants.MASTER_SITELIST_TOTAL_COUNT: return { ...state, total: action.payload }
        case MasterSitelistConstants.MASTER_SITELIST_LOADING_STATUS: return { ...state, loading: action.payload }

        case MasterSitelistConstants.DELETE_MASTER_SITELIST:
        case MasterSitelistConstants.DELETE_MASTER_SITELIST_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case MasterSitelistConstants.ACTIVATE_MASTER_SITELIST:
        case MasterSitelistConstants.ACTIVATE_MASTER_SITELIST_ERROR:
            return { ...state, activateStatus: action.payload }

        case MasterSitelistConstants.ADD_MASTER_SITELIST_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case MasterSitelistConstants.ADD_MASTER_SITELIST_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case MasterSitelistConstants.EDIT_MASTER_SITELIST_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case MasterSitelistConstants.EDIT_MASTER_SITELIST_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
