import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { checkUserTokenStatus } from '../../redux/user/user.actions';

import { getQueryStringParams } from '../../components/utilities/customFunctions'
import { config } from '../../helpers/config'
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Spinner from '../../components/utilities/Spinner';

const Authentication = (props) => {

    const { checking, authenticated, checkUserTokenStatus } = props;
    let { search, pathname } = useLocation()
    const navigate = useNavigate()
    let searchParams = getQueryStringParams(search);

    useEffect(() => {


        if (!authenticated) {
            if (checking) {
                if (searchParams && searchParams.token) {
                    localStorage.setItem(config.bbToken, searchParams.token);
                }
                checkUserTokenStatus(pathname, search, '');
            }
            else {
                
                if(pathname !== "/forgotpassword" && pathname !== "/login" && pathname !== "/" && !pathname.includes("/my-creatives/review/")){                        
                    if(pathname){
                        navigate(`/login?from=${pathname + encodeURIComponent(search)}`)
                    }
                    else{
                        navigate(`/`)
                    }                    
                }
                /* else if (pathname !== "/forgotpassword") {
                                navigate(`/`)                            
                } */
                
            }
        }
        else {
            var from = "";
            if (searchParams && searchParams.from) {
                from = searchParams.from;
            };

            if (from) {
                navigate(decodeURIComponent(from));
            }


        }

        // eslint-disable-next-line
    }, [authenticated, checking, navigate, checkUserTokenStatus])




    if (!checking || authenticated) {
        return <Outlet />
    }
    else {
        return (
            <React.Fragment>
                <div className="mt-5 text-center d-flex flex-column justify-content-center h-100">
                    <Spinner width="100" height="100" />
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        authenticated: state.user.authenticated,
        checking: state.user.checking,
        loading: state.user.loading
    };
}

export default connect(mapStateToProps, { checkUserTokenStatus })(Authentication);

