export const PerformanceSubscriptionConstants = {
    PERFORMANCE_SUBSCRIPTION_CURRENT_PAGE: "PERFORMANCE_SUBSCRIPTION_CURRENT_PAGE",
    PERFORMANCE_SUBSCRIPTION_LIMIT_PER_PAGE: "PERFORMANCE_SUBSCRIPTION_LIMIT_PER_PAGE",

    GET_PERFORMANCE_SUBSCRIPTION_ERROR: "GET_PERFORMANCE_SUBSCRIPTION_ERROR",
    GET_PERFORMANCE_SUBSCRIPTION_SUCCESS: "GET_PERFORMANCE_SUBSCRIPTION_SUCCESS",
    PERFORMANCE_SUBSCRIPTION_LIMIT: "PERFORMANCE_SUBSCRIPTION_LIMIT",
    PERFORMANCE_SUBSCRIPTION_LOADING: "PERFORMANCE_SUBSCRIPTION_LOADING",

    UPDATE_PERFORMANCE_SUBSCRIPTION_SUCCESS: 'UPDATE_PERFORMANCE_SUBSCRIPTION_SUCCESS',
    UPDATE_PERFORMANCE_SUBSCRIPTION_ERROR: 'UPDATE_PERFORMANCE_SUBSCRIPTION_ERROR',
};
