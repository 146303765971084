import { combineReducers } from 'redux';

import { AdByBm } from "./adByBM/adByBM.reducers"

import { SwipeAdBuilder, AdBuilder, Advertisements, MyAdvertisements, VendorAdvertisements, NexdAdBuilder, CreativeReport, NexdBuilder, AirtoryBuilder, PrimoBuilder }
    from "./adBuilder/adBuilder.reducers";

import DSPs from "./dsp/dsp.reducers"

export const bbcreative = combineReducers({
    AdBuilder,
    SwipeAdBuilder,
    Advertisements,
    MyAdvertisements,
    VendorAdvertisements,
    AdByBm,
    NexdAdBuilder,
    DSPs, 
    CreativeReport,
    NexdBuilder,
    AirtoryBuilder,
    PrimoBuilder
});
