import { CreativeRequestConstants } from './request.constants';

export const creativeRequest = (state = {}, action) => {
    switch (action.type) {
        case CreativeRequestConstants.SEND_USER_REQUEST:
            return { ...state, };

        case CreativeRequestConstants.SEND_USER_REQUEST_SUCCESS:
            return { ...state, RequestSuccess: true, ...action.payload };

        case CreativeRequestConstants.SEND_USER_REQUEST_ERROR:
            return { ...state, RequestSuccess: false, ...action.payload };

        case CreativeRequestConstants.SEND_USER_REQUEST_LOADING:
            return { ...state, Requestloading: action.payload };
            
        case CreativeRequestConstants.GET_CURATED_MEDIA_BRIEF_BY_ID_SUCCESS:
            return { ...state, briefData: action.payload, error: '' };
                
        case CreativeRequestConstants.GET_CURATED_MEDIA_BRIEF_BY_ID_ERROR:
            return { ...state, briefData: false, error: action.payload };

        case CreativeRequestConstants.EMPTY_CURATED_MEDIA_BRIEF_BY_ID:
                return { ...state, briefData:"", error: ""};    

        default:
            return state;
    }
}