
export const HelpersConstants = {

    BB_GET_ALL_CURRENCIES_INIT: 'BB_GET_ALL_CURRENCIES_INIT',
    BB_GET_ALL_CURRENCIES_SUCCESS: 'BB_GET_ALL_CURRENCIES_SUCCESS',
    BB_GET_ALL_CURRENCIES_ERROR: 'BB_GET_ALL_CURRENCIES_ERROR',

    BB_GET_ALL_DEAL_TYPE_FORMAT_INIT: 'BB_GET_ALL_DEAL_TYPE_FORMAT_INIT',
    BB_GET_ALL_DEAL_TYPE_FORMAT_SUCCESS: 'BB_GET_ALL_DEAL_TYPE_FORMAT_SUCCESS',
    BB_GET_ALL_DEAL_TYPE_FORMAT_ERROR: 'BB_GET_ALL_DEAL_TYPE_FORMAT_ERROR',
    BB_GET_DEAL_TYPE_BY_COUNTRY_SUCCESS: 'BB_GET_DEAL_TYPE_BY_COUNTRY_SUCCESS',
    BB_GET_DEAL_TYPE_BY_COUNTRY_ERROR: 'BB_GET_DEAL_TYPE_BY_COUNTRY_ERROR',
    BB_EMPTY_DEAL_TYPE_FORMAT:'BB_EMPTY_DEAL_TYPE_FORMAT',
    BB_EMPTY_DEAL_TYPE_BY_COUNTRY:'BB_EMPTY_DEAL_TYPE_BY_COUNTRY',
    
    BB_GET_ALL_SITES_INIT:'BB_GET_ALL_SITES_INIT',
    BB_GET_ALL_SITES_SUCCESS:'BB_GET_ALL_SITES_SUCCESS',
    BB_GET_ALL_SITES_ERROR:'BB_GET_ALL_SITES_ERROR',

    BB_GET_ALL_ROLES_INIT: 'BB_GET_ALL_ROLES_INIT',
    BB_GET_ALL_ROLES_SUCCESS: 'BB_GET_ALL_ROLES_SUCCESS',
    BB_GET_ALL_ROLES_ERROR: 'BB_GET_ALL_ROLES_ERROR',

    BB_GET_ALL_TIMEZONES_INIT: 'BB_GET_ALL_TIMEZONES_INIT',
    BB_GET_ALL_TIMEZONES_SUCCESS: 'BB_GET_ALL_TIMEZONES_SUCCESS',
    BB_GET_ALL_TIMEZONES_ERROR: 'BB_GET_ALL_TIMEZONES_ERROR',
    
    BB_GET_UNIQUE_CPM_INIT:"BB_GET_UNIQUE_CPM_INIT",
    BB_GET_UNIQUE_CPM_SUCCESS:"BB_GET_UNIQUE_CPM_SUCCESS",
    BB_GET_UNIQUE_CPM_ERROR:"BB_GET_UNIQUE_CPM_ERROR",


    BB_GET_ALL_COUNTRIES_INIT: 'BB_GET_ALL_COUNTRIES_INIT',
    BB_GET_ALL_COUNTRIES_SUCCESS: 'BB_GET_ALL_COUNTRIES_SUCCESS',
    BB_GET_ALL_COUNTRIES_ERROR: 'BB_GET_ALL_COUNTRIES_ERROR',

    BB_GET_ALL_STATES_INIT: 'BB_GET_ALL_STATES_INIT',
    BB_GET_ALL_STATES_SUCCESS: 'BB_GET_ALL_STATES_SUCCESS',
    BB_GET_ALL_STATES_ERROR: 'BB_GET_ALL_STATES_ERROR',

    BB_GET_ALL_CITIES_INIT: 'BB_GET_ALL_CITIES_INIT',
    BB_GET_ALL_CITIES_SUCCESS: 'BB_GET_ALL_CITIES_SUCCESS',
    BB_GET_ALL_CITIES_ERROR: 'BB_GET_ALL_CITIES_ERROR',

    BB_EMPTY_CITIES:'BB_EMPTY_CITIES',
    BB_EMPTY_STATES:'BB_EMPTY_STATES',
    
    BB_GET_ALL_PUBLISHERS_INIT: 'BB_GET_ALL_PUBLISHERS_INIT',
    BB_GET_ALL_PUBLISHERS_SUCCESS: 'BB_GET_ALL_PUBLISHERS_SUCCESS',
    BB_GET_ALL_PUBLISHERS_ERROR: 'BB_GET_ALL_PUBLISHERS_ERROR',

    BB_GET_ALL_NETWORKS_INIT: 'BB_GET_ALL_NETWORKS_INIT',
    BB_GET_ALL_NETWORKS_SUCCESS: 'BB_GET_ALL_NETWORKS_SUCCESS',
    BB_GET_ALL_NETWORKS_ERROR: 'BB_GET_ALL_NETWORKS_ERROR',

    BB_GET_ALL_ACCOUNTS_INIT: 'BB_GET_ALL_ACCOUNTS_INIT',
    BB_GET_ALL_ACCOUNTS_SUCCESS: 'BB_GET_ALL_ACCOUNTS_SUCCESS',
    BB_GET_ALL_ACCOUNTS_ERROR: 'BB_GET_ALL_ACCOUNTS_ERROR',

    BB_GET_ALL_ACCOUNTS_POP_INIT: 'BB_GET_ALL_ACCOUNTS_POP_INIT',
    BB_GET_ALL_ACCOUNTS_POP_SUCCESS: 'BB_GET_ALL_ACCOUNTS_POP_SUCCESS',
    BB_GET_ALL_ACCOUNTS_POP_ERROR: 'BB_GET_ALL_ACCOUNTS_POP_ERROR',
    BB_EMPTY_ACCOUNTS_POP:'BB_EMPTY_ACCOUNTS_POP',

    BB_GET_ALL_CAMPAIGNS_INIT: 'BB_GET_ALL_CAMPAIGNS_INIT',
    BB_GET_ALL_CAMPAIGNS_SUCCESS: 'BB_GET_ALL_CAMPAIGNS_SUCCESS',
    BB_GET_ALL_CAMPAIGNS_ERROR: 'BB_GET_ALL_CAMPAIGNS_ERROR',

    BB_GET_ALL_DSPS_INIT: 'BB_GET_ALL_DSPS_INIT',
    BB_GET_ALL_DSPS_SUCCESS: 'BB_GET_ALL_DSPS_SUCCESS',
    BB_GET_ALL_DSPS_ERROR: 'BB_GET_ALL_DSPS_ERROR',

    BB_EMPTY_PUBLISHERS:'BB_EMPTY_PUBLISHERS',
    BB_EMPTY_SITES:'BB_EMPTY_SITES',
    BB_EMPTY_NETWORKS:'BB_EMPTY_NETWORKS',
    BB_EMPTY_ACCOUNTS:'BB_EMPTY_ACCOUNTS',

    BB_EMPTY_CAMPAIGNS:'BB_EMPTY_CAMPAIGNS',
    BB_EMPTY_DSPS:'BB_EMPTY_DSPS',

    BB_CHANGE_OWNERSHIP_STATUS:'BB_CHANGE_OWNERSHIP_STATUS',
    BB_CHANGE_OWNERSHIP_SUCCESS:'BB_CHANGE_OWNERSHIP_SUCCESS',
    BB_CHANGE_OWNERSHIP_ERROR:'BB_CHANGE_OWNERSHIP_ERROR',

    BB_CHECK_ACCESS_STATUS:'BB_CHECK_ACCESS_STATUS',
    BB_CHECK_ACCESS_SUCCESS:'BB_CHECK_ACCESS_SUCCESS',
    BB_CHECK_ACCESS_ERROR:'BB_CHECK_ACCESS_ERROR',
    
    BB_GET_DSP_GUIDE_LOADING:'BB_GET_DSP_GUIDE_LOADING',
    BB_GET_DSP_GUIDE_SUCCESS:'BB_GET_DSP_GUIDE_SUCCESS',
    BB_GET_DSP_GUIDE_ERROR:'BB_GET_DSP_GUIDE_ERROR',

    BB_GET_AD_SPEC_LOADING:'BB_GET_AD_SPEC_LOADING',
    BB_GET_AD_SPEC_SUCCESS:'BB_GET_AD_SPEC_SUCCESS',
    BB_GET_AD_SPEC_ERROR:'BB_GET_AD_SPEC_ERROR',

    BB_GET_VIDEO_ID_LOADING:'BB_GET_VIDEO_ID_LOADING',
    BB_GET_VIDEO_ID_SUCCESS:'BB_GET_VIDEO_ID_SUCCESS',
    BB_GET_VIDEO_ID_ERROR:'BB_GET_VIDEO_ID_ERROR',

    
    BB_GET_ALL_CATEGORIES_INIT:'BB_GET_ALL_CATEGORIES_INIT',
    BB_GET_ALL_CATEGORIES_SUCCESS:'BB_GET_ALL_CATEGORIES_SUCCESS',
    BB_GET_ALL_CATEGORIES_ERROR:'BB_GET_ALL_CATEGORIES_ERROR',
    BB_EMPTY_CATEGORIES:'BB_EMPTY_CATEGORIES',

    BB_GET_HAC_TEMPERATURE_INIT:'BB_GET_HAC_TEMPERATURE_INIT',
    BB_GET_HAC_TEMPERATURE_SUCCESS:'BB_GET_HAC_TEMPERATURE_SUCCESS',
    BB_GET_HAC_TEMPERATURE_ERROR:'BB_GET_HAC_TEMPERATURE_ERROR',

    BB_GROSS_MARGIN_LOADING_STATUS: "BB_GROSS_MARGIN_LOADING_STATUS",
    BB_GROSS_MARGIN_SUCCESS: "BB_GROSS_MARGIN_SUCCESS",
    BB_GROSS_MARGIN_ERROR: "BB_GROSS_MARGIN_ERROR",
    EMPTY_GROSS_MARGIN: "EMPTY_GROSS_MARGIN",
    
    BB_GET_BESPOKE_BY_COUNTRY_INIT: 'BB_GET_BESPOKE_BY_COUNTRY_INIT',
    BB_GET_BESPOKE_BY_COUNTRY_SUCCESS: 'BB_GET_BESPOKE_BY_COUNTRY_SUCCESS',
    BB_GET_BESPOKE_BY_COUNTRY_ERROR: 'BB_GET_BESPOKE_BY_COUNTRY_ERROR',
    EMPTY_BESPOKE_BY_COUNTRY: 'EMPTY_BESPOKE_BY_COUNTRY',
    
    BB_GET_ALL_PROVIDERS_INIT: 'BB_GET_ALL_PROVIDERS_INIT',
    BB_GET_ALL_PROVIDERS_SUCCESS: 'BB_GET_ALL_PROVIDERS_SUCCESS',
    BB_GET_ALL_PROVIDERS_ERROR: 'BB_GET_ALL_PROVIDERS_ERROR',

    BB_PRODUCT_MARGIN_LOADING_STATUS: "BB_PRODUCT_MARGIN_LOADING_STATUS",
    BB_PRODUCT_MARGIN_SUCCESS: "BB_PRODUCT_MARGIN_SUCCESS",
    BB_PRODUCT_MARGIN_ERROR: "BB_PRODUCT_MARGIN_ERROR",
    EMPTY_PRODUCT_MARGIN: "EMPTY_PRODUCT_MARGIN",
};
