import { SegmentPricingConstants } from "./segmentPricing.constants";

export const segmentPricing = (state = {}, action) => {
    switch (action.type) {
        case SegmentPricingConstants.GET_ALL_SEGMENT_PRICING_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case SegmentPricingConstants.GET_ALL_SEGMENT_PRICING_PAGINATE_ERROR: return { ...state, ...action.payload }

        case SegmentPricingConstants.EMPTY_SEGMENT_PRICING_BY_PROFILE: return { ...state, profile: "" }
        case SegmentPricingConstants.GET_SEGMENT_PRICING_BY_ID: return { ...state, profile: action.payload }
        case SegmentPricingConstants.GET_SEGMENT_PRICING_BY_ID_ERROR: return { ...state, profile: action.payload }

        case SegmentPricingConstants.SEGMENT_PRICING_CURRENT_PAGE: return { ...state, page: action.payload }
        case SegmentPricingConstants.SEGMENT_PRICING_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case SegmentPricingConstants.SEGMENT_PRICING_SEARCH_VALUE: return { ...state, search: action.payload }
        case SegmentPricingConstants.SEGMENT_PRICING_STATUS_VALUE: return { ...state, status: action.payload }
        case SegmentPricingConstants.SEGMENT_PRICING_TOTAL_COUNT: return { ...state, total: action.payload }
        case SegmentPricingConstants.SEGMENT_PRICING_LOADING_STATUS: return { ...state, loading: action.payload }

        case SegmentPricingConstants.DELETE_SEGMENT_PRICING:
        case SegmentPricingConstants.DELETE_SEGMENT_PRICING_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case SegmentPricingConstants.ACTIVATE_SEGMENT_PRICING:
        case SegmentPricingConstants.ACTIVATE_SEGMENT_PRICING_ERROR:
            return { ...state, activateStatus: action.payload }

        case SegmentPricingConstants.ADD_SEGMENT_PRICING_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case SegmentPricingConstants.ADD_SEGMENT_PRICING_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case SegmentPricingConstants.EDIT_SEGMENT_PRICING_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case SegmentPricingConstants.EDIT_SEGMENT_PRICING_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
