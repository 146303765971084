export const AdByBmConstants = {
    AD_CREATE_REQUEST: "AD_CREATE_REQUEST",
    AD_CREATE_REQUEST_LOADING: "AD_CREATE_REQUEST_LOADING",
    AD_CREATE_REQUEST_SUCCESS: "AD_CREATE_REQUEST_SUCCESS",
    AD_CREATE_REQUEST_ERROR: "AD_CREATE_REQUEST_ERROR",
    
    NEW_BRIEF_CREATIVE_TAG_LOADING: "NEW_BRIEF_CREATIVE_TAG_LOADING",
    NEW_BRIEF_CREATIVE_TAG_SUCCESS: "NEW_BRIEF_CREATIVE_TAG_SUCCESS",
    NEW_BRIEF_CREATIVE_TAG_ERROR: "NEW_BRIEF_CREATIVE_TAG_ERROR",
    
    EDIT_BRIEF_CREATIVE_TAG_LOADING: "EDIT_BRIEF_CREATIVE_TAG_LOADING",
    EDIT_BRIEF_CREATIVE_TAG_SUCCESS: "EDIT_BRIEF_CREATIVE_TAG_SUCCESS",
    EDIT_BRIEF_CREATIVE_TAG_ERROR: "EDIT_BRIEF_CREATIVE_TAG_ERROR",
};
