
import React from 'react';
import { components } from "react-select";

export const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="fas fa-caret-down"></i>
        </components.DropdownIndicator>
    );
};
export default DropdownIndicator