export const NexdBuilderConstants = {

    NEXD_BUILDER_CREATIVE_LOADING: 'NEXD_BUILDER_CREATIVE_LOADING',
    NEXD_BUILDER_NEW_CREATIVE_SUCCESS: 'NEXD_BUILDER_NEW_CREATIVE_SUCCESS',
    NEXD_BUILDER_NEW_CREATIVE_ERROR: 'NEXD_BUILDER_NEW_CREATIVE_ERROR',

    NEXD_BUILDER_UNMOUNT: 'NEXD_BUILDER_UNMOUNT',

    NEXD_BUILDER_ASSET_LOADING: 'NEXD_BUILDER_ASSET_LOADING',
    NEXD_BUILDER_ADD_ASSET_SUCCESS: 'NEXD_BUILDER_ADD_ASSET_SUCCESS',
    NEXD_BUILDER_ADD_ASSET_ERROR: 'NEXD_BUILDER_ADD_ASSET_ERROR',

    NEXD_BUILDER_PREVIEW_LOADING: 'NEXD_BUILDER_PREVIEW_LOADING',
    NEXD_GENERATE_PREVIEW_SUCCESS: 'NEXD_GENERATE_PREVIEW_SUCCESS',
    NEXD_GENERATE_PREVIEW_ERROR: 'NEXD_GENERATE_PREVIEW_ERROR',

    NEXD_GET_TAG_SUCCESS: 'NEXD_GET_TAG_SUCCESS',
    NEXD_GET_TAG_ERROR: 'NEXD_GET_TAG_ERROR',

    NEXD_BUILDER_REMOVE_ASSET_SUCCESS: 'NEXD_BUILDER_REMOVE_ASSET_SUCCESS',
    NEXD_BUILDER_REMOVE_ASSET_ERROR: 'NEXD_BUILDER_REMOVE_ASSET_ERROR',

    INIT_NEXD_BUILDER_ADD:'INIT_NEXD_BUILDER_ADD',

    NEXD_BUILDER_UPDATE_SUCCESS:'NEXD_BUILDER_UPDATE_SUCCESS',
    NEXD_BUILDER_UPDATE_ERROR:'NEXD_BUILDER_UPDATE_ERROR',

    NEXD_STATUS_LOG_INIT:'NEXD_STATUS_LOG_INIT',
    NEXD_STATUS_LOG_SUCCESS:'NEXD_STATUS_LOG_SUCCESS',
    NEXD_STATUS_LOG_ERROR:'NEXD_STATUS_LOG_ERROR',

    NEXD_OPTIMISATION_SUCCESS: 'NEXD_OPTIMISATION_SUCCESS',
    NEXD_OPTIMISATION_ERROR:'NEXD_OPTIMISATION_ERROR'



};
