export const AllEventsConstants = {
    GET_EVENTS: 'GET_EVENTS', 
    EVENTS_LOADING: 'EVENTS_LOADING',
    GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',

    GET_EVENT_BY_ID: 'GET_EVENT_BY_ID', 
    GET_EVENT_BY_ID_ERROR: 'GET_EVENT_BY_ID_ERROR', 

    GET_EVENT_GALLERY_BY_ID: 'GET_EVENT_GALLERY_BY_ID', 
    GET_EVENT_GALLERY_BY_ID_ERROR: 'GET_EVENT_GALLERY_BY_ID_ERROR', 
    
    EMPTY_EVENTS:'EMPTY_EVENTS'
};
