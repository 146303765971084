export const DominosMasterCampaignConstants = {
    GET_ALL_DOMINOS_MASTER_CAMPAIGN_PAGINATE_SUCCESS: 'GET_ALL_DOMINOS_MASTER_CAMPAIGN_PAGINATE_SUCCESS',
    GET_ALL_DOMINOS_MASTER_CAMPAIGN_PAGINATE_ERROR: 'GET_ALL_DOMINOS_MASTER_CAMPAIGN_PAGINATE_ERROR',

    DOMINOS_MASTER_CAMPAIGN_CURRENT_PAGE: 'DOMINOS_MASTER_CAMPAIGN_CURRENT_PAGE',
    DOMINOS_MASTER_CAMPAIGN_LIMIT_PER_PAGE: 'DOMINOS_MASTER_CAMPAIGN_LIMIT_PER_PAGE',
    DOMINOS_MASTER_CAMPAIGN_SEARCH_VALUE: 'DOMINOS_MASTER_CAMPAIGN_SEARCH_VALUE',
    DOMINOS_MASTER_CAMPAIGN_TOTAL_COUNT: 'DOMINOS_MASTER_CAMPAIGN_TOTAL_COUNT',
    
    GET_DOMINOS_MASTER_CAMPAIGN_BY_ID: 'GET_DOMINOS_MASTER_CAMPAIGN_BY_ID',
    EMPTY_DOMINOS_MASTER_CAMPAIGN_BY_PROFILE: 'EMPTY_DOMINOS_MASTER_CAMPAIGN_BY_PROFILE',
    GET_DOMINOS_MASTER_CAMPAIGN_BY_ID_ERROR: 'GET_DOMINOS_MASTER_CAMPAIGN_BY_ID_ERROR',

    ADD_DOMINOS_MASTER_CAMPAIGN_SUCCESS: 'ADD_DOMINOS_MASTER_CAMPAIGN_SUCCESS',
    ADD_DOMINOS_MASTER_CAMPAIGN_ERROR: 'ADD_DOMINOS_MASTER_CAMPAIGN_ERROR',

    EDIT_DOMINOS_MASTER_CAMPAIGN_SUCCESS: 'EDIT_DOMINOS_MASTER_CAMPAIGN_SUCCESS',
    EDIT_DOMINOS_MASTER_CAMPAIGN_ERROR: 'EDIT_DOMINOS_MASTER_CAMPAIGN_ERROR',
    
    DOMINOS_MASTER_CAMPAIGN_LOADING_STATUS: 'DOMINOS_MASTER_CAMPAIGN_LOADING_STATUS',

    EMPTY_DOMINOS_MASTER_CAMPAIGN: 'EMPTY_DOMINOS_MASTER_CAMPAIGN',
}