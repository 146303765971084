import { SuperFormatConstants } from './superFormat.constants';
import { combineReducers } from 'redux';

const allSuperFormat = (state = {}, action) => {
    switch (action.type) {
        case SuperFormatConstants.GET_ALL_SUPER_FORMAT_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case SuperFormatConstants.ALL_SUPER_FORMAT_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SuperFormatConstants.GET_ALL_SUPER_FORMAT_ERROR:
            return {
                ...state,
                error: true
            };
        case SuperFormatConstants.ALL_SUPER_FORMAT_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case SuperFormatConstants.ALL_SUPER_FORMAT_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case SuperFormatConstants.ALL_SUPER_FORMAT_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case SuperFormatConstants.ALL_SUPER_FORMAT_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case SuperFormatConstants.ALL_SUPER_FORMAT_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case SuperFormatConstants.DELETE_SUPER_FORMAT_SUCCESS:
            return {
                ...state,
                superFormatDelete: action.payload
            };
        case SuperFormatConstants.DELETE_SUPER_FORMAT_ERROR:
            return {
                ...state,
                superFormatDelete: action.payload
            };
        case SuperFormatConstants.ACTIVATE_SUPER_FORMAT_SUCCESS:
            return {
                ...state,
                superFormatActivate: action.payload
            };
        case SuperFormatConstants.ACTIVATE_SUPER_FORMAT_ERROR:
            return {
                ...state,
                superFormatActivate: action.payload
            };
        case SuperFormatConstants.BB_ADD_SUPER_FORMAT_SUCCESS:
            return {
                ...state,
                addSuperFormatSuccess: true,
                addResp: action.payload
            };
        case SuperFormatConstants.BB_ADD_SUPER_FORMAT_ERROR:
            return {
                ...state,
                addSuperFormatSuccess: false,
                ...action.payload
            };
        case SuperFormatConstants.BB_ADD_SUPER_FORMAT_LOADING:
            return {
                ...state,
                addSuperFormatLoading: action.payload
            };
        case SuperFormatConstants.BB_EDIT_SUPER_FORMAT_SUCCESS:
            return {
                ...state,
                editSuperFormatSuccess: true,
                editResp: action.payload
            };
        case SuperFormatConstants.BB_EDIT_SUPER_FORMAT_ERROR:
            return {
                ...state,
                editSuperFormatSuccess: false,
                ...action.payload
            };
        case SuperFormatConstants.BB_GET_USER_SUPER_FORMAT_INIT_DIMENSION:
            return { ...state, allFormatDropDown: { loading: action.payload } };
        case SuperFormatConstants.BB_GET_USER_SUPER_FORMAT_SUCCESS_DIMENSION:
        case SuperFormatConstants.BB_GET_USER_SUPER_FORMAT_ERROR_DIMENSION:
            return { ...state, allFormatDropDown: { data: [...action.payload], loading: false } };
        case SuperFormatConstants.BB_GET_USER_SUPER_FORMAT_INIT:
            return { ...state, dropDown: { loading: action.payload } };
        case SuperFormatConstants.EMPTY_ALL_SUPER_FORMAT:
            return { ...state, dropDown: "" };
        case SuperFormatConstants.BB_GET_USER_SUPER_FORMAT_SUCCESS:
        case SuperFormatConstants.BB_GET_USER_SUPER_FORMAT_ERROR:
            return { ...state, dropDown: { data: [...action.payload], loading: false } };
        case SuperFormatConstants.BB_GET_ALL_SUPER_FORMAT_INIT: return { ...state, all: { loading: true } }
        case SuperFormatConstants.BB_GET_ALL_SUPER_FORMAT_SUCCESS: return { ...state, all: action.payload }
        case SuperFormatConstants.BB_GET_ALL_SUPER_FORMAT_ERROR: return { ...state, all: action.payload }

        case SuperFormatConstants.GET_EDIT_SUPER_FORMAT_INFO_INIT:
            return { ...state, EditModal: { data: "", loading: true } };

        case SuperFormatConstants.GET_EDIT_SUPER_FORMAT_INFO_SUCCESS:
        case SuperFormatConstants.GET_EDIT_SUPER_FORMAT_INFO_ERROR:
            return { ...state, EditModal: { data: action.payload, loading: false } };
        case SuperFormatConstants.EMPTY_EDIT_SUPER_FORMAT_INFO:
            return { ...state, EditModal: { data: action.payload, loading: false } };
        default:
            return state;
    }
}

export const superFormat = combineReducers({
    allSuperFormat
});
