export const TTDRequestConstants = {

    ALL_TTD_REQUEST_LOADING: 'ALL_TTD_REQUEST_LOADING',
    TTD_AUTH_REQUEST_LOADING: 'TTD_AUTH_REQUEST_LOADING',
    TTD_AUTH_REQUEST_SUCCESS: 'TTD_AUTH_REQUEST_SUCCESS',
    TTD_AUTH_REQUEST_ERROR: 'TTD_AUTH_REQUEST_ERROR',

    TTD_ADVERTISER_REQUEST_SUCCESS: 'TTD_ADVERTISER_REQUEST_SUCCESS',
    TTD_ADVERTISER_REQUEST_ERROR: 'TTD_ADVERTISER_REQUEST_ERROR',
    TTD_ADVERTISER_REQUEST_LOADING: 'TTD_ADVERTISER_REQUEST_LOADING',

    TTD_DEPLOY_REQUEST_LOADING: 'TTD_DEPLOY_REQUEST_LOADING',
    TTD_DEPLOY_REQUEST_SUCCESS: 'TTD_DEPLOY_REQUEST_SUCCESS',
    TTD_DEPLOY_REQUEST_ERROR: 'TTD_DEPLOY_REQUEST_ERROR',

    TTD_DEPLOY_FAILED_REQUEST_LOADING: 'TTD_DEPLOY_FAILED_REQUEST_LOADING',
    TTD_DEPLOY_FAILED_REQUEST_SUCCESS: 'TTD_DEPLOY_FAILED_REQUEST_SUCCESS',
    TTD_DEPLOY_FAILED_REQUEST_ERROR: 'TTD_DEPLOY_FAILED_REQUEST_ERROR',

    TTD_AUTH_REQUEST_PASSWORD_LOADING:'TTD_AUTH_REQUEST_PASSWORD_LOADING',
    TTD_AUTH_REQUEST_PASSWORD_SUCCESS:'TTD_AUTH_REQUEST_PASSWORD_SUCCESS',
    TTD_AUTH_REQUEST_PASSWORD_ERROR:'TTD_AUTH_REQUEST_PASSWORD_ERROR'




}