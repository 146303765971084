export const AllCommentConstants = {
    GET_COMMENTS: 'GET_COMMENTS',
    GET_COMMENTS_ERROR: 'GET_COMMENTS_ERROR',

    ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',
    ADD_COMMENT_ERROR: 'ADD_COMMENT_ERROR',

    EDIT_COMMENT_SUCCESS: 'EDIT_COMMENT_SUCCESS',
    EDIT_COMMENT_ERROR: 'EDIT_COMMENT_ERROR',

    DELETE_COMMENT: 'DELETE_COMMENT',
    DELETE_COMMENT_ERROR: 'DELETE_COMMENT_ERROR',

    SEND_TO_CLIENT_SUCCESS: 'SEND_TO_CLIENT_SUCCESS',
    SEND_TO_CLIENT_ERROR: 'SEND_TO_CLIENT_ERROR',
    
    SEND_TO_BIG_SUCCESS: 'SEND_TO_BIG_SUCCESS',
    SEND_TO_BIG_ERROR: 'SEND_TO_BIG_ERROR',
    
    FEEDBACK_CONFIRMED_SUCCESS: 'FEEDBACK_CONFIRMED_SUCCESS',
    FEEDBACK_CONFIRMED_ERROR: 'FEEDBACK_CONFIRMED_ERROR',

    COMMENT_LOADING_STATUS: 'COMMENT_LOADING_STATUS',

    EMPTY_COMMENTS: 'EMPTY_COMMENTS',
};
