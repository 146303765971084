import { TFEConfigConstants } from './tfeConfig.constants';

export const tfeConfig = (state = {}, action) => {
    switch (action.type) {
        case TFEConfigConstants.GET_ALL_TFE_CONFIG_SUCCESS: return { ...state, ...action.payload };
        case TFEConfigConstants.GET_ALL_TFE_CONFIG_ERROR: return { ...state, error: true };

        case TFEConfigConstants.ALL_TFE_CONFIG_LOADING: return { ...state, loading: action.payload };
        case TFEConfigConstants.ALL_TFE_CONFIG_CURRENT_PAGE: return { ...state, page: action.payload };
        case TFEConfigConstants.ALL_TFE_CONFIG_LIMIT_PER_PAGE: return { ...state, limit: action.payload };
        case TFEConfigConstants.ALL_TFE_CONFIG_STATUS_VALUE: return { ...state, status: action.payload };
        case TFEConfigConstants.ALL_TFE_CONFIG_LIMIT: return { ...state, total: action.payload };
        
        case TFEConfigConstants.DELETE_TFE_CONFIG_SUCCESS: return { ...state, deleteStatus: action.payload };
        case TFEConfigConstants.DELETE_TFE_CONFIG_ERROR: return { ...state, deleteStatus: action.payload };
        case TFEConfigConstants.ACTIVATE_TFE_CONFIG_SUCCESS: return { ...state, activateStatus: action.payload };
        case TFEConfigConstants.ACTIVATE_TFE_CONFIG_ERROR: return { ...state, activateStatus: action.payload };
        default:
            return state;
    }
}