import React from 'react'
function VideoPreview(props) {

    const { src, className = "" } = props;

    function playVideo(e){
        e.target.play();
    }
    function pauseVideo(e){
        e.target.pause();
    }


    return (
        <>
            <video className={className} onLoadStart={pauseVideo} 
                    onTouchStart={playVideo} 
                    onTouchEnd={pauseVideo} 
                    onMouseEnter={playVideo} 
                    onMouseOut={pauseVideo} 
                    loop muted playsInline autoPlay>
                <source src={src} />                                
            </video>            
        </>
    )
}

export default VideoPreview