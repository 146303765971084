import { Scope3ReportingConstants } from './reporting.constants';

export const reporting = (state = {}, action) => {
    switch (action.type) {
        case Scope3ReportingConstants.GET_ALL_SCOPE3_REPORTING_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case Scope3ReportingConstants.ALL_SCOPE3_REPORTING_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case Scope3ReportingConstants.GET_ALL_SCOPE3_REPORTING_ERROR:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }
}