import { VerticalsConstants } from './verticals.constants';

export const verticals = (state = {}, action) => {
    switch (action.type) {
        case VerticalsConstants.GET_ALL_SHOWCASE_VERTICALS:
            return {
                ...state,
                ...action.payload
            };

        case VerticalsConstants.ALL_SHOWCASE_VERTICALS_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case VerticalsConstants.GET_ALL_SHOWCASE_VERTICALS_ERROR:
            return {
                ...state,
                error: true
            };
        case VerticalsConstants.ALL_SHOWCASE_VERTICALS_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case VerticalsConstants.ALL_SHOWCASE_VERTICALS_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case VerticalsConstants.ALL_SHOWCASE_VERTICALS_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case VerticalsConstants.ALL_SHOWCASE_VERTICALS_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case VerticalsConstants.ALL_SHOWCASE_VERTICALS_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case VerticalsConstants.DELETE_SHOWCASE_VERTICALS_SUCCESS:
            return {
                ...state,
                verticalsDelete: action.payload
            };
        case VerticalsConstants.DELETE_SHOWCASE_VERTICALS_ERROR:
            return {
                ...state,
                verticalsDelete: action.payload
            };
        case VerticalsConstants.ACTIVATE_SHOWCASE_VERTICALS_SUCCESS:
            return {
                ...state,
                verticalsActivate: action.payload
            };
        case VerticalsConstants.ACTIVATE_SHOWCASE_VERTICALS_ERROR:
            return {
                ...state,
                verticalsActivate: action.payload
            };
        case VerticalsConstants.BB_ADD_SHOWCASE_VERTICALS_SUCCESS:
            return {
                ...state,
                addVerticalsSuccess: true,
                addResp: action.payload
            };
        case VerticalsConstants.BB_ADD_SHOWCASE_VERTICALS_ERROR:
            return {
                ...state,
                addVerticalsSuccess: false,
                ...action.payload
            };
        case VerticalsConstants.BB_ADD_SHOWCASE_VERTICALS_LOADING:
            return {
                ...state,
                addVerticalsLoading: action.payload
            };
        case VerticalsConstants.BB_EDIT_SHOWCASE_VERTICALS_SUCCESS:
            return {
                ...state,
                editVerticalsSuccess: true,
                editResp: action.payload
            };
        case VerticalsConstants.BB_EDIT_SHOWCASE_VERTICALS_ERROR:
            return {
                ...state,
                editVerticalsSuccess: false,
                ...action.payload
            };
        case VerticalsConstants.BB_GET_ACTIVE_SHOWCASE_VERTICALS_INIT:
            return { ...state, dropDown: { loading: action.payload } };
        case VerticalsConstants.BB_GET_ACTIVE_SHOWCASE_VERTICALS_SUCCESS:
        case VerticalsConstants.BB_GET_ACTIVE_SHOWCASE_VERTICALS_ERROR:
            return { ...state, dropDown: { data: [...action.payload], loading: false } };

        default:
            return state;
    }
}
