export const BespokeDictionaryConstants = {
    GET_ALL_BESPOKE_DICTIONARY_PAGINATE_SUCCESS: 'GET_ALL_BESPOKE_DICTIONARY_PAGINATE_SUCCESS',
    GET_ALL_BESPOKE_DICTIONARY_PAGINATE_ERROR: 'GET_ALL_BESPOKE_DICTIONARY_PAGINATE_ERROR',

    BESPOKE_DICTIONARY_CURRENT_PAGE: 'BESPOKE_DICTIONARY_CURRENT_PAGE',
    BESPOKE_DICTIONARY_LIMIT_PER_PAGE: 'BESPOKE_DICTIONARY_LIMIT_PER_PAGE',
    BESPOKE_DICTIONARY_SEARCH_VALUE: 'BESPOKE_DICTIONARY_SEARCH_VALUE',
    BESPOKE_DICTIONARY_STATUS_VALUE: 'BESPOKE_DICTIONARY_STATUS_VALUE',
    BESPOKE_DICTIONARY_TOTAL_COUNT: 'BESPOKE_DICTIONARY_TOTAL_COUNT',
    
    GET_BESPOKE_DICTIONARY_BY_ID: 'GET_BESPOKE_DICTIONARY_BY_ID',
    EMPTY_BESPOKE_DICTIONARY_BY_PROFILE: 'EMPTY_BESPOKE_DICTIONARY_BY_PROFILE',
    GET_BESPOKE_DICTIONARY_BY_ID_ERROR: 'GET_BESPOKE_DICTIONARY_BY_ID_ERROR',

    ADD_BESPOKE_DICTIONARY_SUCCESS: 'ADD_BESPOKE_DICTIONARY_SUCCESS',
    ADD_BESPOKE_DICTIONARY_ERROR: 'ADD_BESPOKE_DICTIONARY_ERROR',

    EDIT_BESPOKE_DICTIONARY_SUCCESS: 'EDIT_BESPOKE_DICTIONARY_SUCCESS',
    EDIT_BESPOKE_DICTIONARY_ERROR: 'EDIT_BESPOKE_DICTIONARY_ERROR',

    ACTIVATE_BESPOKE_DICTIONARY: 'ACTIVATE_BESPOKE_DICTIONARY',
    ACTIVATE_BESPOKE_DICTIONARY_ERROR: 'ACTIVATE_BESPOKE_DICTIONARY_ERROR', 

    DELETE_BESPOKE_DICTIONARY: 'DELETE_BESPOKE_DICTIONARY',
    DELETE_BESPOKE_DICTIONARY_ERROR: 'DELETE_BESPOKE_DICTIONARY_ERROR',
    
    BESPOKE_DICTIONARY_LOADING_STATUS: 'BESPOKE_DICTIONARY_LOADING_STATUS',

    EMPTY_BESPOKE_DICTIONARY: 'EMPTY_BESPOKE_DICTIONARY',
}