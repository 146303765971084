import React from 'react'
import classnames from 'classnames'
import { FormError } from './FormError'
import { notify } from '../utilities/customFunctions'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const TextAreaWithCTC = ({ disabled = false, readOnly = false, field, row = "3", containerClassName = "", form, labelClassName = "", value,
    inputContainerClassName, className = "", label, isHtml = true, placeholder = "", form: { touched, errors },
    isExteranLinkRequired = false
    }) => {

    const styleWithLink = {
        borderTop: '2.5px solid #2a3143', 
        borderLeft: '5px solid #2a3143', 
        borderRadius: '5px'
    };
    const styleWithoutLink = {
        borderRadius: '0 5px 5px 0'
    };
      

    return (
        <div className={containerClassName}>
            {label && <label className={classnames("mb-1", labelClassName)}>{label}</label>}
            <div className={classnames("d-flex flex-row rounded p-0", inputContainerClassName, (value || field.value) ? isExteranLinkRequired ? '' : 'bb-bg_blue-600' : 'bb-bg_disabled')}>

                <textarea
                    value={value || field.value || ""}
                    className={classnames('form-control', className, touched[field.name] && errors[field.name] ? 'error' : '')}
                    rows={row}
                    //{...field}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={e => { form.setFieldValue(field.name, e.target.value) }}
                    disabled={disabled}
                    readOnly={readOnly}
                    placeholder={placeholder}
                />
           
                    
                <div className="d-flex flex-column justify-content-around align-items-center w-100" style={{maxWidth:'60px'}}>
                    { isExteranLinkRequired ?    
                        <a title="Open preview in new tab" 
                            style={{borderBottom: '2.5px solid #2a3143', borderLeft: '5px solid #2a3143', borderRadius: '5px'}}
                            className="h-100 d-flex w-100 justify-content-center align-items-center ripple bb-bg_blue-600 bb-button-cto" 
                            target="_blank" rel="noopener noreferrer" href={value || field.value}>
                                <i className="fas fa-external-link-alt"></i>
                        </a>
                        : null
                    }
                    <CopyToClipboard options={{ format: isHtml ? '' : 'text/plain' }} 
                                    style={isExteranLinkRequired ? styleWithLink : styleWithoutLink}
                                    className={classnames("pl-2 pr-2 d-flex h-100 d-flex w-100 justify-content-center align-items-center ripple bb-bg_blue-600 bb-button-ctc", (value || field.value) ? "cursor-pointer" : "disabled")} text={value || field.value || ""}
                        onCopy={() => { notify('Copied!', "s") }}>                        
                                <span className={classnames("pl-2 pr-2", (value || field.value) ? "cursor-pointer" : "disabled")} title="Click to copy">
                                    <i className={classnames("far fa-copy ", (value || field.value) ? "ctc-element" : "", isExteranLinkRequired ? "fa-1dot5x " : "fa-2x")}></i>
                                </span>
                    </CopyToClipboard> 
                </div>
                <FormError name={field.name} />
            </div>
        </div>

    )
}

export default TextAreaWithCTC