import React, { useState } from 'react';
import Modal from 'react-modal'
import { TagsInput } from '../../../../form-helpers'
import { Field } from 'formik'

function SendEmail(props) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailTo, setEmailTo] = useState([])

    const changeEmail = (emails) => {
        setEmailTo(emails);
    }

    const openModal = (e) => {
        e.stopPropagation();
        let userEmail = [];
        userEmail.push(props.userEmail)

        setIsModalOpen(true);
        setEmailTo(userEmail);

    }
    const closeModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    }

    const renderEmail = () => {
        const { adname, url } = props;

        return (
            <Modal
                isOpen={isModalOpen}
                onRequestClose={(e) => closeModal(e)}
                contentLabel=""
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container"
                ariaHideApp={false}
                bodyOpenClassName="overflow-hidden"
            >
                <div className="bb-modal p-3">
                    <div className="d-flex flex-row justify-content-between">
                        <h5 className="font-weight-bold">Email Preview Link</h5>
                        <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-dark" onClick={(e) => closeModal(e)}>
                        </div>
                    </div>
                    <Field className="form-control"
                        inputContainerClassName="col-9"
                        containerClassName="col-12 p-4 d-flex flex-wrap"
                        labelClassName="col-3"
                        handleChange={changeEmail}
                        label="Email To:"
                        tags={emailTo}
                        name="emailTo"
                        placeholder="Type email(s) here"
                        component={TagsInput} />

                    <div className="d-flex justify-content-end">
                        <button type="button" onClick={(e) => closeModal(e)}
                            className="bb-button p-2 pl-3 pr-3 mr-3 mt-3 text-uppercase">CANCEL</button>
                        <button type="button" onClick={(e) => { props.sendPreviewLink(adname, url, emailTo.toString()); closeModal(e) }}
                            className="bb-button p-2 pl-3 pr-3 mr-3 mt-3 text-uppercase ">SEND</button>
                    </div>
                </div>
            </Modal>
        )
    }

    return (
        <React.Fragment>
            {isModalOpen && renderEmail()}
            {props.emailIcon ?
                <>
                    <button type="button" className="bb-bg_tertiary-20 border-0 text-white rounded w-100 email-button ripple" onClick={(e) => { openModal(e) }}>
                        <img src="/images/icons/email-icon.svg" alt="email" />
                    </button>

                </>
                :
                <button type="button" className="m-2 p-2 bb-bg_primary border-0 text-white rounded" onClick={(e) => { openModal(e) }}>
                    <i className="fas fa-envelope"></i> Email preview link
                </button>
            }

        </React.Fragment>
    )

}

export default SendEmail;