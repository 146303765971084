import { CurationFeeConstants } from './curationFee.constants';

export const curationFee = (state = {}, action) => {
    switch (action.type) {
        case CurationFeeConstants.GET_ALL_CURATION_FEE_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case CurationFeeConstants.ALL_CURATION_FEE_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case CurationFeeConstants.GET_ALL_CURATION_FEE_ERROR:
            return {
                ...state,
                error: true
            };
        case CurationFeeConstants.ALL_CURATION_FEE_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case CurationFeeConstants.ALL_CURATION_FEE_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case CurationFeeConstants.ALL_CURATION_FEE_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case CurationFeeConstants.ALL_CURATION_FEE_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case CurationFeeConstants.ALL_CURATION_FEE_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case CurationFeeConstants.DELETE_CURATION_FEE_SUCCESS:
            return {
                ...state,
                deleteStatus: action.payload
            };
        case CurationFeeConstants.DELETE_CURATION_FEE_ERROR:
            return {
                ...state,
                deleteStatus: action.payload
            };
        case CurationFeeConstants.ACTIVATE_CURATION_FEE_SUCCESS:
            return {
                ...state,
                activateStatus: action.payload
            };
        case CurationFeeConstants.ACTIVATE_CURATION_FEE_ERROR:
            return {
                ...state,
                activateStatus: action.payload
            };
        case CurationFeeConstants.BB_ADD_CURATION_FEE_SUCCESS:
            return {
                ...state,
                addCurationFeeSuccess: true,
                addResp: action.payload
            };
        case CurationFeeConstants.BB_ADD_CURATION_FEE_ERROR:
            return {
                ...state,
                addCurationFeeSuccess: false,
                ...action.payload
            };
        case CurationFeeConstants.BB_EDIT_CURATION_FEE_SUCCESS:
            return {
                ...state,
                editCurationFeeSuccess: true,
                editResp: action.payload
            };
        case CurationFeeConstants.BB_EDIT_CURATION_FEE_ERROR:
            return {
                ...state,
                editCurationFeeSuccess: false,
                ...action.payload
            };
            
        case CurationFeeConstants.EMPTY_CURATION_FEE_PROFILE:
            return { ...state, profile: "" };
        case CurationFeeConstants.GET_CURATION_FEE_BY_ID:
        case CurationFeeConstants.GET_CURATION_FEE_BY_ID_ERROR:
            return { ...state, profile: action.payload };
        default:
            return state;
    }
}
