import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik'

import { Loader } from '../react-overlay-loader';
import { FormInput } from '../form-helpers'
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty'
import { getQueryStringParams, notify } from '../utilities/customFunctions'
import { gotoLogin } from '../utilities/customRoutes'

import { resetPassword, forgotPassword, checkCode } from '../../redux/user/user.actions';
import { connect } from 'react-redux';


const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match!")
        .required('Required'),
});

class ResetPassword extends Component {


    code = "";

    handleFormSubmit(values, actions) {
        this.props.resetPassword({ code: this.code, password: values.password });
    }

    handleForgotSubmit({ emailforgot }) {
        this.props.forgotPassword({ emailforgot })
    }

    componentDidMount() {
        let searchParams = getQueryStringParams(this.props.location.search);

        if (searchParams && searchParams.code) {
            this.code = searchParams.code;
            this.props.checkCode(searchParams.code);
        }
        else {
            gotoLogin();
        }
    }

    componentDidUpdate(prevProps) {
        const { errorMessage } = this.props;
        if (errorMessage !== prevProps.errorMessage) {

                let msg = errorMessage;
                if (msg && msg.includes("~")) {
                    msg = msg.substring(0, msg.indexOf("~"));
                }
                msg && notify(`Oops! ${msg}`, "e");            
        }
    }
    render() {

        const { checking, loading, codeStatus, resetPasswordStatus } = this.props;
        if (codeStatus) {
            return (
                <div className="bb-login-form-container d-flex flex-row w-100 h-100">
                    <Loader loading={loading} />
                    {
                        !resetPasswordStatus ?
                            <div className="h-100 d-flex align-items-center">
                                <Formik
                                    validationSchema={ResetPasswordSchema}
                                    initialValues={{ password: '', confirmPassword: '' }}
                                    onSubmit={this.handleFormSubmit.bind(this)}
                                >{({ dirty, errors, isSubmitting }) => (
                                    <Form className="bb-login-form d-flex flex-column align-items-start">
                                        <img className="logo" src="/images/buddyLogo.svg" alt="" />

                                        <span className='bb-text_small spacer invisible'>.</span>
                                        <p className="bb-text_small">Please enter your new password</p>
                                        <Field containerClassName="w-100 mb-3 input-container" placeholder="New Password" name="password" component={FormInput} type="password" />
                                        <Field containerClassName="w-100 mb-3 input-container" placeholder="Confirm New Password" name="confirmPassword" component={FormInput} type="password" keyPressCheck={false} />
                            
                                        <div className="d-flex flex-row flex-wrap justify-content-between w-100 align-items-center" >
                                            <Link to="/login" className="bb-forgot-password hvr-underline-from-center bb-text_small">Login</Link>
                                            <button type="submit" className="text-uppercase bb-bg_primary bb-signin-btn" disabled={!isEmpty(errors) || !dirty}>
                                                Reset Password</button>
                                        </div>
                                    </Form>
                                )}
                                </Formik>
                            </div>
                            :
                            <React.Fragment>
                                <div className="bb-login-form-container align-items-center d-flex flex-row w-100 h-100">
                                    <div className="bb-login-form d-flex flex-column align-items-start">
                                        <img className="logo" src="/images/buddyLogo.svg" alt="" />
                                        <span className='bb-text_small invisible spacer'>.</span>
                                        <p className="bb-text_small mb-3">Your password has been reset.</p>
                                        <Link to="/login" className="bb-forgot-password hvr-underline-from-center mt-3">Login</Link>
                                    </div>
                                </div>
                            </React.Fragment>
                    }

                </div>
            );
        }
        else {
            return (
                !checking ?
                    <div className="bb-login-form-container align-items-center d-flex flex-row w-100 h-100">
                    <div className="bb-login-form d-flex flex-column align-items-start">
                        <img className="logo" src="/images/buddyLogo.svg" alt="" />
                        <span className='bb-text_small spacer invisible'>.</span>
                        <p className="bb-text_small mb-3">Your code has expired. Please try again.</p>
                        <Link to="/login" className="bb-forgot-password hvr-underline-from-center mt-3">Login</Link>
                    </div>
                </div>
                    : null
            )
        }
    }
}

//export default ResetPassword;

const mapStateToProps = (state) => {
    return {
        errorMessage: state.user.error,
        checking: state.user.resetChecking,
        codeStatus: state.user.codeStatus,
        loading: state.user.loading,
        resetPasswordStatus: state.user.resetPasswordStatus
    }
};

export default (connect(mapStateToProps, { resetPassword, forgotPassword, checkCode })(ResetPassword));


