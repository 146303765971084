import { SupplyConfigurationConstants } from './supplyConfiguration.constants';

export const supplyConfiguration = (state = {}, action) => {
    switch (action.type) {
        case SupplyConfigurationConstants.GET_ALL_SUPPLY_CONFIGURATION_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case SupplyConfigurationConstants.ALL_SUPPLY_CONFIGURATION_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case SupplyConfigurationConstants.GET_ALL_SUPPLY_CONFIGURATION_ERROR:
            return {
                ...state,
                error: true
            };
        case SupplyConfigurationConstants.ALL_SUPPLY_CONFIGURATION_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case SupplyConfigurationConstants.ALL_SUPPLY_CONFIGURATION_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case SupplyConfigurationConstants.ALL_SUPPLY_CONFIGURATION_LIMIT:
            return {
                ...state,
                total: action.payload
            };

            case SupplyConfigurationConstants.EMPTY_SUPPLY_CONFIGURATION_BY_PROFILE: return { ...state, profile: "" }
            case SupplyConfigurationConstants.GET_SUPPLY_CONFIGURATION_BY_ID: return { ...state, profile: action.payload }
            case SupplyConfigurationConstants.GET_SUPPLY_CONFIGURATION_BY_ID_ERROR: return { ...state, profile: action.payload }
    
            case SupplyConfigurationConstants.DELETE_SUPPLY_CONFIGURATION:
            case SupplyConfigurationConstants.DELETE_SUPPLY_CONFIGURATION_ERROR:
                return { ...state, deleteStatus: action.payload }
    
            case SupplyConfigurationConstants.ACTIVATE_SUPPLY_CONFIGURATION:
            case SupplyConfigurationConstants.ACTIVATE_SUPPLY_CONFIGURATION_ERROR:
                return { ...state, activateStatus: action.payload }
    
            case SupplyConfigurationConstants.ADD_SUPPLY_CONFIGURATION_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
            case SupplyConfigurationConstants.ADD_SUPPLY_CONFIGURATION_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
    
            case SupplyConfigurationConstants.EDIT_SUPPLY_CONFIGURATION_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
            case SupplyConfigurationConstants.EDIT_SUPPLY_CONFIGURATION_ERROR: return { ...state, editStatus: false, editResp: action.payload }
        default:
            return state;
    }
}