export const PrebuiltConstants = {
    GET_ALL_PREBUILT_INIT: 'GET_ALL_PREBUILT_INIT',
    GET_ALL_PREBUILT_SUCCESS: 'GET_ALL_PREBUILT_SUCCESS',
    GET_ALL_PREBUILT_ERROR: 'GET_ALL_PREBUILT_ERROR',

    GET_ALL_PREBUILT_PAGINATE_SUCCESS: 'GET_ALL_PREBUILT_PAGINATE_SUCCESS',
    GET_ALL_PREBUILT_PAGINATE_ERROR: 'GET_ALL_PREBUILT_PAGINATE_ERROR',

    PREBUILT_CURRENT_PAGE: 'PREBUILT_CURRENT_PAGE',
    PREBUILT_LIMIT_PER_PAGE: 'PREBUILT_LIMIT_PER_PAGE',
    PREBUILT_SEARCH_VALUE: 'PREBUILT_SEARCH_VALUE',
    PREBUILT_STATUS_VALUE: 'PREBUILT_STATUS_VALUE',
    PREBUILT_TOTAL_COUNT: 'PREBUILT_TOTAL_COUNT',
    
    GET_PREBUILT_BY_ID: 'GET_PREBUILT_BY_ID',
    EMPTY_PREBUILT_BY_PROFILE: 'EMPTY_PREBUILT_BY_PROFILE',
    GET_PREBUILT_BY_ID_ERROR: 'GET_PREBUILT_BY_ID_ERROR',

    ADD_PREBUILT_SUCCESS: 'ADD_PREBUILT_SUCCESS',
    ADD_PREBUILT_ERROR: 'ADD_PREBUILT_ERROR',

    EDIT_PREBUILT_SUCCESS: 'EDIT_PREBUILT_SUCCESS',
    EDIT_PREBUILT_ERROR: 'EDIT_PREBUILT_ERROR',

    ACTIVATE_PREBUILT: 'ACTIVATE_PREBUILT',
    ACTIVATE_PREBUILT_ERROR: 'ACTIVATE_PREBUILT_ERROR', 

    DELETE_PREBUILT: 'DELETE_PREBUILT',
    DELETE_PREBUILT_ERROR: 'DELETE_PREBUILT_ERROR',
    
    PREBUILT_LOADING_STATUS: 'PREBUILT_LOADING_STATUS',

    EMPTY_PREBUILT: 'EMPTY_PREBUILT',
}