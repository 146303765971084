
import { AllSouthpoleConstants } from './southpole.constants';

export const southpole = (state = {}, action) => {
    switch (action.type) {
        case AllSouthpoleConstants.GET_ALL_PROJECT_INIT: return { ...state, all: { loading: true } }
        case AllSouthpoleConstants.GET_ALL_PROJECT_SUCCESS: return { ...state, all: action.payload }
        case AllSouthpoleConstants.GET_ALL_PROJECT_ERROR: return { ...state, all: action.payload }
        case AllSouthpoleConstants.PROJECT_LOADING_STATUS: return { ...state, loading: action.payload }
        case AllSouthpoleConstants.CHECKOUT_SOUTHPOLE: return { ...state, checkoutResp: action.payload, checkoutStatus: true }
        case AllSouthpoleConstants.CHECKOUT_SOUTHPOLE_ERROR: return { ...state, checkoutResp: action.payload, checkoutStatus: false}
        case AllSouthpoleConstants.GET_ORDER_BY_ID:
            return { ...state, order: action.payload }
        case AllSouthpoleConstants.GET_ORDER_BY_ID_ERROR:
            return { ...state, order: action.payload }
        case AllSouthpoleConstants.EMPTY_ORDER_BY_ID:
            return { ...state, order: action.payload }
        default:
            return state;
    }
}
