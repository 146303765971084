import React from 'react'
import classnames from 'classnames'
import { FormError } from '../FormError'

import Toggle from 'react-toggle'
import './toggle.scss'
import Tooltip from '../../utilities/Tooltip'

class FormToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        // eslint-disable-next-line
        const { disabled = false, field, span = "", containerClassName = "", checked = "", form, unchecked = "", defaultChecked = true, labelClassName = "", inputContainerClassName = "", className = "", label, form: { touched, errors }, isAdmin = false} = this.props
        return (
            <div className={containerClassName}>
                {label && <label className={classnames("mb-1", labelClassName)}>{label} {isAdmin ?

                    <Tooltip default_id="star" light={true} className="m-0 cursor-pointer" label={""} iconname="fas fa-star ml-1 ">
                        <div className="p-2 text-left maxWidth-300"><span>This setting is available to admins only.</span></div>
                    </Tooltip>
                    : null}</label>}
                <div className={inputContainerClassName}>
                    <Toggle
                        // eslint-disable-next-line
                        checked={(field.value == "true" || field.value == true) ? true : false}
                        // defaultChecked={defaultChecked}
                        className={classnames(className, touched[field.name] && errors[field.name] ? 'error' : '')}
                        disabled={disabled}
                        onChange={async (option) => {
                            // eslint-disable-next-line
                            await form.setFieldValue(field.name, field.value == "true" || field.value == true ? false : true)
                            if (typeof this.props.showPreview === 'function') {
                                this.props.showPreview(this.props.values)
                            }
                        }}
                        icons={{
                            checked: checked,
                            unchecked: unchecked,
                        }}
                    />
                    <span className="m-2" style={{ position: "absolute" }}>{span}</span>

                    <FormError name={field.name} />

                </div>
            </div>
        )
    }

}
export default FormToggle