import { DimensionConstants } from './dimension.constants';
import { combineReducers } from 'redux';

const allDimension = (state = {}, action) => {
    switch (action.type) {
        case DimensionConstants.GET_ALL_DIMENSION_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case DimensionConstants.ALL_DIMENSION_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case DimensionConstants.GET_ALL_DIMENSION_ERROR:
            return {
                ...state,
                error: true
            };
        case DimensionConstants.ALL_DIMENSION_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case DimensionConstants.ALL_DIMENSION_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case DimensionConstants.ALL_DIMENSION_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case DimensionConstants.ALL_DIMENSION_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case DimensionConstants.ALL_DIMENSION_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case DimensionConstants.DELETE_DIMENSION_SUCCESS:
            return {
                ...state,
                dimensionDelete: action.payload
            };
        case DimensionConstants.DELETE_DIMENSION_ERROR:
            return {
                ...state,
                dimensionDelete: action.payload
            };
        case DimensionConstants.ACTIVATE_DIMENSION_SUCCESS:
            return {
                ...state,
                dimensionActivate: action.payload
            };
        case DimensionConstants.ACTIVATE_DIMENSION_ERROR:
            return {
                ...state,
                dimensionActivate: action.payload
            };
        case DimensionConstants.BB_ADD_DIMENSION_SUCCESS:
            return {
                ...state,
                addDimensionSuccess: true,
                addResp: action.payload
            };
        case DimensionConstants.BB_ADD_DIMENSION_ERROR:
            return {
                ...state,
                addDimensionSuccess: false,
                ...action.payload
            };
        case DimensionConstants.BB_ADD_DIMENSION_LOADING:
            return {
                ...state,
                addDimensionLoading: action.payload
            };
            case DimensionConstants.BB_EDIT_DIMENSION_SUCCESS:
                return {
                    ...state,
                    editDimensionSuccess: true,
                    editResp: action.payload
                };
            case DimensionConstants.BB_EDIT_DIMENSION_ERROR:
                return {
                    ...state,
                    editDimensionSuccess: false,
                    ...action.payload
                };
        case DimensionConstants.BB_GET_USER_DIMENSION_INIT:
            return { ...state, dropDown: { loading: action.payload } };
        case DimensionConstants.EMPTY_ALL_DIMENSION:
            return { ...state, dropDown: "" };
        case DimensionConstants.BB_GET_USER_DIMENSION_SUCCESS:
        case DimensionConstants.BB_GET_USER_DIMENSION_ERROR:
            return { ...state, dropDown: { data: [...action.payload], loading: false } };
        case DimensionConstants.BB_GET_ALL_DIMENSION_INIT: return { ...state, all: {loading: true} }
        case DimensionConstants.BB_GET_ALL_DIMENSION_SUCCESS: return { ...state, all: action.payload }
        case DimensionConstants.BB_GET_ALL_DIMENSION_ERROR:  return { ...state, all: action.payload }

        case DimensionConstants.GET_EDIT_DIMENSION_INFO_INIT:
                return { ...state, EditModal: { data: "", loading: true } };

        case DimensionConstants.GET_EDIT_DIMENSION_INFO_SUCCESS:
        case DimensionConstants.GET_EDIT_DIMENSION_INFO_ERROR:
            return { ...state, EditModal: { data: action.payload, loading: false } };
        case DimensionConstants.EMPTY_EDIT_DIMENSION_INFO:
            return { ...state, EditModal: { data: action.payload, loading: false } };
        default:
            return state;
    }
}

export const dimension = combineReducers({
    allDimension
});
