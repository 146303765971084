import { DominosBasketSizeConstants } from "./dominosBasketSize.constants";

export const dominosBasketSize = (state = {}, action) => {
    switch (action.type) {

        case DominosBasketSizeConstants.GET_ALL_DOMINOS_BASKET_SIZE_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case DominosBasketSizeConstants.GET_ALL_DOMINOS_BASKET_SIZE_PAGINATE_ERROR: return { ...state, ...action.payload }

        case DominosBasketSizeConstants.EMPTY_DOMINOS_BASKET_SIZE_BY_PROFILE: return { ...state, profile: "" }
        case DominosBasketSizeConstants.GET_DOMINOS_BASKET_SIZE_BY_ID: return { ...state, profile: action.payload }
        case DominosBasketSizeConstants.GET_DOMINOS_BASKET_SIZE_BY_ID_ERROR: return { ...state, profile: action.payload }

        case DominosBasketSizeConstants.DOMINOS_BASKET_SIZE_CURRENT_PAGE: return { ...state, page: action.payload }
        case DominosBasketSizeConstants.DOMINOS_BASKET_SIZE_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case DominosBasketSizeConstants.DOMINOS_BASKET_SIZE_SEARCH_VALUE: return { ...state, search: action.payload }
        case DominosBasketSizeConstants.DOMINOS_BASKET_SIZE_STATUS_VALUE: return { ...state, status: action.payload }
        case DominosBasketSizeConstants.DOMINOS_BASKET_SIZE_TOTAL_COUNT: return { ...state, total: action.payload }
        case DominosBasketSizeConstants.DOMINOS_BASKET_SIZE_LOADING_STATUS: return { ...state, loading: action.payload }

        case DominosBasketSizeConstants.DELETE_DOMINOS_BASKET_SIZE:
        case DominosBasketSizeConstants.DELETE_DOMINOS_BASKET_SIZE_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case DominosBasketSizeConstants.ACTIVATE_DOMINOS_BASKET_SIZE:
        case DominosBasketSizeConstants.ACTIVATE_DOMINOS_BASKET_SIZE_ERROR:
            return { ...state, activateStatus: action.payload }

        case DominosBasketSizeConstants.ADD_DOMINOS_BASKET_SIZE_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case DominosBasketSizeConstants.ADD_DOMINOS_BASKET_SIZE_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case DominosBasketSizeConstants.EDIT_DOMINOS_BASKET_SIZE_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case DominosBasketSizeConstants.EDIT_DOMINOS_BASKET_SIZE_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
