import { AllOffsetEmissionConstants } from './offsetEmission.constants';

export const offsetEmission = (state = {}, action) => {
    switch (action.type) {
        case AllOffsetEmissionConstants.GET_DEAL_EMISSION:
        case AllOffsetEmissionConstants.GET_DEAL_EMISSION_ERROR:
        case AllOffsetEmissionConstants.GET_OFFSET_CERTIFICATE:
        case AllOffsetEmissionConstants.GET_OFFSET_CERTIFICATE_ERROR:
        case AllOffsetEmissionConstants.GET_OFFSET_COMMITTED:
        case AllOffsetEmissionConstants.GET_OFFSET_COMMITTED_BY_ID:
        case AllOffsetEmissionConstants.GET_OFFSET_CERTIFICATE_BY_ID:
        case AllOffsetEmissionConstants.GET_OFFSET_COMMITTED_ERROR:
            return { ...state, ...action.payload };

        case AllOffsetEmissionConstants.EMPTY_DEAL_EMISSION:
           return { ...state, data: "" };
        case AllOffsetEmissionConstants.DEAL_EMISSION_LOADING:
            return { ...state, loading: action.payload };
            
        case AllOffsetEmissionConstants.EMPTY_OFFSET_CERTIFICATE:
            return { ...state, offsetCertificateData: "" };
        case AllOffsetEmissionConstants.OFFSET_CERTIFICATE_LOADING:
            return { ...state, loadingOffsetCertificate: action.payload };

        case AllOffsetEmissionConstants.EMPTY_OFFSET_COMMITTED:
            return { ...state, offsetCommittedData: "" };
        case AllOffsetEmissionConstants.OFFSET_COMMITTED_LOADING:
            return { ...state, loadingOffsetCommitted: action.payload };
    
        case AllOffsetEmissionConstants.EMPTY_OFFSET_COMMITTED_BY_ID:
            return { ...state, offsetCommittedIDData: "" };
        case AllOffsetEmissionConstants.OFFSET_COMMITTED_BY_ID_LOADING:
            return { ...state, loadingOffsetCommittedID: action.payload };
        
        case AllOffsetEmissionConstants.EMPTY_OFFSET_CERTIFICATE_BY_ID: return { ...state, offsetCertificateIDData: "" };
        case AllOffsetEmissionConstants.OFFSET_CERTIFICATE_BY_ID_LOADING: return { ...state, loadingOffsetCertificateID: action.payload };

        case AllOffsetEmissionConstants.CREATE_COMMIT_OFFSET_SUCCESS: return { ...state, commitOffsetStatus: true, commitOffsetResp: action.payload }
        case AllOffsetEmissionConstants.CREATE_COMMIT_OFFSET_ERROR: return { ...state, commitOffsetStatus: false, commitOffsetResp: action.payload }
        case AllOffsetEmissionConstants.CREATE_COMMIT_OFFSET_LOADING: return { ...state, loadingOffsetCommit: action.payload }

        case AllOffsetEmissionConstants.SEND_EMAIL_CERT_COMPLETED:
            return { ...state, emailCertCompletedStatus: true, emailCertCompletedResp: action.payload }
        case AllOffsetEmissionConstants.SEND_EMAIL_CERT_COMPLETED_ERROR:
            return { ...state, emailCertCompletedStatus: false, emailCertCompletedResp: action.payload }
            
        case AllOffsetEmissionConstants.SEND_EMAIL_REPORT_COMPLETED:
            return { ...state, emailCompletedStatus: true, emailCompletedResp: action.payload }
        case AllOffsetEmissionConstants.SEND_EMAIL_REPORT_COMPLETED_ERROR:
            return { ...state, emailCompletedStatus: false, emailCompletedResp: action.payload }
            
        case AllOffsetEmissionConstants.SEND_EMAIL_REPORT_COMMITTED:
            return { ...state, emailCommittedStatus: true, emailCommittedResp: action.payload }
        case AllOffsetEmissionConstants.SEND_EMAIL_REPORT_COMMITTED_ERROR:
            return { ...state, emailCommittedStatus: false, emailCommittedResp: action.payload }

        case AllOffsetEmissionConstants.DOWNLOAD_REPORT_COMMITTED_LOADING: return { ...state, loadingReportCommited: action.payload }
        case AllOffsetEmissionConstants.DOWNLOAD_REPORT_COMPLETED_LOADING: return { ...state, loadingReportCompleted: action.payload }

        case AllOffsetEmissionConstants.CREATE_OFFSET_ORDER: return { ...state, createOrder: true, createOrderResp: action.payload }
        case AllOffsetEmissionConstants.CREATE_OFFSET_ORDER_ERROR: return { ...state, createOrder: false, createOrderResp: action.payload }

        default:
            return state;
    }
}
