import React from 'react'
import classnames from 'classnames'
import { FormError } from './FormError'
import Tooltip from '../utilities/Tooltip'

const TextArea = ({ disabled = false, onChange, field, row = "3", containerClassName = "", form, labelClassName = "", inputContainerClassName = "", className = "", textAreaPrefix = "",
    label, placeholder = "Write here...", isOptional = false, form: { touched, errors }, bottomTextClick = false, bottomText = false, topTextClick = false, topText = false, tooltip=false,
    onBlurTextArea = false, macros = "", light = false, maxLength = undefined, iconname = "fa-info-circle"
}) => {
    return (
        <div className={containerClassName}>
            {(label && !tooltip) && <label className={classnames("mb-1", labelClassName)}>{label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}</label>}
            {tooltip && 
                <Tooltip light={light} className={classnames("mb-1 cursor-pointer", labelClassName, "pt-1")} label={label} htmlFor={field.name} iconname={iconname}>
                    <div className="p-2 text-left maxWidth-mc"><span>{tooltip}</span></div>
                </Tooltip>
            }
            <div className={inputContainerClassName}>
                {topText && !disabled ? <p className="text-right  cursor-pointer m-0  hvr-underline-from-center pl-0 bb-text_small  max-w-500" onClick={topTextClick ? () => topTextClick() : null}><i className="fas fa-trash-alt"></i></p> : null}
                <textarea
                    className={classnames('form-control', className, touched[field.name] && errors[field.name] ? 'error' : '')}
                    rows={row} maxLength={maxLength}
                    placeholder={placeholder}
                    disabled={disabled}
                    // onPaste={(e) => handlePaste(e, textAreaPrefix, form.setFieldValue, field)}
                    onChange={e => {
                        // var str = e.target.value;
                        // if (textAreaPrefix) {
                        //     if (!e.target.value.includes(textAreaPrefix)) {
                        //         str = ""
                        //         str = textAreaPrefix + str
                        //     }
                        // }
                        form.setFieldValue(field.name, e.target.value); 
                        if (onChange) {
                            onChange(e, form.setFieldValue);
                        }
                    }}
                    onBlur={ async e => {
                        await form.setFieldTouched(field.name, true);
                        if (typeof onBlurTextArea === 'function') {
                           await onBlurTextArea(field.value, field.name);
                        }
                    }}
                    value={field.value ? field.value : ""}
                />
                <div className="d-flex justify-content-between">
                    <div>
                        <FormError name={field.name} />
                    </div>
                    {maxLength && <div className='text-right bb-text_small bb-color_disabled mt-1'>{field.value?.length || 0} / {maxLength} characters</div>}
                </div>
                <div className="d-flex flex-row justify-content-between max-w-500">             
                    {macros ? 
                    <Tooltip 
                        light={light}                    
                        tooltipClassName={"bb-bg_budster"}
                        default_id={Date.now()}
                        className="cursor-pointer bb-text_small bb-color_disabled font-weight-bold bb-wmc" label={"See Macro"} htmlFor={""}  showIcon = {false}>
                        <div className="p-2 text-left maxWidth-300 bb-wmc"><span>{macros}</span></div>
                    </Tooltip>
                    : null
                    }
                    {bottomText && !disabled  ? <p className="text-right bb-color_secondary-blue cursor-pointer m-0  hvr-underline-from-center pl-0 bb-text_small max-w-500" onClick={bottomTextClick ? () => bottomTextClick() : null}>{bottomText}</p> : null}
                </div>
            </div>
        </div>
    )
}

export default TextArea