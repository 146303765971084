export const PrimoBuilderConstants = {

    PRIMO_BUILDER_CREATIVE_LOADING: 'PRIMO_BUILDER_CREATIVE_LOADING',
    PRIMO_BUILDER_NEW_CREATIVE_SUCCESS: 'PRIMO_BUILDER_NEW_CREATIVE_SUCCESS',
    PRIMO_BUILDER_NEW_CREATIVE_ERROR: 'PRIMO_BUILDER_NEW_CREATIVE_ERROR',

    PRIMO_BUILDER_UNMOUNT: 'PRIMO_BUILDER_UNMOUNT',

    PRIMO_BUILDER_ASSET_LOADING: 'PRIMO_BUILDER_ASSET_LOADING',
    PRIMO_BUILDER_ADD_ASSET_SUCCESS: 'PRIMO_BUILDER_ADD_ASSET_SUCCESS',
    PRIMO_BUILDER_ADD_ASSET_ERROR: 'PRIMO_BUILDER_ADD_ASSET_ERROR',

    PRIMO_BUILDER_PREVIEW_LOADING: 'PRIMO_BUILDER_PREVIEW_LOADING',
    PRIMO_GENERATE_PREVIEW_SUCCESS: 'PRIMO_GENERATE_PREVIEW_SUCCESS',
    PRIMO_GENERATE_PREVIEW_ERROR: 'PRIMO_GENERATE_PREVIEW_ERROR',

    PRIMO_GET_TAG_SUCCESS: 'PRIMO_GET_TAG_SUCCESS',
    PRIMO_GET_TAG_ERROR: 'PRIMO_GET_TAG_ERROR',

    PRIMO_BUILDER_REMOVE_ASSET_SUCCESS: 'PRIMO_BUILDER_REMOVE_ASSET_SUCCESS',
    PRIMO_BUILDER_REMOVE_ASSET_ERROR: 'PRIMO_BUILDER_REMOVE_ASSET_ERROR',

    INIT_PRIMO_BUILDER_ADD:'INIT_PRIMO_BUILDER_ADD',

    PRIMO_BUILDER_UPDATE_SUCCESS:'PRIMO_BUILDER_UPDATE_SUCCESS',
    PRIMO_BUILDER_UPDATE_ERROR:'PRIMO_BUILDER_UPDATE_ERROR',

    PRIMO_STATUS_LOG_INIT:'PRIMO_STATUS_LOG_INIT',
    PRIMO_STATUS_LOG_SUCCESS:'PRIMO_STATUS_LOG_SUCCESS',
    PRIMO_STATUS_LOG_ERROR:'PRIMO_STATUS_LOG_ERROR'



};
