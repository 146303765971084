import { useSpring, animated } from '@react-spring/web'

export const AnimatedNumber = ({n, d = 0}) => {
    const { number } = useSpring({
        from: {number: 0 },
        number: n,
        delay: 0,
        config: { mass: 1, tension: 100, friction: 10 }
    });
    return <animated.div>{number.to((n) => n.toFixed(d))}</animated.div>
}