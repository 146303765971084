import { combineReducers } from 'redux';

import { CuratedMedia } from "./curated-media/c-media.reducer";
import { CuratedAd } from "./curated-ad/c-ad.reducer";
import { Contextual } from "./contextual/contextual-media.reducer";
import { AddOn } from "./addon/addon.reducer";

export const audiences = combineReducers({
    
    CuratedAd,
    CuratedMedia,
    Contextual,
    AddOn

});
