import { PrebuiltGMPConstants } from "./prebuiltGMP.constants";

export const prebuiltMediasGMP = (state = {}, action) => {
    switch (action.type) {
        case PrebuiltGMPConstants.GET_ALL_PREBUILT_GMP_INIT: return { ...state, all: { loading: true } }
        case PrebuiltGMPConstants.GET_ALL_PREBUILT_GMP_SUCCESS: return { ...state, all: action.payload }
        case PrebuiltGMPConstants.GET_ALL_PREBUILT_GMP_ERROR: return { ...state, all: action.payload }

        case PrebuiltGMPConstants.GET_ALL_PREBUILT_GMP_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case PrebuiltGMPConstants.GET_ALL_PREBUILT_GMP_PAGINATE_ERROR: return { ...state, ...action.payload }

        case PrebuiltGMPConstants.EMPTY_PREBUILT_GMP_BY_PROFILE: return { ...state, profile: "" }
        case PrebuiltGMPConstants.GET_PREBUILT_GMP_BY_ID: return { ...state, profile: action.payload }
        case PrebuiltGMPConstants.GET_PREBUILT_GMP_BY_ID_ERROR: return { ...state, profile: action.payload }

        case PrebuiltGMPConstants.PREBUILT_GMP_CURRENT_PAGE: return { ...state, page: action.payload }

        case PrebuiltGMPConstants.PREBUILT_GMP_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case PrebuiltGMPConstants.PREBUILT_GMP_SEARCH_VALUE: return { ...state, search: action.payload }

        case PrebuiltGMPConstants.PREBUILT_GMP_STATUS_VALUE: return { ...state, status: action.payload }

        case PrebuiltGMPConstants.PREBUILT_GMP_TOTAL_COUNT: return { ...state, total: action.payload }

        case PrebuiltGMPConstants.PREBUILT_GMP_LOADING_STATUS: return { ...state, loading: action.payload }

        case PrebuiltGMPConstants.DELETE_PREBUILT_GMP:
        case PrebuiltGMPConstants.DELETE_PREBUILT_GMP_ERROR:
            return { ...state, deleteStatus: action.payload }

            
        case PrebuiltGMPConstants.ACTIVATE_PREBUILT_GMP:
        case PrebuiltGMPConstants.ACTIVATE_PREBUILT_GMP_ERROR:
            return { ...state, activateStatus: action.payload }

        case PrebuiltGMPConstants.ADD_PREBUILT_GMP_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case PrebuiltGMPConstants.ADD_PREBUILT_GMP_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case PrebuiltGMPConstants.EDIT_PREBUILT_GMP_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case PrebuiltGMPConstants.EDIT_PREBUILT_GMP_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
