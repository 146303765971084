export const SegmentPricingConstants = {

    GET_ALL_SEGMENT_PRICING_PAGINATE_SUCCESS: 'GET_ALL_SEGMENT_PRICING_PAGINATE_SUCCESS',
    GET_ALL_SEGMENT_PRICING_PAGINATE_ERROR: 'GET_ALL_SEGMENT_PRICING_PAGINATE_ERROR',

    SEGMENT_PRICING_CURRENT_PAGE: 'SEGMENT_PRICING_CURRENT_PAGE',
    SEGMENT_PRICING_LIMIT_PER_PAGE: 'SEGMENT_PRICING_LIMIT_PER_PAGE',
    SEGMENT_PRICING_SEARCH_VALUE: 'SEGMENT_PRICING_SEARCH_VALUE',
    SEGMENT_PRICING_STATUS_VALUE: 'SEGMENT_PRICING_STATUS_VALUE',
    SEGMENT_PRICING_TOTAL_COUNT: 'SEGMENT_PRICING_TOTAL_COUNT',
    
    GET_SEGMENT_PRICING_BY_ID: 'GET_SEGMENT_PRICING_BY_ID',
    EMPTY_SEGMENT_PRICING_BY_PROFILE: 'EMPTY_SEGMENT_PRICING_BY_PROFILE',
    GET_SEGMENT_PRICING_BY_ID_ERROR: 'GET_SEGMENT_PRICING_BY_ID_ERROR',

    ADD_SEGMENT_PRICING_SUCCESS: 'ADD_SEGMENT_PRICING_SUCCESS',
    ADD_SEGMENT_PRICING_ERROR: 'ADD_SEGMENT_PRICING_ERROR',

    EDIT_SEGMENT_PRICING_SUCCESS: 'EDIT_SEGMENT_PRICING_SUCCESS',
    EDIT_SEGMENT_PRICING_ERROR: 'EDIT_SEGMENT_PRICING_ERROR',

    ACTIVATE_SEGMENT_PRICING: 'ACTIVATE_SEGMENT_PRICING',
    ACTIVATE_SEGMENT_PRICING_ERROR: 'ACTIVATE_SEGMENT_PRICING_ERROR', 

    DELETE_SEGMENT_PRICING: 'DELETE_SEGMENT_PRICING',
    DELETE_SEGMENT_PRICING_ERROR: 'DELETE_SEGMENT_PRICING_ERROR',
    
    SEGMENT_PRICING_LOADING_STATUS: 'SEGMENT_PRICING_LOADING_STATUS',

    EMPTY_SEGMENT_PRICING: 'EMPTY_SEGMENT_PRICING',
}