import { AllPendingEmissionConstants } from './pendingEmission.constants';

export const pendingEmission = (state = {}, action) => {
    switch (action.type) {
        case AllPendingEmissionConstants.EMPTY_PENDING_EMISSION:
           return { ...state, data: "" };
        case AllPendingEmissionConstants.GET_PENDING_EMISSION:
            return { ...state, ...action.payload};

        case AllPendingEmissionConstants.PENDING_EMISSION_LOADING:
            return { ...state, loading: action.payload };
            
        case AllPendingEmissionConstants.GET_PENDING_EMISSION_ERROR:
            return { ...state, ...action.payload};

        default:
            return state;
    }
}
