export const AddonsConstants = {
    GET_ALL_ADDONS_INIT: 'GET_ALL_ADDONS_INIT',
    GET_ALL_ADDONS_SUCCESS: 'GET_ALL_ADDONS_SUCCESS',
    GET_ALL_ADDONS_ERROR: 'GET_ALL_ADDONS_ERROR',

    GET_ALL_ADDONS_PAGINATE_SUCCESS: 'GET_ALL_ADDONS_PAGINATE_SUCCESS',
    GET_ALL_ADDONS_PAGINATE_ERROR: 'GET_ALL_ADDONS_PAGINATE_ERROR',

    ADDONS_CURRENT_PAGE: 'ADDONS_CURRENT_PAGE',
    ADDONS_LIMIT_PER_PAGE: 'ADDONS_LIMIT_PER_PAGE',
    ADDONS_SEARCH_VALUE: 'ADDONS_SEARCH_VALUE',
    ADDONS_STATUS_VALUE: 'ADDONS_STATUS_VALUE',
    ADDONS_TOTAL_COUNT: 'ADDONS_TOTAL_COUNT',
    
    GET_ADDONS_BY_ID: 'GET_ADDONS_BY_ID',
    EMPTY_ADDONS_BY_PROFILE: 'EMPTY_ADDONS_BY_PROFILE',
    GET_ADDONS_BY_ID_ERROR: 'GET_ADDONS_BY_ID_ERROR',

    ADD_ADDONS_SUCCESS: 'ADD_ADDONS_SUCCESS',
    ADD_ADDONS_ERROR: 'ADD_ADDONS_ERROR',

    EDIT_ADDONS_SUCCESS: 'EDIT_ADDONS_SUCCESS',
    EDIT_ADDONS_ERROR: 'EDIT_ADDONS_ERROR',

    ACTIVATE_ADDONS: 'ACTIVATE_ADDONS',
    ACTIVATE_ADDONS_ERROR: 'ACTIVATE_ADDONS_ERROR', 

    DELETE_ADDONS: 'DELETE_ADDONS',
    DELETE_ADDONS_ERROR: 'DELETE_ADDONS_ERROR',
    
    ADDONS_LOADING_STATUS: 'ADDONS_LOADING_STATUS',

    EMPTY_ADDONS: 'EMPTY_ADDONS',
}