import { VendorStatusConstants } from './status.constants';

export const status = (state = {}, action) => {
    switch (action.type) {
        case VendorStatusConstants.GET_ALL_VENDOR_STATUS_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case VendorStatusConstants.ALL_VENDOR_STATUS_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case VendorStatusConstants.GET_ALL_VENDOR_STATUS_ERROR:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }
}