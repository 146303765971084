import { FaqsConstants } from './faq.constants';

export const faqs = (state = {}, action) => {
    switch (action.type) {
        case FaqsConstants.GET_ALL_FAQS:
            return {
                ...state,
                ...action.payload
            };

        case FaqsConstants.ALL_FAQS_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case FaqsConstants.GET_ALL_FAQS_ERROR:
            return {
                ...state,
                error: true
            };
        case FaqsConstants.ALL_FAQS_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case FaqsConstants.ALL_FAQS_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case FaqsConstants.ALL_FAQS_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case FaqsConstants.ALL_FAQS_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case FaqsConstants.ALL_FAQS_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case FaqsConstants.DELETE_FAQS_SUCCESS:
            return {
                ...state,
                faqsDelete: action.payload
            };
        case FaqsConstants.DELETE_FAQS_ERROR:
            return {
                ...state,
                faqsDelete: action.payload
            };
        case FaqsConstants.ACTIVATE_FAQS_SUCCESS:
            return {
                ...state,
                faqsActivate: action.payload
            };
        case FaqsConstants.ACTIVATE_FAQS_ERROR:
            return {
                ...state,
                faqsActivate: action.payload
            };
        case FaqsConstants.BB_ADD_FAQS_SUCCESS:
            return {
                ...state,
                addFaqsSuccess: true,
                addResp: action.payload
            };
        case FaqsConstants.BB_ADD_FAQS_ERROR:
            return {
                ...state,
                addFaqsSuccess: false,
                ...action.payload
            };
        case FaqsConstants.BB_ADD_FAQS_LOADING:
            return {
                ...state,
                addFaqsLoading: action.payload
            };
        case FaqsConstants.BB_EDIT_FAQS_SUCCESS:
            return {
                ...state,
                editFaqsSuccess: true,
                editResp: action.payload
            };
        case FaqsConstants.BB_EDIT_FAQS_ERROR:
            return {
                ...state,
                editFaqsSuccess: false,
                ...action.payload
            };
        case FaqsConstants.BB_GET_ACTIVE_FAQS_INIT:
            return { ...state, dropDown: { loading: action.payload } };
        case FaqsConstants.BB_GET_ACTIVE_FAQS_SUCCESS:
        case FaqsConstants.BB_GET_ACTIVE_FAQS_ERROR:
            return { ...state, dropDown: { data: [...action.payload], loading: false } };
            
        case FaqsConstants.GET_FAQ_BY_ID:
            return { ...state, profile: null };
        case FaqsConstants.GET_FAQ_BY_ID_SUCCESS:
        case FaqsConstants.GET_FAQ_BY_ID_ERROR:
            return { ...state, profile: action.payload};

        default:
            return state;
    }
}
