export const ShowcaseCreativesConstants = {

    GET_ALL_SHOWCASE_CREATIVES_INIT: 'GET_ALL_SHOWCASE_CREATIVES_INIT',
    GET_ALL_SHOWCASE_CREATIVES_SUCCESS: 'GET_ALL_SHOWCASE_CREATIVES_SUCCESS',
    GET_ALL_SHOWCASE_CREATIVES_ERROR: 'GET_ALL_SHOWCASE_CREATIVES_ERROR',

    GET_ALL_SHOWCASE_CREATIVES_PAGINATE_SUCCESS: 'GET_ALL_SHOWCASE_CREATIVES_PAGINATE_SUCCESS',
    GET_ALL_SHOWCASE_CREATIVES_PAGINATE_ERROR: 'GET_ALL_SHOWCASE_CREATIVES_PAGINATE_ERROR',

    SHOWCASE_CREATIVES_CURRENT_PAGE: 'SHOWCASE_CREATIVES_CURRENT_PAGE',
    SHOWCASE_CREATIVES_LIMIT_PER_PAGE: 'SHOWCASE_CREATIVES_LIMIT_PER_PAGE',
    SHOWCASE_CREATIVES_SEARCH_VALUE: 'SHOWCASE_CREATIVES_SEARCH_VALUE',
    SHOWCASE_CREATIVES_STATUS_VALUE: 'SHOWCASE_CREATIVES_STATUS_VALUE',
    SHOWCASE_CREATIVES_TOTAL_COUNT: 'SHOWCASE_CREATIVES_TOTAL_COUNT',

    GET_SHOWCASE_CREATIVES_BY_ID: "GET_SHOWCASE_CREATIVES_BY_ID",
    EMPTY_SHOWCASE_CREATIVES_BY_PROFILE: "EMPTY_SHOWCASE_CREATIVES_BY_PROFILE",
    GET_SHOWCASE_CREATIVES_BY_ID_ERROR: "GET_SHOWCASE_CREATIVES_BY_ID_ERROR",

    EDIT_SHOWCASE_CREATIVES_SUCCESS: "EDIT_SHOWCASE_CREATIVES_SUCCESS",
    EDIT_SHOWCASE_CREATIVES_ERROR: "EDIT_SHOWCASE_CREATIVES_ERROR",

    ADD_SHOWCASE_CREATIVES_SUCCESS: "ADD_SHOWCASE_CREATIVES_SUCCESS",
    ADD_SHOWCASE_CREATIVES_ERROR: "ADD_SHOWCASE_CREATIVES_ERROR",

    DELETE_SHOWCASE_CREATIVES: 'DELETE_SHOWCASE_CREATIVES',
    DELETE_SHOWCASE_CREATIVES_ERROR: 'DELETE_SHOWCASE_CREATIVES_ERROR',

    ACTIVATE_SHOWCASE_CREATIVES: 'ACTIVATE_SHOWCASE_CREATIVES',
    ACTIVATE_SHOWCASE_CREATIVES_ERROR: 'ACTIVATE_SHOWCASE_CREATIVES_ERROR',

    SHOWCASE_CREATIVES_LOADING_STATUS: "SHOWCASE_CREATIVES_LOADING_STATUS",

    EMPTY_SHOWCASE_CREATIVES: 'EMPTY_SHOWCASE_CREATIVES',


    BB_SHOWCASE_GET_ALL_DEALS_INIT: "BB_SHOWCASE_GET_ALL_DEALS_INIT",
    BB_SHOWCASE_GET_ALL_DEALS_SUCCESS: "BB_SHOWCASE_GET_ALL_DEALS_SUCCESS",
    BB_SHOWCASE_GET_ALL_DEALS_ERROR: "BB_SHOWCASE_GET_ALL_DEALS_ERROR",
    BB_SHOWCASE_EMPTY_ALL_DEALS: "BB_SHOWCASE_EMPTY_ALL_DEALS",

    BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_INIT: "BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_INIT",
    BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_SUCCESS: "BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_SUCCESS",
    BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_ERROR: "BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_ERROR",
    BB_SHOWCASE_EMPTY_ALL_CREATIVES_DEAL: "BB_SHOWCASE_EMPTY_ALL_CREATIVES_DEAL",

    BB_SHOWCASE_GET_ALL_ACCOUNTS_INIT: "BB_SHOWCASE_GET_ALL_ACCOUNTS_INIT",
    BB_SHOWCASE_GET_ALL_ACCOUNTS_SUCCESS: "BB_SHOWCASE_GET_ALL_ACCOUNTS_SUCCESS",
    BB_SHOWCASE_GET_ALL_ACCOUNTS_ERROR: "BB_SHOWCASE_GET_ALL_ACCOUNTS_ERROR",
    BB_SHOWCASE_EMPTY_ALL_ACCOUNTS: "BB_SHOWCASE_EMPTY_ALL_ACCOUNTS",

    GET_SHOWCASE_CREATIVE_PROFILE_ID: 'GET_SHOWCASE_CREATIVE_PROFILE_ID',
    GET_SHOWCASE_CREATIVE_PROFILE_ID_ERROR: 'GET_SHOWCASE_CREATIVE_PROFILE_ID_ERROR',
    BB_SHOWCASE_EMPTY_CREATIVE_PROFILE: 'BB_SHOWCASE_EMPTY_CREATIVE_PROFILE',
};
