import { ShowcaseBennchmarkConstants } from './benchmark.constants';

export const benchmark = (state = {}, action) => {

    switch (action.type) {

        case ShowcaseBennchmarkConstants.GET_ALL_SHOWCASE_BENCHMARK_INIT: return { ...state, all: { loading: true } }
        case ShowcaseBennchmarkConstants.GET_ALL_SHOWCASE_BENCHMARK_SUCCESS: return { ...state, all: action.payload }
        case ShowcaseBennchmarkConstants.GET_ALL_SHOWCASE_BENCHMARK_ERROR: return { ...state, all: action.payload }

        case ShowcaseBennchmarkConstants.GET_ALL_SHOWCASE_BENCHMARK_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case ShowcaseBennchmarkConstants.GET_ALL_SHOWCASE_BENCHMARK_PAGINATE_ERROR: return { ...state, ...action.payload }

        case ShowcaseBennchmarkConstants.EMPTY_SHOWCASE_BENCHMARK_BY_PROFILE: return { ...state, profile: "" }
        case ShowcaseBennchmarkConstants.GET_SHOWCASE_BENCHMARK_BY_ID: return { ...state, profile: action.payload }
        case ShowcaseBennchmarkConstants.GET_SHOWCASE_BENCHMARK_BY_ID_ERROR: return { ...state, profile: action.payload }

        case ShowcaseBennchmarkConstants.SHOWCASE_BENCHMARK_CURRENT_PAGE: return { ...state, page: action.payload }

        case ShowcaseBennchmarkConstants.SHOWCASE_BENCHMARK_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case ShowcaseBennchmarkConstants.SHOWCASE_BENCHMARK_SEARCH_VALUE: return { ...state, search: action.payload }

        case ShowcaseBennchmarkConstants.SHOWCASE_BENCHMARK_STATUS_VALUE: return { ...state, status: action.payload }

        case ShowcaseBennchmarkConstants.SHOWCASE_BENCHMARK_TOTAL_COUNT: return { ...state, total: action.payload }

        case ShowcaseBennchmarkConstants.SHOWCASE_BENCHMARK_LOADING_STATUS: return { ...state, loading: action.payload }

        case ShowcaseBennchmarkConstants.DELETE_SHOWCASE_BENCHMARK:
        case ShowcaseBennchmarkConstants.DELETE_SHOWCASE_BENCHMARK_ERROR:
            return { ...state, deleteStatus: action.payload }

        case ShowcaseBennchmarkConstants.ACTIVATE_SHOWCASE_BENCHMARK:
        case ShowcaseBennchmarkConstants.ACTIVATE_SHOWCASE_BENCHMARK_ERROR:
            return { ...state, activateStatus: action.payload }

        case ShowcaseBennchmarkConstants.ADD_SHOWCASE_BENCHMARK_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case ShowcaseBennchmarkConstants.ADD_SHOWCASE_BENCHMARK_ERROR: return { ...state, addNewStatus: false, ...action.payload }

        case ShowcaseBennchmarkConstants.EDIT_SHOWCASE_BENCHMARK_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case ShowcaseBennchmarkConstants.EDIT_SHOWCASE_BENCHMARK_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
