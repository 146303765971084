
import { combineReducers } from 'redux';
import { categories } from "./category/categories.reducer"
import { addons } from "./addons/addons.reducer"
import { adTypes } from './format-type/adType.reducer';
import { prebuiltMedias } from './prebuilt-media/prebuilt.reducer';
import { prebuiltMediasGMP } from "./prebuilt-media-gmp/prebuiltGMP.reducer"
import { bespokeDictionary } from "./bespoke-dictionary/bespokeDictionary.reducer"
import { dealTokenRefs } from './deal-token-reference/dealTokenRef.reducer';
import { masterSitelists } from './master-sitelist/masterSitelist.reducer';

export const curatedMediaSettings = combineReducers({
    categories,
    adTypes,
    addons,
    dealTokenRefs,
    prebuiltMedias,
    prebuiltMediasGMP,
    bespokeDictionary,
    masterSitelists
});

