

const convertHexToRGB = (hex) => {
    hex = hex.replace("#", "");
    if (!hex || hex.length !== 6) {
        return hex;
    }

    var aRgbHex = hex.match(/.{1,2}/g);
    var aRgb = [
        parseInt(aRgbHex[0], 16),
        parseInt(aRgbHex[1], 16),
        parseInt(aRgbHex[2], 16)
    ];

    return aRgb;
}

export const initTheme = (t) => {
    let link = "/favicon.ico";

    if (t.favicon_image) {
        link = t.favicon_image;
    }

    let favicon = document.getElementById("bb-favicon")
    if (favicon !== null) {
        favicon.href = link
    } else {
        favicon = document.createElement("link")
        favicon.rel = "shortcut icon";
        favicon.id = "bb-favicon";
        favicon.href = link;
        document.head.appendChild(favicon);
    }

    document.title = "Buddy - " + t.name;

    /* Dynamic theme */
    let root = document.querySelector(`:root`);
    root.style.setProperty('--bb-theme-color', t.primaryColor);
    root.style.setProperty('--bb-theme-color-rgb', convertHexToRGB(t.primaryColor));
    root.style.setProperty('--bb-text-color', t.primaryColor);
    root.style.setProperty('--bb-text-color-vendor', t.accentColor);
    //root.style.setProperty('--bb-bg-color', "#212121");

    root.style.setProperty('--bb-small-logo', `url("${t.logo}")`);

    //root.style.setProperty('--bb-bg-color-tertiary', "#263238");
    //root.style.setProperty('--bb-bg-color-tertiary-20', `rgba(${convertHexToRGB(t.primaryColor)},0.2)`);
    //root.style.setProperty('--bb-bg-color-quaternary', "#404040");
    //root.style.setProperty('--bb-blue-color', "#546e7a");
    root.style.setProperty('--bb-blue-color-secondary', "#19A4CF");

    /* --bb-theme-color: #E83457;
    --bb-theme-color-rgb: 232, 52, 87;
    --bb-text-color: #E83457;
    --bb-text-color-vendor: #FFF;
    --bb-bg-color: #1C2135; */
}
