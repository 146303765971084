import { ShowcaseCreativesConstants } from './creatives.constants';

export const creatives = (state = {}, action) => {

    switch (action.type) {

        case ShowcaseCreativesConstants.GET_ALL_SHOWCASE_CREATIVES_INIT: return { ...state, all: { loading: true } }
        case ShowcaseCreativesConstants.GET_ALL_SHOWCASE_CREATIVES_SUCCESS: return { ...state, all: action.payload }
        case ShowcaseCreativesConstants.GET_ALL_SHOWCASE_CREATIVES_ERROR: return { ...state, all: action.payload }

        case ShowcaseCreativesConstants.GET_ALL_SHOWCASE_CREATIVES_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case ShowcaseCreativesConstants.GET_ALL_SHOWCASE_CREATIVES_PAGINATE_ERROR: return { ...state, ...action.payload }

        case ShowcaseCreativesConstants.EMPTY_SHOWCASE_CREATIVES_BY_PROFILE: return { ...state, profile: "" }
        case ShowcaseCreativesConstants.GET_SHOWCASE_CREATIVES_BY_ID: return { ...state, profile: action.payload }
        case ShowcaseCreativesConstants.GET_SHOWCASE_CREATIVES_BY_ID_ERROR: return { ...state, profile: action.payload }

        case ShowcaseCreativesConstants.SHOWCASE_CREATIVES_CURRENT_PAGE: return { ...state, page: action.payload }

        case ShowcaseCreativesConstants.SHOWCASE_CREATIVES_LIMIT_PER_PAGE: return { ...state, limit: action.payload }

        case ShowcaseCreativesConstants.SHOWCASE_CREATIVES_SEARCH_VALUE: return { ...state, search: action.payload }

        case ShowcaseCreativesConstants.SHOWCASE_CREATIVES_STATUS_VALUE: return { ...state, status: action.payload }

        case ShowcaseCreativesConstants.SHOWCASE_CREATIVES_TOTAL_COUNT: return { ...state, total: action.payload }

        case ShowcaseCreativesConstants.SHOWCASE_CREATIVES_LOADING_STATUS: return { ...state, loading: action.payload }

        case ShowcaseCreativesConstants.DELETE_SHOWCASE_CREATIVES:
        case ShowcaseCreativesConstants.DELETE_SHOWCASE_CREATIVES_ERROR:
            return { ...state, deleteStatus: action.payload }

        case ShowcaseCreativesConstants.ACTIVATE_SHOWCASE_CREATIVES:
        case ShowcaseCreativesConstants.ACTIVATE_SHOWCASE_CREATIVES_ERROR:
            return { ...state, activateStatus: action.payload }

        case ShowcaseCreativesConstants.ADD_SHOWCASE_CREATIVES_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }

        case ShowcaseCreativesConstants.ADD_SHOWCASE_CREATIVES_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }

        case ShowcaseCreativesConstants.EDIT_SHOWCASE_CREATIVES_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }

        case ShowcaseCreativesConstants.EDIT_SHOWCASE_CREATIVES_ERROR: return { ...state, editStatus: false, editResp: action.payload }


        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_DEALS_INIT:
            return { ...state, dealRefs: { loading: action.payload } };
        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_DEALS_SUCCESS:
        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_DEALS_ERROR:
            return { ...state, dealRefs: { data: [...action.payload], loading: false } };
        case ShowcaseCreativesConstants.BB_SHOWCASE_EMPTY_ALL_DEALS:
            return { ...state, dealRefs: "" };

        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_INIT:
            return { ...state, dealCreatives: { loading: action.payload } };
        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_SUCCESS:
        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_CREATIVES_DEAL_ERROR:
            return { ...state, dealCreatives: { data: [...action.payload], loading: false } };
        case ShowcaseCreativesConstants.BB_SHOWCASE_EMPTY_ALL_CREATIVES_DEAL:
            return { ...state, dealCreatives: "" };

        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_ACCOUNTS_INIT:
            return { ...state, accounts: { loading: action.payload } };
        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_ACCOUNTS_SUCCESS:
        case ShowcaseCreativesConstants.BB_SHOWCASE_GET_ALL_ACCOUNTS_ERROR:
            return { ...state, accounts: { data: [...action.payload], loading: false } };
        case ShowcaseCreativesConstants.BB_SHOWCASE_EMPTY_ALL_ACCOUNTS:
            return { ...state, accounts: "" };

        case ShowcaseCreativesConstants.GET_SHOWCASE_CREATIVE_PROFILE_ID:
        case ShowcaseCreativesConstants.GET_SHOWCASE_CREATIVE_PROFILE_ID_ERROR:
        case ShowcaseCreativesConstants.BB_SHOWCASE_EMPTY_CREATIVE_PROFILE:
            return { ...state, creativeProfile: action.payload  };

        default:
            return state;
    }
};
