export const CategoryConstants = {
    GET_ALL_CATEGORY_INIT: 'GET_ALL_CATEGORY_INIT',
    GET_ALL_CATEGORY_SUCCESS: 'GET_ALL_CATEGORY_SUCCESS',
    GET_ALL_CATEGORY_ERROR: 'GET_ALL_CATEGORY_ERROR',

    GET_ALL_CATEGORY_PAGINATE_SUCCESS: 'GET_ALL_CATEGORY_PAGINATE_SUCCESS',
    GET_ALL_CATEGORY_PAGINATE_ERROR: 'GET_ALL_CATEGORY_PAGINATE_ERROR',

    CATEGORY_CURRENT_PAGE: 'CATEGORY_CURRENT_PAGE',
    CATEGORY_LIMIT_PER_PAGE: 'CATEGORY_LIMIT_PER_PAGE',
    CATEGORY_SEARCH_VALUE: 'CATEGORY_SEARCH_VALUE',
    CATEGORY_STATUS_VALUE: 'CATEGORY_STATUS_VALUE',
    CATEGORY_TOTAL_COUNT: 'CATEGORY_TOTAL_COUNT',
    
    GET_CATEGORY_BY_ID: 'GET_CATEGORY_BY_ID',
    EMPTY_CATEGORY_BY_PROFILE: 'EMPTY_CATEGORY_BY_PROFILE',
    GET_CATEGORY_BY_ID_ERROR: 'GET_CATEGORY_BY_ID_ERROR',

    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    ADD_CATEGORY_ERROR: 'ADD_CATEGORY_ERROR',

    EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
    EDIT_CATEGORY_ERROR: 'EDIT_CATEGORY_ERROR',

    ACTIVATE_CATEGORY: 'ACTIVATE_CATEGORY',
    ACTIVATE_CATEGORY_ERROR: 'ACTIVATE_CATEGORY_ERROR', 

    DELETE_CATEGORY: 'DELETE_CATEGORY',
    DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',
    
    CATEGORY_LOADING_STATUS: 'CATEGORY_LOADING_STATUS',

    EMPTY_CATEGORY: 'EMPTY_CATEGORY',
}