import React from 'react'
import classnames from 'classnames'
import { FormError } from '../FormError'
import Slider from 'rsuite/Slider';
import './index.scss';


class FormSlider extends React.Component {

    render() {
        const { disabled = false, field, containerClassName = "",
            form, min = 1, max = 5, step = 0.5, defaultValue = 1.5, labelClassName = "", inputContainerClassName = "", suffix = "",
            className = "", label, isOptional = false, form: { touched, errors },
            sliderClassName = ""
        } = this.props;
        return (
            <div className={containerClassName}>
                {label && <label className={classnames("mb-1 mr-2", labelClassName)}>{label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}</label>}
                <div className={inputContainerClassName}>
                    <Slider
                        value={field.value || defaultValue}
                        barClassName={classnames(className, touched[field.name] && errors[field.name] ? 'error' : '')}
                        disabled={disabled}
                        onChangeCommitted={async (number) => {
                            // eslint-disable-next-line    
                            await form.setFieldValue(field.name, number)
                            if (typeof this.props.showPreview === 'function') {
                                this.props.showPreview(this.props.values, true)
                            }
                        }}
                        onChange={async (number, event) => {
                            let isClick = event && event.type === "click" ? true : false;
                            // eslint-disable-next-line    
                            //if (!isDragging){
                            await form.setFieldValue(field.name, number)
                            if (typeof this.props.showPreview === 'function') {
                                this.props.showPreview(this.props.values, isClick)
                            }
                            //}

                        }}
                        min={min}
                        max={max}
                        step={step}
                        className={classnames("bb-custom-slider", sliderClassName)}
                        handleTitle={<span>{field.value || defaultValue}</span>}
                        handleStyle={{
                            borderRadius: "50%",
                            color: '#fff',
                            fontSize: 12,
                            width: 28,
                            height: 28
                        }}
                        tooltip={false}
                        progress={true}
                    />
                    

                    <FormError name={field.name} />

                </div>
                <span className='m-1 text-center font-weight-bold bb-color_semi-muted'>{field.value || defaultValue} {suffix}</span>
            </div>
        )

    }
}

export default FormSlider