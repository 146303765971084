import React from 'react'
import classnames from 'classnames'
import {FormError} from './FormError'
import Dropdown from "../utilities/react-dropdown"

const ReactDropdown = ({ placeholder, disabled,  datas, field, form, onChange, selectClassName, containerClassName, inputContainerClassName, labelClassName, className, label, isOptional = false, form: { touched, errors } }) => {
    return (
        <div className={containerClassName}>
            {label && <label className={classnames("pt-2 mb-1", labelClassName)}>{label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}</label>}
            <div className={inputContainerClassName}>
                <Dropdown
                    onChange={async (value) => {
                        await form.setFieldValue(field.name, value);
                        if (onChange) { onChange(value, form.setFieldValue,value); }
                        }
                        }        
                    disabled={disabled}            
                    name= {field.name}
                    value = {field.value}
                    onBlur={field.onBlur}
                    className={classnames(selectClassName, 'bb-select-input form-control', touched[field.name] && errors[field.name] ? 'error' : '')} 
                    options={datas}
                />
                <div className="mt-2">
                    <FormError name={field.name}/>
                </div>
            </div>            
        </div>
    )
}

export default ReactDropdown