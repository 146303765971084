import { LumenReportConstants } from './report.constants';

export const report = (state = {}, action) => {
    switch (action.type) {
        case LumenReportConstants.GET_ALL_LUMEN_REPORT_SUCCESS:
            return {
                ...state,
                ...action.payload
            };

        case LumenReportConstants.ALL_LUMEN_REPORT_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case LumenReportConstants.GET_ALL_LUMEN_REPORT_ERROR:
            return {
                ...state,
                error: true
            };
        case LumenReportConstants.ALL_LUMEN_REPORT_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case LumenReportConstants.ALL_LUMEN_REPORT_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case LumenReportConstants.ALL_LUMEN_REPORT_LIMIT:
            return {
                ...state,
                total: action.payload
            };
        default:
            return state;
    }
}