import React from 'react'
import './index.scss';
import classnames from 'classnames';

export const renderFormatGuide = (containerClassName = "") => {
    return (
        <div className={classnames("d-flex flex-row justify-content-start", containerClassName)} >
            <img src="/images/icons/budster-head.png" alt="" />
            <div className="align-self-center">
                <p className="bb-text_small p-0 m-0">
                    Unsure which ad format to use? <br />
                    <span className="hvr-underline-from-center font-weight-bold bb-text_small"
                        onClick={() => { window.open(window.location.origin + ("/download/BIG Format Recommendations.pdf"), "_blank") }}
                    >Click here</span></p>
            </div>
        </div>
    )
}


export const renderScrolloGuide = (containerClassName = "") => {
    return (
        <div className={classnames("d-flex flex-row justify-content-start", containerClassName)} >
            {/* <img src="/images/icons/budster-head.png" alt="" /> */}
            <div className="align-self-center">
                <p className="bb-text_small p-0 m-0">
                    <span className="hvr-underline-from-center font-weight-bold bb-text_small"
                        onClick={() => { window.open("https://docs.google.com/presentation/d/e/2PACX-1vQk4honPiw8j-kAUtpRu0Ne5lJgMxKr5avCT5fmJnwHjO6zFV9DmsppAt_hPdXYq2TDut5rRsZ9QX62/pub?start=false&loop=false&delayms=30000", "_blank") }}
                    >Click here </span>
                    for instructions on how to build a Scrollo
                </p>
            </div>
        </div>
    )
}

export const renderDimensionalGuide = (LeftToolTip, TopToolTip) => {
    return (
        <div className="d-flex justify-content-start" >
            {LeftToolTip}
            <br />
            {TopToolTip}
        </div>
    )

}


export const renderNoAddonInfo = (containerClassName = "") => {
    return (
        <div className={classnames("d-flex flex-row justify-content-start", containerClassName)} >
            <div className="align-self-center">
                <p className="bb-text_small p-0 m-0">
                    Attention Time Reporting and Optimisation
                    isn’t available for this format yet</p>
            </div>
        </div>
    )
}


export const renderAddonMessage = (containerClassName = "custom-tip", e) => {

    if (document && document.getElementById("hover-text" + e)) {
        let z = 9999 - e;
        document.getElementById("hover-text"+ e).parentElement.style.zIndex = z
    }

    return (
        <div className={classnames("flex-row justify-content-start ", containerClassName)} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
        }}>
            <div className="align-self-center">
                <p className="bb-text_small p-0 m-0">
                    This add-on is available via managed service only.
                    <a
                        className='mailto'
                        href='mailto:sales@bigads.co'
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            window.location.href = "mailto:sales@bigads.co";
                        }}
                    >
                        {" "}<strong className='hvr-underline-from-center text-white'>Please get in touch to book.</strong>
                    </a>
                </p>
            </div>
        </div>
    )
}