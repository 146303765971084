import React from "react";
import { getDayName, getDateNumber, getMonthName, getDiffInHours } from './components/utilities/customFunctions'
import { confirmAlert } from 'react-confirm-alert'

export const renderMaintenanceInfo = (m) => {
    return (
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='bb-confirm-box' style={{ maxWidth: "470px" }}>
                        <h4 className="pb-3 ft-title font-weight-bold">SCHEDULED MAINTENANCE</h4>
                        <div>Buddy is offline for scheduled maintenance on 
                            <b>{` ${getDayName(m.date)} ${getDateNumber(m.date)} ${getMonthName(m.date)} `}</b> 
                            at <b>{` ${m.start_time} AEST `}</b>
                            for approximately <b>{` ${getDiffInHours(m.start_time, m.end_time)} `}</b> hour(s). We are using this time to add new features 
                            to ensure Buddy continues to make digital advertising easier, 
                            faster and more effective for you.</div>
                        <div className="d-flex flex-row justify-content-end">
                            <button className="bb-button mr-3" onClick={() => { onClose(); localStorage.setItem("bb-maintenance", "ack~" + m.id);
                            }}>Okay</button>
                        </div>
                    </div>
                )
            }
        })
    )
}
