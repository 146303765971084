import { BillingConstants } from './billing.constants';

export const billings = (state = {}, action) => {

    switch (action.type) {

        case BillingConstants.BILLING_REPORT_INIT: return { ...state, billing: { loading: true } }
        case BillingConstants.BILLING_REPORT_SUCCESS: return { ...state, billing: { loading: false, ...action.payload } }
        case BillingConstants.BILLING_REPORT_ERROR: return { ...state, billing: { loading: false, error: action.payload } }

        case BillingConstants.BILLING_REPORT_CSV_INIT: return { ...state, billingCSV: { loading: true } }
        case BillingConstants.BILLING_REPORT_CSV_SUCCESS: return { ...state, billingCSV: { loading: false, ...action.payload, date: new Date() } }
        case BillingConstants.BILLING_REPORT_CSV_ERROR: return { ...state, billingCSV: { loading: false, error: action.payload, date: new Date() } }
        case BillingConstants.EMPTY_BILLING_REPORT_CSV: return { ...state, billingCSV: {} }

        case BillingConstants.AIRTORY_BILLING_REPORT_INIT: return { ...state, airtoryBilling: { loading: true } }
        case BillingConstants.AIRTORY_BILLING_REPORT_SUCCESS: return { ...state, airtoryBilling: { loading: false, ...action.payload } }
        case BillingConstants.AIRTORY_BILLING_REPORT_ERROR: return { ...state, airtoryBilling: { loading: false, error: action.payload } }

        case BillingConstants.AIRTORY_BILLING_REPORT_CSV_INIT: return { ...state, airtoryBillingCSV: { loading: true } }
        case BillingConstants.AIRTORY_BILLING_REPORT_CSV_SUCCESS: return { ...state, airtoryBillingCSV: { loading: false, ...action.payload, date: new Date() } }
        case BillingConstants.AIRTORY_BILLING_REPORT_CSV_ERROR: return { ...state, airtoryBillingCSV: { loading: false, error: action.payload, date: new Date() } }
        case BillingConstants.EMPTY_AIRTORY_BILLING_REPORT_CSV: return { ...state, airtoryBillingCSV: {} }

        default:
            return state;
    }
};
