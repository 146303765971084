import React from 'react'
import { ToastContainer } from "react-toastify"

import './toastify.scss'
import "react-toastify/ReactToastify.min.css";

const Toastify = () => {
    return (
        <ToastContainer
            position="top-right" autoClose={5000}
            icon={false} theme="colored"
        />
    )
}

export default Toastify