import axios from 'axios';

import { ThemeConstants } from './theme.constants'
import { config } from '../../helpers'

const ROOT_URL = config.apiServer;

/* For whitelabel */

const loading = (status) => {
    return {
        type: ThemeConstants.GET_THEME_INFO_LOADING,
        payload: status
    };
};

/* const re = {
    name: "Undertaker",
    logo: "https://s3-ap-southeast-2.amazonaws.com/demo.bigmobile.com/creative_assets/_Sagar/undertaker/logo.png",
    favicon_image: "https://s3-ap-southeast-2.amazonaws.com/demo.bigmobile.com/creative_assets/_Sagar/undertaker/logo.png",
    primaryColor: "#9100df",
    accentColor: "#000"
} */
export const getInfo = () => {
    return (dispatch) => {
        dispatch(loading(true));
     /*    setTimeout(function(){
            dispatch({ type: ThemeConstants.GET_THEME_INFO, payload: re });
            dispatch(loading(false));
        }, 3000) 
    */
        
        axios.post(`${ROOT_URL}/info`, {})
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: ThemeConstants.GET_THEME_INFO, payload: response.data.data });
                }
                else {
                    dispatch({ type: ThemeConstants.GET_THEME_INFO_ERROR, payload: "" });
                }

                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: ThemeConstants.GET_THEME_INFO_ERROR, payload: "" });
                dispatch(loading(false));
            });
    };
};