import React, { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import classnames from 'classnames'
import Tooltip from '../utilities/Tooltip'
import { FormError } from './FormError'

const NumberFormatInput = ({ disabled, field, containerClassName,
    labelClassName, inputContainerClassName, className,
    suffixLabel = false, suffixLabelClassname,
    prefixLabel = false, prefixLabelClassname,
    prefixIconClassname = "",
    thousandSeparator = true,
    allowNegative = false,
    onBlur = false,
    prefix = "",
    suffix = "",
    decimalScale = 5,
    minValue = false,
    maxValue = false,
    tooltip = false,
    light = false,
    form: { setFieldTouched },
    label, placeholder, isOptional = false, form: { touched, errors, setFieldValue } }) => {

    const [numValue, setNumValue] = useState(field.value)
    
    const renderField = () => {
        return (<>
            <NumericFormat
                className={classnames("form-control", className, touched[field.name] && errors[field.name] ? 'error' : '', prefixLabel || prefixIconClassname ? 'input-no-left-border-radius' : '')}
                {...field}
                thousandsGroupStyle="thousand"
                thousandSeparator={thousandSeparator ? "," : ""}
                allowNegative={allowNegative}
                placeholder={placeholder ? placeholder : label}
                value={field.value}
                disabled={disabled}
                //prefix={prefix}
                //suffix={suffix}
                decimalScale={decimalScale}
                onFocus={f => setFieldTouched(field.name, true, true)}
                valueIsNumericString={true}
                // onChange={(e) => {
                //     let v = e.target.value
                //     if (v && typeof v === "string") {
                //         v = v.replace(/,/g, "")
                //     }
                //     let newVal = v
                //     if (typeof minValue == "number" && typeof maxValue == "number") {
                //         if (Number(v) >= Number(maxValue)) {
                //             newVal = Number(maxValue) - 1
                //         }
                //         else if (Number(v) <= Number(minValue)) {
                //             newVal = Number(minValue) + 1
                //         }
                //     }
                //     else if (typeof minValue == "number") {
                //         if (Number(v) <= Number(minValue)) {
                //             newVal = Number(minValue) + 1
                //         }
                //     }
                //     else if (typeof minValue == "number") {
                //         if (Number(v) >= Number(maxValue)) {
                //             newVal = Number(maxValue) - 1
                //         }
                //     }
                //     setFieldValue(field.name, newVal.toString())
                // }}
                isAllowed={(values) => {
                    let { floatValue } = values;
                    let returnVal = (!minValue && !maxValue) || floatValue == null ||
                        ((minValue == null || floatValue > minValue) &&
                        (maxValue == null || floatValue < maxValue));

                    if (!returnVal) {
                        if (typeof minValue === "number" && floatValue <= minValue) {
                            floatValue = minValue + 1;
                        }
                        if (typeof maxValue === "number" && floatValue >= maxValue) {
                            floatValue = maxValue - 1;
                        }
                    }
                    setFieldValue(field.name, floatValue ? floatValue.toString() : floatValue)

                    return returnVal;
                }}
                onChange={(e) => {}}
                onBlur={(e) => {
                    //eslint-disable-next-line
                    if (onBlur && (e.target.value != numValue)) {
                        onBlur(e.target.value);
                        setNumValue(e.target.value);
                    }
                }
                }
            />
        </>)
    }
    return (
        <div className={containerClassName}>
            {!tooltip ?
                <label className={classnames("mb-1 pt-2", labelClassName)}>
                    {label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}
                </label>
                :
                <Tooltip light={light} className={classnames("mb-1 cursor-pointer", labelClassName, "pt-1")} label={label} htmlFor={field.name} iconname="fa-info-circle">
                    <div className="p-2 text-left maxWidth-300"><span>{tooltip}</span></div>
                </Tooltip>
            }

            <div className={classnames(inputContainerClassName, "pr-2")}>
                {prefixLabel || prefixIconClassname ?
                    <div className="prefix-container">
                        <div className={classnames("input-group-prepend")}>
                            <span className={classnames("input-group-text border-right-0", prefixLabelClassname, errors[field.name] ? 'error' : '')}><span className={prefixIconClassname}>{prefixLabel}</span></span>
                        </div>
                    {renderField()}
                    </div>
                    : renderField()
                }
                <FormError name={field.name} />
            </div>
            {suffixLabel ?
                <label className={classnames("mb-1 pt-2", suffixLabelClassname)}>
                    {suffixLabel}
                </label>
                : null

            }
        </div>
    )
}
export default NumberFormatInput