import { PerformanceProgramsConstants } from './performancePrograms.constants';

export const performancePrograms = (state = {}, action) => {
    switch (action.type) {
        case PerformanceProgramsConstants.PERFORMANCE_PROGRAMS_LOADING: return { ...state, loading: action.payload };
        case PerformanceProgramsConstants.GET_PERFORMANCE_PROGRAMS_SUCCESS: return { ...state, performanceData: action.payload };
        case PerformanceProgramsConstants.GET_PERFORMANCE_PROGRAMS_ERROR: return { ...state, performanceError: true };

        case PerformanceProgramsConstants.GET_PERFORMANCE_SPENDING_SUCCESS: return { ...state, spendingData: action.payload };
        case PerformanceProgramsConstants.GET_PERFORMANCE_SPENDING_ERROR: return { ...state, spendingError: true };
        
        case PerformanceProgramsConstants.REQUEST_PERFORMANCE_DEMO_SUCCESS: return { ...state, requestStatus: true, requestResp: action.payload, programDesc: action.programDesc }
        case PerformanceProgramsConstants.REQUEST_PERFORMANCE_DEMO_ERROR: return { ...state, requestStatus: false, requestResp: action.payload }

        default: return state;
    }
}