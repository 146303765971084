import { RubiconConstants } from './rubicon.constants';

export const rubicon = (state = {}, action) => {

    switch (action.type) {
        
        /* Rubicon buyer seta */
        case RubiconConstants.GET_BUYER_SEATS_FROM_RB_INIT: return { ...state, seats: {loading: true} }
        
        case RubiconConstants.GET_BUYER_SEATS_FROM_RB_SUCCESS:  
        case RubiconConstants.GET_BUYER_SEATS_FROM_RB_ERROR:  
            return { ...state, seats: action.payload }

        default:
            return state;
    }
};
