import { ContextualConstants } from "./contextual.constants";

export const contextuals = (state = {}, action) => {
    switch (action.type) {
        case ContextualConstants.GET_ALL_CONTEXTUAL_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case ContextualConstants.GET_ALL_CONTEXTUAL_PAGINATE_ERROR: return { ...state, ...action.payload }

        case ContextualConstants.EMPTY_CONTEXTUAL_BY_ID: return { ...state, profile: "" }
        case ContextualConstants.GET_CONTEXTUAL_BY_ID: return { ...state, profile: action.payload }
        case ContextualConstants.GET_CONTEXTUAL_BY_ID_ERROR: return { ...state, profile: action.payload }

        case ContextualConstants.CONTEXTUAL_CURRENT_PAGE: return { ...state, page: action.payload }
        case ContextualConstants.CONTEXTUAL_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case ContextualConstants.CONTEXTUAL_SEARCH_VALUE: return { ...state, search: action.payload }
        case ContextualConstants.CONTEXTUAL_STATUS_VALUE: return { ...state, status: action.payload }
        case ContextualConstants.CONTEXTUAL_TOTAL_COUNT: return { ...state, total: action.payload }

        case ContextualConstants.CONTEXTUAL_LOADING_STATUS: return { ...state, loading: action.payload }
        
        case ContextualConstants.EDIT_CONTEXTUAL_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case ContextualConstants.EDIT_CONTEXTUAL_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        case ContextualConstants.SYNC_CONTEXTUAL_SUCCESS: return { ...state, syncStatus: true, syncResp: action.payload }
        case ContextualConstants.SYNC_CONTEXTUAL_ERROR: return { ...state, syncStatus: false, syncResp: action.payload }

        case ContextualConstants.EMPTY_CONTEXTUAL_STATUS_LOG: return { ...state, log: "" }
        case ContextualConstants.GET_CONTEXTUAL_STATUS_LOG: return { ...state, log: action.payload }
        case ContextualConstants.GET_CONTEXTUAL_STATUS_LOG_ERROR: return { ...state, log: action.payload }
        case ContextualConstants.CONTEXTUAL_STATUS_LOG_LOADING_STATUS: return { ...state, status_loading: action.payload }

        default:
            return state;
    }
};
