import { RequestConstants } from './requests.constants';

export const requests = (state = {}, action) => {
    switch (action.type) {
        case RequestConstants.EMPTY_ALL_REQUESTS:
            return { ...state, data: "" };
        case RequestConstants.GET_ALL_REQUEST:
            return { ...state, ...action.payload };

        case RequestConstants.ALL_REQUEST_LOADING:
            return { ...state, loading: action.payload };

        case RequestConstants.GET_ALL_REQUEST_ERROR:
            return { ...state, error: true };

        case RequestConstants.ALL_REQUEST_CURRENT_PAGE:
            return { ...state, page: action.payload };

        case RequestConstants.ALL_REQUEST_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload };

        case RequestConstants.ALL_REQUEST_SEARCH_VALUE:
            return { ...state, name: action.payload };

        case RequestConstants.ALL_REQUEST_STATUS_VALUE:
            return { ...state, status: action.payload };

        case RequestConstants.ALL_REQUEST_LIMIT:
            return { ...state, total_rows: action.payload };

        case RequestConstants.CHECK_REQUEST_SUCCESS:
            return { ...state, requestDelete: action.payload };

        case RequestConstants.CHECK_REQUEST_ERROR:
            return { ...state, requestDelete: action.payload };

        case RequestConstants.GET_ALL_LATEST_REQUEST:
            return { ...state, ...action.payload };

        case RequestConstants.GET_ALL_LATEST_REQUEST_ERROR:
            return { ...state, error: true };

        case RequestConstants.ALL_LATEST_REQUEST_CURRENT_PAGE:
            return { ...state, page: action.payload };

        case RequestConstants.ALL_LATEST_REQUEST_LIMIT_PER_PAGE:
            return { ...state, limit: action.payload };

        case RequestConstants.ALL_LATEST_REQUEST_SEARCH_VALUE:
            return { ...state, name: action.payload };

        case RequestConstants.ALL_LATEST_REQUEST_STATUS_VALUE:
            return { ...state, status: action.payload };

        case RequestConstants.ALL_LATEST_REQUEST_LIMIT:
            return { ...state, total_rows: action.payload };

        case RequestConstants.BB_GET_DATA_MODAL_SUCCESS:
            return { ...state, ...action.payload };

        case RequestConstants.BB_GET_DATA_MODAL_ERROR:
            return { ...state, ...action.payload };

        case RequestConstants.MODAL_LOADING:
            return { ...state, modalLoaidng: action.payload };

        case RequestConstants.ARCHIVE_STATUS_REQUEST_SUCCESS:
        case RequestConstants.ARCHIVE_STATUS_REQUEST_ERROR:
            return { ...state, deleteStatus: action.payload }

        case RequestConstants.ACTIVATE_STATUS_REQUEST_SUCCESS:
        case RequestConstants.ACTIVATE_STATUS_REQUEST_ERROR:
            return { ...state, activateStatus: action.payload }
        default:
            return state;
    }
}
