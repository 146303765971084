export const SegmentCategoryConstants = {
    GET_ALL_SEGMENT_CATEGORY_INIT: 'GET_ALL_SEGMENT_CATEGORY_INIT',
    GET_ALL_SEGMENT_CATEGORY_SUCCESS: 'GET_ALL_SEGMENT_CATEGORY_SUCCESS',
    GET_ALL_SEGMENT_CATEGORY_ERROR: 'GET_ALL_SEGMENT_CATEGORY_ERROR',

    GET_ALL_SEGMENT_CATEGORY_PAGINATE_SUCCESS: 'GET_ALL_SEGMENT_CATEGORY_PAGINATE_SUCCESS',
    GET_ALL_SEGMENT_CATEGORY_PAGINATE_ERROR: 'GET_ALL_SEGMENT_CATEGORY_PAGINATE_ERROR',

    SEGMENT_CATEGORY_CURRENT_PAGE: 'SEGMENT_CATEGORY_CURRENT_PAGE',
    SEGMENT_CATEGORY_LIMIT_PER_PAGE: 'SEGMENT_CATEGORY_LIMIT_PER_PAGE',
    SEGMENT_CATEGORY_SEARCH_VALUE: 'SEGMENT_CATEGORY_SEARCH_VALUE',
    SEGMENT_CATEGORY_STATUS_VALUE: 'SEGMENT_CATEGORY_STATUS_VALUE',
    SEGMENT_CATEGORY_TOTAL_COUNT: 'SEGMENT_CATEGORY_TOTAL_COUNT',
    
    GET_SEGMENT_CATEGORY_BY_ID: 'GET_SEGMENT_CATEGORY_BY_ID',
    EMPTY_SEGMENT_CATEGORY_BY_PROFILE: 'EMPTY_SEGMENT_CATEGORY_BY_PROFILE',
    GET_SEGMENT_CATEGORY_BY_ID_ERROR: 'GET_SEGMENT_CATEGORY_BY_ID_ERROR',

    ADD_SEGMENT_CATEGORY_SUCCESS: 'ADD_SEGMENT_CATEGORY_SUCCESS',
    ADD_SEGMENT_CATEGORY_ERROR: 'ADD_SEGMENT_CATEGORY_ERROR',

    EDIT_SEGMENT_CATEGORY_SUCCESS: 'EDIT_SEGMENT_CATEGORY_SUCCESS',
    EDIT_SEGMENT_CATEGORY_ERROR: 'EDIT_SEGMENT_CATEGORY_ERROR',

    ACTIVATE_SEGMENT_CATEGORY: 'ACTIVATE_SEGMENT_CATEGORY',
    ACTIVATE_SEGMENT_CATEGORY_ERROR: 'ACTIVATE_SEGMENT_CATEGORY_ERROR', 

    DELETE_SEGMENT_CATEGORY: 'DELETE_SEGMENT_CATEGORY',
    DELETE_SEGMENT_CATEGORY_ERROR: 'DELETE_SEGMENT_CATEGORY_ERROR',
    
    SEGMENT_CATEGORY_LOADING_STATUS: 'SEGMENT_CATEGORY_LOADING_STATUS',

    EMPTY_SEGMENT_CATEGORY: 'EMPTY_SEGMENT_CATEGORY',
}