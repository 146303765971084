import { AdTypeFormatConstants } from "./adTypeFormat.constants";

export const adTypeFormat = (state = {}, action) => {
    switch (action.type) {
        case AdTypeFormatConstants.GET_ALL_AD_TYPE_FORMAT:
            return {
                ...state,
                ...action.payload
            };

        case AdTypeFormatConstants.ALL_AD_TYPE_FORMAT_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case AdTypeFormatConstants.GET_ALL_AD_TYPE_FORMAT_ERROR:
            return {
                ...state,
                error: true
            };
        case AdTypeFormatConstants.ALL_AD_TYPE_FORMAT_CURRENT_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case AdTypeFormatConstants.ALL_AD_TYPE_FORMAT_LIMIT_PER_PAGE:
            return {
                ...state,
                limit: action.payload
            };
        case AdTypeFormatConstants.ALL_AD_TYPE_FORMAT_SEARCH_VALUE:
            return {
                ...state,
                name: action.payload
            };
        case AdTypeFormatConstants.ALL_AD_TYPE_FORMAT_STATUS_VALUE:
            return {
                ...state,
                status: action.payload
            };
        case AdTypeFormatConstants.ALL_AD_TYPE_FORMAT_LIMIT:
            return {
                ...state,
                total_rows: action.payload
            };
        case AdTypeFormatConstants.DELETE_AD_TYPE_FORMAT_SUCCESS:
            return {
                ...state,
                adTypeFormatDelete: action.payload
            };
        case AdTypeFormatConstants.DELETE_AD_TYPE_FORMAT_ERROR:
            return {
                ...state,
                adTypeFormatDelete: action.payload
            };
        case AdTypeFormatConstants.ACTIVATE_AD_TYPE_FORMAT_SUCCESS:
            return {
                ...state,
                adTypeFormatActivate: action.payload
            };
        case AdTypeFormatConstants.ACTIVATE_AD_TYPE_FORMAT_ERROR:
            return {
                ...state,
                adTypeFormatActivate: action.payload
            };
        case AdTypeFormatConstants.BB_ADD_AD_TYPE_FORMAT_SUCCESS:
            return {
                ...state,
                addAdTypeFormatSuccess: true,
                addResp: action.payload
            };
        case AdTypeFormatConstants.BB_ADD_AD_TYPE_FORMAT_ERROR:
            return {
                ...state,
                addAdTypeFormatSuccess: false,
                ...action.payload
            };
        case AdTypeFormatConstants.BB_ADD_AD_TYPE_FORMAT_LOADING:
            return {
                ...state,
                addAdTypeFormatLoading: action.payload
            };
        case AdTypeFormatConstants.BB_EDIT_AD_TYPE_FORMAT_SUCCESS:
            return {
                ...state,
                editAdTypeFormatSuccess: true,
                editResp: action.payload
            };
        case AdTypeFormatConstants.BB_EDIT_AD_TYPE_FORMAT_ERROR:
            return {
                ...state,
                editAdTypeFormatSuccess: false,
                ...action.payload
            };
        case AdTypeFormatConstants.BB_GET_ALL_AD_TYPE_FORMAT_INIT:
            return { ...state, dropDown: { loading: action.payload } };
        case AdTypeFormatConstants.EMPTY_GET_ALL_AD_TYPE_FORMAT:
            return { ...state, dropDown: ""  };    
        case AdTypeFormatConstants.BB_GET_ALL_AD_TYPE_FORMAT_SUCCESS:
        case AdTypeFormatConstants.BB_GET_ALL_AD_TYPE_FORMAT_ERROR:
            return { ...state, dropDown: { data: [...action.payload], loading: false } };
    
        case AdTypeFormatConstants.BB_GET_AD_TYPE_FORMAT_BY_ID:
            return { ...state, profile: null };
        case AdTypeFormatConstants.EMPTY_GET_AD_TYPE_FORMAT_BY_ID:
            return { ...state, profile: ""  };    
        case AdTypeFormatConstants.BB_GET_AD_TYPE_FORMAT_BY_ID_SUCCESS:
        case AdTypeFormatConstants.BB_GET_AD_TYPE_FORMAT_BY_ID_ERROR:
            return { ...state, profile: action.payload};

        default:
            return state;
    }
}