
import { AllAddOnConstants } from './addon.constants';

export const AddOn = (state = {}, action) => {
    switch (action.type) {
        case AllAddOnConstants.EMPTY_ADD_ON:
           return { ...state, data: "" };
        case AllAddOnConstants.GET_ADD_ON:
            return { ...state, ...action.payload, addOnChange: Date.now() + "Suceess"};

        case AllAddOnConstants.ADD_ON_LOADING:
            return { ...state, loading: action.payload };
            
        case AllAddOnConstants.GET_ADD_ON_ERROR:
            return { ...state, ...action.payload, addOnChange: Date.now() + "False" };

        default:
            return state;
    }
}
