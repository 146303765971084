export const PrebuiltGMPConstants = {
    GET_ALL_PREBUILT_GMP_INIT: 'GET_ALL_PREBUILT_GMP_INIT',
    GET_ALL_PREBUILT_GMP_SUCCESS: 'GET_ALL_PREBUILT_GMP_SUCCESS',
    GET_ALL_PREBUILT_GMP_ERROR: 'GET_ALL_PREBUILT_GMP_ERROR',

    GET_ALL_PREBUILT_GMP_PAGINATE_SUCCESS: 'GET_ALL_PREBUILT_GMP_PAGINATE_SUCCESS',
    GET_ALL_PREBUILT_GMP_PAGINATE_ERROR: 'GET_ALL_PREBUILT_GMP_PAGINATE_ERROR',

    PREBUILT_GMP_CURRENT_PAGE: 'PREBUILT_GMP_CURRENT_PAGE',
    PREBUILT_GMP_LIMIT_PER_PAGE: 'PREBUILT_GMP_LIMIT_PER_PAGE',
    PREBUILT_GMP_SEARCH_VALUE: 'PREBUILT_GMP_SEARCH_VALUE',
    PREBUILT_GMP_STATUS_VALUE: 'PREBUILT_GMP_STATUS_VALUE',
    PREBUILT_GMP_TOTAL_COUNT: 'PREBUILT_GMP_TOTAL_COUNT',
    
    GET_PREBUILT_GMP_BY_ID: 'GET_PREBUILT_GMP_BY_ID',
    EMPTY_PREBUILT_GMP_BY_PROFILE: 'EMPTY_PREBUILT_GMP_BY_PROFILE',
    GET_PREBUILT_GMP_BY_ID_ERROR: 'GET_PREBUILT_GMP_BY_ID_ERROR',

    ADD_PREBUILT_GMP_SUCCESS: 'ADD_PREBUILT_GMP_SUCCESS',
    ADD_PREBUILT_GMP_ERROR: 'ADD_PREBUILT_GMP_ERROR',

    EDIT_PREBUILT_GMP_SUCCESS: 'EDIT_PREBUILT_GMP_SUCCESS',
    EDIT_PREBUILT_GMP_ERROR: 'EDIT_PREBUILT_GMP_ERROR',

    ACTIVATE_PREBUILT_GMP: 'ACTIVATE_PREBUILT_GMP',
    ACTIVATE_PREBUILT_GMP_ERROR: 'ACTIVATE_PREBUILT_GMP_ERROR', 

    DELETE_PREBUILT_GMP: 'DELETE_PREBUILT_GMP',
    DELETE_PREBUILT_GMP_ERROR: 'DELETE_PREBUILT_GMP_ERROR',
    
    PREBUILT_GMP_LOADING_STATUS: 'PREBUILT_GMP_LOADING_STATUS',

    EMPTY_PREBUILT_GMP: 'EMPTY_PREBUILT_GMP',
}